import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { Events } from '@constants';
import { IPoi, IPoiCategory, IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";
import { get, poisByCategory } from "../../api";
import MarkerList from "../MarkerList";
import { projectData } from "../../constants";
import { checkMarkerStaffOnlyToShow } from "../../helpers/utils";

export interface ICategoryItems {
    project: IProject;
    pois: IPoi [];
    floor: number;
    changeFloor: (floor: number) => void;
    cname: any;
    fromFilter?: boolean;
    fromNavigate?: boolean;
    navigatePoint?: string;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    facilityNames?: any;
    category?: IPoiCategory;
}

const CategoryItems: FC<ICategoryItems> = (props: PropsWithChildren<ICategoryItems>) => {
    const {
        project,
        changeFloor,
        floor,
        cname,
        pois,
        fromNavigate,
        navigatePoint,
        fromPoi,
        toPoi,
        facilityNames,
        category
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const currentMarkers: MutableRefObject<any> = React.useRef([]);
    const [loading, setLoading] = React.useState(true);

    const renderCategoryPoiList = React.useCallback(() => {
        if (currentMarkers.current.length) {
            return <MarkerList markers={currentMarkers.current}
                               project={project}
                               pois={pois}
                               sort={true}
                               floor={floor}
                               changeFloor={changeFloor}
                               fromCategory={true}
                               cname={cname}
                               fromNavigate={fromNavigate}
                               navigatePoint={navigatePoint}
                               fromPoi={fromPoi}
                               toPoi={toPoi}
                               facilityNames={facilityNames}
                               category={category}
                    />
        }

        return (<p className="no-poi-category">{(window as any).lang.getString('categoryItems_noPointsOfInterest')}</p>);
    }, [
        project,
        pois,
        floor,
        changeFloor,
        fromNavigate,
        navigatePoint,
        fromPoi,
        toPoi,
        facilityNames,
        cname,
        category
    ]);

    const loadCategoryPois = React.useCallback(() => {
        Emitter.emit(Events.SIDEBAR_LOADING, true);

        let prefix: string = '';
        if(category && !category.filter) {
            prefix = encodeURIComponent('##');
        }

        if(category && !category.menu) {
            prefix = encodeURIComponent('$$');
        }

        const cnameEncoded: string = encodeURIComponent(cname);
        const path: string = poisByCategory(project.pid, project.userLang, `${prefix}${cnameEncoded}`);
        get(path, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data: any) => {
            if(data.parsedBody.status === 500) {
                currentMarkers.current = [];
                Emitter.emit(Events.SIDEBAR_LOADING, false);
                setLoading(false);

                return;
            }

            const jdata = data.parsedBody.content;
            const userLang = jdata.some((item: any) => item.lang === project.userLang) ? project.userLang : projectData.defaultLang;
            // Add category pois to current marker array (only if languages and visible match)
            for (let i = 0; i < jdata.length; i++) {
                if (jdata[i].fsmAction) {
                    const bookedPoidInLocal = localStorage.getItem("myBookedPoid") || '';
                    if (jdata[i].poid === bookedPoidInLocal && jdata[i].fsmAction.fsmFacilityState
                        && jdata[i].fsmAction.fsmFacilityState.fsmState
                        && jdata[i].fsmAction.fsmFacilityState.fsmState.id === 1
                    ) {
                        localStorage.setItem("myBookedPoid", "");
                        localStorage.setItem("bookedPOI", "");
                    } else if (jdata[i].fsmAction.fsmFacilityState
                        && jdata[i].fsmAction.fsmFacilityState.fsmState
                        && jdata[i].fsmAction.fsmFacilityState.fsmState.id === 2
                        && jdata[i].fsmAction.activator === localStorage.getItem("samlNameId") || ''
                    ) {
                        localStorage.setItem("myBookedPoid", jdata[i].poid);
                        localStorage.setItem("bookedPOI", JSON.stringify(jdata[i]));
                    }

                    jdata[i].eventStartTime = jdata[i].fsmAction.eventStartTime;
                    jdata[i].fsmFacilityState = jdata[i].fsmAction.fsmFacilityState;
                    jdata[i].activator = jdata[i].fsmAction.activator;
                }

                if (jdata[i].lang === userLang && jdata[i].visible
                    && checkMarkerStaffOnlyToShow(jdata[i].staffOnly, project.pid)) {
                    currentMarkers.current.push(jdata[i]);
                }
            }

            Emitter.emit(Events.SIDEBAR_LOADING, false);
            setLoading(false);
        });
    }, [
        currentMarkers,
        setLoading,
        project,
        cname,
        category
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        if(typeof(cname) === 'string') {
            currentMarkers.current = [];
            setLoading(true);
            loadCategoryPois();
        }

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        loadCategoryPois,
        cleanupFunction,
        cname
    ]);

    return (
        <>
            {!loading && renderCategoryPoiList() }
        </>
    );
}

export default CategoryItems;
