import React, { FC, MutableRefObject, useRef, useState, useMemo, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { Events, mobile } from "@constants";
import Emitter from "../../helpers/emitter";

import "./howto-styles.scss";

export interface IHowTo {}

const HowTo: FC<IHowTo> = () => {
  const cleanupFunction: MutableRefObject<any> = useRef(false);
  const [show, setShow] = useState(false);
  const desktopContent = useMemo(() => [
    {
      header: (window as any).lang.getString('howTo_desktop_p1_title'),
      description: (window as any).lang.getString('howTo_desktop_p1_descr')
    },
    {
      header: (window as any).lang.getString('howTo_desktop_p2_title'),
      description: (window as any).lang.getString('howTo_desktop_p2_descr')
    },
    {
      header: (window as any).lang.getString('howTo_desktop_p3_title'),
      description: (window as any).lang.getString('howTo_desktop_p3_descr')
    },
    {
      header: (window as any).lang.getString('howTo_desktop_p4_title'),
      description: (window as any).lang.getString('howTo_desktop_p4_descr')
    }
  ], []);
  const mobileContent = useMemo(() => [
    {
      header: (window as any).lang.getString('howTo_mobile_p1_title'),
      description: (window as any).lang.getString('howTo_mobile_p1_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p2_title'),
      description: (window as any).lang.getString('howTo_mobile_p2_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p3_title'),
      description: (window as any).lang.getString('howTo_mobile_p3_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p4_title'),
      description: (window as any).lang.getString('howTo_mobile_p4_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p5_title'),
      description: (window as any).lang.getString('howTo_mobile_p5_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p6_title'),
      description: (window as any).lang.getString('howTo_mobile_p6_descr')
    },
    {
      header: (window as any).lang.getString('howTo_mobile_p7_title'),
      description: (window as any).lang.getString('howTo_mobile_p7_descr')
    }
  ], []);
  const pageContent: any = useRef(null);
  const pageFooter: any = useRef(null);

  const currentPage = useRef(1);

  useEffect(() => {
    cleanupFunction.current = false;

    Emitter.on(Events.OPEN_HOWTO_MODAL, (newValue) => {
      if (!cleanupFunction.current) {
        currentPage.current = 1;
        setShow(newValue);
      }
    });

    return () => {
      cleanupFunction.current = true;
    };
  }, [setShow, cleanupFunction]);

  const onClose = useCallback(() => {
    if (!cleanupFunction.current) {
      setShow(false);
      localStorage.setItem('howTo_showed', 'yes');
    }
  }, [setShow]);

  const changePage = useCallback((direction: string) => {
    if (direction === 'prev' && currentPage.current === 1) return false;

    if (direction === 'next' &&
      (mobile && currentPage.current === mobileContent.length ||
        !mobile && currentPage.current === desktopContent.length)) {
      currentPage.current = 0;
    }

    currentPage.current = (direction === 'next' ? currentPage.current + 1 : currentPage.current - 1);

    const { header, description } = mobile ?
      mobileContent[currentPage.current - 1] :
      desktopContent[currentPage.current - 1];

    pageContent.current.querySelector('.headline').innerText = header;
    pageContent.current.querySelector('.page-descr').innerText = description;

    const pageImg = pageContent.current.querySelector('.page-img > span');
    pageImg.className = '';
    pageImg.classList.add(`page-${currentPage.current}`);

    const backBtn = pageFooter.current.querySelector('.left');

    if (currentPage.current > 1) {
      backBtn.classList.remove('invisible');
    } else {
      backBtn.classList.add('invisible');
    }

    const nextBtn = pageFooter.current.querySelector('.filled-btn');

    if (mobile && currentPage.current === mobileContent.length ||
      !mobile && currentPage.current === desktopContent.length) {
      nextBtn.innerText = (window as any).lang.getString('btnRestart');
    } else {
      nextBtn.innerText = (window as any).lang.getString('btnNext');
    }

    return true;
  }, [
    pageContent,
    pageFooter,
    currentPage,
    mobileContent,
    desktopContent
  ]);

  return (
    <>
      <Modal show={show} animation={false} centered dialogClassName={mobile ? 'mobile' : ''} backdropClassName={mobile ? 'd-none' : ''} id="howto" onHide={() => false}>
        <Modal.Body className="modal-width">
          <div className="howto-modal" ref={pageContent}>
            {
              !mobile ?
                <div className="page">
                  <h4 className="headline">{desktopContent[0].header}</h4>
                  <div className="page-img desktop">
                    <span className="page-1"/>
                  </div>
                  <div className="page-descr">{desktopContent[0].description}</div>
                </div> :
                <div className="page">
                  <button type="button" className="close-btn" onClick={onClose}>
                    <i className="close-ico" aria-hidden="true"/>
                  </button>
                  <div className="page-img mobile">
                    <span className="page-1"/>
                  </div>
                  <h4 className="headline">{mobileContent[0].header}</h4>
                  <div className="page-descr">{mobileContent[0].description}</div>
                </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          {
            !mobile ?
              <div className="action-buttons two-sides" ref={pageFooter}>
                <button type="button" className="left invisible" onClick={() => changePage('prev')}>
                  {(window as any).lang.getString('btnBack')}
                </button>
                <div className="right">
                  <button type="button" onClick={onClose}>
                    {(window as any).lang.getString('btnClose')}
                  </button>
                  <button type="button" className="filled-btn" onClick={() => changePage('next')}>
                    {(window as any).lang.getString('btnNext')}
                  </button>
                </div>
              </div>:
              <div className="action-buttons two-sides" ref={pageFooter}>
                <button type="button" className="left invisible" onClick={() => changePage('prev')}>
                  {(window as any).lang.getString('btnBack')}
                </button>
                <button type="button" className="right filled-btn" onClick={() => changePage('next')}>
                  {(window as any).lang.getString('btnNext')}
                </button>
              </div>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HowTo;