import React, { FC, MutableRefObject, PropsWithChildren, useMemo } from 'react';
import Emitter from '../../helpers/emitter';
import {
    filterCategory,
    getSearchText,
    getShowInMap,
    processSearchInput,
    resetFilter,
    resetFilterCategory,
    toggleShowInMap
} from '../../helpers/utils';
import { TabNames, Events, TabModes, mobile, NavigatePoints, ScanFrom, BackStates } from '../../constants';
import { useForceUpdate } from '../../hooks';
import { IPoi, IPoiCategory, IPOIsCategories, ISearchState } from '../../interfaces';
import Search from "../../helpers/search";
import { resetMap, resetSearch, resetSearchForBack } from "../../helpers/marker";

interface  ISearchBox {
    tab: string;
    pois?: any;
    project?: any;
    buildings?: any;
    occupancy?: any;
    changeFloor?: (floor: number) => void;
    backButton: boolean;
    closeButton: boolean;
    filterButton?: boolean;
    fromNavigate?: boolean;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    navigatePoint?: string;
    changeLoading?: (loading: boolean) => void;
    fromThirdScreen?: boolean;
    navObject?: any;
    facilityNames?: any;
    backState?: any;
    floor?: number;
    categories?: any;
    poisCategories?: IPOIsCategories;
    referrer?: string;
}

const SearchBox: FC<ISearchBox> = (props: PropsWithChildren<ISearchBox>) => {
    const {
        tab,
        pois,
        project,
        buildings,
        occupancy,
        backButton,
        closeButton,
        filterButton,
        fromNavigate,
        fromPoi,
        toPoi,
        navigatePoint,
        fromThirdScreen,
        navObject,
        facilityNames,
        backState,
        changeFloor,
        floor,
        categories,
        poisCategories,
        referrer
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const searchInput: any = React.useRef(null);
    const mounted: any = React.useRef(false);
    const [searchPlaceholder, setSearchPlaceholder] = React.useState((window as any).lang.getString('searchBarText'));

    const searchInitState: ISearchState = new Search("#homeSearchBar", "#find-ui-window", 'home', project);
    const [search, setSearch] = React.useState(searchInitState);
    const [showInMap] = React.useState(false);

    const searchablePOIs = useMemo(
      () => pois?.filter((p: any) => p.searchable),
      [pois]
    );

    const searchInputCallbackRef = React.useCallback(inputElement => {
        searchInput.current = inputElement;

        if (inputElement && mobile && fromNavigate) {
            inputElement.focus();
        }
    }, [fromNavigate]);

    const searchFocusHandler = React.useCallback(() => {
        if(mobile) {
            const sidebar: any = document.getElementById('sidebar');
            sidebar.classList.remove('landscape');
        }

        Emitter.emit(Events.UNSELECT_CATEGORY, null);
        Emitter.emit(Events.COLLAPSE_CATEGORY_BAR, null);
        Emitter.emit(Events.SHOW_CATEGORY_BAR, null);

        if (mobile) {
            Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
            const infoWindow = (window as any).infoWindow || null;
            if (infoWindow) {
                infoWindow.close();
            }
        }

        if (mobile && getShowInMap()) {
            toggleShowInMap(false);
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            Emitter.emit(Events.HANDLE_SEARCH, null);
        }

        resetSearchForBack();
        const searchBox: string = localStorage.getItem('search') || '';
        if (searchBox === '') {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.OPEN_SEARCH,
                tabData: null,
                tabBackButton: true,
                tabCloseButton: true,
                tabFilterButton: true
            });

            setTimeout(() => {
                Emitter.emit(Events.SET_FOCUS, null);
            }, 100);
        }
    }, []);

    const searchBlurHandler = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setSearchPlaceholder((window as any).lang.getString('searchBarText'));
        }
    }, [setSearchPlaceholder, cleanupFunction]);

    const backToHandler = React.useCallback(() => {
        resetFilter(project);

        if (fromNavigate) {
            if (mobile) {
                Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
            }
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: {
                    fromPoi,
                    toPoi,
                    navigatePoint,
                    navObject,
                    facilityNames,
                    referrer
                },
                tabBackButton: false,
                tabCloseButton: false,
            });

            localStorage.setItem('search', '');
        } else {
            resetMap(project);
            Emitter.emit(Events.UNSET_CURRENT_POI, null);
            Emitter.emit(Events.UNSELECT_CATEGORY, null);
            Emitter.emit(Events.UNSET_SEARCH_MODE, 'search');

            if (mobile) {
                Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            }

            if(backState && backState.state === BackStates.MARKER_LIST) {
                const searchForBack: string = localStorage.getItem('searchForBack') || '';
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.FIND,
                    tabMode: TabModes.OPEN_SEARCH,
                    tabData: null,
                    tabBackButton: true,
                    tabCloseButton: true,
                    tabFilterButton: true
                });
                Emitter.emit(Events.HANDLE_SEARCH, { searchForBack });

            } else if(backState && backState.state === BackStates.CATEGORY_POI_LIST) {
                const category: IPoiCategory = backState?.category;
                const categoryName: string = backState?.cname;
                filterCategory(categoryName);

                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.CATEGORIES,
                    tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
                    tabData: {
                        categories,
                        poisCategories,
                        project,
                        floor,
                        changeFloor,
                        pois,
                        cname: categoryName,
                        category,
                        tabMode: TabModes.OPEN_CATEGORY_POI_LIST
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            } else {
                Emitter.emit(Events.CLOSE_SIDEBAR, null);
            }
        }
    }, [
        project,
        fromNavigate,
        fromPoi,
        toPoi,
        navigatePoint,
        navObject,
        facilityNames,
        backState,
        categories,
        poisCategories,
        floor,
        changeFloor,
        pois,
        referrer
    ]);

    const searchCloseHandler = React.useCallback(() => {
        if (fromNavigate) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: {
                    fromPoi,
                    toPoi,
                    navigatePoint,
                    navObject,
                    facilityNames
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        } else {
            resetMap(project);
            resetSearchForBack();

            Emitter.emit(Events.UNSET_CURRENT_POI, null);
            Emitter.emit(Events.UNSELECT_CATEGORY, null);
            Emitter.emit(Events.UNSET_SEARCH_MODE, 'search');
            Emitter.emit(Events.CLOSE_TAB, null);
        }
    }, [
        project,
        fromNavigate,
        fromPoi,
        toPoi,
        navObject,
        navigatePoint,
        facilityNames
    ]);

    const searchClickHandler = React.useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        resetFilterCategory();

        if (mobile) {
            const isThirdScreen = event.target.offsetParent?.offsetParent.id === 'third-screen-searchbar' // check if the searchbox clicked was a 'third-screen' searchbox
            if (isThirdScreen) {
                Emitter.emit(Events.EXIT_THIRD_SCREEN, event);
            }
        }

        if (!fromNavigate) Emitter.emit(Events.SET_SEARCH_MODE,  event);
    }, [fromNavigate]);

    const handleKeyUp = React.useCallback((event) => {
        const data = { fromPoi, toPoi, navObject, facilityNames, referrer };
        const searchInputBox = event.target;
        search.input = searchInputBox.value;
        setSearch(search);

        localStorage.setItem('search', search.input);
        localStorage.setItem('searchForBack', search.input);

        processSearchInput(search, searchablePOIs, occupancy, fromNavigate, navigatePoint, data);
    }, [
        search,
        setSearch,
        searchablePOIs,
        occupancy,
        navigatePoint,
        fromNavigate,
        fromPoi,
        toPoi,
        navObject,
        facilityNames,
        referrer
    ]);

    const filterHandler = React.useCallback(() => {
        if (mobile && fromThirdScreen) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.FILTERS,
            tabMode: TabModes.FILTERS,
            tabData: {
                project,
                buildings,
                fromNavigate,
                fromPoi,
                toPoi,
                navigatePoint,
                navObject,
                facilityNames,
                referrer
            },
            tabBackButton: true,
            tabCloseButton: false,
            tabFilterButton: false
        });
    }, [
        project,
        buildings,
        fromNavigate,
        fromPoi,
        toPoi,
        navigatePoint,
        fromThirdScreen,
        navObject,
        facilityNames,
        referrer
    ]);

    const openCamera = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!cleanupFunction.current) Emitter.emit(Events.OPEN_QR_SCANNER, { isShow: true, from: ScanFrom.SEARCH });
    }, [cleanupFunction]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        if (!mounted.current) {
            Emitter.on(Events.SET_SEARCH_MODE, () => {
                searchFocusHandler();
            });

            Emitter.on(Events.UNSET_SEARCH_MODE, () => {
                resetSearch();
                searchBlurHandler();
            });

            Emitter.on(Events.HANDLE_SEARCH, (value: any) => {
                const data = {
                    fromPoi: value?.fromPoi,
                    toPoi: value?.toPoi,
                    navObject: value?.navObject,
                    facilityNames: value?.facilityNames,
                    referrer: value?.referrer
                };
                const searchForBack: string | null = value?.searchForBack;
                search.input = searchForBack || getSearchText();

                if (searchablePOIs) {
                    processSearchInput(search, searchablePOIs, occupancy, value?.fromNavigate, value?.navigatePoint, data);
                    setTimeout(() => {
                        Emitter.emit(Events.SET_SEARCH, search.input);
                        if (!getShowInMap()) Emitter.emit(Events.SET_FOCUS, null);
                    }, 200);
                }
            });

            Emitter.on(Events.SET_FOCUS, () => {
                if (!cleanupFunction.current && searchInput.current) {
                    searchInput.current.focus();
                    setSearchPlaceholder((window as any).lang.getString('searchText'));
                }
            });

            Emitter.on(Events.SET_SEARCH, (value: string) => {
                if (!cleanupFunction.current && searchInput.current) {
                    searchInput.current.value = value;
                }
            });

            Emitter.on(Events.UNSET_SEARCH_FOCUS, () => {
                if (!cleanupFunction.current && searchInput.current) {
                    searchInput.current.blur();
                }
            });

            mounted.current = true;
        }

        switch (navigatePoint) {
            case NavigatePoints.START_POINT:
                setSearchPlaceholder((window as any).lang.getString('SearchOrigin'))
                break;
            case NavigatePoints.END_POINT:
                setSearchPlaceholder((window as any).lang.getString('SearchDestination'))
                break;
            default:
                break;
        }

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        forceUpdate,
        searchBlurHandler,
        searchFocusHandler,
        cleanupFunction,
        searchCloseHandler,
        search,
        setSearch,
        searchablePOIs,
        occupancy,
        fromNavigate,
        navigatePoint,
        tab,
        mounted,
        facilityNames
    ]);

    let rightButton = null;

    if (closeButton && !mobile) {
        rightButton = (
            <div className="input-group-btn search-close-cont" style={{ 'display': 'table-cell' }}>
                <button className="btn btn-default srch-close-btn " type="button" onClick={searchCloseHandler}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        );
    }

    if (mobile && !showInMap) {
        rightButton = (
            <div className="input-group-btn search-close-cont" style={{ 'display': 'table-cell' }}>
                <button className="btn btn-default srch-close-btn " type="button" id="qrCode" onClick={(e) => openCamera(e)}>
                    <i className="qr-code-ico" />
                </button>
            </div>
        );
    }

    let leftButton = (
        <button className="back-btn pull-left" type="button" id="backBtn" onClick={() => backToHandler()}>
            <i className="back-btn-ico" />
        </button>
    );

    const searchForBack: string = localStorage.getItem('searchForBack') || '';

    if (searchForBack) {
        localStorage.setItem('search', searchForBack);
    }

    const inputBox: string = localStorage.getItem('search') || '';
    let filterButtonElement = null;

    if (filterButton && inputBox !== '') {
        filterButtonElement = (
            <div className="input-group-btn search-close-cont" style={{ 'display': 'table-cell' }}>
                <button className="btn btn-default filter-btn srch-close-btn"
                        type="button" id="filterBtn"
                        onClick={() => filterHandler()}
                >
                    <i className="filter-btn-ico" />
                </button>
            </div>
        );
    }

    if (!backButton) {
        leftButton = (
            <a href="/#"
               id={`bars-${tab}`}
               className="bars-btn pull-left"
               onClick={(e) => e.preventDefault()}
            >
                <i className="fas fa-search" />
            </a>
        );
    }

    return (
        <div className="sidebar-header" id={ `${tab}-header` }>
            {leftButton}
            <div id="searchBar" className="input-group spreo-search">
                <input type="text"
                       placeholder={searchPlaceholder}
                       autoComplete="off"
                       className="form-control srch-form full-width"
                       ref={searchInputCallbackRef}
                       onBlur={searchBlurHandler}
                       onClick={(event) => searchClickHandler(event)}
                       onKeyUp={(event) => handleKeyUp(event)}
                       id={`${tab}-input`}
                />
                {filterButtonElement}
                {rightButton}
            </div>
        </div>
    );
}

export default SearchBox;
