import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import { IProject } from "../../interfaces";
import Pdf from "../../helpers/pdf";

export interface IDownloadModal {
    project: IProject;
    pdfInfo: any;
    instructionList: any;
}

const DownloadModal: FC<IDownloadModal> = (props: PropsWithChildren<IDownloadModal>) => {
    const {
        project,
        pdfInfo,
        instructionList
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);

    const onDownload = React.useCallback(() => {
        const printPDF = new Pdf(project, pdfInfo, instructionList);
        printPDF.print();

        setShow(false);
    }, [
        project,
        pdfInfo,
        instructionList
    ]);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_DOWNLOAD_MODAL, ({ isShow }) => {
            if (!cleanupFunction.current) {
                setShow(isShow);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <Modal show={show} animation={false} centered id="download-modal" onHide={() => false }>
            <Modal.Body className="modal-width">
                <div className="campus-modal">
                    <h4>{ (window as any).lang.getString('modalPrint_titleText') }</h4>
                    <div className="download-btns">
                        <button type="button"
                                className="modal-btn close-btn close-share-modal"
                                onClick={onClose}>
                            <span>{ (window as any).lang.getString('btnClose') }</span>
                        </button>
                        <button type="button"
                                className="modal-btn download-btn"
                                onClick={onDownload}>
                            <span>{ (window as any).lang.getString('btnDownload') }</span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DownloadModal;
