import React, { FC } from 'react';
import loader from '@assets/images/others/loader-1.gif';

import './loader.scss';

export interface ILoader {
    loading: boolean;
    mapOverlay: boolean;
}

const Loader: FC<ILoader> = ({ loading, mapOverlay }) => {
    const classNameOverlay = mapOverlay ? 'tab-overlay-desktop map' : 'tab-overlay';
    const classNameOverlaySpin = mapOverlay ? 'overlay-spin map loader-width' : 'overlay-spin loader-width';

    if (loading) {
        return (
            <div className={ classNameOverlay }>
                <img src={ loader } className={ classNameOverlaySpin } alt="loader" />
            </div>
        );
    }

    return null;
};

export default Loader;
