import { INav, IPoi, IProject } from "../interfaces";
import Path from "./path";
import { Events, mobile, NavigateModes, NavigatePoints, NavStates, TabModes, TabNames } from "../constants";
import { centerMap } from "./marker";
import Emitter from "./emitter";

const path = new Path();

class Nav implements INav {

    public origin: any;

    public destination: any;

    public state: string;

    public project: any;

    public markers: any;

    public handicapped: boolean;

    public directions: boolean;

    public changeFloor: (floor: number) => void;

    public currentFloor: number;

    public mode: string;

    constructor() {
        this.origin = {};
        this.destination = {};
        this.state = NavStates.N;
        this.project = null;
        this.markers = null;
        this.handicapped = false;
        this.directions = false;
        this.changeFloor = () => {};
        this.currentFloor = 0;
        this.mode = NavigateModes.GO_NOW;

        this.setNavigateState();
    }

    /**
     *
     * @param project
     */
    setProject (project: IProject) {
        this.project = project;
        this.project.imageUrl = './images/';
    }

    /**
     *
     * @param markerMap
     */
    setMarkers (markerMap: any) {
        this.markers = markerMap;
        path.init(markerMap);
    }

    /**
     *
     * @param poi
     * @param isDelete
     */
    setDestination (
        poi: IPoi,
        isDelete: boolean = true
    ) {
        const { google } = window;
        const { gmap } = window as any;

        let markerFloor: number = poi.floor;
        const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
        const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

        if (poi.x === 0 && poi.y === 0 && markerFloor !== outdoorFloor) {
            markerFloor = outdoorFloor;
        }

        if (this.origin.poi) {
            if (poi.poid === this.origin.poi.poid) {
                return false;
            }
        }

        if (isDelete) {
            if (this.destination.marker) {
                this.destination.marker.setMap(null);
                delete this.destination.marker;
            }
            delete this.destination.poi;
            delete this.destination.info;
        }

        Nav.adjustMap(poi);

        if (this.origin && this.origin.poi && this.origin.poi.floor) {
            if (this.origin.poi.x === 0 && this.origin.poi.y === 0 && this.origin.poi.floor !== outdoorFloor) {
                this.changeViewFloor(outdoorFloor);
            }
            else {
                this.changeViewFloor(this.origin.poi.floor);
            }
        } else {
            this.changeViewFloor(markerFloor);
        }

        const map = gmap;
        this.destination.poi = poi;

        let markerSize = new google.maps.Size(18, 25);
        if (mobile) {
            markerSize = new google.maps.Size(25, 35);
        }

        const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
        const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;

        const marker = new google.maps.Marker({
            map,
            icon: {
                url: `${this.project.imageUrl}map-icons/navigation_end_point_no_shadow.svg`,
                size: markerSize,
                scaledSize: markerSize,
                origin: new google.maps.Point(0, 0),
            },
            animation: false,
            position: new google.maps.LatLng(lat, lon), // zIndex: 500
        });

        this.destination.marker = marker;

        Emitter.emit(Events.SET_FLOOR_MARKER, { floorNumber: markerFloor, type: 'end' })

        // InfoWindow for navigation marker
        const destinationInfo = new google.maps.InfoWindow({
            content: poi.description,
        });
        destinationInfo.open(map, marker);
        this.destination.info = destinationInfo;

        if (this.markers[this.destination.poi.poid]) {
            this.markers[this.destination.poi.poid].visibleInNav = true;

            if (this.destination.marker.map === null) {
                this.destination.marker.setMap(map);
            }
        }

        if (this.state === NavStates.O) {
            this.state = NavStates.W;

            path.createPath(this).then(() => {
                this.state = NavStates.OD;
                this.setNavigateState();
            });

            this.setNavigateState();
        } else {
            this.state = NavStates.D;
            this.setNavigateState();
        }

        return true;
    }

    /**
     *
     * @param poi
     * @param isDelete
     */
    setOrigin(
        poi: IPoi,
        isDelete: boolean = true
    ) {
        const { google } = window;
        const { gmap } = window as any;

        if (this.destination.poi) {
            if (poi.poid === this.destination.poi.poid) {
                return false;
            }
        }

        let markerFloor: number = poi.floor;
        const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
        const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

        if (poi.x === 0 && poi.y === 0 && markerFloor !== outdoorFloor) {
            markerFloor = outdoorFloor;
        }

        if (isDelete) {
            if (this.origin.marker) {
                this.origin.marker.setMap(null);
                delete this.origin.marker;
            }
            delete this.origin.poi;
            delete this.origin.info;
        }

        Nav.adjustMap(poi);
        this.changeViewFloor(markerFloor);

        const map = gmap;
        this.origin.poi = poi;

        let markerSize = new google.maps.Size(18, 25);
        if (mobile) {
            markerSize = new google.maps.Size(25, 35);
        }

        const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
        const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;

        const marker = new google.maps.Marker({
            map,
            icon: {
                url: `${this.project.imageUrl}map-icons/navigation_start_point_no_shadow.svg`,
                size: markerSize,
                scaledSize: markerSize,
                origin: new google.maps.Point(0, 0),
            },
            animation: false,
            position: new google.maps.LatLng(lat, lon),
            zIndex: 500,
        });

        this.origin.marker = marker;

        if (!this.destination.poi || this.destination.poi.floor !== poi.floor) {
            Emitter.emit(Events.SET_FLOOR_MARKER, { floorNumber: markerFloor, type: 'start' })
        }

        const originInfo = new google.maps.InfoWindow({
            content: poi.description,
        });
        this.origin.info = originInfo;
        originInfo.open(map, marker);

        if (this.markers[this.origin.poi.poid]) {
            this.markers[this.origin.poi.poid].visibleInNav = true;
            if (this.origin.marker.map === null) {
                this.origin.marker.setMap(map);
            }
        }

        if (this.state === NavStates.D) {
            this.state = NavStates.W;

            path.createPath(this).then(() => {
                this.state = NavStates.OD;
                this.setNavigateState();
            });

            this.setNavigateState();
        } else {
            this.state = NavStates.O;
            this.setNavigateState();
        }

        return true;
    }

    removeOrigin() {
        if (this.origin.marker) {
            this.origin.marker.setMap(null);
            this.origin.marker.visibleInNav = false;
            delete this.origin.marker;
        }

        delete this.origin.poi;
        delete this.origin.info;

        Emitter.emit(Events.UNSET_FLOOR_MARKERS, { type: 'start' });
    }

    removeOriginPath() {
        if (this.state === NavStates.OD || this.state === NavStates.W) {
            this.state = NavStates.D;
            path.reset();
        } else {
            this.state = NavStates.N;
        }

        this.setNavigateState();
    }

    removeDestination() {
        if (this.destination.marker) {
            this.destination.marker.setMap(null);
            this.destination.marker.visibleInNav = false;
            delete this.destination.marker;
        }

        delete this.destination.poi;
        delete this.destination.info;

        Emitter.emit(Events.UNSET_FLOOR_MARKERS, { type: 'end' });
    }

    removeDestinationPath() {
        if (this.state === NavStates.OD || this.state === NavStates.W) {
            this.state = NavStates.O;
            path.reset();
        } else {
            this.state = NavStates.N;
        }

        this.setNavigateState();
    }

    setNavigateState() {
        sessionStorage.setItem('navigate.state', this.state);
    }

    static removeOriginAndDestination() {
        Emitter.emit(Events.REMOVE_NAV_POINT, NavigatePoints.END_POINT);
    }

    addListener (place: string) {
        const { google } = window;
        let tabData = null;

        if (place === NavigatePoints.START_POINT) {
            google.maps.event.addListener(this.origin.info, "closeclick", () => {
                Emitter.emit(Events.REMOVE_NAV_POINT, place);
                Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
                Emitter.emit(Events.EXIT_GO_NOW, null);
                Emitter.emit(Events.UNSET_CURRENT_POI, null);

                tabData = { toPoi: this.destination.poi, nav: this };
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData,
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            });
        } else if (place === NavigatePoints.END_POINT) {
            google.maps.event.addListener(this.destination.info, "closeclick", () => {
                Emitter.emit(Events.REMOVE_NAV_POINT, place);
                Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
                Emitter.emit(Events.EXIT_GO_NOW, null);
                Emitter.emit(Events.UNSET_CURRENT_POI, null)

                tabData = { fromPoi: this.origin.poi, nav: this };
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData,
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            });
        }
    }

    floorChange(from: number, to: number) {
        const { gmap } = window as any;

        if (this.origin.marker && this.origin.poi.floor === from) {
            if(from !== to) {
                this.origin.marker.setOpacity(0.25);
                if (this.origin.info) {
                    this.origin.info.close();
                }
            }
        } else if (this.origin.marker && this.origin.poi.floor === to) {
            this.origin.marker.setOpacity(1);
            if (this.origin.marker.map === null) this.origin.marker.setMap(gmap);

            this.origin.marker.setZIndex(100);

            if (this.origin.info) {
                this.origin.info.open(gmap, this.origin.marker);
            }
        }

        if (this.destination.marker && this.destination.poi.floor === from) {
            if(from !== to) {
                this.destination.marker.setOpacity(0.25);
                if(this.destination.info) {
                    this.destination.info.close();
                }
            }

        } else if (this.destination.marker && this.destination.poi.floor === to) {
            this.destination.marker.setOpacity(1);
            if (this.destination.marker.map === null) {
                this.destination.marker.setMap(gmap);
            }

            this.destination.marker.setZIndex(100);
            this.destination.info.open(gmap, this.destination.marker);
        }

        path.floorChange(from, to);
    }

    /**
     *
     * @param poi
     */
    static adjustMap(poi: IPoi) {
        const { google } = window;
        const { gmap } = window as any;

        const currentZoom = gmap.getZoom();
        const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
        const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
        const newCenter = new google.maps.LatLng(lat, lon);
        const newZoom = currentZoom < poi.visibleAtZoom ? poi.visibleAtZoom : currentZoom;

        centerMap(gmap, newCenter, newZoom);
    }

    /**
     *
     * @param _floor
     */
    changeViewFloor(_floor: number) {
        this.changeFloor(_floor);
        Emitter.emit(Events.SCROLL_FLOOR_PICKER, _floor);
        this.currentFloor = _floor;
    }

    /**
     *
     * @param obj
     */
    static cloneDeep(obj: any) {
        return obj ? JSON.parse(JSON.stringify(obj)) : null;
    }

    swapOriginDestination() {
        const newDestination = Nav.cloneDeep(this.origin.poi) || null;
        const newOrigin = Nav.cloneDeep(this.destination.poi) || null;
        this.reset(true);

        if (newOrigin) {
            this.setOrigin(newOrigin, true);
            this.addListener(NavigatePoints.START_POINT);
        }

        if (newDestination) {
            this.setDestination(newDestination, true);
            this.addListener(NavigatePoints.END_POINT);
        }

        if (newOrigin && newDestination) {
            this.floorChange(newDestination.floor, newOrigin.floor);
            this.changeFloor(newOrigin.floor);
        }
    }

    reset (swap: boolean | undefined) {
        swap = swap === undefined ? false : swap;

        if (this.origin.marker) {
            this.origin.marker.setMap(null);
            delete this.origin.marker;
        }
        delete this.origin.poi;
        delete this.origin.info;

        if (this.destination.marker) {
            this.destination.marker.setMap(null);
            delete this.destination.marker;
        }
        delete this.destination.poi;
        delete this.destination.info;

        this.state = NavStates.Z;
        sessionStorage.setItem('navigate.state', this.state);

        // Reset path object
        path.reset();

        // Reset directions
        if (this.directions) {
            this.directions = false;
        }

        Emitter.emit(Events.UNSET_FLOOR_MARKERS, { type: 'start' });
        Emitter.emit(Events.UNSET_FLOOR_MARKERS, { type: 'end' });
    }

    resetHandicapped() {
        this.handicapped = false;
    }
}

export default Nav;
