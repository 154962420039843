import React, { FC, PropsWithChildren } from 'react';
import Favorites from "../../Favorites";

interface  IFavorite {
    tabData: any;
    tabMode: string;
}

const Favorite: FC<IFavorite> = (props: PropsWithChildren<IFavorite>) => {
    const {
        tabData
    } = props;

    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const changeFloor = React.useMemo(() => tabData?.changeFloor || null, [tabData]);
    const pois = React.useMemo(() => tabData?.pois || [], [tabData]);
    const fromSearch = React.useMemo(() => tabData?.fromSearch || false, [tabData]);

    return (
        <>
            <Favorites project={project}
                       changeFloor={changeFloor}
                       pois={pois}
                       fromSearch={fromSearch}
            />
        </>
    );
}

export default Favorite;
