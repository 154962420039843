import { BaseAction, Payload } from '@constants';

export enum LoaderEnum {
  CHANGELOADING = 'CHANGELOADING',
  LOADERREQUESST = 'LOADERREQUESST',
  LOADERRSUCCESS = 'LOADERRSUCCESS',
  LOADERRERROR = 'LOADERRERROR'
}

/**
 * Change loader in storage
 * @param payload
 */
export const changeLoading = (payload: Payload): BaseAction => ({
  type: LoaderEnum.CHANGELOADING,
  payload,
});

/**
 * Request loader
 * @param payload
 */
export const requestLoader = (payload: Payload): BaseAction => ({
  type: LoaderEnum.LOADERREQUESST,
  payload,
});

/**
 *
 * @param payload
 * @constructor
 */
export const LoaderSuccess = (payload: Payload): BaseAction => ({
  type: LoaderEnum.LOADERRSUCCESS,
  payload,
});

/**
 *
 * @param payload
 */
export const loaderError = (payload: Payload): BaseAction => ({
  type: LoaderEnum.LOADERRERROR,
  payload,
});
