import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from 'react-bootstrap/Modal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Emitter from '../../helpers/emitter';
import { IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import { sortCategories } from "../../helpers/utils";
import { Events } from '../../constants';
import 'react-perfect-scrollbar/dist/css/styles.css';

export interface IMapFilter {
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    project: IProject;
}

const MapFilter: FC<IMapFilter> = (props: PropsWithChildren<IMapFilter>) => {
    const {
        categories,
        poisCategories,
        project
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const allField: any = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const [allChecked, setAllChecked] = React.useState(true);

    const showMapFilter = React.useCallback((value: boolean = false): void => {
        if (cleanupFunction.current) {
            return;
        }

        setShow(value);
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);
        const fcStorage: string | null = sessionStorage.getItem('filterCats');
        const filterCats = fcStorage ? JSON.parse(fcStorage) : [];

        if (filterCats.length !== data.length) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }
    }, [setShow, setAllChecked, categories, poisCategories, project, cleanupFunction]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.SHOW_MAP_FILTER, (newValue) => showMapFilter(newValue));

        return () => {
            Emitter.off(Events.SHOW_MAP_FILTER, () => showMapFilter());
            sessionStorage.removeItem('filterCats');

            cleanupFunction.current = true;
        };
    }, [showMapFilter, setShow, setAllChecked, cleanupFunction]);

    const onCloseMapFilter = React.useCallback((event) => {
        event.preventDefault();
        if (!cleanupFunction.current) setShow(false);
    }, [setShow]);

    const changeHandler = React.useCallback((catName: string): void => {
        const fcStorage: string | null = sessionStorage.getItem('filterCats');
        const filterCats: string [] = fcStorage ? JSON.parse(fcStorage) : [];

        if (fcStorage !== null) {
            const catExists = filterCats.indexOf(catName);
            if (catExists !== -1) {
                const index = filterCats.findIndex((name: string) => name === catName);
                filterCats.splice(index, 1);
            } else {
                filterCats.push(catName);
            }
        }

        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);
        allField.current.checked = !(filterCats.length !== data.length);
        sessionStorage.setItem('filterCats', JSON.stringify(filterCats));

        Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
    }, [categories, poisCategories, project, allField]);

    const allCheckboxHandler = React.useCallback(() => {
        const { checked } = allField.current
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);

        data.forEach((category: IPoiCategory) => {
            const item: any = document.getElementById(`cat${category.id}`);
            item.checked = checked;
        });

        if (!checked) {
            sessionStorage.setItem('filterCats', '[]');
        } else {
            const filterCats: string [] = [];
            data.forEach((category: IPoiCategory) => {
                filterCats.push(category.name);
            });
            sessionStorage.setItem('filterCats', JSON.stringify(filterCats));
        }

        Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
    }, [categories, poisCategories, project]);

    const renderOptions = React.useCallback(() => {
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);

        const fcStorage: string | null = sessionStorage.getItem('filterCats');
        const filterCats = fcStorage ? JSON.parse(fcStorage) : [];

        if (fcStorage === null) {
            data.forEach((category: IPoiCategory) => {
                filterCats.push(category.name);
            });
        }

        const ret = data ? data.map((category: IPoiCategory) => {
            let checked = false;
            if (filterCats.includes(category.name)) {
                checked = true;
            }

            return (
                <li key={category.id}>
                    <div className="checkbox">
                        <label className="black-checkbox" htmlFor={`cat${category.id}`}>
                            <input type="checkbox" value={category.name} id={`cat${category.id}`} defaultChecked={checked} onChange={() => changeHandler(category.name)}/><span />
                            {category.name}
                        </label>
                    </div>
                </li>
            )
        }) : null;

        sessionStorage.setItem('filterCats', JSON.stringify(filterCats));
        return ret;
    }, [categories, poisCategories, project, changeHandler]);

    return (
        <Modal show={show} animation={false} centered id="category-filter" onHide={() => true }>
            <Modal.Body className="modal-width">
                <div className="campus-modal" id="workstation-data">
                    <h4 data-lang-key="mapFilter">Map Filter</h4>
                    <form>
                        <PerfectScrollbar>
                            <ul className="category-list">
                                <li>
                                    <div className="checkbox">
                                        <label className="black-checkbox" htmlFor="all">
                                            <input type="checkbox" value="all" defaultChecked={allChecked} id="all" ref={allField} onChange={() => allCheckboxHandler()} />
                                            <span />
                                            <div data-lang-key="mapFilter_all" className="checkbox-title">All</div>
                                        </label>
                                    </div>
                                </li>
                                { renderOptions() }
                            </ul>
                        </PerfectScrollbar>
                    </form>

                    <div className="close-campus">
                        <a href="/#" data-lang-key="btnClose" onClick={(event) => { onCloseMapFilter(event) }}>Close</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MapFilter;
