import { BaseAction, Payload } from '@constants';

export enum PoiActions {
    REQUESTPOI = 'REQUESTPOI',
    REQUESTPOISUCCESS = 'REQUESTPOISUCCESS',
    REQUESTPOIERROR = 'REQUESTPOIERROR'
}

/**
 * Fetch all points of view
 * @param payload
 */
export const requestPoi = (payload: Payload): BaseAction => ({
    type: PoiActions.REQUESTPOI,
    payload,
});

/**
 * Loading POI was successfully
 * @param payload
 */
export const requestPoiSuccess = (payload: Payload): BaseAction => ({
    type: PoiActions.REQUESTPOISUCCESS,
    payload,
});

/**
 * Error was occurred
 * @param payload
 */
export const requestPoiError = (payload: Payload): BaseAction => ({
    type: PoiActions.REQUESTPOIERROR,
    payload,
});
