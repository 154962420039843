import React, { FC, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";

export interface IGuides {
    onCloseContent: () => void;
}

const Guides: FC<IGuides> = (props: PropsWithChildren<IGuides>) =>  {
    const {
        onCloseContent
    } = props;

    return (
        <div className="help-center-container">
            <div className="help-center-header">
                <h4>Guides</h4>
                <i className="close-btn"
                   onClick={() => onCloseContent()}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="close content"
                />
            </div>
            <div className="help-center-data">
                <PerfectScrollbar>
                    <ul className="guides-items">
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                        <li>
                            <a href="/#">
                                Lorem ipsum dolor sit
                            </a>
                            <i className="fa fa-chevron-right"/>
                        </li>
                    </ul>
                </PerfectScrollbar>
            </div>
        </div>
    );
}

export default Guides;
