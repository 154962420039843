import React from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';

interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }) => (
  <ConnectedRouter history={history}>
    <Routes />
  </ConnectedRouter>
);

export default App;
