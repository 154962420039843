import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { tns } from "tiny-slider/src/tiny-slider"
import { IFacilityFloor, IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import { useForceUpdate } from "../../hooks";
import { isSamsungDefaultBrowser } from "../../helpers/utils";

export interface IFloor {
    project: IProject;
    floor: number;
    changeFloor: (floor: number) => void;
}

/**
 * Component which displays floor picker
 * @param {PropsWithChildren<IFloor>} props
 * @constructor
 */
const Floor: FC<IFloor> = (props: PropsWithChildren<IFloor>) => {
    const {
        project,
        floor,
        changeFloor
    } = props;

    const arrowUp: any = React.useRef(null);
    const arrowDown: any = React.useRef(null);

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const [startFloor, setStartFloor] = React.useState(-1);
    const [floorsMap, setFloorsMap] = React.useState(new Map());
    const [endFloor, setEndFloor] = React.useState(-1);
    const currentFloor = React.useRef(floor);
    const [currentProject] = React.useState(project.pid);
    const floorPicker: any = React.useRef(null);
    const mounted: any = React.useRef(false);
    const currentFloors: any = React.useRef(0);

    const scrollFloorPicker = React.useCallback((_floor: number) => {
        const totalFloor: number = project.floors - 2;
        if(floorPicker.current) floorPicker.current.goTo(totalFloor - _floor);
    }, [
        floorPicker,
        project
    ]);

    const onChangeFloor = React.useCallback((floorNumber: number): void => {
        const infoWindow = (window as any).infoWindow || null;
        if (infoWindow) {
            infoWindow.close();
        }

        // Navigation, Location and polygon floor change
        const navState: string = sessionStorage.getItem('navigate.state') || 'z';
        if (navState !== "z") {
            Emitter.emit(Events.NAV_FLOOR_CHANGE, { from: currentFloor.current, to: floorNumber });
        }

        // In order to change floor map
        changeFloor(floorNumber);
        currentFloor.current = floorNumber;
        scrollFloorPicker(floorNumber);

        setTimeout(() => {
            Emitter.emit(Events.MAP_DRAG_END, null);
        }, 200);
    }, [
        changeFloor,
        currentFloor,
        scrollFloorPicker
    ]);

    const addFloorButton = React.useCallback((buttonName: string, floorNumber: number) => {
        let className = 'floor-btn tns-item tns-slide-active';
        if (floor === floorNumber) {
            className += ' current-floor';
        }

        return (
            <div id={`floorbtn${floorNumber}`}
                 className={className}
                 key={`floorbtn${floorNumber}`}
                 aria-hidden="true"
                 onClick={() => onChangeFloor(floorNumber)}>
                { startFloor === floorNumber ? <img src="images/map-icons/navigation_start_point_no_shadow.svg"
                                                                 className="floor-btn-nav-mark start" alt="start floor"/> : null }
                { endFloor === floorNumber ? <img src="images/map-icons/navigation_end_point_no_shadow.svg"
                                                                  className="floor-btn-nav-mark end" alt="end floor"/> : null }
                {buttonName}
                { floorsMap.size &&
                (floorsMap.has(`floor_${floorNumber - 1}`) && floorsMap.get(`floor_${floorNumber - 1}`) > 0) ? (
                    <div className={floor === floorNumber - 1 ? "count-badge selected-floor-badge" : "count-badge"}>
                        { floorsMap.get(`floor_${floorNumber - 1}`) }
                    </div>) : null
                }
            </div>
        )
    }, [
        floor,
        onChangeFloor,
        startFloor,
        endFloor,
        floorsMap
    ]);

    const renderFloors = React.useCallback(() => {
        currentFloors.current = 0;
        project.floorNames = [];
        const range: number [] = [];
        for (let floorNumber = project.floors; floorNumber > 0; floorNumber--) {
            range.push(floorNumber);
        }

       return range.map((floorNumber: number) => {
           currentFloors.current += 1;
           const facilityFloor: IFacilityFloor = project.facilities[project.biggest].floors[floorNumber - 1];
           project.floorNames[facilityFloor.findex] = facilityFloor.title;

           return addFloorButton(facilityFloor.title, floorNumber - 1);
       });
    }, [project, addFloorButton]);

    const wheelHandler = React.useCallback((event: any) => {
        event.preventDefault();

        if (event.deltaY < 0) {
            floorPicker.current.goTo("next");
        } else {
            floorPicker.current.goTo("prev");
        }

        return true;
    }, [floorPicker]);

    const initFloorPicker = React.useCallback(() => {
        if (floorPicker.current) {
            return;
        }

        floorPicker.current = tns({
            container: ".floors-btn",
            items: 3,
            slideBy: 1,
            axis: "vertical",
            center: false,
            controls: true,
            prevButton: ".prev-butt",
            nextButton: ".next-butt",
            nav: false,
            loop: false,
            mouseDrag: true,
            gutter: 2,
            preventScrollOnTouch: 'auto',
            lazyload: true,
            onInit: () => {
                setTimeout(() => {
                    let goToIndex: number = project.pid === 'sheba_medical_center' ? 12 : project.floors - currentFloor.current - 1;
                    if (currentFloor.current !== project.floors - 1 && currentFloor.current !== 0) {
                        goToIndex -= 1;
                    }

                    floorPicker.current.goTo(goToIndex);
                }, 200);
            },
        });

        const tnsElement: any = document.querySelector(".tns-outer");
        tnsElement.onmousewheel = null;

        if (project.floors > 3) {
            document.querySelector("#floating-panel .tns-outer")?.addEventListener("wheel", (event: any) => {
                wheelHandler(event);
            }, false);

            arrowUp.current.style.display = 'block';
            arrowDown.current.style.display = 'block';
        }
    }, [
        floorPicker,
        currentFloor,
        project,
        wheelHandler
    ]);

    const scrollFloorToBottom = React.useCallback(() => {
        const outdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '';
        if(outdoorFloor) {
            setTimeout(() => {
                const goToFloor = parseInt(outdoorFloor, 10) + 1;
                scrollFloorPicker(goToFloor);
            }, 250);
        }
    }, [
        scrollFloorPicker
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        initFloorPicker();
        currentFloor.current = floor;

        if(!mounted.current) {
            Emitter.on(Events.SCROLL_FLOOR_PICKER, (_floor: number) => {
                scrollFloorPicker(_floor);
            });

            Emitter.on(Events.SET_FLOOR_MARKER, ({ floorNumber, type }) => {
                switch (type) {
                    case 'start':
                        setStartFloor(floorNumber);
                        break;
                    case 'end':
                        setEndFloor(floorNumber);
                        break;
                }
            });

            Emitter.on(Events.UNSET_FLOOR_MARKERS, ({ type }) => {
                switch (type) {
                    case 'start':
                        setStartFloor(-1);
                        break;
                    case 'end':
                        setEndFloor(-1);
                        break;
                }
            });

            Emitter.on(Events.SHOW_FLOORS_MAP, (_floorsMap: any) => {
                if (!cleanupFunction.current) setFloorsMap(_floorsMap);
            });

            Emitter.on(Events.RESET_FLOORS_MAP, () => {
                if (!cleanupFunction.current) setFloorsMap(new Map());
            });

            scrollFloorToBottom();
            mounted.current = true;
        }

        const floatingPanel: any = document.getElementById('floating-panel');
        floatingPanel.style.bottom = `25px`;

        if (project.floors === 0) {
            floatingPanel.style.display = 'none';
        }

        const tnsOvh: any = document.querySelector('#floating-panel .tns-ovh');
        if(currentFloors.current < 3) {
            tnsOvh.style.borderRadius = '0';
            tnsOvh.style.boxShadow = 'none';
            tnsOvh.style.overflow = 'unset';
        }

        if(currentFloors.current <= 3) {
            tnsOvh.style.position = 'relative';
            tnsOvh.style.bottom = '70px';
        }

        if(currentFloors.current === 2) {
            tnsOvh.style.height = '80px';
        }

        if(currentFloors.current === 1) {
            tnsOvh.style.height = '40px';
        }

        // Detect Samsung Phone or Tablet default browser
        if(isSamsungDefaultBrowser())  {
            floatingPanel.style.setProperty('bottom', '210px', 'important');
        }

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        project,
        wheelHandler,
        currentProject,
        floorPicker,
        initFloorPicker,
        setStartFloor,
        setEndFloor,
        currentFloor,
        forceUpdate,
        cleanupFunction,
        scrollFloorPicker,
        floor,
        setFloorsMap,
        scrollFloorToBottom
    ]);

    return project ? (
        <div className='floor-picker'>
            <span className="page-arrow prev-butt" aria-controls="tns1" data-controls="prev"
                  aria-disabled="false" ref={arrowUp}>
                <i className="fas fa-angle-up" />
            </span>
            <div className="floors-btn">
                { renderFloors() }
            </div>
            <span className="page-arrow next-butt" aria-controls="tns1" data-controls="next"
                  aria-disabled="true" ref={arrowDown}>
                <i className="fas fa-angle-down" />
            </span>
        </div>
    ) : null;
};

export default Floor;
