import { connect } from 'react-redux';
import ProjectList from '@components/ProjectList';
import { Ipropduct } from '@reducers/projectLists';
import { loaderActions } from '@actions';

interface IProjects {
  projects: Ipropduct[];
}
interface State {
  projectLists: IProjects;
}

const mapStateToProps = (state: State) => ({
  projects: state.projectLists.projects,
});

const mapDispatch = {
  changeLoading: (loading: boolean ) => loaderActions.changeLoading({ loading } )
}

export default connect(mapStateToProps, mapDispatch)(ProjectList);
