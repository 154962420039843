import React, { FC, MutableRefObject, PropsWithChildren, useState } from 'react';
import { sanitize } from "dompurify";
import ImageGallery from 'react-image-gallery';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Events, mobile, TabModes, TabNames, uiSettings, BackStates, Referrers } from '@constants';
import { IGallery, IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import {
    execValiant360,
    filterCategory,
    getFacilityAndFloor,
    resetFilterCategory,
    sortCategories
} from "../../helpers/utils";

import './styles.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Emitter from "../../helpers/emitter";
import * as Favorites from '../../helpers/favorites';

import "react-image-gallery/styles/scss/image-gallery.scss";
import ShareModal from "../ShareModal";
import { useForceUpdate } from "../../hooks";
import ReportAnIssue from "../ReportAnIssue";
import { resetSearch, resetSearchForBack, selectPoi } from "../../helpers/marker";

export interface IMarkerCard {
    project: IProject;
    poi: any;
    pois: any [];
    changeFloor: (floor: number) => void;
    galleries: IGallery [][];
    backState: any;
    categories?: any;
    poisCategories: IPOIsCategories;
    floor?: number;
}

const MarkerCard: FC<IMarkerCard> = (props: PropsWithChildren<IMarkerCard>) => {
    const {
        project,
        poi,
        pois,
        changeFloor,
        galleries,
        backState,
        categories,
        poisCategories,
        floor
    } = props;

    const [isFavorite, setIsFavorite] = useState(false);
    const [isParking, setIsParking] = useState(false);
    const shareLink = React.useRef('');

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const reserveWorkstation = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const showAvailability = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const bookByOutlook = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const showMap = React.useCallback(() => {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.INFO,
            tabMode: TabModes.OPEN_INFO,
            tabData: { poi, project, changeFloor },
            tabBackButton: false,
            tabCloseButton: false,
        });

        if (mobile) setTimeout(() => Emitter.emit(Events.HIDE_FLOATING_PANEL, null), 50);
    }, [project, poi, changeFloor]);

    const handleDirection = React.useCallback((e: any, _poi: IPoi) => {
        e.preventDefault();
        resetSearchForBack();

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.NAVIGATE,
            tabMode: TabModes.OPEN_NAVIGATE_UI,
            tabData: { toPoi: _poi, referrer: Referrers.POI_CARD },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, []);

    const shareButtonClick = React.useCallback((e, poid) => {
        e.preventDefault();

        // Get rid of possible query params or tab hash in window href
        const link = window.location.href.split('?')[0].split('#')[0];
        shareLink.current = `${link}?poi=${poid}&lang=${project.userLang}`;

        forceUpdate();

        Emitter.emit(Events.OPEN_SHARE_MODAL, true);
    }, [project, shareLink, forceUpdate]);

    const favoritesButtonClick = React.useCallback((e, poid, is_favorite) => {
        e.preventDefault();

        if (!is_favorite) {
            Favorites.addFavorite(project.pid, poid);
        } else {
            Favorites.removeFavorite(project.pid, poid);
        }

        setIsFavorite(current => !current)
    }, [setIsFavorite, project]);

    const parkingButtonClick = React.useCallback((poid) => {
        if (!isParking) {
            Favorites.saveParking(project.pid, poid);
        } else {
            Favorites.deleteParking(project.pid);
        }

        setIsParking(current => !current);
    }, [setIsParking, isParking, project]);

    const selectMarker = React.useCallback((e, poiItem) => {
        e.preventDefault();
        selectPoi(poiItem, project, changeFloor);
    }, [
        project,
        changeFloor
    ]);

    const reportAnIssueModal = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.OPEN_REPORT_AN_ISSUE, true);
    }, []);

    const addMarkerGallery = React.useCallback((uri: string, marker: IPoi, key: number) => {
        const base: string = `${project.url}/middle/ios/res/${project.pid}/${marker.campus}/${marker.facility}/`;

        if (marker.poid === poi.poid) {
            if (uri.indexOf("egret") !== -1 || uri.indexOf("meetingc") !== -1) {
                // Bigger sidebar size
                const photoUrl = uri.indexOf("egret") !== -1 ? "images/others/egret.jpg" : "images/others/meeting_room_C.jpg";

                return (
                    <div className="col-xs-12 valiantPhoto three-dimensions"
                         data-photo-src={photoUrl}
                         style={{ width: '256px', height: '195px', marginTop: '20px' }}
                         key={`${marker.id}_${key}`}
                    />
                );
            }

            return (
                    <img src={`${base}${uri}`} className="space-img" alt="Space" key={`${marker.id}_${key}`}/>
                );
        }

        return null;
    }, [project, poi]);

    const goToParkingList = React.useCallback(() => {
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);
        const parking: any = data.find((item: IPoiCategory) => item.name.indexOf('Parking') !== -1);
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.CATEGORIES,
            tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
            tabData: {
                categories,
                poisCategories,
                project,
                floor,
                changeFloor,
                pois,
                cid: parking?.id,
                cname: parking?.name,
                tabMode: TabModes.OPEN_CATEGORY_POI_LIST
            },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [
        categories,
        poisCategories,
        project,
        floor,
        pois,
        changeFloor
    ]);

    const backToMenu = React.useCallback(() => {
        resetFilterCategory();
        Emitter.emit(Events.INIT_LABELS, null);

        if (mobile) setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);

        switch (backState.state) {
            case BackStates.MAP:
                Emitter.emit(Events.CLOSE_SIDEBAR, null);
                break;
            case BackStates.NAVIGATE:
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData: {
                        fromPoi: backState.fromPoi,
                        toPoi: backState.toPoi
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
                break;
            case BackStates.GO_NOW:
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData: {
                        fromPoi: backState.fromPoi,
                        toPoi: backState.toPoi
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });

                break;
            case BackStates.MARKER_LIST:
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.FIND,
                    tabMode: TabModes.OPEN_SEARCH,
                    tabData: null,
                    tabBackButton: true,
                    tabCloseButton: true,
                    tabFilterButton: true
                });
                Emitter.emit(Events.HANDLE_SEARCH, {
                    searchForBack: localStorage.getItem('searchForBack') || ''
                });
                break;
            case BackStates.CATEGORY_POI_LIST:
                filterCategory(backState?.cname);

                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.CATEGORIES,
                    tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
                    tabData: {
                        categories,
                        poisCategories,
                        project,
                        floor,
                        changeFloor,
                        pois,
                        cname: backState?.cname,
                        tabMode: TabModes.OPEN_CATEGORY_POI_LIST
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
                break;
            case BackStates.FAVORITE_LIST:
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.FAVORITES,
                    tabMode: TabModes.OPEN_FAVORITE_LIST,
                    tabData: {
                        project,
                        changeFloor,
                        pois
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
                break;
            case BackStates.PARKING_LIST:
                goToParkingList();
                break;
            case BackStates.SHOW_IN_MAP:
                showMap();
                break;
            case BackStates.MARKER_CARD_QR:
                window.location.reload();
                break;
            default:
                Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.HOME,
                tabMode: '',
                tabData: null,
                tabBackButton: false,
                tabCloseButton: true,
                })
                break;
        }

    }, [
        backState,
        categories,
        poisCategories,
        project,
        floor,
        changeFloor,
        pois,
        goToParkingList,
        showMap
    ]);

    const renderMarkerCard = React.useCallback(() => {
        const isParkingPOI = poi.categories ? (poi.categories.toLocaleLowerCase().includes('parking') || poi.poid.startsWith('prk')) : false;

        // Get info to display underneath poi name
        const poiInfo: any = getFacilityAndFloor(project, poi.facility, poi.floor);
        const categoryInfo = poi.categories ? poi.categories.slice(1, -1) : "";
        const isWorkstation =
            categoryInfo === (window as any).lang.getString('findTab_categoryDesks') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryWorkstations');
        const isConfRooms =
            categoryInfo === (window as any).lang.getString('findTab_categoryMRooms') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryMRoom') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryMOffices') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryCRooms') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryCRoomsS') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryCRoomsM') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryCRoomsL') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryPRooms') ||
            categoryInfo === (window as any).lang.getString('findTab_categoryWRooms');
        const isBookable = isWorkstation || isConfRooms;

        const amenities: string = poi.amenities ? poi.amenities.replace("[", "").replace("]", "") : [];
        let amenitiesElement;
        if (amenities.length) {
            const amenitiesList: string [] = amenities.split(",");
            amenitiesElement = (
                <ul className="list-inline poi-amenities-list" id="amenities-list">
                    { amenitiesList.map((amenity: string) => (
                        <li key={amenity}>
                            <span>{ amenity }</span>
                        </li>
                    )) }
                </ul>
            );
        }

        let poiDescButtons;
        if (isBookable) {
            let btnText: string = (window as any).lang.getString('btnClaim');
            let btnClass: string = "reserve-btn";

            if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.id === 2 && localStorage.samlNameId === poi.activator) {
                btnText = (window as any).lang.getString('btnRelease');
                btnClass = "reserve-btn release-btn";
            } else if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.id === 2 && localStorage.samlNameId !== poi.activator) {
                btnText = (window as any).lang.getString('btnUnavailable');
                btnClass = "reserve-btn unavailable-btn";
            } else if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.id === 5) {
                btnText = (window as any).lang.getString('btnUnavailable');
                btnClass = "reserve-btn unavailable-btn";
            } else if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.id === 6) {
                const { myBookedPoid } = localStorage;
                if (myBookedPoid === poi.poid) {
                    btnText = (window as any).lang.getString('btnRelease');
                    btnClass = "reserve-btn release-btn";
                } else {
                    btnText = (window as any).lang.getString('btnUnavailable');
                    btnClass = "reserve-btn unavailable-btn";
                }
            } else if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.id === 1 && poi.activator === "System") {
                btnText = (window as any).lang.getString('btnClaim');
                btnClass = "reserve-btn";
            } else if (poi.fsmFacilityState && poi.fsmFacilityState.fsmState
                && poi.fsmFacilityState.fsmState.name === "Unavailable") {
                btnText = (window as any).lang.getString('btnUnavailable');
                btnClass = "reserve-btn unavailable-btn";
            }

            if (isWorkstation) {
                const mobileStyle: any = mobile ? { paddingLeft: "5px" } : null;
                poiDescButtons = (
                    <div className="col-xs-12" style={ mobileStyle }>
                        <a href="/#"
                           className={`space-operation-btn book reserve-btn-${poi.poid} ${btnClass}`}
                           onClick={(e) => reserveWorkstation(e)}
                        >
                         <span className={`main-reserve-button main-button-${poi.poid}`}>
                             { btnText }
                         </span>
                        </a>
                    </div>
                );
            }
        }

        // Button Set up
        let cssClass = isParkingPOI ? "col-xs-4" : "col-xs-4";
        if (mobile) {
            cssClass = isParkingPOI ? "col-xs-4" : "col-xs-4";
        }

        let buttonsAppendTo;
        if (isConfRooms && uiSettings.corporate) {
            cssClass = "col-xs-4";
            buttonsAppendTo = (
                <>
                    <div className={cssClass}
                         onClick={(e) => showAvailability(e)}
                         role="button"
                         onKeyPress={(e) => e.preventDefault()}
                         tabIndex={0}
                    >
                        <a href="/#" onClick={(e) => e.preventDefault()}>
                            <span className="availability-btn"/>
                        </a>
                        <div>
                            { (window as any).lang.getString('btnAvailability') }
                        </div>
                    </div>
                    <div className={cssClass}
                         onClick={(e) => bookByOutlook(e)}
                         role="button"
                         onKeyPress={(e) => e.preventDefault()}
                         tabIndex={0}
                    >
                        <a href="/#" onClick={(e) => e.preventDefault()}>
                            <span className="book-btn"/>
                        </a>
                        <div>
                            { (window as any).lang.getString('btnReserve') }
                        </div>
                    </div>
                </>
            );
        }

        const navButton = (
            <div className={cssClass} id="navBtn">
                <a href="/#" onClick={(e) => handleDirection(e, poi)}>
                    <span className="navigate-btn"/>
                </a>
                <div>
                    { (window as any).lang.getString('btnNavigate') }
                </div>
            </div>
        );

        if (isConfRooms && uiSettings.corporate) {
            cssClass = "col-xs-4";
        }

        let showMapBtn;
        if (mobile) {
            showMapBtn = (
                <div className={cssClass}
                     onClick={() => showMap()}
                     role="button"
                     onKeyPress={(e) => e.preventDefault()}
                     tabIndex={0}
                >
                    <a href="/#" onClick={(e) => e.preventDefault()}>
                        <span className="show-map-btn"/>
                    </a>
                    <div>
                        { (window as any).lang.getString('btnMap') }
                    </div>
                </div>
            );
        }

        let lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
        let lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;

        const parkingPOI = pois.find((item) => item.poid === poi.future2);

        if (parkingPOI) {
            lat = parkingPOI.geoPoint ? parkingPOI.geoPoint.lat : parkingPOI.lat;
            lon = parkingPOI.geoPoint ? parkingPOI.geoPoint.lon : parkingPOI.lon;
        }

        const driveHereButton = (
          <div className={cssClass}
               role="button"
               onKeyPress={(e) => e.preventDefault()}
               tabIndex={0}
          >
              <a href={encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}&dir_action=navigate`)} className="drive-here-btn" target="_blank" rel="noreferrer">
                  <span className="drive-btn"/>
              </a>
              <div>
                  { (window as any).lang.getString('btnDriveHere') }
              </div>
          </div>
        );

        const marginLeftStyle: any = isConfRooms && uiSettings.corporate && !mobile ? { marginLeft: '21.5%' } : null;
        const shareButton = (
            <div className={cssClass}
                 onClick={(e) => shareButtonClick(e, poi.poid)}
                 style={marginLeftStyle}
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
            >
                <a href="/#" onClick={(e) => e.preventDefault()}>
                    <span className="share-btn"/>
                </a>
                <div>
                    { (window as any).lang.getString('btnShare') }
                </div>
            </div>
        );

        if (isParkingPOI && mobile) {
            cssClass = "col-xs-4";
        }

        const favoriteIcon = 'poi-descr-ico favorites-ico';
        const favoriteBtn = (
            <>
                <div className={cssClass} style={marginLeftStyle}>
                    <a
                       href="/#"
                       onClick={(e) => favoritesButtonClick(e, poi.poid, isFavorite)}
                       className="space-operation-btn"
                       aria-hidden="true"
                       data-toggle="tooltip"
                       title={ isFavorite ? (window as any).lang.getString('btnRemove') : (window as any).lang.getString('btnFavorite') }
                    >
                        <i className={ isFavorite ? `${favoriteIcon} checked` : favoriteIcon }
                           aria-label="favorite"
                        />
                    </a>
                    <div>
                        { isFavorite ? (window as any).lang.getString('btnRemove') : (window as any).lang.getString('btnFavorite') }
                    </div>
                </div>
            </>
        );

        let parkingButton;
        if (isParkingPOI && mobile) {
            parkingButton = (
                <div className={cssClass}
                     onClick={() => parkingButtonClick(poi.poid)}
                     role="button"
                     onKeyPress={(e) => e.preventDefault()}
                     tabIndex={0}
                >
                    <a href="/#"
                       onClick={(e) => e.preventDefault()}
                       className={isParking ? "parking-btn-link parking-btn-saved-link" : "parking-btn-link"}
                    >
                        <span className={isParking ? "parking-btn parking-saved-btn" : "parking-btn"}/>
                    </a>
                    <div>
                        { isParking ? (window as any).lang.getString('btnRemove') : (window as any).lang.getString('btnSave') }
                    </div>
                </div>
            );
        }

        // TODO Need to be inserted Neighbour
        let recommendedParking;

        if (poi.future2 !== '' && poi.future2 !== 'false' && poi.future2 !== null && parkingPOI) {
            recommendedParking = (
                <div className="col-xs-12 misc">
                    <div className="misc-title col-xs-4">
                        { (window as any).lang.getString('findTab_recommendedParking') }
                    </div>
                    <div className="misc-body col-xs-8">
                        <a href="/#" onClick={(e) => selectMarker(e, parkingPOI)}>
                            { parkingPOI.description }
                        </a>
                    </div>
                </div>
            );
        }

        // Add details and gallery image
        let activeHours;

        if (poi.activeHours) {
            activeHours = (
                <div className="col-xs-12 misc">
                    <div className="misc-title col-xs-4">
                        { (window as any).lang.getString('bookTab_hoursText') }
                    </div>
                    <div className="misc-body col-xs-8" style={{ whiteSpace: 'pre' }}>
                        { poi.activeHours }
                    </div>
                </div>
            );
        }

        let websiteInfo;
        let websiteElement = `#poiDesc .space`;
        if (poi.website && poi.website.length > 6) {
            const url: string = poi.website.slice(0, 4) === "http" ? poi.website : `https://${poi.website}`;
            let text: string = "3D View";
            let cls: string = "misc-url";

            if (url.indexOf("matterport") === -1) {
                text = poi.urlTitle || url;
                cls = "misc-body col-xs-8";
                websiteElement = "#misc-details";
            }

            websiteInfo = (
                <>
                    <div className="col-xs-12 misc">
                        <div className="misc-title col-xs-4">Website: </div>
                        <div className={cls} id="website-address">
                            <a href={url}>
                                { text }
                            </a>
                        </div>
                    </div>
                </>
            );
        }

        let contactEmails;
        if (poi.contactEmails) {
            contactEmails = (
                <>
                    <div className="col-xs-12 misc">
                        <div className="misc-title col-xs-4">Email: </div>
                        <div className="misc-body col-xs-8">
                            <a href={`mailto:${poi.contactEmails}`}>
                                { poi.contactEmails }
                            </a>
                        </div>
                    </div>
                </>
            );
        }

        let phoneNumbers;
        if (poi.phones1Numbers) {
            const phones2Numbers = poi.phones2Numbers ? `<br/><a href="tel:${poi.phones2Numbers}">${poi.phones2Numbers}</a>` : "";
            const sanitizePhoneNumbers: string = sanitize(`<a href="tel:${poi.phones1Numbers}">${poi.phones1Numbers}</a> ${phones2Numbers}`);
            phoneNumbers = (
                <>
                    <div className="col-xs-12 misc">
                        <div className="misc-title col-xs-4">
                            { (window as any).lang.getString('findTab_poiDetailsPhone') }
                        </div>
                        <div className="misc-body col-xs-8"
                           dangerouslySetInnerHTML={{ __html: sanitizePhoneNumbers }}/>
                    </div>
                </>
            );
        }

        let reportAnIssueButton;
        if ((window as any).config.reportAnIssue) {
            reportAnIssueButton = (
                <div className="main-menu-container">
                    <div className="menu-btns-container poi-menu-container"
                         onClick={(e) => reportAnIssueModal(e)}
                         role="button"
                         onKeyPress={(e) => e.preventDefault()}
                         tabIndex={0}
                         aria-label="Open camera"
                    >
                        <i className="btns-ico warning-ico"/>
                        <span>{ (window as any).lang.getString('btnReportIssue') }</span>
                    </div>
                </div>
            );
        }

        // Add image gallery
        let imgCount = 0;
        const imageGallery: any = galleries.map((gallery: IGallery []) => gallery.map((item: IGallery, key: number) => {
                if (item.poi_id === poi.poid && item.type === 'head') {
                    imgCount += 1;
                    return addMarkerGallery(item.uri, poi, key);
                }

                return null;
            }
        ));

        const baseUrl: string = `${project.url}/middle/ios/res/${project.pid}/${poi.campus}/${poi.facility}/`;
        const images: any [] = [];
        if (imgCount >= 2) {
            galleries.forEach((gallery: IGallery []) => gallery.forEach((item: IGallery) => {
                    if (item.poi_id === poi.poid) {
                        images.push({
                            original: baseUrl + item.uri
                        });
                    }
                }
            ));
        }

        const capacity: string = poi.capacity ? (window as any).lang.getString('findTab_seatsDescr', [poi.capacity]) : "";
        const sanitizeHelpBlock: string = poi.x === 0 && poi.y === 0 || (poi.navtype === 'OUTDOOR' || !poi.navtype) ?
          sanitize(`${capacity}`) :
          sanitize(`${(window as any).lang.getString('bookTab_poiDetails', [poiInfo.facility, poiInfo.floorname])}<br />${capacity}`);
        const sanitizeDetails: string = poi.details ? poi.details.replace(/\n/g, "<br/>") : null;

        Emitter.emit(Events.IMAGES_POST_PROCESS, null);

        return (
            <PerfectScrollbar>
                <div id="poiDesc">
                    <div className="space">
                        <div className="poi-gallery" id="gallery">
                            {mobile && <div className="poi-back-btn"
                                            onClick={() => backToMenu()}
                                            role="button"
                                            onKeyPress={(e) => e.preventDefault()}
                                            tabIndex={0}
                                            aria-label="Back to main menu"
                            >
                                <i />
                            </div>}
                            { imgCount <= 1 ? imageGallery : (
                                <ImageGallery items={images}
                                              showNav={false}
                                              showFullscreenButton={false}
                                              showThumbnails={false}
                                              showPlayButton={false}
                                              showBullets={true}
                                />
                            ) }
                        </div>
                        {mobile ? <div className={imgCount ? "col-xs-12 poi-desc-header" : "col-xs-12 poi-desc-header pl-75 pt-10"}>
                            <h4 id="poi-title">
                                <div>
                                    { poi.description }
                                </div>
                            </h4>
                            <p  className="help-block" dangerouslySetInnerHTML={{ __html: sanitizeHelpBlock }} />
                        </div> : (
                            <div className="col-xs-12 poi-desc-header">
                                <h4 id="poi-title">
                                    <div>
                                        { poi.description }
                                    </div>
                                </h4>
                                <p  className="help-block" dangerouslySetInnerHTML={{ __html: sanitizeHelpBlock }} />
                            </div>
                            )}
                        <div className="col-xs-12 text-center poi-btns" id="poi-desc-buttons">
                            { poiDescButtons }
                            { buttonsAppendTo }
                            { navButton }
                            <>
                                { showMapBtn }
                                { driveHereButton }
                                { shareButton }
                                { parkingButton }
                                { favoriteBtn }
                            </>
                        </div>
                        <div className="col-xs-12 poi-separator" />
                        <div className="col-xs-12 description" id="poi-amenities">
                            { amenitiesElement }
                        </div>
                        {sanitizeDetails && <div className="col-xs-12 description" id="poi-details">
                            <p dangerouslySetInnerHTML={{ __html: sanitizeDetails }} />
                        </div>}
                        { recommendedParking }
                        { activeHours }
                        { websiteElement === '#misc-details' ? websiteInfo : null }
                        { contactEmails }
                        { phoneNumbers }
                        { websiteElement === '#poiDesc .space' ? websiteInfo : null }
                    </div>
                    { reportAnIssueButton }
                </div>
            </PerfectScrollbar>
        )

    }, [
        bookByOutlook,
        favoritesButtonClick,
        parkingButtonClick,
        poi,
        pois,
        project,
        reserveWorkstation,
        selectMarker,
        shareButtonClick,
        showAvailability,
        showMap,
        galleries,
        addMarkerGallery,
        isFavorite,
        isParking,
        reportAnIssueModal,
        handleDirection,
        backToMenu
    ]);

    const imagePostProcess = React.useCallback(() => {
        setTimeout(() => {
            execValiant360();
        }, 400);
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.IMAGES_POST_PROCESS, imagePostProcess);

        // initialise favorites state
        const favorites = Favorites.getFavorites(project.pid);
        const isFavoriteInitialState: boolean = favorites?.length && favorites.includes(poi.poid)
        setIsFavorite(isFavoriteInitialState)

        // initialise parking state
        const parking = Favorites.getParking(project.pid);
        const isParkingInitialState: boolean = parking && parking === poi.poid
        setIsParking(isParkingInitialState)

        return () => {
            cleanupFunction.current = true;
        }
    }, [cleanupFunction, imagePostProcess, poi, project]);

    resetSearch();
    return (
        <>
            { renderMarkerCard() }
            <ShareModal link={shareLink.current} />
            <ReportAnIssue project={project}/>
        </>
    );
}

export default MarkerCard;
