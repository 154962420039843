import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import md5 from 'crypto-js/md5';
import loader from '@assets/images/others/loader-1.gif';
import { Events } from '@constants';
import { post, staffLogin } from '@api';
import { IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";

import './styles.scss';
import { saveStaff } from "../../helpers/staff";

export interface IStaffLogin {
    project: IProject
}

export interface IStaff {
    token: string;
}

const StaffLogin: FC<IStaffLogin> = (props: PropsWithChildren<IStaffLogin>) => {
    const {
        project
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const formRef = React.useRef(null);
    const error = React.useRef('');

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    const onSubmit = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setHasError(false);
            setLoading(true);
        }
        const form: HTMLFormElement | undefined = formRef.current || undefined;
        const formData = form ? new FormData(form) : null;

        if(!formData) {
            setLoading(false);
            return;
        }

        const body = {
            password: formData.get('password'),
            project: project.pid,
            userId: formData.get('userid')
        };

        if(!body.userId || !body.password) {
            error.current = (window as any).lang.getString('userNameOrPasswordIsEmpty');
            setHasError(true);
            setLoading(false);
            return;
        }

        const path: string = staffLogin(project.pid);
        post(path, body, { method: 'post', body: JSON.stringify(body), headers: {
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            if (!cleanupFunction.current) {
                setLoading(false);
            }

            if(response.parsedBody.message === 'Given credentials do not match the records') {
                error.current = (window as any).lang.getString('invalidUserNameOrPassword');
                setHasError(true);
                return;
            }

            if(response.parsedBody.message === 'Successfully logged in'
                && body.userId && body.password
            ) {
                project.staffUserId = body.userId;
                project.staffPassword = body.password;

                const staff: IStaff = {
                    token: ''
                }
                staff.token = md5(`${body.userId}${body.password}`).toString();
                saveStaff(project.pid, staff.token);
            }

            Emitter.emit(Events.MENU_UPDATE, null);
            Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
            Emitter.emit(Events.SCROLL_CATEGORIES_UPDATE, null);

            onClose();
        });
    }, [setLoading, onClose, project]);

    const onKeyPress = React.useCallback((e) => {
        if(e.which === 13 || e.keyCode === 13) {
            onSubmit();
        }
    }, [onSubmit]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        Emitter.on(Events.OPEN_STAFF_LOGIN, (newValue) => {
            if (!cleanupFunction.current) {
                setShow(newValue);
                setHasError(false);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <>
            <Modal show={show} animation={false} centered id="staff_login" onHide={() => false }>
                <Modal.Body className="modal-width">
                    {loading &&
                    <div className="staff-form-loading">
                        <img src={ loader } alt="loader" className="loader-img"/>
                    </div>
                        }
                    <div className="staff-login-modal">
                        <h4>{ (window as any).lang.getString('titleStaffLogin') }</h4>
                        <form autoComplete="off" ref={formRef}>
                            <div className="staff-login-form">
                                <div className="staff-login-content">
                                    <div className="staff-form-control">
                                        <input type="text" name="userid" className="staff-name" placeholder={(window as any).lang.getString('StaffUserName')} onKeyUp={(e) => onKeyPress(e)}/>
                                    </div>
                                    <div className="staff-form-control">
                                        <input type="password" name="password" className="staff-passw" placeholder={(window as any).lang.getString('StaffPassword')} onKeyUp={(e) => onKeyPress(e)}/>
                                    </div>
                                </div>
                                <div className="staff-login-buttons">
                                    <button type="button" className="cancel" onClick={onClose}>
                                        <span>{ (window as any).lang.getString('btnCancel') }</span>
                                    </button>
                                    <button type="button" onClick={onSubmit}>
                                        <span>{ (window as any).lang.getString('btnOk') }</span>
                                    </button>
                                </div>
                                {hasError && <div className="staff-login-error-container"><span className="staff-login-error">{ error.current }</span></div>}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default StaffLogin;
