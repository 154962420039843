import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from 'react-bootstrap/Tooltip';
import Emitter from "../../helpers/emitter";
import { Events, mobile } from "../../constants";
import { useForceUpdate } from "../../hooks";
import { post, reportAnIssue } from "../../api";
import { IProject } from "../../interfaces";
import { getBrowser, getDeviceType, getOS } from "../../helpers/utils";
import ThankYouModal from "../ThankYouModal";

export interface IReportAnIssue {
    project: IProject
}

interface IUploadFiles {
    fileData: string;
    fileExtension: string;
    fileName: string;
}

const ReportAnIssue: FC<IReportAnIssue> = (props: PropsWithChildren<IReportAnIssue>) => {
    const {
        project
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const filesUploadState = React.useState([]);
    const filesToUpload: any = filesUploadState[0];
    const setFilesToUpload: any = filesUploadState[1];
    const forceUpdate = useForceUpdate(cleanupFunction);
    const issueFile = React.useRef(null);
    const issueTextarea = React.useRef(null);
    const target = React.useRef(null);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
            setFilesToUpload([]);
            setSubmitDisabled(true);
        }

        const textarea: any = issueTextarea.current;
        if (textarea) textarea.value = '';
    }, [setShow, setFilesToUpload, setSubmitDisabled, issueTextarea]);

    const submitIssue = React.useCallback(() => {
        if (!cleanupFunction.current) setLoading(true);
        const body = {
            project: project.pid,
            browserVersion: getBrowser(),
            device: getDeviceType(),
            operatingSystem: getOS(),
            description: message,
            fileList: filesToUpload
        };
        const path: string = reportAnIssue();
        post(path, body, { method: 'post', body: JSON.stringify(body), headers: {
                'Content-Type': 'application/json'
            }
        }).then((data: any) => {
            onClose();
            if (!cleanupFunction.current) {
                setLoading(false);
                setResponse(data);
                setFilesToUpload([]);
                setSubmitDisabled(true);
            }

            const textarea: any = issueTextarea.current;
            if (textarea) textarea.value = '';

            Emitter.emit(Events.OPEN_THANK_YOU_MODAL, true);
        });
    }, [
        project,
        message,
        filesToUpload,
        onClose,
        setResponse,
        issueTextarea,
        setFilesToUpload,
        setSubmitDisabled,
        setLoading
    ]);

    const selectFile = React.useCallback(() => {
        const file: any = issueFile.current;
        if (file) file.click();
    }, [issueFile]);

    const removeFileItem = React.useCallback((file: IUploadFiles) => {
        const index = filesToUpload.findIndex((item: IUploadFiles) => item.fileName === file.fileName);
        filesToUpload.splice(index, 1);

        setFilesToUpload(filesToUpload);
        forceUpdate();
    }, [filesToUpload, setFilesToUpload, forceUpdate]);

    const selectedFiles = React.useCallback(() => filesToUpload.map((file: IUploadFiles) => (
            <li className="selected-file" key={file.fileName}>
                <span>{file.fileName}</span>
                <i className="far fa-times-circle"
                   onClick={() => removeFileItem(file)}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="Remove file item"
                />
            </li>
    )), [filesToUpload, removeFileItem]);

    const showAlert = React.useCallback(() => {
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
            setAlertMessage('');
        }, 5000);
    }, [setShowTooltip, setAlertMessage]);

    const uploadFiles = React.useCallback((e) => {
        if (filesToUpload.length > 3) {
            setAlertMessage((window as any).lang.getString('modalReportIssue_numFilesAlertMsg'));
            showAlert();

            return false;
        }

        const allowedExtensions: RegExp = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        const fileList = Array.from(e.target.files);

        fileList.forEach((file: any, index: number) => {
            if (!allowedExtensions.exec(file.name)) {
                setAlertMessage((window as any).lang.getString('modalReportIssue_fileTypeAlertMsg', [file.name]));
                showAlert();
                fileList.splice(index, 1);

                return false;
            }

            const reader = new FileReader();
            reader.onload = (event: any) => {
                const { result } = event.target;
                const fileObj: IUploadFiles = {
                    fileData: result.substring(result.indexOf(",") + 1),
                    fileExtension: file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1),
                    fileName: file.name
                };

                filesToUpload.push(fileObj);
                setFilesToUpload(filesToUpload);

                forceUpdate();
            }
            reader.readAsDataURL(file);

            if (filesToUpload.length > 3) {
                filesToUpload.pop();
                setFilesToUpload(filesToUpload);
                setAlertMessage((window as any).lang.getString('modalReportIssue_numFilesAlertMsg'));
                showAlert();

                return false;
            }

            return true;
        });

        return true;
    }, [
        filesToUpload,
        setFilesToUpload,
        forceUpdate,
        setAlertMessage,
        showAlert
    ]);

    const checkIssueMessage = React.useCallback((e) => {
        const msg = e.target.value;
        if (msg.length > 0) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }

        setMessage(msg);
    }, [setSubmitDisabled, setMessage]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_REPORT_AN_ISSUE, (newValue) => {
            if (!cleanupFunction.current) setShow(newValue)
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <>
            <Modal show={show} animation={false} centered id="report_an_issue" onHide={() => false }>
                <Modal.Body className="modal-width">
                    <div className="campus-modal">
                        {mobile && <div className="window-header">
                            <div className="window-header-left">
                                <button className="back-btn pull-left"
                                        type="button"
                                        onClick={onClose}
                                >
                                    <i className="back-btn-ico" />
                                </button>
                            </div>
                            <button className="btn btn-default srch-close-btn "
                                    type="button"
                                    onClick={onClose}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>}
                        <h4>{ (window as any).lang.getString('btnReportIssue') }</h4>
                        <i className="btns-ico warning-ico modal-report-issue-ico"/>
                        <div className="report-issue-form">
                            <div className="report-issue-content">
                                <span>{ (window as any).lang.getString('whatIsYourIssue') }?</span>
                                <textarea placeholder={ (window as any).lang.getString('describeTheIssueHere') }
                                          onKeyUp={(e) => checkIssueMessage(e)}
                                          ref={issueTextarea}
                                />
                                <button type="button"
                                        className="upload-image"
                                        onClick={selectFile}
                                        ref={target}
                                >
                                    <i className="camera-ico"/>
                                    <span>{ (window as any).lang.getString('modalReportIssue_issueImageText') }</span>
                                    <input type="file" accept="image/*"
                                           className="issue-file"
                                           name="issue-file[]"
                                           id="issue-file"
                                           multiple={true}
                                           ref={issueFile}
                                           onChange={(e) => uploadFiles(e)}
                                    />
                                    <Overlay target={target.current} show={showTooltip} placement="top">
                                        {(tooltipProps) => (
                                            <Tooltip id="alert-msg" {...tooltipProps}>
                                                { alertMessage }
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                </button>
                                {
                                    filesToUpload && filesToUpload.length ?
                                    (
                                        <>
                                            <span>
                                                { (window as any).lang.getString('selectedFiles') }:
                                            </span>
                                            <ul>
                                                {selectedFiles()}
                                            </ul>
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className="report-issue-buttons">
                                <div className="btn-toolbar">
                                    <button type="button" className="cancel" onClick={onClose}>
                                        <span>{ (window as any).lang.getString('btnCancel') }</span>
                                    </button>
                                    <button type="button"
                                            className={submitDisabled ? "submit disabled" : "submit available"}
                                            disabled={submitDisabled}
                                            onClick={submitIssue}
                                    >
                                        {loading ? <i className="fa fa-refresh fa-spin spreo-btn-loader" /> : null}
                                        <span>{ (window as any).lang.getString('btnSubmit') }</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ThankYouModal response={response} />
        </>
    );
};

export default ReportAnIssue;
