/* eslint no-eval: 0 */

class StateStorage {
    /**
     * @return {String}
     */
    static getPrefix(): string {
        return StateStorage.getCurrentSession() || 'defaultSession';
    }

    /**
     * @param {String} value
     */
    static setLastSessionHash(value: string): void {
        window.localStorage.setItem('lastSessionHash', value);
    }

    /**
     * @returns {String | null}
     */
    static getLastSessionHash(): string | null {
        return window.localStorage.getItem('lastSessionHash') || null;
    }

    /**
     * @param {String} value
     */
    static setCurrentSession(value: string): void {
        StateStorage.setLastSessionHash(value);
        window.sessionStorage.setItem('currentSession', value);
    }

    /**
     * @return {String}
     */
    static getCurrentSession(): string | null {
        let currentSession: string | null = window.sessionStorage.getItem('currentSession');

        if (!currentSession) {
            currentSession = StateStorage.getLastSessionHash();

            if (currentSession) {
                StateStorage.setCurrentSession(currentSession);
            }
        } else {
            StateStorage.setLastSessionHash(currentSession);
        }

        return currentSession;
    }

    /**
     * @return {Object}
     */
    static getStorage(): any {
        const v: string | null = window.localStorage.getItem(StateStorage.getPrefix());
        return v ? JSON.parse(v) : {};
    }

    /**
     * @param {String} name Possibly dot-separated.
     * @param {*} defaultValue
     */
    static getItem(name: string, defaultValue = '') {
        let storage = StateStorage.getStorage();
        const parts = name.split('.');

        for (let i = 0; i < parts.length; i++) {
            if (storage) {
                storage = storage[parts[i]];
            } else {
                return defaultValue;
            }
        }

        return storage;
    }

    /**
     * @param {String} name Possibly dot-separated.
     * @param {*} value
     */
    // eslint-disable-next-line no-unused-vars
    static setItem(name: string, value: string) {
        const parts = name.split('.');
        const storage = StateStorage.getStorage();

        parts.unshift('storage');

        let varName;
        for (let i = 1; i <= parts.length; i++) {
            varName = parts.slice(0, i).join('.');

            if (i === parts.length) {
                eval(`${varName} = value`);
            }
            else if (eval(`typeof ${varName}`) !== 'object') {
                eval(`${varName} = {}`);
            }
        }

        window.localStorage.setItem(StateStorage.getPrefix(), JSON.stringify(storage));
    }

    /**
     * @param {String} name Possibly dot-separated.
     */
    static removeItem(name: string) {
        const parts: string [] = name.split('.');
        const storage: [] = StateStorage.getStorage();
        parts.unshift('storage');

        let error: boolean = false;
        let varName: string = '';
        for (let i = 1; i <= parts.length; i++) {
            varName = parts.slice(0, i).join('.');
            const typeOfVar: string = eval(`typeof ${varName}`);
            if (typeOfVar === 'undefined') {
                error = true;
                break;
            }
        }

        if (!error) {
            eval(`delete ${varName}`);
            window.localStorage.setItem(StateStorage.getPrefix(), JSON.stringify(storage));
        }
    }

    /**
     * @param {String} name Possibly dot-separated.
     */
    static hasItem(name: string) {
        let storage: [] = StateStorage.getStorage();
        const parts: any [] = name.split('.');

        for (let i = 0; i < parts.length; i++) {
            if (storage) {
                storage = storage[parts[i]];
            } else {
                return false;
            }
        }

        return true;
    }
}

export default StateStorage;
