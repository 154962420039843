import { takeLatest, call, put, all } from 'redux-saga/effects';
import { get, poiAllListOtherAPI, poisListByLanguage } from '@api';
import { HttpResponse } from '@api/interfaces';
import { loaderActions, poiActions } from '@actions';
import { BaseAction, mapOptions } from '@constants';
import { updateOutdoorFloor } from '../helpers/utils';
import { IPoi, IPOIDs, IPOIsCategories } from '../interfaces';

interface IPois extends HttpResponse<Response> {
    parsedBody: any;
}

let outdoorFloor: number | null = mapOptions.defaultFloor;

function* requestPoi(action: BaseAction) {
    const { projectId } = action.payload;
    const language: string = localStorage.getItem('lang') || 'en';

    try {
        let params: string = '';

        if (language === 'es') {
            params = yield call(poisListByLanguage, projectId, language);
        } else {
            params = yield call(poiAllListOtherAPI, projectId);
        }

        const resPoi: IPois = yield call(get, params);

        yield put({
            type: poiActions.PoiActions.REQUESTPOISUCCESS,
            payload: resPoi.parsedBody,
        });

        const { poidMap, poids, pois, poisCategories }: IPOIDs = yield processPOI(resPoi.parsedBody);

        yield put({
            type: poiActions.PoiActions.REQUESTPOISUCCESS,
            payload: {
                pois,
                outdoorFloor,
                poids,
                poidMap,
                poisCategories
            },
        });
    } catch(err) {
        yield put({
            type: poiActions.PoiActions.REQUESTPOIERROR,
            payload: JSON.stringify(err),
        });
        yield put({
            type: loaderActions.LoaderEnum.LOADERRERROR,
            payload: {
                loading: false,
                mapOverlay: false,
            },
        });
    }
}

function processPOI(response: any): IPOIDs {
    const pois: IPoi [] = [];
    const poids: IPoi [] = [];
    const poidMap: IPoi [] = [];
    const poisCategories: IPOIsCategories = {};
    const recNum: number = response.length;

    for (let i: number = 0; i < recNum; i++) {
        const poi: any = response[i];

        if (poi.fsmAction) {
            const bookedPoidInLocal = localStorage.getItem('myBookedPoid');
            if (poi.poid === bookedPoidInLocal && poi.fsmAction.fsmFacilityState
                && poi.fsmAction.fsmFacilityState.fsmState && poi.fsmAction.fsmFacilityState.fsmState.id === 1) {
                localStorage.setItem('myBookedPoid', '');
                localStorage.setItem('bookedPOI', '');
            } else if (poi.fsmAction.fsmFacilityState && poi.fsmAction.fsmFacilityState.fsmState
                && poi.fsmAction.fsmFacilityState.fsmState.id === 2
                && poi.fsmAction.activator === localStorage.getItem('samlNameId')) {
                localStorage.setItem('myBookedPoid', poi.poid);
                localStorage.setItem("bookedPOI", JSON.stringify(poi));
            }
            poi.eventStartTime = poi.fsmAction.eventStartTime;
            poi.fsmFacilityState = poi.fsmAction.fsmFacilityState;
            poi.activator = poi.fsmAction.activator;
        }

        if (poi.language === undefined && poi.lang) poi.language = poi.lang;

        // Check through pois to determine floor of entrances/exits
        if(!sessionStorage.getItem('outdoorFloor')) {
            outdoorFloor = updateOutdoorFloor(poi, outdoorFloor);

            if (outdoorFloor) {
                sessionStorage.setItem('outdoorFloor', outdoorFloor.toString());
            }
        }

        poids.push(poi.poid);
        poidMap[poi.poid] = poi;

        if (poi.categories === undefined) poi.categories = '[]';

        const poiCategories: string[] = poi.categories.replace('[', '').replace(']', '').split(',');
        poiCategories.forEach(pC => {
            pC = pC.replace('##', '').replace('$$', '');

            if (poisCategories[pC] === undefined) poisCategories[pC] = { staffPoisNum: 0, poisNum: 0 };

            let { staffPoisNum, poisNum } = poisCategories[pC];

            if (poi.staffOnly) staffPoisNum += 1;

            poisNum += 1;

            poisCategories[pC] = {
                staffPoisNum,
                poisNum
            };
        })

        // Save all pois only with "Show on map" or "Show in search" option enabled
        if (poi.searchable || poi.visible) pois.push(poi);
    }

    return { pois, poids, poidMap, poisCategories };
}

function* PoiSaga() {
    yield takeLatest(poiActions.PoiActions.REQUESTPOI, requestPoi);
}

function* All() {
    yield all([PoiSaga()]);
}

export default All;
