import { Reducer } from 'redux';
import { poiActions } from '@actions';
import { BaseAction } from '@constants';

const initState: any = {
    floor: 0,
    zoom: 0,
};

const poiStore: Reducer<any, BaseAction> = (state = initState, action) => {
    switch (action.type) {
        case poiActions.PoiActions.REQUESTPOI:
        case poiActions.PoiActions.REQUESTPOIERROR:
        case poiActions.PoiActions.REQUESTPOISUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default poiStore;
