import React, { PropsWithChildren } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import './map.scss';
import { uiSettings, Events, TabNames, mobile, BackStates, mapOptions } from '@constants';
import gMapStyle from './gmapStyle.json';
import {
    IBuilding,
    ICampus,
    IGallery,
    IPoi,
    IPoiCategory,
    IPOIsCategories,
    IPolygon,
    IReportAnalyticsRequest,
} from '../../interfaces';
import {
    getProjectBounds,
    boundsChange,
    addTileOverlay,
    changeViewFloor,
    getShowInMap,
    startExpiryTimeout,
    removeExpiry,
    isGuest,
    resetFilter,
    centerCampus,
    generateRandomId, reportAnalytics
} from '../../helpers/utils';
import LabelMarker from "../LabelMarker";
import FloatingPanel from "../FloatingPanel";
import MapFilter from "../MapFilter";
import Sidebar from "../Sidebar";
import Occupancy from "../../helpers/occupancy";
import ScrollCategories from "../ScrollCategories";
import Emitter from "../../helpers/emitter";
import { parseURLParameters, resetSearch } from "../../helpers/marker";
import PrintLoader from "../PrintLoader";
import HowTo from "../HowTo";

interface IMatchParams {
    projectId: string;
}

interface IGMapProps extends RouteComponentProps<IMatchParams> {
    googleMapsApiKey: string;
    center: { lat: number; lng: number };
    zoom: number;
    floor: number;
    project: any;
    pois: IPoi [];
    poidMap: IPoi [];
    poisCategories: IPOIsCategories;
    changeFloor: (floor: string | number) => void;
    changeLoading: (loading: boolean) => void;
    requestOccupancy: (projectId: string) => void;
    categories: IPoiCategory [];
    polygons: IPolygon [];
    galleries?: IGallery [][];
    buildings: IBuilding [];
    projects: ICampus [];
}

/**
 * Display google map
 * @param {PropsWithChildren<IGMapProps>} props
 * @constructor
 */
const GMap: React.FC<IGMapProps> = (props: PropsWithChildren<IGMapProps>) => {
    const {
        googleMapsApiKey,
        center,
        zoom,
        floor,
        match: {
            params: { projectId },
        },
        project,
        pois,
        poisCategories,
        poidMap,
        changeFloor,
        changeLoading,
        categories,
        polygons,
        requestOccupancy,
        galleries,
        buildings,
        projects
    } = props;

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        version: '3.53',
        googleMapsApiKey
    });

    const [currentZoom, setCurrentZoom] = React.useState(zoom);
    const [currentFloor, setCurrentFloor] = React.useState(floor);
    const [occupancy, setOccupancy] = React.useState(null);
    const parseUrlDone = React.useRef(false);
    const [backState, setBackState] = React.useState({ state: BackStates.MAP, fromPoi: null, toPoi: null }) // uses for passing 'showMarkerInfo' the back state for back button
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const mounted = React.useRef(false);

    const facilityNames: any = React.useRef({});

    const initFacilityNamesObject = React.useCallback(() => {
        buildings.forEach((building: IBuilding) => {
            facilityNames.current[building.fid] = building.fname
        });
    }, [
        buildings,
        facilityNames
    ]);

    const fitMap = React.useCallback(() => {
        const { google } = window;
        const { gmap } = (window as any);
        if (project.bounds === null) {
            project.bounds = getProjectBounds(project);
        }
        boundsChange(project, gmap, google);

        // Zoom changed
        gmap.addListener("zoom_changed", () => {
            setCurrentZoom(gmap.getZoom());
        });

        // Fit bounds
        gmap.fitBounds(project.bounds);

        setTimeout(() => {
            centerCampus(project);
            if (gmap.getZoom() < 16) gmap.setZoom(16);
        }, 300);
    }, [project, setCurrentZoom]);

    const [options, setOptions] = React.useState({});
    const loadMap = React.useCallback(() => {
        const { google } = window;
        setOptions({
            styles: gMapStyle,
            minZoom: 16,
            maxZoom: 22,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

        addTileOverlay(floor, projectId);
        fitMap();

        setTimeout(() => {
            const outdoorFloor = sessionStorage.getItem('outdoorFloor') || mapOptions.defaultFloor;
            if (outdoorFloor) {
                changeFloor(Number(outdoorFloor));
            }

            setMapLoaded(true);
        }, 500);
    }, [
        setMapLoaded,
        floor,
        projectId,
        fitMap,
        changeFloor
    ]);

    const onLoad = React.useCallback(
        (gmap) => {
            Object.defineProperty(window, 'gmap', {
                writable: true,
                configurable: false,
                enumerable: true,
                value: gmap
            });

            sessionStorage.setItem('navigate.state', 'z');
            localStorage.removeItem('currentCategoryName');
        },
        []
    );

    const tilesLoaded = React.useCallback(() => loadMap(), [loadMap]);

    const mapProcess = React.useCallback(() => {
        const { gmap } = (window as any);
        if (isLoaded && gmap) {
            if (mobile) {
                setTimeout(() => {
                    Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null);
                }, 1000);
            }

            changeViewFloor(currentFloor, floor, projectId);
            setCurrentFloor(floor);

            gmap.addListener("dragend", () => {
                Emitter.emit(Events.MAP_DRAG_END, null);
            });

            gmap.addListener("idle", () => {
                Emitter.emit(Events.MAP_DRAG_END, null);
            });
        }
    }, [
        isLoaded,
        projectId,
        floor,
        currentFloor,
        setCurrentFloor
    ])

    React.useEffect(() => {
        const { google } = window;

        window.onload = () => {
            if(!isGuest()) startExpiryTimeout(removeExpiry);
        }

        if (mobile) {
            Emitter.on(Events.OPEN_TAB, tabData => setThirdScreen(tabData)); // emitter event for adding third-screen class
            Emitter.on(Events.GO_NOW, data => {
                setThirdScreen();
                setBackState({ state: BackStates.GO_NOW, fromPoi: data.fromPoi, toPoi: data.toPoi })
            });
            Emitter.on(Events.EXIT_THIRD_SCREEN, () => stopThirdScreen());
            Emitter.on(Events.DISPLAY_ROUTE, data => setBackState({ state: BackStates.NAVIGATE, fromPoi: data.fromPoi, toPoi: data.toPoi }))
            Emitter.on(Events.CLOSE_SIDEBAR, () => setBackState({ state: BackStates.MAP, fromPoi: null, toPoi: null }));
        }

        if (localStorage.getItem('howTo_showed') !== 'yes') {
            Emitter.emit(Events.OPEN_HOWTO_MODAL, true);
        }

        setTimeout(() => {
            mapProcess();
        }, 500);

        if(buildings) {
            initFacilityNamesObject();
        }

        if (project && isLoaded) {
            const projectBounds = new google.maps.LatLngBounds();
            projectBounds.extend(new google.maps.LatLng(
                project.projectBoundariesDto.topLeftLatitude,
                project.projectBoundariesDto.topLeftLongitude
            ));
            projectBounds.extend(new google.maps.LatLng(
                project.projectBoundariesDto.bottomRightLatitude,
                project.projectBoundariesDto.bottomRightLongitude
            ));
            project.bounds = projectBounds;

            resetFilter(project);

            if(!mounted.current) {
                const data: string = generateRandomId();
                const request: IReportAnalyticsRequest = {
                    action: "new_session",
                    clientId: data,
                    platform: "web",
                    data,
                    project: project.pid,
                    campus: project.campus.cid,
                    facility: ""
                };

                reportAnalytics(request);
                mounted.current = true;
            }
        }

        if (!parseUrlDone.current &&
            poidMap &&
            project &&
            pois) {

            setTimeout(() => {
                changeLoading(false);
                Emitter.emit(Events.MAP_DRAG_END, null);
            }, 1500);

            setTimeout(() => {
                parseURLParameters(poidMap, project, changeFloor, categories, changeLoading);
            }, 1500);

            parseUrlDone.current = true;
        }

        if (project && uiSettings.corporate) {
            sessionStorage.setItem('projectCid', project.cid);
            requestOccupancy(projectId);
        }

        // TODO need to load occupancy map and initiate occupancy instance for corporate version
        const occupancyInstance: any = new Occupancy(pois);
        occupancyInstance.start(polygons, []);
        setOccupancy(occupancyInstance);

        Emitter.on(Events.CLOSE_SIDEBAR, () => {
            Emitter.emit(Events.CLOSE_TAB, null);
        });
    }, [
        floor,
        projectId,
        isLoaded,
        currentFloor,
        polygons,
        project,
        requestOccupancy,
        pois,
        poisCategories,
        changeLoading,
        changeFloor,
        parseUrlDone,
        categories,
        poidMap,
        backState,
        initFacilityNamesObject,
        buildings,
        mapProcess
    ]);

    window.addEventListener('DOMContentLoaded', () => {
       resetSearch();
    });

    // useState statement for modifying main class
    const [mapContainerClass, setMapContainerClass] = React.useState("spreo-map")

    const onUnmount = React.useCallback(() => {
        // do your stuff before map is unmounted
    }, []);

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    // add css class for third-screen mode
    const setThirdScreen = (tabData?: any) => {
        if ([
            TabNames.BUILDINGS,
            TabNames.CATEGORIES,
            TabNames.FAVORITES,
            TabNames.INFO,
            TabNames.PARKING
        ].includes(tabData?.tabName) || (
            tabData?.tabName === TabNames.FIND && getShowInMap()
        )) {
            setMapContainerClass('spreo-map third-screen')
        }

        // case for in-route (Go Now) mode
        if (!tabData) {
            setMapContainerClass('spreo-map third-screen-route')
        }
    }

    // remove 'third-screen' css class
    const stopThirdScreen = () => {
        setMapContainerClass('spreo-map')
    }

    return isLoaded && project && categories && galleries ? (
        <>
            <PrintLoader />
            <Sidebar pois={pois}
                     project={project}
                     occupancy={occupancy}
                     floor={floor}
                     changeFloor={changeFloor}
                     galleries={galleries}
                     buildings={buildings}
                     projects={projects}
                     categories={categories}
                     poisCategories={poisCategories}
                     poidMap={poidMap}
                     changeLoading={changeLoading}
                     facilityNames={facilityNames.current}
            />
            <ScrollCategories categories={categories}
                              poisCategories={poisCategories}
                              project={project}
                              floor={floor}
                              changeFloor={changeFloor}
                              pois={pois}
            />
            <GoogleMap mapContainerClassName={mapContainerClass}
                       center={ center }
                       zoom={ zoom }
                       onLoad={ onLoad }
                       onUnmount={ onUnmount }
                       tilt={ 0 }
                       options={ options }
                       onTilesLoaded={ !mapLoaded ? tilesLoaded : undefined }
            >
                <LabelMarker pois={ pois }
                             project={ project }
                             zoom={ currentZoom }
                             floor={ floor }
                             poidMap={ poidMap }
                             backState={ backState }
                />
                <FloatingPanel project={ project }
                               floor={ floor }
                               changeFloor={ changeFloor }
                />
            </GoogleMap>
            <MapFilter categories={ categories } poisCategories={ poisCategories } project={ project } />
            <HowTo />
        </>
    ) : (
        <></>
    );
};

export default withRouter(GMap);
