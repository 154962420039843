import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BackStates, Events, mobile, NavigatePoints, projectData, TabModes, TabNames } from '@constants';
import { IPoi, IPoiCategory, IProject, IReportAnalyticsRequest } from '../../interfaces';
import {
    arrayUnique,
    calculateNumbersOnFloor, checkMarkerStaffOnlyToShow, generateRandomId,
    getFacilityAndFloor,
    getShowInMap, removeNullElements, reportAnalytics,
    sortByDistanceToPOI,
    sortByFacility,
    sortByPOIName
} from '../../helpers/utils';

import './markerList.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { selectPoi } from "../../helpers/marker";
import Emitter from "../../helpers/emitter";
import StateStorage from "../../helpers/stateStorage";
import FilteredBlock from "../FilteredBlock";

export interface IMarkerList {
    markers: any [];
    project: IProject;
    pois: any [];
    sort: boolean;
    floor: number;
    changeFloor: (floor: number) => void;
    fromNavigate?: boolean;
    navigatePoint?: string;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    fromCategory?: boolean;
    fromNavSearch?: boolean;
    navObject?: any;
    facilityNames?: any;
    category?: IPoiCategory;
    cname?: string;
    fromSearch?: boolean;
    referrer?: string;
}

const MarkerList: FC<IMarkerList> = (props: PropsWithChildren<IMarkerList>) => {
    const {
        markers,
        project,
        pois,
        sort,
        floor,
        changeFloor,
        fromNavigate,
        navigatePoint,
        fromCategory,
        navObject,
        fromNavSearch,
        facilityNames,
        category,
        cname,
        fromSearch,
        referrer
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [, setCurrentFloor] = React.useState(floor);
    const [filterBuilding, setFilterBuilding] = React.useState((window as any).lang.getString('modalFilter_selectOptionAny'));
    const [filterFloor, setFilterFloor] = React.useState((window as any).lang.getString('modalFilter_selectOptionAny'));
    const Any: string = (window as any).lang.getString('modalFilter_selectOptionAny');

    const setFilters = React.useCallback(() => {
        if(fromCategory) return false;

        const filterBuildingStorage = project ? StateStorage.getItem(`${project.pid}.filter.building`, Any) : Any;
        const filterFloorStorage = project ? StateStorage.getItem(`${project.pid}.filter.floor`, Any) : Any;
        setFilterBuilding(filterBuildingStorage);
        setFilterFloor(filterFloorStorage);

        return true;
    }, [
        project,
        setFilterBuilding,
        setFilterFloor,
        Any,
        fromCategory
    ])

    const toggleShowInMap = React.useCallback((e) => {
        e.preventDefault();
        localStorage.setItem('showInMap', JSON.stringify(!getShowInMap()));

        if (getShowInMap()) {
            const filterBuildingStorage = project ? StateStorage.getItem(`${project.pid}.filter.building`, Any) : Any;
            const filterFloorStorage = project ? StateStorage.getItem(`${project.pid}.filter.floor`, Any) : Any;

            Emitter.emit(Events.SHOW_FILTERED_BLOCK, {
                project,
                filterBuilding: filterBuildingStorage,
                setFilterBuilding,
                filterFloor: filterFloorStorage,
                setFilterFloor
            });
        }

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.FIND,
            tabMode: TabModes.OPEN_SEARCH,
            tabData: null,
            tabBackButton: true,
            tabCloseButton: true,
            tabFilterButton: true
        });
        Emitter.emit(Events.HANDLE_SEARCH, null);

        if (getShowInMap()) {
            Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
        } else {
            Emitter.emit(Events.SHOW_CATEGORY_BAR, null);
        }
    }, [
        Any,
        project,
        setFilterBuilding,
        setFilterFloor
    ]);

    const toggleSearchFocus = React.useCallback((e) => {
        Emitter.emit(Events.UNSET_SEARCH_FOCUS, null);
    }, []);

    const clickPoiBtn = React.useCallback((marker: any) => {
        if (fromNavigate) {
            if (fromNavSearch) {
                let tabData;
                if (navigatePoint === NavigatePoints.START_POINT) {
                    tabData = { fromPoi: marker, navObject, referrer };
                } else {
                    tabData = { toPoi: marker, navObject, referrer };
                }

                localStorage.setItem('search', '');
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData,
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            } else {
                Emitter.emit(Events.SET_NAV_POI, { poi: marker })
                if (mobile) {
                    Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
                }
            }
        } else {
            if(fromSearch) {
                const clientId: string = generateRandomId();
                const request: IReportAnalyticsRequest = {
                    action: "search",
                    clientId,
                    platform: "web",
                    data: marker.description,
                    project: project.pid,
                    campus: project.campus.cid,
                    facility: marker.facility,
                    floor: marker.floor
                };

                reportAnalytics(request);
            }

            const allPois = pois;
            allPois && allPois.length && allPois.forEach((poi) => {
                if (poi.poid === marker.poid && (poi.categories === `[${ (window as any).lang.getString('findTab_categoryDesks') }]` || poi.categories === `[${ (window as any).lang.getString('findTab_categoryWorkstations') }]`)) {
                    if (poi.eventStartTime && poi.fsmFacilityState) {
                        marker.activator = poi.activator;
                        marker.eventStartTime = poi.eventStartTime;
                        marker.fsmFacilityState = poi.fsmFacilityState;
                    }
                }
            });

            if (!cleanupFunction.current) setCurrentFloor(marker.floor);

            Emitter.emit(Events.SET_CURRENT_POI, marker);
            selectPoi(marker, project, changeFloor, false, '', {
                state: fromCategory ? BackStates.CATEGORY_POI_LIST : BackStates.MARKER_LIST,
                cname,
                category
            }, floor);
        }
    }, [
        project,
        setCurrentFloor,
        changeFloor,
        pois,
        fromNavigate,
        navigatePoint,
        navObject,
        fromNavSearch,
        cname,
        category,
        fromCategory,
        fromSearch,
        referrer,
        floor
    ]);

    const markerButton = React.useCallback((marker) => {
        const markerInfo = getFacilityAndFloor(project, marker.facility, marker.floor);
        const markerInfoHelpBlock: string = marker.x === 0 && marker.y === 0 || (marker.navtype === 'OUTDOOR' || !marker.navtype) ?
          `` :
          `${(window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname])}`;
        let keywordsMatch: any;

        if (marker.matchedKeywords) {
            keywordsMatch = (
                <div className={project.userLang === 'he' ? 'matched-keywords right-dir' : 'matched-keywords'}>
                    {(window as any).lang.getString('search_matchedKeywords')} {marker.matchedKeywords}
                </div>
            );
        }

        const className = 'spreo-poi-btn btn-default btn-block';
        const angleClassName = project.userLang === "he" ? 'fa fa-angle-left pull-left' : 'fa fa-angle-right pull-right';

        if (marker.categories === `[${(window as any).lang.getString('findTab_categoryDesks')}]` ||
            marker.categories === `[${(window as any).lang.getString('findTab_categoryWorkstations')}]`) {
            let colorClass = "green";

            if (marker.fsmFacilityState && marker.fsmFacilityState.color) {
                colorClass = marker.fsmFacilityState.color.toLowerCase();
            }

            let colorClassName = project.userLang === "he" ? 'pull-right right-dir' : 'pull-left';
            colorClassName += ` dot dot-${colorClass}`;

            return (
                <button type="button"
                        className={ project.userLang === "he" ? `${ className } text-right` : `${ className } text-left` }
                        onClick={ () => clickPoiBtn(marker) }
                        key={ marker.id }
                >
                    <div className="recent-info">
                        <div>
                            <span id={`dot-${marker.poid}`} className={colorClassName} />
                        </div>
                        <div className="recent-info-text">
                            <span className="poi-description">{ marker.description }</span>
                            {markerInfoHelpBlock ? <span className="poi-info">
                                { (window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname]) }
                            </span> : ''}
                            {keywordsMatch}
                        </div>
                        <div className={project.userLang === "he" ? "angle-left" : ""}>
                            <i className={angleClassName} aria-hidden="true" />
                        </div>
                    </div>
                </button>
            );
        }

        const iconURL = `${project.url}/middle/ios/resources/${project.pid}/${marker.campus}/${marker.facility}/icons/${marker.iconUrl}`;
        const markerURL = marker.iconUrl.indexOf(project.url) === -1 ? iconURL : marker.iconUrl;

        return (
            <button type="button"
                    className={ project.userLang === "he" ? `${ className } text-right` : `${ className } text-left` }
                    onClick={ () => clickPoiBtn(marker) }
                    key={ marker.id }
            >
                <div className={`recent-info ${!markerInfoHelpBlock ? 'no-info-block' : ''}`}>
                    {!fromCategory && <div className={project.userLang === "he" ? "image-ico-right" : ""}>
                        <img src={markerURL} alt="Marker Icon"/>
                    </div>}
                    <div className={project.userLang === "he" ? "recent-info-text info-right" : "recent-info-text"}>
                        <span className={`poi-description ${!markerInfoHelpBlock ? 'no-info-block' : ''}`}>{ marker.description }</span>
                        {markerInfoHelpBlock ? <span className="poi-info">
                            { markerInfoHelpBlock }
                        </span> : ''}
                        {keywordsMatch}
                    </div>
                    <div className={project.userLang === "he" ? "angle-left" : ""}>
                        <i className={angleClassName} aria-hidden="true" />
                    </div>
                </div>
            </button>
        );
    }, [
        project,
        clickPoiBtn,
        fromCategory
    ]);

    const markerMyEvent = React.useCallback((marker: any, index: number): any => {
        let colorClass = "green";

        if (marker.fsmFacilityState && marker.fsmFacilityState.color) {
            colorClass = marker.fsmFacilityState.color.toLowerCase();
        }
        const markerInfo = getFacilityAndFloor(project, marker.facility, marker.floor);
        const firstButtonClassName = `spreo-poi-btn btn-default btn-block my-event-class my-event-btn category-event${marker.poid}`;
        const buttonClassName = `spreo-poi-btn btn-default btn-block my-event-class`;
        const colorClassName = `pull-left dot dot-${colorClass}`;
        const markerInfoHelpBlock: string = marker.x === 0 && marker.y === 0 || (marker.navtype === 'OUTDOOR' || !marker.navtype) ?
          `` :
          `${(window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname])}`;

        return !index ? (
            <button type="button" id={marker.poid} className={project.userLang === "he" ? `${firstButtonClassName} text-right` : `${firstButtonClassName} text-left`} key={marker.id}>
                <span className="poi-descr first-my-event">{marker.description}</span>
                <span id={`dot-${marker.poid}`} className={colorClassName} />
                <i className='fa fa-angle-right pull-right' aria-hidden="true" />
                {markerInfoHelpBlock ? <span className="spreo-poi-span">
                    { markerInfoHelpBlock }
                </span> : ''}
            </button>
        ) : (
            <button type="button" id={marker.poid} className={project.userLang === "he" ? `${buttonClassName} text-right` : `${buttonClassName} text-left`} key={marker.id}>
                <span className="poi-descr">{marker.description}</span>
                <span id={`dot-${marker.poid}`} className={colorClassName} />
                <i className='fa fa-angle-right pull-right' aria-hidden="true" />
                {markerInfoHelpBlock ? <span className="spreo-poi-span">
                    { markerInfoHelpBlock }
                </span> : ''}
            </button>
        );


    }, [project]);

    const profileButton = React.useCallback((marker: any, sharing_enabled: boolean) => {
        let text = (window as any).lang.getString('marker_locationNotAvail');

        if (sharing_enabled && marker.lat !== -1 && marker.shared) {
            const info = getFacilityAndFloor(project, marker.fid, marker.floor);
            text = ` ${info.facility}, ${info.floorname}`;
        }

        return (
            <button type="button" id={`user${marker.id}`} className='spreo-poi-btn btn-default btn-block text-left' key={marker.id}>
                <span className="poi-descr">{`${marker.first_name} ${marker.last_name}`}</span>
                <img src={marker.icon_url} alt="" className='pull-left'/>
                <i className='fa fa-angle-right pull-right' aria-hidden="true" />
                <span className="spreo-poi-span">
                    {text}
                </span>
            </button>
        );
    }, [project]);

    const emptyResult = React.useCallback(() => {
        const floorBadges: any = document.querySelectorAll('.selected-floor-badge');
        if(floorBadges && floorBadges.length) {
            setTimeout(() => {
                Emitter.emit(Events.RESET_FLOORS_MAP, null);
            }, 300);
        }

        const search: string = localStorage.getItem('search') || '';
        return (
            <div className="empty-search-intro">
                <p>
                    <i className="fas fa-search" />
                </p>
                <p>
                    {(window as any).lang.getString('markerList_weCouldNotFind')}{ search }{(window as any).lang.getString('markerList_pleaseTryAnotherSearch')}
                </p>
            </div>
        );
    }, [])

    const addMarkerList = React.useCallback(() => {
        if (!fromNavigate && !fromCategory && (!markers || !markers.length)) {
            return emptyResult();
        }
        let processMarkers: any [] = arrayUnique(markers);
        const { floorNames } = project;

        // Filter by building and floor
        if (filterBuilding !== Any && filterFloor !== Any) {
            processMarkers = processMarkers.filter((item: IPoi) => facilityNames && facilityNames[item.facility] === filterBuilding && `L${floorNames[item.floor]}` === filterFloor);
        }
        if (filterBuilding !== Any && filterFloor === Any) {
            processMarkers = processMarkers.filter((item: IPoi) => facilityNames && facilityNames[item.facility] === filterBuilding);
        }
        if (filterBuilding === Any && filterFloor !== Any) {
            processMarkers = processMarkers.filter((item: IPoi) => `L${floorNames[item.floor]}` === filterFloor);
        }

        // If there's a number in first element, sort numerically, if not sort alphabetically
        const userLocation: any = localStorage.getItem("user.location") || null;
        const booked: any = localStorage.getItem('bookedPOI') || '';
        const favorites: any = localStorage.getItem('favorite.spaces') || null;

        const userPOI: any = userLocation ? JSON.parse(userLocation) : null;
        const bookedPOI: any = booked ? JSON.parse(booked) : null;
        const favoriteSpaces: any = favorites ? JSON.parse(favorites) : null;

        let isFavoriteNeighborhoodExists = false;
        let neighborhoodPOI;

        if (project.isSearch) {
            if (favoriteSpaces !== null && favoriteSpaces.length) {
                const firstPOID: string = favoriteSpaces[0];
                const poiData: any = pois.filter((poi: IPoi) => poi.poid === firstPOID);

                if (poiData.categories === `[${(window as any).lang.getString('findTab_categoryNeighborhoods')}]`) {
                    isFavoriteNeighborhoodExists = true;
                    neighborhoodPOI = poiData;
                }
            }
        }

        if (bookedPOI && project.isSearch) {
            processMarkers = sortByDistanceToPOI(processMarkers, bookedPOI);
        }
        else if (isFavoriteNeighborhoodExists && project.isSearch) {
            processMarkers = sortByDistanceToPOI(processMarkers, neighborhoodPOI);
        }
        else if (!localStorage.myBookedPoid && !userPOI && !localStorage.nearestNeighborhoodSort) {
            if (project.currentBuilding) {
                const { fid } = project.facilities[project.currentBuilding];

                const buildingPoi = processMarkers.filter(marker => marker.facility === fid);
                buildingPoi.sort(sortByFacility);

                const otherPoi = processMarkers.filter(marker => marker.facility !== fid);
                otherPoi.sort(sortByPOIName);

                processMarkers = [...buildingPoi, ...otherPoi];
            } else if(sort) {
                processMarkers.sort(sortByPOIName);
            }
        }

        if (!fromNavigate && !fromCategory) {
            const floorsMap: any = calculateNumbersOnFloor(processMarkers, project);
            if (floorsMap && floorsMap.size) {
                setTimeout(() => {
                    Emitter.emit(Events.SHOW_FLOORS_MAP, floorsMap);
                }, 300);
            }
        }

        project.isSearch = false;
        const updatedPois = pois;
        const userLang = processMarkers.some((item: any) => item.lang === project.userLang) ? project.userLang : projectData.defaultLang;
        let markerList = processMarkers.map((marker: any, index: number) => {
            // Update all markers with the current pois.
            if (marker.categories === `[${(window as any).lang.getString('findTab_categoryDesks')}]` ||
                marker.categories === `[${(window as any).lang.getString('findTab_categoryWorkstations')}]`) {
                updatedPois.length && updatedPois.forEach((poi) => {
                    if (marker.poid === poi.poid && poi.fsmFacilityState && poi.fsmFacilityState) {
                        marker.eventStartTime = poi.eventStartTime || {};
                        marker.fsmFacilityState = poi.fsmFacilityState || {};
                        marker.activator = poi.activator || "";
                        let color = "";
                        if (poi.fsmFacilityState?.color) {
                            switch(poi.fsmFacilityState.color) {
                                case 'Red':
                                    color = "#FF0000";
                                    break;
                                case 'Green':
                                    color = "#00E500";
                                    break;
                                case 'Yellow':
                                    color = "#FCE80A";
                                    break;
                                case 'Gray':
                                    color = "#AAAAAA";
                                    break;
                            }
                        }
                        marker.iconColor = color;
                    }
                });

                // Check my booked pois and push it into myEvents array
                if (marker.fsmFacilityState && marker.fsmFacilityState.fsmState &&
                    (localStorage.samlNameId === marker.activator ||
                        (marker.fsmFacilityState.fsmState.id === 6 &&
                            localStorage.myBookedPoid === marker.poid))) {
                    return markerMyEvent(marker, index);
                }
            }

            if (marker.navtype === undefined && marker.shared !== undefined) {
                return profileButton(marker, true);
            }

            if (marker.lang === userLang && marker.showInCategory
                && checkMarkerStaffOnlyToShow(marker.staffOnly, project.pid)) {
                return markerButton(marker);
            }

            if (marker.language === userLang && checkMarkerStaffOnlyToShow(marker.staffOnly, project.pid)) {
                return markerButton(marker);
            }

            return null;
        });

        markerList = removeNullElements(markerList);
        if(!fromCategory && (!markerList || !markerList.length)) {
            return emptyResult();
        }

        return markerList;
    }, [
        markerButton,
        markers,
        project,
        markerMyEvent,
        pois,
        sort,
        profileButton,
        filterBuilding,
        filterFloor,
        Any,
        fromNavigate,
        fromCategory,
        facilityNames,
        emptyResult
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        setFilters();

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        cleanupFunction,
        project,
        setFilters,
        markers
    ]);

    return (
        <>
            {!getShowInMap() && <FilteredBlock project={project}
                           filterBuilding={filterBuilding}
                           setFilterBuilding={setFilterBuilding}
                           filterFloor={filterFloor}
                           setFilterFloor={setFilterFloor}
            />}
            {mobile && markers.length && !fromNavigate && !fromCategory ? (
                <div className="window-header results-header">
                    <h4>{(window as any).lang.getString('markerList_results')}</h4>
                    <a href="/#" onClick={(e) => toggleShowInMap(e)} className="show-in-map hidden">
                        {!getShowInMap() ? (window as any).lang.getString('markerList_showInMap') : (window as any).lang.getString('markerList_hideMap')}
                    </a>
                </div>
            ) : null}
            <PerfectScrollbar onTouchStart={(e) => toggleSearchFocus(e)}>
                { addMarkerList() }
            </PerfectScrollbar>
        </>
    );
};

// @ts-ignore
export default MarkerList;
// @ts-ignore
