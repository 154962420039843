import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Emitter from "../../helpers/emitter";
import { Events, TabModes, TabNames } from "../../constants";
import { IPoi, IPoiCategory, IProject } from "../../interfaces";
import { deleteParking } from "../../helpers/favorites";
import { resetMap } from "../../helpers/marker";

export interface IDeleteParking {
    project: IProject;
    changeFloor: (floor: number) => void;
    categories: IPoiCategory [];
    pois: IPoi [];
}

const DeleteParking: FC<IDeleteParking> = (props: PropsWithChildren<IDeleteParking>) => {
    const {
        project,
        pois,
        changeFloor,
        categories
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    const removeParking = React.useCallback(() => {
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);

        deleteParking(project.pid);
        setShow(false);

        resetMap(project, changeFloor);

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.PARKING,
            tabMode: TabModes.OPEN_MY_PARKING,
            tabData: {
                project,
                poi: null,
                changeFloor,
                pois,
                categories
            },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [
        project,
        setShow,
        changeFloor,
        pois,
        categories
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_DELETE_PARKING_MODAL, (isShow) => {
            if (!cleanupFunction.current) {
                setShow(isShow);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <Modal show={show} animation={false} centered id="delete-parking-modal" onHide={() => false }>
            <Modal.Body className="modal-width">
                <div className="campus-modal">
                    <h4>{(window as any).lang.getString('deleteParking')}</h4>
                    <div className="btns-group">
                        <button type="button"
                                className="modal-btn close-btn"
                                onClick={onClose}
                        >
                            <span>{ (window as any).lang.getString('btnNo') }</span>
                        </button>
                        <button type="button"
                                className="modal-btn yes-btn"
                                onClick={() => removeParking()}>
                            <span>{ (window as any).lang.getString('btnYes') }</span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteParking;
