import { takeLatest, call, put, all } from 'redux-saga/effects';
import { get, authUrl, query } from '@api';
import { HttpResponse } from '@api/interfaces';
import { loginActions, loaderActions } from '@actions';
import { BaseAction , Events } from '@constants';
import { loginSuccess } from '../actions/login'
import Emitter from '../helpers/emitter';
import { setSessionData } from "../helpers/utils";
import { projectData } from "../constants";

function* requestLogin(action: BaseAction) {
  yield put({
    type: loaderActions.LoaderEnum.LOADERREQUESST,
    payload: {
      loading: true,
      mapOverlay: true,
    },
  });

  try {
    const params: string = yield call(query, action.payload);
    const loginUrl: string = yield `${authUrl}?${params}`;
    const resLogin: HttpResponse<any> = yield call(get, loginUrl);
    const authStatus: string = yield resLogin.parsedBody.status;

    localStorage.setItem('username', action.payload.name);

    if (authStatus === 'ok') {
      const { projects } = resLogin.parsedBody;
      localStorage.setItem("proj", JSON.stringify(projects));
      setSessionData(projectData.timeoutInMinutes);

      yield loginSuccess({ ...resLogin.parsedBody }, action.prevLink)
    } else {
      Emitter.emit(Events.AUTH_ERROR, null);
      throw Error('authentication failed');
    }
  } catch (error) {
    yield put({
      type: loginActions.LoginActions.REQUESTLOGINERROR,
      payload: JSON.stringify(error),
    });
    yield put({
      type: loaderActions.LoaderEnum.LOADERRERROR,
      payload: {
        loading: false,
        mapOverlay: false,
      },
    });
  }
}

function* login() {
  yield takeLatest(loginActions.LoginActions.REQUESTLOGIN, requestLogin);
}

function* All() {
  yield all([login()]);
}

export default All;
