import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { IPoi, IProject } from "../../interfaces";
import { checkMarkerStaffOnlyToShow, getFacilityAndFloor, removeNullElements } from "../../helpers/utils";
import { selectMarker } from "../../helpers/marker";
import { getRecent } from "../../helpers/recent";
import Emitter from "../../helpers/emitter";
import { Events, mobile } from "../../constants";

export interface IRecentItems {
    project: IProject;
    pois: IPoi [];
    changeFloor: (floor: number) => void;
    fromNavigate?: boolean;
}

const RecentItems: FC<IRecentItems> = (props: PropsWithChildren<IRecentItems>) => {
    const {
        project,
        changeFloor,
        pois,
        fromNavigate
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);

    const selectPoint = React.useCallback((e: any, poi: IPoi) => {
        e.preventDefault();
        e.stopPropagation();

        if (!fromNavigate) {
            selectMarker(poi, project, changeFloor);
        } else {
            Emitter.emit(Events.SET_NAV_POI, { poi });
            if (mobile) {
                Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
            }
        }
    }, [
        fromNavigate,
        project,
        changeFloor
    ]);

    const noRecentItems = React.useCallback(() => (
            <div className="paragraph-container">
                <p>
                    { (window as any).lang.getString('noRecentItemsYet') }
                </p>
            </div>
        ), []);

    const renderRecentList = React.useCallback(() => {
        const className: string = 'spreo-poi-btn btn-default btn-block button-leave';
        const recent = project ? getRecent(project.pid) : [];

        if (recent?.length) {
            const slicedRecent = !fromNavigate ? recent.slice(-5) : recent;
            const angleClassName = project.userLang === "he" ? 'fa fa-angle-left pull-left' : 'fa fa-angle-right pull-right';

            let recentItems = slicedRecent.map((poid: string) => {
                const poi: any = pois.find((item: IPoi) => item.poid === poid && item.searchable);
                if (!poi || !checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid)) {
                    return null;
                }

                const markerInfo = getFacilityAndFloor(project, poi.facility, poi.floor);
                const iconURL = `${project.url}/middle/ios/resources/${project.pid}/${poi.campus}/${poi.facility}/icons/${poi.iconUrl}`;
                const markerURL = poi.iconUrl.indexOf(project.url) === -1 ? iconURL : poi.iconUrl;
                const markerInfoHelpBlock: string = poi.x === 0 && poi.y === 0 || (poi.navtype === 'OUTDOOR' || !poi.navtype) ?
                  `` :
                  `${(window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname])}`;

                return (
                    <button type="button"
                            className={ project.userLang === "he" ? `${ className } text-right pr-0` : `${ className } text-left` }
                            onClick={ (e) => selectPoint(e, poi) }
                            key={ poid }
                    >
                        {!fromNavigate ? (
                            <div className="recent-info">
                                <div className={project.userLang === "he" ? "image-ico-right" : ""}>
                                    <img src={markerURL} alt="Marker Icon"/>
                                </div>
                                <div className={project.userLang === "he" ? "recent-info-text info-right" : "recent-info-text"}>
                                    <span className="poi-description">{ poi.description }</span>
                                    {markerInfoHelpBlock ? <span className="poi-info">
                                        { (window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname]) }
                                    </span> : ''}
                                </div>
                                <div className={project.userLang === "he" ? "angle-left" : ""}>
                                    <i className={angleClassName} aria-hidden="true" />
                                </div>
                            </div> ) : (
                            <div className={project.userLang === "he" ? "recent-info-text info-right" : "recent-info-text"}>
                                <span className="poi-description">{ poi.description }</span>
                                <span className="poi-info">
                                        { (window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname]) }
                                    </span>
                            </div>
                        )}
                    </button>
                );
            });

            recentItems = removeNullElements(recentItems);
            if(!recentItems || !recentItems.length) {
                return noRecentItems();
            }

            return recentItems;
        }

        return noRecentItems();
    }, [
        pois,
        project,
        fromNavigate,
        selectPoint,
        noRecentItems
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    return (
        <>
            { renderRecentList() }
        </>
    );
};

export default RecentItems;
