import React, { FC, PropsWithChildren } from 'react';
import StateStorage from "../../helpers/stateStorage";
import { IProject } from "../../interfaces";
import { getShowInMap } from "../../helpers/utils";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";

export interface IFilteredBlock {
    project: IProject;
    filterBuilding: string;
    setFilterBuilding: (value: string) => void;
    filterFloor: string;
    setFilterFloor: (value: string) => void;
}

const FilteredBlock: FC<IFilteredBlock> = (props: PropsWithChildren<IFilteredBlock>) => {
    const {
        project,
        filterBuilding,
        setFilterBuilding,
        filterFloor,
        setFilterFloor
    } = props;

    const Any: string = (window as any).lang.getString('modalFilter_selectOptionAny');
    const removeFilter = React.useCallback((e: any, type: string) => {
        e.preventDefault();
        e.stopPropagation();

        switch(type) {
            case 'buildings':
                StateStorage.setItem(`${ project.pid }.filter.building`, Any);
                setFilterBuilding(Any);
                break;
            case 'floors':
                StateStorage.setItem(`${ project.pid }.filter.floor`, Any);
                setFilterFloor(Any);
                break;
        }

        if (getShowInMap()) {
            const filterBuildingStorage = project ? StateStorage.getItem(`${project.pid}.filter.building`, Any) : Any;
            const filterFloorStorage = project ? StateStorage.getItem(`${project.pid}.filter.floor`, Any) : Any;

            Emitter.emit(Events.SHOW_FILTERED_BLOCK, {
                project,
                filterBuilding: filterBuildingStorage,
                setFilterBuilding,
                filterFloor: filterFloorStorage,
                setFilterFloor
            });
        }
    }, [
        setFilterBuilding,
        setFilterFloor,
        project,
        Any
    ]);

    return (<div className="filtered-block">
        {
            filterBuilding !== Any ? (
                <button className="selected-filter filter-item ml-0"
                        type="button"
                >
                    <span>{ filterBuilding }</span>
                    <i onClick={(e) => removeFilter(e, 'buildings')}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="remove item"
                    />
                </button>
            ): null
        }
        {
            filterFloor !== Any ? (
                <button className="selected-filter filter-item ml-0"
                        type="button"
                >
                    <span>{ filterFloor }</span>
                    <i onClick={(e) => removeFilter(e, 'floors')}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="remove item"
                    />
                </button>
            ): null
        }
    </div>);
};

export default FilteredBlock;
