import { IInstructions, IProject } from "../interfaces";

const FLOOR_CHANGE = 2;		// "Proceed to floor"
const ARRIVE = 5;				// "Arrive at your destination"

const mapIcons: string [] = [];
mapIcons.push("./images/map-icons/navigation_from_icon.svg");
mapIcons.push("./images/map-icons/navigation_walk.svg");
mapIcons.push("./images/map-icons/navigation_level_change1.svg");
mapIcons.push("./images/map-icons/navigation_left.svg");
mapIcons.push("./images/map-icons/navigation_right.svg");
mapIcons.push("./images/map-icons/navigation_to_icon.svg");
mapIcons.push("./images/map-icons/navigation_entrance_exit1.svg");
mapIcons.push("./images/map-icons/navigation_entrance_exit1.svg");
mapIcons.push("./images/map-icons/navigation_entrance_exit1.svg");

class Instructions implements IInstructions {

    public navigation_paths: any;

    public outdoorFloor: number;

    public markers: any;

    public origin: any;

    public destination: any;

    public lang: string;

    public list: any [];

    public distance: number;

    public floorChanges: any [];

    public simplified: any [];

    public project: IProject;

    constructor(navdata: any, language: string, project: IProject) {
        this.navigation_paths = navdata.navigation_paths;
        this.outdoorFloor = navdata.outdoorFloor;
        this.markers = navdata.markers;
        this.origin = navdata.origin;
        this.destination = navdata.destination;

        this.lang = language || 'en';  // Default language is English

        // Objects to return
        this.list = [];
        this.distance = 0;
        this.floorChanges = [];
        this.simplified = [];
        this.project = project;

        this.createInstructions();
    }

    createInstructions () {
        for (let i = 0; i < this.navigation_paths.length; i++) {
            const navPath = this.navigation_paths[i];
            const { floor } = navPath;
            const facility = navPath.fid;
            

            const pathLength = (navPath.segments) ? navPath.segments.length : 0;

            /* eslint no-continue: 0 */
            for (let j = 0; j < pathLength; j++) {
                // Get Instructions
                const segment = navPath.segments[j]
                const { instruction, simplifiedInstruction } = navPath.segments[j];
                if (!simplifiedInstruction) {
                    continue;
                }

                if (instruction.poi !== undefined) {
                    if (this.origin.poid === instruction.poi.poid
                        && this.markers[instruction.poi.poid].nodalPOI
                        && instruction.type !== 2) {
                        continue;
                    }
                }

                const pathInstruction: any = {};
                let text = "";

                pathInstruction.icon = mapIcons[simplifiedInstruction.type];
                pathInstruction.type = simplifiedInstruction.type;

                if (simplifiedInstruction.msg) {
                    switch (instruction.type) {
                        case ARRIVE:
                            text = (window as any).lang.getString('navInstruction_arriveText', [this.destination.description]);
                            break;

                        default:
                            text = simplifiedInstruction.msg;
                            break;
                    }

                    text = text[0].toUpperCase() + text.slice(1);

                    // Add poi to instruction text if appropriate
                    if (instruction.poi && instruction.poi.visibleInInstructions) {
                        if (instruction.type === FLOOR_CHANGE && instruction.poi.poid &&
                            (instruction.poi.categories.indexOf((window as any).lang.getString('navInstruction_categoryStairs')) !== -1 ||
                                instruction.poi.categories.indexOf((window as any).lang.getString('navInstruction_categoryElevator')) !== -1)) {
                            this.floorChanges.push({
                                poid: instruction.poi.poid,
                                floor: instruction.poi.floor
                            });
                        }
                        pathInstruction.poi = instruction.poi;
                    }
                }

                pathInstruction.text = text;
                pathInstruction.floor = floor;
                pathInstruction.fid = facility;

                // Calculate segment center for later map centering
                const CalculateMiddle = (x: number ,y: number) => x<=y ? (y-x)/2+x : (x-y)/2+y;
                pathInstruction.segment_center = [];
                pathInstruction.segment_center.latitude = CalculateMiddle(segment.start.latitude, segment.end.latitude)
                pathInstruction.segment_center.longitude = CalculateMiddle(segment.start.longitude, segment.end.longitude)


                // Get Distance
                if (simplifiedInstruction.distance > 0) {
                    this.distance += simplifiedInstruction.distance;
                    pathInstruction.distance = simplifiedInstruction.distance;
                }

                this.list.push(pathInstruction);
                this.simplified.push(pathInstruction);
            }
        }
    }
}

export default Instructions;
