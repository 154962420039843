import { BaseAction, Payload } from '@constants';

export enum OccupancyActions {
    REQUESTOCCUPANCY = 'REQUESTOCCUPANCY',
    REQUESTOCCUPANCYSUCCESS = 'REQUESTOCCUPANCYSUCCESS',
    REQUESTOCCUPANCYERROR = 'REQUESTOCCUPANCYERROR'
}

/**
 * Fetch all occupancies
 * @param payload
 */
export const requestOccupancy = (payload: Payload): BaseAction => ({
    type: OccupancyActions.REQUESTOCCUPANCY,
    payload,
});

/**
 * Loading occupancies was successfully
 * @param payload
 */
export const requestOccupancySuccess = (payload: Payload): BaseAction => ({
    type: OccupancyActions.REQUESTOCCUPANCYSUCCESS,
    payload,
});

/**
 * Some error was occurred
 * @param payload
 */
export const requestOccupancyError = (payload: Payload): BaseAction => ({
    type: OccupancyActions.REQUESTOCCUPANCYERROR,
    payload,
});
