import React from "react";

interface ICleanupFunction {
    current: boolean
}

/**
 * Hook for component forceUpdate
 * @param cleanupFunction
 */
export const useForceUpdate = (cleanupFunction: ICleanupFunction): () => void => {
    const [, setTick] = React.useState(0);
    return () => {
        if (!cleanupFunction.current) setTick(tick => tick + 1);
    }
}
