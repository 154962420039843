import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Buildings from "../../Buildings";
import { TabModes } from "../../../constants";

export interface IBuilding {
    tabData: any;
    tabMode: string;
}

const Building: FC<IBuilding> = (props: PropsWithChildren<IBuilding>) => {
    const {
        tabData,
        tabMode
    } = props;

    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const buildings = React.useMemo(() => tabData?.buildings || [], [tabData]);
    const changeFloor = React.useMemo(() => tabData?.changeFloor || null, [tabData]);
    const pois = React.useMemo(() => tabData?.pois || [], [tabData]);
    const fname = React.useMemo(() => tabData?.fname || null, [tabData]);
    const fid = React.useMemo(() => tabData?.fid || null, [tabData]);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    let buildingContext;
    switch(tabMode) {
        case TabModes.OPEN_BUILDING_LIST:
            buildingContext = (
                <Buildings project={project}
                           buildings={buildings}
                           changeFloor={changeFloor}
                           pois={pois}
                           tabMode={tabMode}
                />
            );
            break;
        case TabModes.OPEN_BUILDING_POI_LIST:
            buildingContext = (
                <Buildings project={project}
                           buildings={buildings}
                           changeFloor={changeFloor}
                           pois={pois}
                           fname={fname}
                           fid={fid}
                           tabMode={tabMode} />
            );
            break;
    }

    return (
        <>
            { buildingContext }
        </>
    );
};

export default Building;
