import { takeLatest, call, put, all } from 'redux-saga/effects';
import { get, polygonList } from '@api';
import { HttpResponse } from '@api/interfaces';
import { loaderActions, polygonActions } from '@actions';
import { BaseAction } from '@constants';

interface IPolygon extends HttpResponse<Response> {
    parsedBody: any;
}

function* requestPolygon(action: BaseAction) {
    try {
        const { projectId } = action.payload;
        const language: string = localStorage.getItem('lang') || 'en';
        const params: string = yield call(polygonList, projectId, language);
        const resPolygon: IPolygon = yield call(get, params);

        yield put({
            type: polygonActions.PolygonActions.REQUESTPOLYGONSUCCESS,
            payload: resPolygon.parsedBody,
        });
    } catch (error) {
        yield put({
            type: polygonActions.PolygonActions.REQUESTPOLYGONERROR,
            payload: JSON.stringify(error),
        });
        yield put({
            type: loaderActions.LoaderEnum.LOADERRERROR,
            payload: {
                loading: false,
                mapOverlay: false,
            },
        });
    }
}

function* PolygonSaga() {
    yield takeLatest(polygonActions.PolygonActions.REQUESTPOLYGON, requestPolygon);
}

function* All() {
    yield all([PolygonSaga()]);
}

export default All;
