import { Reducer } from 'redux';
import { categoryActions } from '@actions';
import { BaseAction } from '@constants';

const initState: any = {};

const categoryStore: Reducer<any, BaseAction> = (state = initState, action) => {
    switch (action.type) {
        case categoryActions.CategoryActions.REQUESTCATEGORY:
        case categoryActions.CategoryActions.REQUESTCATEGORYERROR:
        case categoryActions.CategoryActions.REQUESTCATEGORYSUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default categoryStore;
