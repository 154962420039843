import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import GMap from '@components/GMap';
import { mapOptions } from '@constants';
import ErrorPage from '@components/ErrorPage';
import { IBuilding, ICampus, IGallery, IPoi, IPoiCategory, IPOIsCategories, IPolygon } from '@interfaces';
import Lang from '../../helpers/lang';

interface IMatchParams {
  projectId: string;
}
interface IProjectProps extends RouteComponentProps<IMatchParams> {
  mapStore: any;
  requestMap: (projectId: string) => void;
  requestPoi: (projectId: string) => void;
  requestCategory: (projectId: string) => void;
  requestPolygon: (projectId: string) => void;
  requestOccupancy: (projectId: string) => void;
  changeFloor: (floor: string | number) => void;
  changeLoading: (loading: boolean) => void;
  center: any;
  pid: string;
  floor: number;
  zoom: number;
  pois: IPoi [];
  poidMap: IPoi [];
  poisCategories: IPOIsCategories;
  categories: IPoiCategory [];
  polygons: IPolygon [];
  galleries: IGallery [][];
  buildings: IBuilding [];
  projects: ICampus [];
}

const centerCord = {
  lat: 0,
  lng: 0,
};

const Project: FC<IProjectProps> = (props) => {
  const {
    match: {
      params: { projectId },
    },
    requestMap,
    requestPoi,
    requestCategory,
    requestPolygon,
    requestOccupancy,
    changeFloor,
    changeLoading,
    zoom,
    floor,
    center,
    mapStore,
    pois,
    poidMap,
    poisCategories,
    categories,
    polygons,
    galleries,
    buildings,
    projects
  } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const mounted = React.useRef(false);
  let campuses = projects;

  if (!projects || !projects.length) {
    const proj = localStorage.getItem("proj") || null;
    campuses = proj ? JSON.parse(proj) : null;
  }

  useEffect(() => {
    requestMap(projectId);
    requestPoi(projectId);
    requestCategory(projectId);
    requestPolygon(projectId);

    if(!mounted.current && !(window as any).lang) {
      const lang: string = localStorage.getItem('lang') || 'en';
      Object.defineProperty(window, 'lang', {
        writable: true,
        configurable: false,
        enumerable: true,
        value: new Lang(lang)
      });

      mounted.current = true;
    }
  }, [projectId, requestMap, requestPoi, requestCategory, requestPolygon]);

  return (
    <>
      {mapStore.project && (!urlParams.has('test') && !sessionStorage.getItem('uuid')) &&
        (!mapStore.project.webAppEnabled || !mapStore.project.production) ?
        <ErrorPage project={mapStore.project} changeLoading={changeLoading}/> :
        <GMap center={center || centerCord}
              zoom={zoom}
              googleMapsApiKey={mapOptions.googleMapsApiKey}
              floor={floor}
              project={mapStore.project}
              pois={pois}
              poidMap={poidMap}
              poisCategories={poisCategories}
              changeFloor={changeFloor}
              changeLoading={changeLoading}
              categories={categories}
              polygons={polygons}
              requestOccupancy={requestOccupancy}
              galleries={galleries}
              buildings={buildings}
              projects={campuses}
        />
      }
    </>
  );
};

export default Project;
