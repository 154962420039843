import { takeLatest, call, put, all } from 'redux-saga/effects';
import { get, projectUrl, availableLanguages, geofenceContents, poiGallery } from '@api';
import { HttpResponse } from '@api/interfaces';
import { loaderActions, mapActions } from '@actions';
import { BaseAction, mapOptions, baseUrl, imagePath, mobile } from '@constants';
import { IBuilding, IProject } from '@interfaces';
import { getOS, sortFloors, updateOutdoorFloor } from '../helpers/utils';
import { history } from "../configStore";

interface ICampuses extends HttpResponse<Response> {
  parsedBody: any;
}

interface ILanguage extends HttpResponse<Response> {
  parsedBody: any
}

interface IGallery extends HttpResponse<Response> {
  parsedBody: any
}

interface IGeofenceContent extends HttpResponse<Response> {
  parsedBody: any
}

function* requestMap(action: BaseAction) {
  yield put({
    type: loaderActions.LoaderEnum.LOADERREQUESST,
    payload: {
      loading: true,
      mapOverlay: true,
    },
  });

  try {
    const { projectId } = action.payload;
    const language: string = localStorage.getItem('lang') || 'en';
    const params: string = yield call(projectUrl, projectId, language);

    const resLogin: ICampuses = yield call(get, params);
    if(resLogin.status === 500) {
      history.replace('/');
    }

    const { pid } = resLogin.parsedBody;
    const project = getProjectInfo(resLogin, language);
    const urlParams = new URLSearchParams(window.location.search);
    const sourceSystem: any = urlParams.has('sourceSystem') ? urlParams.get('sourceSystem') : null;

    if (sourceSystem && sourceSystem.toLowerCase() === 'epic' && mobile) {
      switch (getOS()) {
        case 'Android': {
          const epicAdroidAppLinkParam = project.campusParameterList.find((param: any) => param.paramName === "android_app_link");

          if (epicAdroidAppLinkParam) {
            (window as any).location.href = epicAdroidAppLinkParam.paramValue;
          }
          break;
        }
        case 'iOS': {
          const epiciOSAppLinkParam = project.campusParameterList.find((param: any) => param.paramName === "ios_app_link");

          if (epiciOSAppLinkParam) {
            (window as any).location.href = epiciOSAppLinkParam.paramValue;
          }
          break;
        }
      }
    }

    const langParams: string = yield call(availableLanguages, project);
    const languages: ILanguage = yield call(get, langParams);
    project.availableLangs = languages.parsedBody.langs;

    const geofenceParams: string = yield call(geofenceContents, project);
    const projectGeofenceContents: IGeofenceContent = yield call(get, geofenceParams);

    if (projectGeofenceContents.parsedBody.status !== "operation failed") {
      localStorage.setItem("geofence_contents", JSON.stringify(projectGeofenceContents.parsedBody.campaigns));
    }

    // Load image galleries
    const galleries = [];
    for (let i = 0; i < project.facilities.length; i++) {
        const galleryParams: string = yield call(poiGallery, project.pid, project.cid, project.facilities[i].fid);
        const resGallery: IGallery = yield call(get, galleryParams);

        galleries.push(resGallery.parsedBody);
    }

    // Process buildings
    let buildings: IBuilding [] = [...project.facilities];
    buildings = buildings.sort((a: IBuilding, b: IBuilding) => a.fname.localeCompare(b.fname, undefined,
        {
                  numeric: true, sensitivity: 'base'
            })
    );

    const { lat, lon } = project.campus;
    yield put({
      type: mapActions.MapActions.REQUESTMAPSUCCESS,
      payload: {
        pid,
        project,
        galleries,
        buildings,
        center: {
          lat,
          lng: lon,
        },
        zoom: mapOptions.defaultZoom
      },
    });
  } catch (error) {
    yield put({
      type: mapActions.MapActions.REQUESTMAPERROR,
      payload: JSON.stringify(error),
    });
    yield put({
      type: loaderActions.LoaderEnum.LOADERRERROR,
      payload: {
        loading: false,
        mapOverlay: false,
      },
    });
  }
}

function* MapSaga() {
  yield takeLatest(mapActions.MapActions.REQUESTMAP, requestMap);
}

function* All() {
  yield all([MapSaga()]);
}

/**
 * Prepare project object
 * Project object used throughout all modules to keep most project-wide global variables
 *
 * @param {ICampuses} resLogin
 * @param {String} language
 */
function getProjectInfo(resLogin: ICampuses, language: string): IProject {
    const { campuses, pid, pname, projectBoundariesDto, production, webAppEnabled, kioskEnabled } = resLogin.parsedBody;
    const campus = campuses[0];

    let max: number = 0;
    let index: number = 0;
    for (let i: number = 0; i < campus.facilities.length; i++) {
      const facility: any = campus.facilities[i];
      if (facility.floors.length > max) {
        max = facility.floors.length;
        index = i;
      }
    }

    const facility: any = campus.facilities[index];
    sortFloors(facility.floors);

    const project: IProject = {
      pid,
      apiKey: '',
      currentBuilding: 0,
      isSearch: false,
      cookiesExpireTime: mapOptions.cookiesExpire,
      url: baseUrl,
      imagePath,
      language,
      userLang: language,
      ratio: 16 / 15,
      markerZoom: 22,
      defaultFloor: mapOptions.defaultFloor,
      homeTab: 'home',
      useAssociatedParking: false,
      campus,
      facilities: campus.facilities,
      cid: campus.cid,
      bounds: null,
      pname,
      floors: max,
      biggest: index,
      availableLangs: [],
      floorNames: [],
      projectBoundariesDto,
      campusParameterList: campus.campusParameterList,
      staffUserId: '',
      staffPassword: '',
      production,
      webAppEnabled,
      kioskEnabled
    };

  const width = mobile ? window.screen.availWidth : 350;
  project.defaultProjectImage = `https://via.placeholder.com/${width}?text=maps.spreo.co`;

  // Check through pois to determine floor of entrances/exits
  sessionStorage.removeItem('outdoorFloor');
  const outdoorFloor = updateOutdoorFloor(null, null, project);
  if(outdoorFloor !== null) {
    sessionStorage.setItem('outdoorFloor', outdoorFloor.toString());
  }

  campuses.forEach((camp: any): void => {
    const useAssocParkParam = camp.campusParameterList.find((param: any) => param.paramName === "useAssociatedParking");

    if (useAssocParkParam) {
      project.useAssociatedParking = JSON.parse(useAssocParkParam.paramValue) === true;
    }
  });

  return project;
}

export default All;