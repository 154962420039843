import { combineReducers } from 'redux';
import { History } from 'history';
import { RouterState, connectRouter } from 'connected-react-router';
import { ILoader } from '@components/Loader';

import projectLists, { IprojectLists } from './projectLists';
import loader from './loader';
import mapStore from './map';
import poiStore from './poi';
import categoryStore from './category';
import polygonStore from './polygon';
import occupancyStore from './occupancy';

export interface State {
    router: RouterState;
    projectLists: IprojectLists;
    loader: ILoader;
    mapStore: any;
    poiStore: any;
    categoryStore: any;
    polygonStore: any;
    occupancyStore: any;
}

const rootReducer = (history: History) =>
    combineReducers<State>({
        router: connectRouter(history),
        projectLists,
        loader,
        mapStore,
        poiStore,
        categoryStore,
        polygonStore,
        occupancyStore
    });

export default rootReducer;
