import React, { FC, MutableRefObject, PropsWithChildren, useState, useRef } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { tns } from "tiny-slider/src/tiny-slider"
import loader from '@assets/images/others/loader-1.gif';
import { BackStates, Events, mobile, TabModes, TabNames } from '@constants';
import { get, poiGallery, qrData, query } from '@api';
import { IGallery, IPoi, IReportAnalyticsRequest } from '@interfaces';
import Emitter from "../../helpers/emitter";
import { generateRandomId, getFacilityAndFloor, getPoi, reportAnalytics } from "../../helpers/utils";

import './styles.scss';
import { resetMap, selectMarker } from "../../helpers/marker";
import * as Favorites from "../../helpers/favorites";

export interface IMarkerCardQR {
    project: any;
    poi: IPoi;
    pois: IPoi [];
    poidMap: IPoi [];
    destinationPOID: string;
    isParkingPOI: boolean;
    galleries: IGallery [][];
    changeFloor: (floor: number) => void;
    thirdScreen?: boolean;
}

const MarkerCardQR: FC<IMarkerCardQR> = (props: PropsWithChildren<IMarkerCardQR>) => {
    const {
        project,
        pois,
        poi,
        poidMap,
        destinationPOID,
        isParkingPOI,
        changeFloor,
        thirdScreen
    } = props;

    let cssClass = "qr-btns";

    if (mobile) {
        cssClass += isParkingPOI ? "col-xs-4" : "col-xs-6";
    }

    const parkingPOID: string = Favorites.getParking(project.pid);
    const parkingPOI = parkingPOID ? getPoi(parkingPOID, pois) : null;
    const destinationPOI = destinationPOID ? getPoi(destinationPOID, pois) : null;
    const favoriteSpaces = Favorites.getFavorites(project.pid);

    const cleanupFunction: MutableRefObject<any> = useRef(false);
    const [loading, setLoading] = useState(true);
    const poiDetailsState: any = useState(null);
    const poiDetails: any = poiDetailsState[0];
    const setPoiDetails: any = poiDetailsState[1];

    const imagesState: any = useState(null);
    const poiImages: any = imagesState[0];
    const setPoiImages: any = imagesState[1];
    const didMount: any = useRef(false);
    const favoritePrev: any = useRef(null);
    const favoriteNext: any = useRef(null);
    const popularPrev: any = useRef(null);
    const popularNext: any = useRef(null);

    const favoritesSlider: any = useRef(null);
    const popularSlider: any = useRef(null);
    const favoritesSliderElement: any = useRef(null);
    const popularSliderElement: any = useRef(null);
    const scrollBar: any = useRef(null);
    const [parkingValue, setParkingValue] = useState(parkingPOI && parkingPOID === poi.poid);
    const [qrScreenGalleryImages, setQrScreenGalleryImages] = useState([]);

    const backToMenu = React.useCallback(() => {
        if (mobile) Emitter.emit(Events.ENTER_THIRD_SCREEN, null);
        else {
            resetMap(project);
            Emitter.emit(Events.UNSET_CURRENT_POI, null);
            Emitter.emit(Events.CLOSE_SIDEBAR, null);
        }
    }, [project]);

    const closeSidebar = React.useCallback(() => {
        // Map back to defaults, including default floor
        const infoWindow = (window as any).infoWindow || null;

        if (infoWindow) {
            infoWindow.close();
        }

        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        Emitter.emit(Events.CLOSE_SIDEBAR, null);

        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);

            setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);
            setTimeout(() => Emitter.emit(Events.MAP_DRAG_END, null), 500);
        }
    }, []);

    const checkForDownloading = React.useCallback((images: any [], _poi: IPoi) =>  ({
            download: true,
            poid: _poi?.poid,
            campus: _poi?.campus,
            facility: _poi?.facility
        }), []);

    const addGalleryToDownload = React.useCallback((_poi: IPoi, images: any []) => {
        const existsPOI: any = images.find(i => i.poid === _poi?.poid);

        if (!existsPOI) {
            images.push(checkForDownloading(images, _poi));
        }

        return images;
    }, [checkForDownloading]);

    const showOnMap = React.useCallback((e) => {
        e.preventDefault();

        if (mobile) Emitter.emit(Events.ENTER_THIRD_SCREEN, null);
        else {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.INFO,
                tabMode: TabModes.OPEN_INFO,
                tabData: { poi, project, changeFloor, youAreHere: true },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }
    }, [
        poi,
        project,
        changeFloor
    ]);

    const showMapBtnMobile = React.useCallback(() => (
      <button type="button"
              className="show-map-btn"
              onClick={(e: any) => showOnMap(e)}
      >
          <span>{(window as any).lang.getString('btnShowOnMap')}</span>
          <i />
      </button>
    ), [showOnMap]);

    const parkingButtonClick = React.useCallback((e: any) => {
        e.preventDefault();
        const { poid } = poi;
        if (!parkingValue) {
            Favorites.saveParking(project.pid, poid);
        } else {
            Favorites.deleteParking(project.pid);
        }

        setParkingValue(!parkingValue);
    }, [
        setParkingValue,
        parkingValue,
        project,
        poi
    ]);

    const saveParkingBtn = React.useCallback(() => (
        <div className={cssClass}>
            <a href="/#" className={parkingValue ? "parking-btn-link parking-btn-saved-link" : "parking-btn-link"} onClick={(e) => parkingButtonClick(e)}>
                <span className={parkingValue ? "parking-btn parking-saved-btn" : "parking-btn"}/>
            </a>
            <div>
                { parkingValue ? (window as any).lang.getString('btnRemove') : (window as any).lang.getString('btnSave') }
            </div>
        </div>
    ), [
        cssClass,
        parkingButtonClick,
        parkingValue
    ]);

    const saveParkingBtnMobile = React.useCallback(() => (
      <button type="button"
              className={parkingValue ? "poi-parking-btn parking-saved-btn" : "poi-parking-btn"}
              onClick={(e) => parkingButtonClick(e)}
      >
          <span>{(window as any).lang.getString('userLocationInformation_parkingStickerTitle')}</span>
          <i/>
      </button>
    ), [
        parkingButtonClick,
        parkingValue
    ]);

    const getPoiDetails = React.useCallback((e) => {
        e.preventDefault();
        selectMarker(poi, project, changeFloor, false, '', { state: BackStates.MARKER_CARD_QR });
    }, [
        poi,
        project,
        changeFloor
    ]);

    const poiDetailsBtn = React.useCallback(() => (
        <div id="navBtnInfo" className={cssClass}>
            <a href="/#" onClick={(e: any) => getPoiDetails(e)}>
                <span id="qr-navigate-btn-info" className="buttons navigate-btn"/>
            </a>
            <div>
                {(window as any).lang.getString('userLocationInformationQr')}
            </div>
        </div>
    ), [cssClass, getPoiDetails]);

    const poiDetailsBtnMobile = React.useCallback(() => (
      <button type="button"
              className="poi-info-btn"
              onClick={(e: any) => getPoiDetails(e)}
      >
          <span>{(window as any).lang.getString('userLocationInformationQr')}</span>
          <i />
      </button>
    ), [getPoiDetails]);

    const buildNavigation = React.useCallback((_poi: IPoi | null) => {
        if (!thirdScreen) {
            const tabData = _poi ?  { fromPoi: poi, toPoi: _poi } : { fromPoi: poi };
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData,
                tabBackButton: false,
                tabCloseButton: false,
            });
        }
    }, [
        poi,
        thirdScreen
    ]);

    const PoiImages: any = React.useMemo(() => ({}), []);
    const existedDownloads: any = React.useMemo(() => ({}), []);
    const processResponse = React.useCallback((item: IPoi, response: any, isNewDownload: boolean) => {
        response = response?.parsedBody || [];
        PoiImages[item.poid] = [];

        if (isNewDownload) {
            existedDownloads[`${item.campus}-${item.facility}`] = [];
        }

        if (response.length) {
            if (isNewDownload) {
                existedDownloads[`${item.campus}-${item.facility}`] = response;
            }

            let mainImagesExists: boolean = false;
            response.forEach((r: any) => {
                if (r.type === 'head' && r.poi_id === item.poid && r.uri) {
                    mainImagesExists = true;
                    PoiImages[item.poid].push(
                        `${project.url}/middle/ios/res/${project.pid}/${item.campus}/${item.facility}/${r.uri}`
                    )
                }
            });

            if (!mainImagesExists) {
                PoiImages[item.poid].push(null);
            }
        }
        else {
            // we need to set default project image here
            PoiImages[item.poid].push(null);
        }
    }, [
        project,
        PoiImages,
        existedDownloads
    ]);

    const renderDestination = React.useCallback(() => {
        if (destinationPOI) {
            const poiInfo = getFacilityAndFloor(project, destinationPOI.facility, destinationPOI.floor);

            return (
                <div className="col-xs-12 text-center continue-nav"
                     onClick={() => buildNavigation(destinationPOI)}
                     role="button"
                     onKeyPress={(e) => e.preventDefault()}
                     tabIndex={0}
                     aria-label="build navigation"
                >
                    <h4>
                        {(window as any).lang.getString('userLocationInformationQrContinue')}
                    </h4>
                    <div className="media">
                        <div className="media-body">
                            <div className="destination-poi-details">
                                <h4 className="media-heading">
                                    { destinationPOI.description }
                                </h4>
                                <p className="help-block">
                                    {(window as any).lang.getString('bookTab_poiDetails', [poiInfo.facility, poiInfo.floorname])}
                                </p>
                            </div>
                        </div>
                        {poiImages[destinationPOI.poid] && <div className="media-right">
                            <span className="media-object" style={{ backgroundImage: `url(${poiImages[destinationPOI.poid][0]})` }}/>
                        </div>}
                    </div>
                </div>
            );
        }

        return null;
    }, [
        project,
        poiImages,
        destinationPOI,
        buildNavigation
    ]);

    const renderFavoriteItem = React.useCallback(
        (_poi: IPoi,
                poiInfo: any,
                isParking: any,
                fromPOI: any,
                associatedPOI: any) => {
        const poiImg = poiImages[_poi?.poid] ? poiImages[_poi?.poid][0] : null;
        const associatedPOIData: any [] = [];

        if (associatedPOI) {
            associatedPOIData.push(
                <div key={`associated-poi-${_poi?.poid}`}>
                    <span>{associatedPOI.orientation}</span>
                    {
                        associatedPOI.relativeDirection !== 'NO_DIRECTION' ?
                            (<span className="direction-ico">
                                <i className={`icon-${associatedPOI.relativeDirection}`} aria-hidden={true}/>
                            </span>) : ''
                    }
                </div>
            );
        }

        return (
            <div className="qr-screen-slider-item"
                 key={`favorite-${_poi?.poid}`}
                 onClick={() => buildNavigation(_poi)}
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
                 aria-label="build navigation"
            >
                <div className="slider-item-img">
                    <span className="item-sticker">
                        { isParking ?
                            <span className="parking-ico">P</span> :
                            <i className="item-sticker-ico fav" aria-hidden={true}/>
                        }
                        { isParking ?
                            (window as any).lang.getString('userLocationInformation_parkingStickerTitle') :
                            (window as any).lang.getString('userLocationInformation_favoriteStickerTitle')
                        }
                    </span>
                    {poiImg && <span className="item-img" style={{ backgroundImage: `url(${poiImg})` }} />}
                </div>
                <div className="slider-item-text">
                    <p className="item-text-title">
                        { _poi?.description }
                    </p>
                    <p className="item-text-help">
                        { (window as any).lang.getString('bookTab_poiDetails', [poiInfo.facility, poiInfo.floorname]) }
                    </p>
                </div>
                <div className="slider-item-nav" style={{ visibility: associatedPOI ? 'visible' : 'hidden' }}>
                    { associatedPOIData }
                </div>
            </div>
        );
    }, [
        poiImages,
        buildNavigation
    ]);

    const favoritesItems = React.useCallback(
        (favorites: string [],
                 parking: string,
                 fromPOI: any,
                 associatedPOIs: any []) => {
        const items: any [] = [];

        if (favorites) {
            favorites.forEach((f: string) => {
                const favoritePoi = getPoi(f, pois);

                if (favoritePoi) {
                    const poiInfo = getFacilityAndFloor(project, favoritePoi.facility, favoritePoi.floor);
                    const associatedPOI = associatedPOIs.find(aP => aP.relativePoi.poid === favoritePoi.poid);

                    items.push(renderFavoriteItem(favoritePoi, poiInfo, false, fromPOI, associatedPOI));
                }
            });
        }

        if (parking) {
            const parkingPoi = getPoi(parking, pois);

            if (parkingPoi) {
                const poiInfo = getFacilityAndFloor(project, parkingPoi.facility, parkingPoi.floor);
                const associatedPOI = associatedPOIs.find(aP => aP.relativePoi.poid === parkingPoi.poid);

                items.push(renderFavoriteItem(parkingPoi, poiInfo, true, fromPOI, associatedPOI));
            }
        }

        return items;
    }, [
        project,
        pois,
        renderFavoriteItem
    ]);

    const checkItems = React.useCallback((buttonType: string, itemType: string) => {
        if (!thirdScreen) {
            const slider: any = itemType === 'favorite' ? favoritesSlider.current : popularSlider.current;
            const { displayIndex } = slider.getInfo();
            const itemPrev: any = itemType === 'favorite' ? favoritePrev.current : popularPrev.current;
            const itemNext: any = itemType === 'favorite' ? favoriteNext.current : popularNext.current;

            if (buttonType === 'prev') {
                if (displayIndex <= 1) {
                    itemPrev.classList.remove('exist-items');
                }

                if (displayIndex > 0) {
                    itemNext.classList.add('exist-items');
                }
            }

            if (buttonType === 'next') {
                if (displayIndex === 2) {
                    itemPrev.classList.add('exist-items');
                }

                if (displayIndex >= (slider.getInfo().slideCount - 1)) {
                    itemNext.classList.remove('exist-items');
                }
            }

            if (buttonType === 'move') {
                if (displayIndex === 2) {
                    itemPrev.classList.add('exist-items');
                }

                if (displayIndex < 2) {
                    itemPrev.classList.remove('exist-items');
                }

                if (displayIndex > 0) {
                    itemNext.classList.add('exist-items');
                }

                if (displayIndex >= (slider.getInfo().slideCount - 1)) {
                    itemNext.classList.remove('exist-items');
                }
            }
        }
    }, [
        favoritesSlider,
        favoritePrev,
        favoriteNext,
        popularSlider,
        popularPrev,
        popularNext,
        thirdScreen
    ]);

    const renderFavoritePath = React.useCallback(() => {
        if (favoriteSpaces || parkingPOID) {
            const associatedPOIs: any = poiDetails?.poiRelations;

            return (
                <div className="col-xs-12 favorites-cont">
                    <div className="col-xs-12 text-center flex-center">
                        <div className="col-xs-2 text-right prev-slide">
                            <i className="arrow-ico-prev"
                               ref={favoritePrev}
                               onClick={() => checkItems('prev', 'favorite')}
                               role="button"
                               onKeyPress={(e) => e.preventDefault()}
                               tabIndex={0}
                               aria-label="favorite prev button"
                            />
                        </div>
                        <div className="col-xs-8 text-center favorites-title">
                            {(window as any).lang.getString('userLocationInformation_favoritesTitle')}
                        </div>
                        <div className="col-xs-2 text-left next-slide">
                            <i className="arrow-ico-next exist-items"
                               ref={favoriteNext}
                               onClick={() => checkItems('next', 'favorite')}
                               role="button"
                               onKeyPress={(e) => e.preventDefault()}
                               tabIndex={0}
                               aria-label="favorite next button"
                            />
                        </div>
                    </div>
                    <div className="favorites-slider" ref={favoritesSliderElement}>
                        { favoritesItems(favoriteSpaces, parkingPOID, poi, associatedPOIs) }
                        <div className="qr-screen-slider-item tiny"
                             onClick={() => buildNavigation(null)}
                             role="button"
                             onKeyPress={(e) => e.preventDefault()}
                             tabIndex={0}
                             aria-label="build navigation"
                        >
                            <div className="slider-item-img empty">
                                <i className="slider-search-ico" aria-hidden={true}/>
                            </div>
                            <div className="slider-item-text">
                                <p className="item-text-title">
                                    {(window as any).lang.getString('userLocationInformation_sliderSearchDest')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }, [
        favoritesItems,
        poi,
        poiDetails,
        checkItems,
        favoriteSpaces,
        parkingPOID,
        buildNavigation
    ]);

    const renderPopularItem = React.useCallback((associatedPOI: any) => {
        const popularPoi = poidMap[associatedPOI.relativePoi.poid];
        const poiInfo = getFacilityAndFloor(project, popularPoi.facility, popularPoi.floor);
        const poiImg = poiImages[popularPoi.poid] ? poiImages[popularPoi.poid][0] : null;
        const stickerData: any [] = [];

        if (parkingPOID && popularPoi.poid === parkingPOID) {
            stickerData.push(
                <div key={`parking-${parkingPOID}`}>
                    <span className="parking-ico">P</span>
                    {(window as any).lang.getString('userLocationInformation_parkingStickerTitle')}
                </div>
            );
        }

        if (favoriteSpaces && favoriteSpaces.includes(popularPoi.poid)) {
            stickerData.push(
                <div key={`fav-${popularPoi.poid}`}>
                    <i className="item-sticker-ico fav" aria-hidden={true}/>
                    {(window as any).lang.getString('userLocationInformation_favoriteStickerTitle')}
                </div>
            );
        }

        return (
            <div className="qr-screen-slider-item"
                 key={`popular-${popularPoi.poid}`}
                 onClick={() => buildNavigation(popularPoi)}
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
                 aria-label="build navigation"
            >
                <div className="slider-item-img">
                    <span className="item-sticker" style={{ visibility: stickerData.length ? 'visible' : 'hidden' }}>
                        { stickerData }
                    </span>
                    {poiImg && <span className="item-img" style={{ backgroundImage: `url(${poiImg})` }} />}
                </div>
                <div className="slider-item-text">
                    <p className="item-text-title">
                        { popularPoi.description }
                    </p>
                    <p className="item-text-help">
                        { (window as any).lang.getString('bookTab_poiDetails', [poiInfo.facility, poiInfo.floorname]) }
                    </p>
                </div>
                <div className={`slider-item-nav ${associatedPOI.orientation === '' &&  associatedPOI.relativeDirection === 'NO_DIRECTION' ? 'hidden' : ''}`}>
                    <span>
                        {associatedPOI.orientation}
                    </span>
                    {
                        associatedPOI.relativeDirection !== 'NO_DIRECTION' ?
                          (<span className="direction-ico">
                                <i className={`icon-${associatedPOI.relativeDirection}`} aria-hidden={true}/>
                            </span>) : ''
                    }
                </div>
            </div>
        );
    }, [
        project,
        poiImages,
        poidMap,
        favoriteSpaces,
        parkingPOID,
        buildNavigation
    ]);

    const associatedPOIsItems = React.useCallback((associatedPOIs: any) => {
        const items: any [] = [];
        associatedPOIs.forEach((aP: any) => {
            items.push(renderPopularItem(aP));
        });

        return items;
    }, [
        renderPopularItem
    ]);

    const renderPopularPath = React.useCallback(() => {
        const associatedPOIs: any = poiDetails?.poiRelations;
        if (associatedPOIs.length) {
            return (
                <div className="col-xs-12 associated-pois-cont">
                    <div className="col-xs-12 text-center flex-center">
                        <div className="col-xs-2 text-right prev-slide">
                            <i className="arrow-ico-prev"
                               ref={popularPrev}
                               onClick={() => checkItems('prev', 'popular')}
                               role="button"
                               onKeyPress={(e) => e.preventDefault()}
                               tabIndex={0}
                               aria-label="popular prev button"
                            />
                        </div>
                        <div className="col-xs-8 text-center favorites-title">
                            {(window as any).lang.getString('userLocationInformation_associatedPOIsTitle')}
                        </div>
                        <div className="col-xs-2 text-left next-slide">
                            <i className="arrow-ico-next exist-items"
                               ref={popularNext}
                               onClick={() => checkItems('next', 'popular')}
                               role="button"
                               onKeyPress={(e) => e.preventDefault()}
                               tabIndex={0}
                               aria-label="popular next button"
                            />
                        </div>
                    </div>
                    <div className="associated-pois-slider" ref={popularSliderElement}>
                        { associatedPOIsItems(associatedPOIs) }
                        <div className="qr-screen-slider-item tiny"
                             onClick={() => buildNavigation(null)}
                             role="button"
                             onKeyPress={(e) => e.preventDefault()}
                             tabIndex={0}
                             aria-label="build navigation"
                        >
                            <div className="slider-item-img empty">
                                <i className="slider-search-ico" aria-hidden={true}/>
                            </div>
                            <div className="slider-item-text">
                                <p className="item-text-title">
                                    {(window as any).lang.getString('userLocationInformation_sliderSearchDest')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }, [
        poiDetails,
        associatedPOIsItems,
        checkItems,
        buildNavigation,
    ]);

    const qrScreenRender = React.useCallback((_poiDetails: any) => {
        let galleryImagesExists: boolean = false;
        const QRScreenGalleryImages: any = [];

        // Show qr code image gallery
        if (_poiDetails.qrCode &&
            _poiDetails.qrCode.images &&
            _poiDetails.qrCode.images.length) {
                galleryImagesExists = true;
                let k = 0;
                _poiDetails.qrCode.images.forEach((im: any) => {
                    if(im) {
                        QRScreenGalleryImages.push(
                            <img src={ im.fileUrl } className="space-img" alt="Space img" key={ `img-${ k }` } />
                        );
                        k += 1;
                    }
                });
        }

        // Show main poi image if no qr code images
        if (!galleryImagesExists && PoiImages[poi.poid] && PoiImages[poi.poid].length) {
            let k = 0;
            PoiImages[poi.poid].forEach((im: string) => {
                if(im) {
                    QRScreenGalleryImages.push(
                        <img src={ im } className="space-img" alt="Space img" key={ `img-${ k }` } />
                    );

                    k += 1;
                }
            });
        }

        setPoiImages(PoiImages);
        setPoiDetails(_poiDetails);
        setQrScreenGalleryImages(QRScreenGalleryImages);

        setLoading(false);
    }, [
        PoiImages,
        poi,
        setQrScreenGalleryImages,
        setPoiDetails,
        setPoiImages
    ]);

    const Promises: any = React.useRef([]);
    const galleriesToLoad: any = React.useRef([
        {
            download: true,
            poid: poi.poid,
            campus: poi.campus,
            facility: poi.facility
        }
    ]);

    const initAssociatedPoisSlider = React.useCallback((qrPOIFullDetails: any) => {
        const associatedPoisSlider: any = document.querySelector('.associated-pois-slider');
        const associatedPOIs: any = qrPOIFullDetails.poiRelations || [];
        if (associatedPOIs?.length
          && !popularSlider.current
          && associatedPoisSlider
        ) {
            setTimeout(() => {
                associatedPoisSlider.style.width = `calc(186px * ${associatedPOIs?.length + (mobile ? 0 : 1)} - 50px)`;

                popularSlider.current = tns({
                    container: ".associated-pois-slider",
                    slideBy: 1,
                    center: false,
                    controls: true,
                    prevButton: ".associated-pois-cont .prev-slide",
                    nextButton: ".associated-pois-cont .next-slide",
                    nav: false,
                    loop: false,
                    gutter: 0,
                    mouseDrag: false,
                    touch: true,
                    preventScrollOnTouch: 'auto'
                });

                popularSlider.current.events.on('touchEnd', () => {
                    checkItems('move', 'popular');
                });
            }, 50);
        }
    }, [
        checkItems
    ]);
    const initFavoritesSlider = React.useCallback(() => {
        const favoritesSliderDiv: any = document.querySelector('.favorites-slider');
        if ((favoriteSpaces || parkingPOID)
            && favoritesSliderDiv
        ) {
            setTimeout(() => {
                favoritesSliderDiv.style.width = `calc(186px * ${(favoriteSpaces ? favoriteSpaces.length : 0) + (mobile ? 0 : 1) + (parkingPOID ? 1 : 0)} - 50px)`;

                favoritesSlider.current = tns({
                    container: ".favorites-slider",
                    slideBy: 1,
                    center: false,
                    controls: true,
                    prevButton: ".favorites-cont .prev-slide",
                    nextButton: ".favorites-cont .next-slide",
                    nav: false,
                    loop: false,
                    gutter: 0,
                    mouseDrag: false,
                    touch: true,
                    preventScrollOnTouch: 'auto',
                });

                favoritesSlider.current.events.on('touchEnd', () => {
                    checkItems('move', 'favorite');
                });
            }, 50);
        }
    }, [
        checkItems,
        favoriteSpaces,
        parkingPOID
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        if (!didMount.current) {
            if (parkingPOI) {
                galleriesToLoad.current = addGalleryToDownload(parkingPOI, galleriesToLoad.current)
            }

            if (destinationPOI) {
                galleriesToLoad.current = addGalleryToDownload(destinationPOI, galleriesToLoad.current)
            }

            if (favoriteSpaces) {
                favoriteSpaces.forEach((f: string) => {
                    galleriesToLoad.current = addGalleryToDownload(getPoi(f, pois), galleriesToLoad.current)
                });
            }

            const params: any = query({
                campus: poi.campus,
                facility: poi.facility,
                floor: poi.floor
            });
            let path: string = qrData(project, poi);
            path += `?${ params }`;

            get(path, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((qrPOIFullDetails: any) => {
                qrPOIFullDetails = qrPOIFullDetails.parsedBody;

                if (qrPOIFullDetails.poiRelations.length) {
                    qrPOIFullDetails.poiRelations.forEach((pR: any) => {
                        galleriesToLoad.current = addGalleryToDownload(poidMap[pR.relativePoi.poid], galleriesToLoad.current)
                    })
                }

                galleriesToLoad.current
                    .filter((gtl: any) => gtl.download === true)
                    .forEach((gL: any) => {
                        Promises.current.push(
                            new Promise((resolve: any): void => {
                                const galleryPath: string = poiGallery(project.pid, gL.campus, gL.facility);
                                get(galleryPath, {
                                    method: 'get', headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then((result: any) => {
                                    processResponse(gL, result, true);
                                    resolve();
                                });
                            })
                        );
                    });

                Promise
                    .all(Promises.current)
                    .then(
                        () => {
                            let poisProcessed: number = 0;

                            // proceed with pois for which we didn't need to load galleries
                            const getGalleriesForPOINoDownloaded = galleriesToLoad.current.filter((gtl: any) => gtl.download === false);

                            if (getGalleriesForPOINoDownloaded.length) {
                                getGalleriesForPOINoDownloaded
                                    .forEach((gL: any, index: number, array: any []) => {
                                        processResponse(gL, existedDownloads[`${ gL.campus }-${ gL.facility }`], false);
                                        poisProcessed += 1;

                                        if (poisProcessed === array.length) {
                                            qrScreenRender(qrPOIFullDetails);
                                        }
                                    })
                            } else {
                                qrScreenRender(qrPOIFullDetails);
                            }

                            initFavoritesSlider();
                            initAssociatedPoisSlider(qrPOIFullDetails);
                        },
                        () => {
                            qrScreenRender(qrPOIFullDetails);
                        }
                    );
            });

            // Analytics QR code reporting
            const clientId: string = generateRandomId();
            const request: IReportAnalyticsRequest = {
                action: "qr",
                clientId,
                platform: "web",
                data: poi.description,
                project: project.pid,
                campus: project.campus.cid,
                facility: poi.facility,
                floor: poi.floor
            };

            reportAnalytics(request);
            didMount.current = true;
        }

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        project,
        addGalleryToDownload,
        destinationPOI,
        favoriteSpaces,
        pois,
        poidMap,
        poi,
        parkingPOI,
        processResponse,
        qrScreenRender,
        didMount,
        Promises,
        existedDownloads,
        parkingPOID,
        checkItems,
        isParkingPOI,
        initFavoritesSlider,
        initAssociatedPoisSlider
    ]);

    const scrollBarCallbackRef = React.useCallback(element => {
        scrollBar.current = element;

        if (element && mobile) {
            // eslint-disable-next-line no-underscore-dangle
            scrollBar.current._ps.settings.suppressScrollY = thirdScreen;
            scrollBar.current.updateScroll();
        }
    }, [thirdScreen]);

    const qrPOIInfo = getFacilityAndFloor(project, poi.facility, poi.floor);
    const qrPOIInfoHelpBlock: string = poi.x === 0 && poi.y === 0 || !poi.navtype ?
      `` :
      `${(window as any).lang.getString('bookTab_poiDetails', [qrPOIInfo.facility, qrPOIInfo.floorname])}`;

    return (
        <PerfectScrollbar ref={scrollBarCallbackRef}>
            {loading ?
                <div className="sidebar-loading">
                    <img src={loader} alt="loading"/>
                </div>:
                (<div id="poiDesc" className="qr-scan-data">
                    <div className="space">
                        {mobile && <div className="top-panel">
                            <div className="panel-group">
                                <button className="back-btn"
                                        type="button"
                                        onClick={backToMenu}
                                >
                                    <i className="back-btn-ico" />
                                </button>
                                <div className="qr-poi-title">
                                    <h4 className="poi-title">{poi.description}</h4>
                                    {qrPOIInfoHelpBlock && <p className="help-block">
                                        { qrPOIInfoHelpBlock }
                                    </p>}
                                </div>
                            </div>
                            <button className="btn btn-default srch-close-btn "
                                    type="button"
                                    onClick={closeSidebar}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>}
                        {mobile && <div className="main-content">
                            <div className="poi-image">
                                { qrScreenGalleryImages }
                            </div>
                            <div className="poi-btns">
                                { showMapBtnMobile() }
                                { isParkingPOI ? saveParkingBtnMobile() : null }
                                { poiDetailsBtnMobile() }
                            </div>
                            <div className="qr-overlay-mobile" />
                        </div>}
                        {!mobile && <div className="poi-gallery qr-screen" id="gallery">
                            { qrScreenGalleryImages }
                        </div>}
                        {!mobile && <div className="col-xs-12 poi-desc-header">
                            <h5 id="you-are-at">
                                <div>
                                    {(window as any).lang.getString('userLocationQr')}
                                </div>
                            </h5>
                            <h4 id="poi-title">
                                <div>
                                    {poi.description}
                                </div>
                            </h4>
                            {qrPOIInfoHelpBlock && <p className="help-block">
                                { qrPOIInfoHelpBlock }
                            </p>}
                        </div>}
                        {!mobile && <div id="poi-qr-buttons" className="col-xs-12 text-center poi-btns">
                            { isParkingPOI ? saveParkingBtn() : null }
                            { poiDetailsBtn() }
                        </div>}
                        {renderDestination()}
                        {renderFavoritePath()}
                        {renderPopularPath()}
                    </div>
                </div>)
            }
        </PerfectScrollbar>
    );
}

export default MarkerCardQR;
