import { BaseAction, Payload } from '@constants';

export enum PolygonActions {
    REQUESTPOLYGON = 'REQUESTPOLYGON',
    REQUESTPOLYGONSUCCESS = 'REQUESTPOLYGONSUCCESS',
    REQUESTPOLYGONERROR = 'REQUESTPOLYGONERROR'
}

/**
 * Fetch all polygons
 * @param payload
 */
export const requestPolygon = (payload: Payload): BaseAction => ({
    type: PolygonActions.REQUESTPOLYGON,
    payload,
});

/**
 * Loading polygons was successfully
 * @param payload
 */
export const requestPolygonSuccess = (payload: Payload): BaseAction => ({
    type: PolygonActions.REQUESTPOLYGONSUCCESS,
    payload,
});

/**
 * Some error was occurred
 * @param payload
 */
export const requestPolygonError = (payload: Payload): BaseAction => ({
    type: PolygonActions.REQUESTPOLYGONERROR,
    payload,
});
