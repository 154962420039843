import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Emitter from "../../helpers/emitter";
import { Events, mobile, NavigateModes, NavigatePoints, TabModes, TabNames } from '../../constants';

import './styles.scss';
import ShareNavigateModal from "../ShareNavigateModal";
import DownloadModal from "../DownloadModal";
import { IProject } from "../../interfaces";

export interface INavigateBottomBar {
    project: IProject;
    tabData: any;
    goNow?: boolean;
    isPath?: boolean;
}

const NavigateBottomBar: FC<INavigateBottomBar> = (props: PropsWithChildren<INavigateBottomBar>) => {
    const {
        project,
        tabData,
        goNow,
        isPath
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const pdfInfo = tabData?.pdfInfo || null;
    const path = tabData?.pdfInfo?.path || null;
    const instructionList = tabData?.simplifiedList || null;

    let initHandicapped: boolean = false;

    if (path && path?.navObj && path?.navObj?.handicapped) initHandicapped = path?.navObj?.handicapped;
    else if (tabData) {
        if (tabData?.navObject && tabData?.navObject?.handicapped) initHandicapped = tabData?.navObject?.handicapped;
        else if (tabData?.handicapped) initHandicapped = tabData?.handicapped;
    }

    const [handicapped, setHandicapped] = React.useState(initHandicapped);
    const [showDownload, setShowDownload] = React.useState(true);

    const openDownloadModal = React.useCallback(() => {
        Emitter.emit(Events.OPEN_DOWNLOAD_MODAL, { isShow: true });
    }, []);

    const toggleHandicapped = React.useCallback(() => {
        setHandicapped(!handicapped);

        if (pdfInfo) {
            let reset: boolean = false;
            const nav: any = pdfInfo.path.navObj;
            nav.handicapped = !handicapped;

            if (nav.mode === NavigateModes.ROUTE) reset = true;

            nav.mode = NavigateModes.GO_NOW;
            pdfInfo.path.resetFloorChangeInfo();
            pdfInfo.path.reset();

            if (reset) {
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_NAVIGATE_UI,
                    tabData: {
                        toPoi: tabData?.toPoi,
                        navObject: nav,
                        handicapped: nav.handicapped,
                        referrer: tabData?.referrer,
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            }
            else {
                pdfInfo.path.createPath(nav).then(() => {
                    nav.state = "od";
                    nav.setNavigateState();

                    let markerFloor: number = nav.origin.poi.floor;
                    const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
                    const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

                    if (nav.origin.poi.x === 0 && nav.origin.poi.y === 0 && markerFloor !== outdoorFloor) {
                        markerFloor = outdoorFloor;
                    }

                    nav.changeFloor(markerFloor);
                });
            }
        } else {
            Emitter.emit(Events.ADD_DIRECTIONS, {
                poi: tabData?.toPoi,
                place: NavigatePoints.END_POINT,
                handicapped: !handicapped
            });
        }

    }, [handicapped, setHandicapped, pdfInfo, tabData]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        if(!cleanupFunction.current) {
            setHandicapped(initHandicapped);
        }

        Emitter.on(Events.TOGGLE_NAV_ELEMENTS, (isShow: boolean) => {
            if(!cleanupFunction.current) setShowDownload(isShow);
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        setHandicapped,
        path,
        setShowDownload,
        initHandicapped
    ]);

    return (
        <div className={goNow ? "handicapped in-route go-now-handicapped" : `handicapped in-route${mobile? ' inactive' : ''}`}>
            <div className="col-xs-3" id="handicapped-container">
                <div className="handicapped-checkbox">
                    <span className="handicapped-switcher">
                        <i className={handicapped ? "handicapped-ico active" : "handicapped-ico"}
                           onClick={() => toggleHandicapped()}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="toggle handicapped"
                        />
                        { handicapped && (
                            <span className="handicapped-text">
                                {(window as any).lang.getString('nav_handicappedText')}
                            </span>
                        )}
                    </span>
                </div>
            </div>
            {isPath && <div className="col-xs-9" id="route-buttons">
                {showDownload && <span className="download">
                    <i onClick={() => openDownloadModal()}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="open download modal"
                    />
                </span>}
                <span className="share">
                    <i onClick={() => { Emitter.emit(Events.OPEN_SHARE_NAVIGATE_MODAL, true); }}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="share nav link"
                    />
                </span>
                {(window as any).config.reportAnIssue &&
                    <span className="report-issue">
                        <i onClick={() => { Emitter.emit(Events.OPEN_REPORT_AN_ISSUE, true); }}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="report an issue"
                        />
                    </span>
                }
            </div>}
            <ShareNavigateModal pdfInfo={pdfInfo}
                                project={project}
            />
            <DownloadModal project={project}
                           pdfInfo={pdfInfo}
                           instructionList={instructionList}
            />
        </div>
    );
};

export default NavigateBottomBar;
