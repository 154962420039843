import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, TabModes, TabNames, mobile } from "@constants";
import { IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import { filterCategory, resetFilterCategory, sortCategories } from "../../helpers/utils";
import Emitter from "../../helpers/emitter";
import CategoryItems from "../CategoryItems";
import { getParking } from "../../helpers/favorites";

export interface ICategories {
    project: IProject;
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    changeFloor: (floor: number) => void;
    pois: IPoi [];
    cname?: string;
    cid?: number;
    tabMode: string;
    fromNavigate?: boolean;
    fromHome?: boolean;
    fromParking?: boolean;
    floor: number;
    facilityNames?: any;
    category?: IPoiCategory;
}

const Categories: FC<ICategories> = (props: PropsWithChildren<ICategories>) => {
    const {
        project,
        categories,
        poisCategories,
        changeFloor,
        pois,
        cname,
        cid,
        fromNavigate,
        tabMode,
        fromHome,
        fromParking,
        floor,
        facilityNames,
        category
    } = props;

    const categoryName = React.useMemo(() => cname || '', [cname]);
    const categoryId = React.useMemo(() => cid || 0, [cid]);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [noBackButton, setNoBackButton] = React.useState(fromHome || false);

    const showCategory = React.useCallback((
        e: any,
        _categoryId: number,
        _categoryName: string,
        index: number,
        cat?: IPoiCategory
    ) => {
        e.preventDefault();

        if (!fromNavigate) {
            filterCategory(_categoryName);

            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.CATEGORIES,
                tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
                tabData: {
                    categories,
                    poisCategories,
                    project,
                    floor,
                    changeFloor,
                    pois,
                    cid: _categoryId,
                    cname: _categoryName,
                    fromHome,
                    category: cat,
                    tabMode: TabModes.OPEN_CATEGORY_POI_LIST
                },
                tabBackButton: false,
                tabCloseButton: false,
            });

            if (mobile) {
                setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);
                Emitter.emit(Events.SELECT_CATEGORY, {
                    cname: _categoryName,
                    index
                });
            }
        } else {
            Emitter.emit(Events.SELECT_NAV_POI, { cid: _categoryId, cname: _categoryName })
        }
    }, [
        categories,
        poisCategories,
        project,
        floor,
        changeFloor,
        pois,
        fromNavigate,
        fromHome
    ]);

    const goToCategoryList = React.useCallback(() => {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.CATEGORIES,
            tabMode: TabModes.OPEN_CATEGORY_LIST,
            tabData: {
                categories,
                poisCategories,
                project,
                floor,
                changeFloor,
                pois,
                tabMode: TabModes.OPEN_CATEGORY_LIST
            },
            tabBackButton: false,
            tabCloseButton: false,
        });

        if (mobile) {
            Emitter.emit(Events.UNSELECT_CATEGORY, null);
            Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
            const sidebar: any = document.getElementById('sidebar');
            sidebar.classList.remove('landscape');
        }
    }, [
        categories,
        poisCategories,
        project,
        floor,
        changeFloor,
        pois
    ]);

    const backToCategories = React.useCallback((e) => {
        resetFilterCategory();

        if (mobile) {
            const sidebar: any = document.getElementById('sidebar');

            if(fromParking) {
                const parkingPoi: any = pois.find((item: IPoi) => getParking(project.pid) === item.poid);
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.PARKING,
                    tabMode: TabModes.OPEN_MY_PARKING,
                    tabData: {
                        project,
                        poi: parkingPoi,
                        floor,
                        changeFloor,
                        pois,
                        categories
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            } else if(sidebar.classList.contains('third-screen')) {
                goToCategoryList();
            } else {
                const data: IPoiCategory [] = sortCategories(categories, poisCategories, project, false, true);
                const index: number = data.findIndex((item: IPoiCategory) => item.id === categoryId);
                showCategory(e, categoryId, categoryName, index);
            }

            setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);

            if (!cleanupFunction.current && fromHome) setNoBackButton(true);
        } else {
            goToCategoryList();
        }
    }, [
        categories,
        poisCategories,
        categoryName,
        categoryId,
        fromHome,
        fromParking,
        setNoBackButton,
        showCategory,
        goToCategoryList,
        project,
        floor,
        changeFloor,
        pois
    ]);

    const renderCategoryList = React.useCallback(() => {
        /** Adding categories in sidebar */
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project, false, true);
        const className = 'spreo-poi-btn btn-default btn-block';

        if (data.length) {
            return data.map((cat: IPoiCategory, index: number) => {
                const angleClassName = project.userLang === "he" ? 'fa fa-angle-left pull-left' : 'fa fa-angle-right pull-right';

                return (
                  <button type="button"
                          id={`category${cat.id}`}
                          className={project.userLang === "he" ? `${className} text-right item-right` : `${className} text-left`}
                          key={`category${cat.id}`}
                          onClick={(e) => showCategory(e, cat.id, cat.name, index, cat)}
                  >
                      <span>{ cat.name }</span>
                      <i className={angleClassName} aria-hidden="true" />
                  </button>
                );
            });
        }

        return null;
    }, [
        project,
        categories,
        poisCategories,
        showCategory
    ]);

    const closeSidebar = React.useCallback(() => {
        resetFilterCategory();

        Emitter.emit(Events.CLOSE_SIDEBAR, null);
        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            Emitter.emit(Events.UNSELECT_CATEGORY, null);
            Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null);
        }
    }, []);

    const backToMenu = React.useCallback(() => {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.HOME,
            tabMode: '',
            tabData: null,
            tabBackButton: false,
            tabCloseButton: true,
        });
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;

        if (mobile) {
            setNoBackButton(fromHome || false);

            Emitter.on(Events.ADD_BACK_BUTTON, () => {
                if (!cleanupFunction.current) setNoBackButton(false);
            });
        }

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        cleanupFunction,
        setNoBackButton,
        fromHome
    ]);

    const data: IPoiCategory [] = sortCategories(categories, poisCategories, project, false, true);

    let categoryContext;

    switch(tabMode) {
        case TabModes.OPEN_CATEGORY_LIST:
            categoryContext = !fromNavigate ? (
                <div id="category-ui-window" className="spreo-ui-window ui-window">
                    <div className="window-header">
                        {mobile ?
                            <div className="window-header-left">
                                <button className="back-btn pull-left"
                                        type="button"
                                        onClick={backToMenu}
                                >
                                    <i className="back-btn-ico" />
                                </button>
                                <h4 className="window-poi-title">{ (window as any).lang.getString('homeTab_categoriesMenuItem') }</h4>
                            </div> :
                            <h4 id="header">{(window as any).lang.getString('homeTab_categoriesMenuItem')}</h4>
                        }
                        <button className="btn btn-default srch-close-btn "
                                type="button"
                                onClick={closeSidebar}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {data.length ?
                        <PerfectScrollbar>
                            { renderCategoryList() }
                        </PerfectScrollbar> : null
                    }
                </div>
            ) : (
                <>
                    { renderCategoryList() }
                </>
            );
            break;
        case TabModes.OPEN_CATEGORY_POI_LIST:
            categoryContext = (
                <div id="category-ui-window" className="spreo-ui-window ui-window category-poi-list">
                    <div className="window-header">
                        <div className="window-header-left">
                            { (mobile && !noBackButton) &&
                                <button className="back-btn pull-left"
                                        type="button"
                                        onClick={ (e) => backToCategories(e) }
                                >
                                    <i className="back-btn-ico" />
                                </button>
                            }
                            {!mobile &&
                                <button className="back-btn pull-left"
                                        type="button"
                                        onClick={ (e) => backToCategories(e) }
                                >
                                    <i className="back-btn-ico" />
                                </button>
                            }
                            <h4 className="window-poi-title">{ categoryName }</h4>
                        </div>
                        <button className="btn btn-default srch-close-btn "
                                type="button"
                                onClick={closeSidebar}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <PerfectScrollbar>
                        <CategoryItems project={project}
                                       pois={pois}
                                       floor={floor}
                                       changeFloor={changeFloor}
                                       cname={categoryName}
                                       facilityNames={facilityNames}
                                       category={category}
                        />
                    </PerfectScrollbar>
                </div>
            );
            break;
    }

    return (
        <>
            { categoryContext }
        </>
    );
};

export default Categories;
