import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Filters from "../../Filters";

interface  IFilter {
    tabData: any;
    tabMode: string;
}

const Filter: FC<IFilter> = (props: PropsWithChildren<IFilter>) => {
    const {
        tabData
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const buildings = React.useMemo(() => tabData?.buildings || [], [tabData]);
    const fromNavigate = React.useMemo(() => tabData?.fromNavigate || false, [tabData]);
    const fromPoi = React.useMemo(() => tabData?.fromPoi || null, [tabData]);
    const toPoi = React.useMemo(() => tabData?.toPoi || null, [tabData]);
    const navigatePoint = React.useMemo(() => tabData?.navigatePoint || '', [tabData]);
    const navObject = React.useMemo(() => tabData?.navObject || null, [tabData]);
    const facilityNames = React.useMemo(() => tabData?.facilityNames || null, [tabData]);
    const referrer = React.useMemo(() => tabData?.referrer || null, [tabData]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    return <Filters project={project}
                    buildings={buildings}
                    fromNavigate={fromNavigate}
                    fromPoi={fromPoi}
                    toPoi={toPoi}
                    navigatePoint={navigatePoint}
                    navObject={navObject}
                    facilityNames={facilityNames}
                    referrer={referrer}
            />
}

export default Filter;
