import { Reducer } from 'redux';
import { mapActions } from '@actions';
import { BaseAction } from '@constants';

const initState: any = {
  floor: 0,
  zoom: 0,
};

const mapStore: Reducer<any, BaseAction> = (state = initState, action) => {
  switch (action.type) {
    case mapActions.MapActions.CHANGEFLOOR:
    case mapActions.MapActions.REQUESTMAP:
    case mapActions.MapActions.REQUESTMAPERROR:
    case mapActions.MapActions.REQUESTMAPSUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default mapStore;
