import { Reducer } from 'redux';
import { polygonActions } from '@actions';
import { BaseAction } from '@constants';

const initState: any = {};

const polygonStore: Reducer<any, BaseAction> = (state = initState, action) => {
    switch (action.type) {
        case polygonActions.PolygonActions.REQUESTPOLYGON:
        case polygonActions.PolygonActions.REQUESTPOLYGONERROR:
        case polygonActions.PolygonActions.REQUESTPOLYGONSUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default polygonStore;
