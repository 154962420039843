import { all } from 'redux-saga/effects';
import login from './login';
import gmap from './map';
import poi from './poi';
import category from './category';
import polygon from './polygon';
import occupancy from './occupancy';

export const rootSaga = function* root() {
  yield all(
      [
          login(),
          gmap(),
          poi(),
          category(),
          polygon(),
          occupancy()
      ]);
};
