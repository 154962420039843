import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import MyParking from "../../MyParking";

interface  IParking {
    tabData: any;
    tabMode: string;
}

const Parking: FC<IParking> = (props: PropsWithChildren<IParking>) => {
    const {
        tabData,
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const poi = React.useMemo(() => tabData?.poi || null, [tabData]);
    const floor = React.useMemo(() => tabData?.floor || 0, [tabData]);
    const changeFloor = React.useMemo(() => tabData?.changeFloor || null, [tabData]);
    const pois = React.useMemo(() => tabData?.pois || null, [tabData]);
    const categories = React.useMemo(() => tabData?.categories || null, [tabData]);
    const poisCategories = React.useMemo(() => tabData?.poisCategories || null, [tabData]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    return <MyParking project={project}
                      poi={poi}
                      floor={floor}
                      changeFloor={changeFloor}
                      pois={pois}
                      categories={categories}
                      poisCategories={poisCategories}
    />;
}

export default Parking;
