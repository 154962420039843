import { Reducer } from 'redux';
import { loginActions } from '@actions';
import { BaseAction } from '@constants';

export interface Ipropduct {
  id: string;
  name: string;
  key: string;
  production: boolean;
  webAppEnabled: boolean;
  kioskEnabled: boolean;
}

export interface IprojectLists {
  projects: Ipropduct[] | unknown;
  error: string | unknown;
  gmapStyles?: any | unknown;
}

const initState: IprojectLists = {
  projects: [],
  error: '',
  gmapStyles: null,
};

const projectLists: Reducer<IprojectLists, BaseAction> = (state = initState, action) => {
  switch (action.type) {
    case loginActions.LoginActions.REQUESTLOGINSUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case loginActions.LoginActions.REQUESTLOGINERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default projectLists;
