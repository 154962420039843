import Loader, { ILoader } from '@components/Loader';
import { connect } from 'react-redux';

interface State {
  loader: ILoader;
}

const mapStateToProps = (state: State) => ({
  loading: state.loader.loading,
  mapOverlay: state.loader.mapOverlay,
});

export default connect(mapStateToProps)(Loader);
