import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import { IProject } from "../../interfaces";
import { deleteStaff } from "../../helpers/staff";

export interface IStaffLogout {
    project: IProject;
}

const StaffLogout: FC<IStaffLogout> = (props: PropsWithChildren<IStaffLogout>) => {
    const {
        project
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);

    const onLogout = React.useCallback(() => {
        deleteStaff(project.pid);
        Emitter.emit(Events.MENU_UPDATE, null);
        Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
        Emitter.emit(Events.SCROLL_CATEGORIES_UPDATE, null);
        setShow(false);
    }, [
        project
    ]);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_STAFF_LOGOUT, (isShow) => {
            if (!cleanupFunction.current) {
                setShow(isShow);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        setShow,
        cleanupFunction
    ]);

    return (
        <Modal show={show} animation={false} centered id="staff-logout" onHide={() => false}>
            <Modal.Body className="modal-width">
                <div className="campus-modal finish">
                    <h4>{(window as any).lang.getString('staffLogoutConfirmationText')}</h4>
                    <div className="exit-navigation-btns">
                        <button type="button"
                                className="modal-btn logout"
                                onClick={onLogout}>
                            <span>{(window as any).lang.getString('btnYes')}</span>
                        </button>
                        <button type="button"
                                className="modal-btn continue-btn"
                                onClick={onClose}>
                            <span>{(window as any).lang.getString('btnNo')}</span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default StaffLogout;
