import StateStorage from './stateStorage';

/**
 *
 * @param pid
 */
export const getRecent = (pid: string) => StateStorage.getItem(`${pid}.recent.spaces`, "");

/**
 *
 * @param pid
 * @param poid
 */
export const addRecent = (pid: string, poid: string) => {
    let recent = getRecent(pid) || [];
    if (!recent.includes(poid)) recent.push(poid);
    recent = [...new Set(recent)]
    StateStorage.setItem(`${pid}.recent.spaces`, recent);
}
