import React, { FC, PropsWithChildren } from 'react';
import { IBuilding, IPoi, IProject } from '@interfaces';
import SearchBox from "../SearchBox";
import MarkerList from "../MarkerList";

import './styles.scss';

export interface ISearchNavigate {
    project: IProject;
    buildings: IBuilding [];
    pois: IPoi [];
    changeFloor: (floor: number) => void;
    floor: number;
    tabData: any;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    navigatePoint: string;
    navObject?: any;
    facilityNames?: any;
}

const SearchNavigate: FC<ISearchNavigate> = (props: PropsWithChildren<ISearchNavigate>) => {
    const {
        project,
        buildings,
        pois,
        tabData,
        changeFloor,
        floor,
        fromPoi,
        toPoi,
        navigatePoint,
        navObject,
        facilityNames
    } = props;

    const searchMode: string | null = tabData?.searchMode || null;
    const results = tabData?.results || null;
    const navPoint = tabData?.navigatePoint || null;
    const from = tabData?.fromPoi || null;
    const to = tabData?.toPoi || null;
    const nav = tabData?.navObject || null;
    const facilities = tabData?.facilityNames || null;
    const search: string = localStorage.getItem('search') || '';
    const referrer = tabData?.referrer || null;

    return (
        <>
            <SearchBox tab="navigate"
                       project={project}
                       buildings={buildings}
                       pois={pois}
                       backButton={true}
                       closeButton={false}
                       fromNavigate={true}
                       filterButton={true}
                       fromPoi={fromPoi}
                       toPoi={toPoi}
                       navigatePoint={navigatePoint}
                       navObject={navObject}
                       facilityNames={facilityNames}
                       referrer={referrer}
            />
            <div id="find-ui-window" className="spreo-ui-window ui-window ui-navigate-search">
                {searchMode && searchMode === 'results' && results && results.length ?
                    <MarkerList markers={results}
                                project={project}
                                pois={pois}
                                sort={true}
                                floor={floor}
                                changeFloor={changeFloor}
                                fromNavigate={true}
                                navigatePoint={navPoint}
                                fromPoi={from}
                                toPoi={to}
                                fromNavSearch={true}
                                navObject={nav}
                                facilityNames={facilities}
                                referrer={referrer}
                    /> :
                        <div className="empty-search-intro">
                            <p>
                                <i className="fas fa-search" />
                            </p>
                            {search ?
                                <p>
                                    {(window as any).lang.getString('markerList_weCouldNotFind')}{ search }{(window as any).lang.getString('markerList_pleaseTryAnotherSearch')}
                                </p>
                                :
                                <p>
                                    {(window as any).lang.getString('typeKeywordsToFindThat')}
                                </p>
                            }
                        </div>
                    }
            </div>
        </>
    );
}

export default SearchNavigate;
