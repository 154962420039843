import StateStorage from './stateStorage';

export const getFavorites = (pid: string) => StateStorage.getItem(`${pid}.favorite.spaces`, "");

/**
 * @param pid
 * @param poid
 * @return void
 */
export const addFavorite = (pid: string, poid: string): void => {
    let favorites = getFavorites(pid) || [];
    favorites.push(poid);
    favorites = [...new Set(favorites)];
    StateStorage.setItem(`${pid}.favorite.spaces`, favorites);
}

/**
 *
 * @param pid
 * @param poid
 */
export const removeFavorite = (pid: string, poid: string) => {
    const favorites = getFavorites(pid) || [];
    favorites.splice(favorites.indexOf(poid), 1);
    StateStorage.setItem(`${pid}.favorite.spaces`, favorites);
}

/**
 *
 * @param pid
 */
export const getParking = (pid: string) => StateStorage.getItem(`${pid}.favorite.parking`, "");

/**
 * @param pid
 * @param poid
 */
export const saveParking = (pid: string, poid: string) => StateStorage.setItem(`${pid}.favorite.parking`, poid);

/**
 * @param pid
 *
 */
export const deleteParking = (pid: string) => StateStorage.removeItem(`${pid}.favorite.parking`);
