import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { IBuilding, IPoi, IProject } from "../../interfaces";
import StateStorage from "../../helpers/stateStorage";
import Emitter from "../../helpers/emitter";
import { Events, mobile, TabModes, TabNames } from "../../constants";
import { getShowInMap } from "../../helpers/utils";

export interface IFilters {
    project: IProject;
    buildings: IBuilding [];
    fromNavigate?: boolean;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    navigatePoint?: string;
    navObject?: any;
    facilityNames?: any;
    referrer?: string;
}

const Filters: FC<IFilters> = (props: PropsWithChildren<IFilters>) => {
    const {
        project,
        buildings,
        fromNavigate,
        fromPoi,
        toPoi,
        navigatePoint,
        navObject,
        facilityNames,
        referrer
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);

    const [filterBuilding, setFilterBuilding] = React.useState((window as any).lang.getString('modalFilter_selectOptionAny'));
    const [filterFloor, setFilterFloor] = React.useState((window as any).lang.getString('modalFilter_selectOptionAny'));
    const [buildingExpand, setBuildingExpand] = React.useState(false);
    const [floorExpand, setFloorExpand] = React.useState(false);
    const [searchedBuildings, setSearchedBuildings] = React.useState(buildings);
    const floors = React.useRef([]);
    const [searchedFloors, setSearchedFloors] = React.useState([]);
    const [canFilter, setCanFilter] = React.useState(false);
    const searchBuilding: any = React.useRef(null);
    const searchFloor: any = React.useRef(null);
    const Any: string = (window as any).lang.getString('modalFilter_selectOptionAny');

    const backToSearching = React.useCallback(() => {
        if (fromNavigate) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_SEARCH,
                tabData: {
                    fromPoi,
                    toPoi,
                    navigatePoint,
                    fromNavigate,
                    navObject,
                    facilityNames,
                    referrer
                },
                tabBackButton: false,
                tabCloseButton: false,
                tabFilterButton: true
            });

            Emitter.emit(Events.HANDLE_SEARCH, {
                fromPoi,
                toPoi,
                navigatePoint,
                fromNavigate,
                navObject,
                facilityNames,
                referrer
            });
        } else {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.OPEN_SEARCH,
                tabData: null,
                tabBackButton: true,
                tabCloseButton: true,
                tabFilterButton: true
            });
            Emitter.emit(Events.HANDLE_SEARCH, null);

            if (mobile && getShowInMap()) {
                Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
            }
        }
    }, [
        fromPoi,
        toPoi,
        navigatePoint,
        fromNavigate,
        navObject,
        facilityNames,
        referrer
    ]);

    const toggleCanFilter = React.useCallback(() => {
        if (filterBuilding === (window as any).lang.getString('modalFilter_selectOptionAny') && filterFloor === (window as any).lang.getString('modalFilter_selectOptionAny')) {
            setCanFilter(false);
        } else {
            setCanFilter(true);
        }
    }, [
        setCanFilter,
        filterBuilding,
        filterFloor
    ]);

    const checkCanFilter = React.useCallback(() => {
        const can: boolean = filterBuilding !== (window as any).lang.getString('modalFilter_selectOptionAny') || filterFloor !== (window as any).lang.getString('modalFilter_selectOptionAny');
        setCanFilter(can);
    }, [
        setCanFilter,
        filterBuilding,
        filterFloor
    ]);

    const selectItem = React.useCallback((e: any, type: string, name: string) => {
        switch(type) {
            case 'buildings':
                StateStorage.setItem(`${ project.pid }.filter.building`, name);
                setFilterBuilding(name);
                break;
            case 'floors':
                StateStorage.setItem(`${ project.pid }.filter.floor`, name);
                setFilterFloor(name);
                break;
        }

        toggleCanFilter();
    }, [
        project,
        setFilterBuilding,
        setFilterFloor,
        toggleCanFilter
    ]);

    const removeItem = React.useCallback((e: any, type: string) => {
        e.preventDefault();
        e.stopPropagation();

        switch(type) {
            case 'buildings':
                StateStorage.setItem(`${ project.pid }.filter.building`, (window as any).lang.getString('modalFilter_selectOptionAny'));
                setFilterBuilding((window as any).lang.getString('modalFilter_selectOptionAny'));
                break;
            case 'floors':
                StateStorage.setItem(`${ project.pid }.filter.floor`, (window as any).lang.getString('modalFilter_selectOptionAny'));
                setFilterFloor((window as any).lang.getString('modalFilter_selectOptionAny'));
                break;
        }

        toggleCanFilter();
    }, [
        project,
        setFilterBuilding,
        setFilterFloor,
        toggleCanFilter
    ]);

    const renderBuildingList = React.useCallback(() => {
        const buildingList: any [] = [];
        buildingList.push((
            <button key="any"
                    type="button"
                    className={filterBuilding === (window as any).lang.getString('modalFilter_selectOptionAny') ? "filter-item selected-filter" : "filter-item"}
                    onClick={(e) => selectItem(e, 'buildings', (window as any).lang.getString('modalFilter_selectOptionAny'))}
            >
                <span>{(window as any).lang.getString('modalFilter_selectOptionAny')}</span>
                <i onClick={(e) => removeItem(e, 'buildings')}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="remove item"
                />
            </button>
        ));

        searchedBuildings.forEach((building: IBuilding) => {
            let buttonClass = "filter-item";
            buttonClass += filterBuilding === building.fname ? " selected-filter" : "";

            buildingList.push((
                <button key={building.fid}
                        type="button"
                        className={buttonClass}
                        onClick={(e) => selectItem(e, 'buildings', building.fname)}
                >
                    <span>{building.fname}</span>
                    <i onClick={(e) => removeItem(e, 'buildings')}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="remove item"
                    />
                </button>
            ));
        });

        return buildingList;
    }, [
        searchedBuildings,
        filterBuilding,
        removeItem,
        selectItem
    ]);

    const renderFloorList = React.useCallback(() => {
        const floorList: any [] = [];
        floorList.push((
            <button key="any"
                    type="button"
                    className={filterFloor === (window as any).lang.getString('modalFilter_selectOptionAny') ? "filter-item selected-filter" : "filter-item"}
                    onClick={(e) => selectItem(e, 'floors', (window as any).lang.getString('modalFilter_selectOptionAny'))}
            >
                <span>{(window as any).lang.getString('modalFilter_selectOptionAny')}</span>
                <i onClick={(e) => removeItem(e, 'floors')}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="remove item"
                />
            </button>
        ));

        searchedFloors.forEach((floor: string) => {
            let buttonClass = "filter-item";
            buttonClass += filterFloor === floor ? " selected-filter" : "";

            floorList.push((
                <button key={floor}
                        type="button"
                        className={buttonClass}
                        onClick={(e) => selectItem(e, 'floors', floor)}
                >
                    <span>{floor}</span>
                    <i onClick={(e) => removeItem(e, 'floors')}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                       aria-label="remove item"
                    />
                </button>
            ));
        });

        return floorList;
    }, [
        filterFloor,
        searchedFloors,
        removeItem,
        selectItem
    ]);

    const toggleAccordion = React.useCallback((type: string) => {
        switch(type) {
            case 'buildings':
                setBuildingExpand(!buildingExpand);
                break;
            case 'floors':
                setFloorExpand(!floorExpand);
                break;
        }
    }, [
        setBuildingExpand,
        buildingExpand,
        setFloorExpand,
        floorExpand
    ]);

    const searchItems = React.useCallback((type: string) => {
        const query: string = type === 'buildings' ? searchBuilding.current.value : searchFloor.current.value;

        if (query === '') {
            return type === 'buildings' ? setSearchedBuildings(buildings) : setSearchedFloors(floors.current);
        }

        const regExp = new RegExp(`${query}`, "ig");
        let items: any;
        switch(type) {
            case 'buildings':
                items = buildings.filter((item: IBuilding) => item.fname.match(regExp));
                setSearchedBuildings(items);
                break;
            case 'floors':
                items = floors.current.filter((item: string) => item.match(regExp));
                setSearchedFloors(items);
                break;
        }

        return true;
    }, [
        buildings,
        setSearchedBuildings,
        floors,
        setSearchedFloors
    ]);

    const mounted = React.useCallback(() => {
        searchItems('buildings');
        searchItems('floors');
    }, [
        searchItems
    ]);

    const clearAll = React.useCallback((e: any) => {
        removeItem(e, 'buildings');
        removeItem(e, 'floors');

        searchBuilding.current.value = '';
        searchFloor.current.value = '';

        mounted();
    }, [
        removeItem,
        searchBuilding,
        searchFloor,
        mounted
    ]);

    const filterItems = React.useCallback(() => {
        backToSearching();

        if (getShowInMap()) {
            const filterBuildingStorage = project ? StateStorage.getItem(`${project.pid}.filter.building`, Any) : Any;
            const filterFloorStorage = project ? StateStorage.getItem(`${project.pid}.filter.floor`, Any) : Any;

            Emitter.emit(Events.SHOW_FILTERED_BLOCK, {
                project,
                filterBuilding: filterBuildingStorage,
                setFilterBuilding,
                filterFloor: filterFloorStorage,
                setFilterFloor
            });
        }
    }, [
        backToSearching,
        Any,
        project,
        setFilterBuilding,
        setFilterFloor
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        if (project && !cleanupFunction.current) {
            const filterBuildingStorage = project ? StateStorage.getItem(`${project.pid}.filter.building`, (window as any).lang.getString('modalFilter_selectOptionAny')) : (window as any).lang.getString('modalFilter_selectOptionAny');
            const filterFloorStorage = project ? StateStorage.getItem(`${project.pid}.filter.floor`, (window as any).lang.getString('modalFilter_selectOptionAny')) : (window as any).lang.getString('modalFilter_selectOptionAny');
            setFilterBuilding(filterBuildingStorage);
            setFilterFloor(filterFloorStorage);
            checkCanFilter();

            const range: number [] = [];
            for (let floorNumber = 1; floorNumber <= project.floors; floorNumber += 1) {
                range.push(floorNumber);
            }

            const floorNames: any = [];
            range.forEach((floorNumber: number) => {
                const facilityFloor = project.facilities[project.biggest].floors[floorNumber - 1];
                floorNames.push(`L${ facilityFloor.title }`);
            });

            floors.current = floorNames;
        }
        mounted();

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        cleanupFunction,
        mounted,
        checkCanFilter,
        project
    ]);

    return (
        <div id="filters-ui-window" className="spreo-ui-window ui-window">
            <div className="window-header filters-header">
                <div className="window-header-left">
                    <button className="back-btn pull-left"
                            type="button"
                            onClick={backToSearching}
                    >
                        <i className="back-btn-ico" />
                    </button>
                    <h4 className="window-poi-title">{(window as any).lang.getString('tabFilters_title')}</h4>
                </div>
            </div>
            <PerfectScrollbar>
                <div className="filters-container">
                    <div>
                        <Accordion>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toggleAccordion('buildings')}>
                                <div>
                                    <div className="filter-left">
                                        <i className="filter-buildings-ico" />
                                        <span>{(window as any).lang.getString('homeTab_buildingsMenuItem')}</span>
                                    </div>
                                    <div>
                                        {buildingExpand ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                                    </div>
                                </div>
                                <div>
                                    <button className="selected-filter filter-item ml-20"
                                            type="button"
                                            style={buildingExpand ? { display: 'none' } : {}}
                                    >
                                        <span>{ filterBuilding }</span>
                                        <i onClick={(e) => removeItem(e, 'buildings')}
                                           role="button"
                                           onKeyPress={(e) => e.preventDefault()}
                                           tabIndex={0}
                                           aria-label="remove item"
                                        />
                                    </button>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <>
                                    <div className="sidebar-header filter-header">
                                        <a href="/#" className="bars-btn pull-left" onClick={(e) => e.preventDefault()}>
                                            <i className="fas fa-search" />
                                        </a>
                                        <div className="input-group spreo-search">
                                            <input type="text"
                                                   placeholder={(window as any).lang.getString('searchBuilding')}
                                                   autoComplete="off"
                                                   className="form-control srch-form full-width"
                                                   onKeyUp={() => searchItems('buildings')}
                                                   ref={searchBuilding}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-list">
                                        {renderBuildingList()}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </Accordion>
                        <Accordion>
                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toggleAccordion('floors')}>
                                <div>
                                    <div className="filter-left">
                                        <i className="filter-floors-ico" />
                                        <span>{(window as any).lang.getString('modalFilter_filterFloor')}</span>
                                    </div>
                                    <div>
                                        {floorExpand ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                                    </div>
                                </div>
                                <div>
                                    <button className="selected-filter filter-item ml-20"
                                            type="button"
                                            style={floorExpand ? { display: 'none' } : {}}
                                    >
                                        <span>{ filterFloor }</span>
                                        <i onClick={(e) => removeItem(e, 'floors')}
                                           role="button"
                                           onKeyPress={(e) => e.preventDefault()}
                                           tabIndex={0}
                                           aria-label="remove item"
                                        />
                                    </button>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <>
                                    <div className="sidebar-header filter-header">
                                        <a href="/#" className="bars-btn pull-left" onClick={(e) => e.preventDefault()}>
                                            <i className="fas fa-search" />
                                        </a>
                                        <div className="input-group spreo-search">
                                            <input type="text"
                                                   placeholder={(window as any).lang.getString('searchFloor')}
                                                   autoComplete="off"
                                                   className="form-control srch-form full-width"
                                                   onKeyUp={() => searchItems('floors')}
                                                   ref={searchFloor}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-list floor-list">
                                        {renderFloorList()}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                    {
                        canFilter ?
                            <div className="filter-btns">
                                <button type="button"
                                        className="filter-btn clear"
                                        onClick={(e) => clearAll(e)}
                                >
                                    <span>{(window as any).lang.getString('btnClear')}</span>
                                </button>
                                <button type="button"
                                        className="filter-btn filter"
                                        onClick={filterItems}
                                >
                                    <span>{(window as any).lang.getString('btnFilter')}</span>
                                </button>
                            </div>
                            :
                            null
                    }
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default Filters;
