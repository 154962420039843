import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from 'react-bootstrap/Tooltip';
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import { HttpResponse } from "../../api/interfaces";

export interface IThankYouModal {
    response: HttpResponse<any> | null;
}

const ThankYouModal: FC<IThankYouModal> = (props: PropsWithChildren<IThankYouModal>) => {
    const {
        response
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const target = React.useRef(null);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) setShow(false);
    }, [setShow]);

    const showCopiedInfo = React.useCallback(() => {
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    }, [setShowTooltip]);

    const copyCode = React.useCallback((code) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(() => showCopiedInfo());
            return;
        }

        if (document.execCommand) {
            const area: any = document.createElement('textarea');
            area.value = code;
            area.select();
            document.execCommand('copy');
            showCopiedInfo();
        }
    }, [showCopiedInfo]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_THANK_YOU_MODAL, (newValue) => {
            if (!cleanupFunction.current) setShow(newValue)
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <Modal show={show} animation={false} centered id="thank-you" onHide={() => false }>
            <Modal.Body className="modal-width">
                <div className="campus-modal">
                    <h4>{ (window as any).lang.getString('modalReportIssue_confirmTitleText') }</h4>
                    <p>{ (window as any).lang.getString('modalReportIssue_confirmMessageText') }</p>
                    <h4>{ (window as any).lang.getString('modalReportIssue_confirmReferenceNumber') }</h4>
                    <div className="reference-code">
                        <strong>{response?.parsedBody.referenceNumber}</strong>
                        <i className="far fa-copy"
                           onClick={() => copyCode(response?.parsedBody.referenceNumber)}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="Copy reference code"
                           ref={target}
                        />
                        <Overlay target={target.current} show={showTooltip} placement="top">
                            {(tooltipProps) => (
                                <Tooltip id="overlay-example" {...tooltipProps}>
                                    { (window as any).lang.getString('modalReportIssue_confirmReferenceCodeWasCopied') }
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                    <button type="button"
                            className="modal-btn close-btn"
                            onClick={onClose}>
                        <span>{ (window as any).lang.getString('btnClose') }</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ThankYouModal;
