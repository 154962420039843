import { LoginForm } from '@components';
import { ILoginForm, IFormData } from '@components/LoginForm';
import { connect } from 'react-redux';
import { loginActions } from '@actions';
import { Payload } from '@constants';

const mapDispatch: ILoginForm = {
  onSubmit: (payload: IFormData, prevLink?: any) => loginActions.requestLogin((payload as unknown) as Payload, prevLink as any),
};

export default connect(null, mapDispatch)(LoginForm);
