import { Reducer } from 'redux';
import { loaderActions } from '@actions';
import { BaseAction } from '@constants';
import { ILoader } from '@components/Loader';

const initState: ILoader = {
  loading: false,
  mapOverlay: false,
};

const loader: Reducer<ILoader, BaseAction> = (state = initState, action) => {
  switch (action.type) {
    case loaderActions.LoaderEnum.CHANGELOADING:
    case loaderActions.LoaderEnum.LOADERREQUESST:
    case loaderActions.LoaderEnum.LOADERRERROR:
    case loaderActions.LoaderEnum.LOADERRSUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loader;
