import React, { FC, MutableRefObject, PropsWithChildren, useMemo } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, mobile, Referrers, TabModes, TabNames, uiSettings } from '@constants';
import { IBuilding, ICampus, IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import Emitter from "../../helpers/emitter";
import ReportAnIssue from "../ReportAnIssue";
import { history } from "../../configStore";
import CampusMap from "../CampusMap";
import LangPicker from "../LangPicker/LangPicker";
import { getParking } from "../../helpers/favorites";
import CleanDefaultCampus from "../CleanDefaultCampus";
import { resetSearch } from "../../helpers/marker";
import StaffLogin from "../StaffLogin";
import { getStaff } from "../../helpers/staff";
import StaffLogout from "../StaffLogout";
import StateStorage from '../../helpers/stateStorage';

export interface IMainMenu {
    project: IProject;
    buildings: IBuilding [];
    projects: ICampus [];
    floor: number;
    changeFloor: (floor: number) => void;
    pois: IPoi [];
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
}

const MainMenu: FC<IMainMenu> = (props: PropsWithChildren<IMainMenu>) => {
    const {
        project,
        projects,
        buildings,
        floor,
        changeFloor,
        pois,
        categories,
        poisCategories
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const mounted: MutableRefObject<any> = React.useRef(false);
    const [currentProjId, setCurrentProjId] = React.useState(project.pid);
    const [update, setUpdate] = React.useState(false);
    const lang: string = localStorage.getItem('lang') || 'en';

    const logout = React.useCallback((e) => {
        e.preventDefault();

        sessionStorage.clear();
        history.push('/');
    }, []);

    const openMyParking = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            const parkingPoi: any = pois.find((item: IPoi) => getParking(project.pid) === item.poid);
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.PARKING,
                tabMode: TabModes.OPEN_MY_PARKING,
                tabData: {
                    project,
                    poi: parkingPoi,
                    floor,
                    changeFloor,
                    pois,
                    categories
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }
        return true;
    }, [
        project,
        floor,
        changeFloor,
        pois,
        categories
    ]);

    const openCategoryList = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.CATEGORIES,
                tabMode: TabModes.OPEN_CATEGORY_LIST,
                tabData: {
                    categories,
                    poisCategories,
                    project,
                    floor,
                    changeFloor,
                    pois
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }

        return true;
    }, [
        categories,
        poisCategories,
        project,
        floor,
        changeFloor,
        pois
    ]);

    const openBuildingList = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.BUILDINGS,
                tabMode: TabModes.OPEN_BUILDING_LIST,
                tabData: {
                    buildings,
                    project,
                    changeFloor,
                    pois
                },
                tabBackButton: false,
                tabCloseButton: false,
            });

        }

        return true;
    }, [
        buildings,
        project,
        changeFloor,
        pois
    ]);

    const openFavoriteList = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FAVORITES,
                tabMode: TabModes.OPEN_FAVORITE_LIST,
                tabData: {
                    project,
                    changeFloor,
                    pois
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }

        return true;
    }, [
        project,
        changeFloor,
        pois
    ]);

    const openNavigate = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: {
                    referrer: Referrers.MENU_NAVIGATE
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }

        return true;
    }, []);

    const openHowToModal = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.OPEN_HOWTO_MODAL, true);

        return true;
    }, []);

    const getProductionProjects = (data: ICampus[]) => {
        if (data && data.length) {
            let prodProjects = [...data];

            if (StateStorage.getItem(`projects.test`) === 'false') {
                prodProjects = prodProjects.filter(({ production }) => production);

                // Only the projects that have IsWebAppEnabled flag set to true
                prodProjects = prodProjects.filter(pr => pr.webAppEnabled || pr.webAppEnabled === undefined);
            }

            return prodProjects;
        }

        return data;
    }

    const prodProjects = useMemo(
      () => getProductionProjects(projects),
      [projects]
    );

    const renderCampuses = React.useCallback(() => prodProjects.map((proj: ICampus, i) => (
        <span key={proj.key}>
            <input id={`singleSelect${i}`} className="__select__input" type="radio" name="singleSelect" defaultChecked={currentProjId === proj.id}/>
            <label htmlFor={`singleSelect${i}`} className="__select__label" data-id={proj.id}>
                {proj.name}
            </label>
        </span>
    )), [prodProjects, currentProjId]);

    const renderMainMenu = React.useCallback(() => {
        let menuItems = [
            {
                idx: 1,
                icon: 'categories-ico',
                title: (window as any).lang.getString('homeTab_categoriesMenuItem'),
                attrs: { href: "/#", click: (e: any) => openCategoryList(e) }
            }
        ];

        if (buildings && buildings.length > 1) {
            menuItems.push(
                {
                    idx: 2,
                    icon: 'buildings-ico',
                    title: (window as any).lang.getString('homeTab_buildingsMenuItem'),
                    attrs: { href: "/#", click: (e: any) => openBuildingList(e) },
                }
            );
        }

        menuItems = menuItems.concat([
            {
                idx: 3,
                icon: 'navigate-ico',
                title: (window as any).lang.getString('homeTab_navigateMenuItem'),
                attrs: { href: "/#", click: (e: any) => openNavigate(e) }
            },
            {
                idx: 4,
                icon: 'favorites-ico',
                title: (window as any).lang.getString('homeTab_favoritesMenuItem'),
                attrs: { href: "/#", click: (e: any) => openFavoriteList(e) },
            }
        ]);

        if (mobile) {
            menuItems.push({
                idx: 5,
                icon: 'parking-ico',
                title: (window as any).lang.getString('homeTab_parkingMenuItem'),
                attrs: { href: "/#", click: (e: any) => openMyParking(e) },
            });
        }

        if (uiSettings.corporate) {
            menuItems = [
                {
                    idx: 1,
                    icon: 'categories-ico',
                    title: (window as any).lang.getString('homeTab_categoriesMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    },
                },
                {
                    idx: 2,
                    icon: 'workstation-ico',
                    title: (window as any).lang.getString('homeTab_workstationMenuItem'),
                    attrs: { href: "/#", click: () => true },
                },
                {
                    idx: 3,
                    icon: 'meetings-ico',
                    title: (window as any).lang.getString('homeTab_meetingsMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    },
                },
                {
                    idx: 4,
                    icon: 'neighborhoods-ico',
                    title: (window as any).lang.getString('homeTab_neighborhoodsMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    },
                },
                {
                    idx: 5,
                    icon: 'favorites-ico',
                    title: (window as any).lang.getString('homeTab_favoritesMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    },
                },
                {
                    idx: 6,
                    icon: 'parking-ico',
                    title: (window as any).lang.getString('homeTab_parkingMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    },
                },
                {
                    idx: 7,
                    icon: 'buildings-ico',
                    title: (window as any).lang.getString('homeTab_buildingsMenuItem'),
                    attrs: { href: "/#", click: () => true },
                },
                {
                    idx: 8,
                    icon: 'navigate-ico',
                    title: (window as any).lang.getString('homeTab_navigateMenuItem'),
                    attrs: {
                        href: "/#", click: () => true
                    }
                }
            ];
        }

        menuItems.push({
            idx: 9,
            icon: 'help-ico',
            title: (window as any).lang.getString('homeTab_howToMenuItem'),
            attrs: { href: "/#", click: (e: any) => openHowToModal(e) },
        });

        return menuItems.map((item) => (
                <li key={`item-${item.idx}`}>
                    <div onClick={(e) => item.attrs.click(e)}
                         role="button"
                         onKeyPress={(e) => e.preventDefault()}
                         tabIndex={0}
                         aria-label="Menu item"
                         className="interactive-element"
                    >
                        <i className={ item.icon } />
                        <span>
                            { item.title }
                        </span>
                    </div>
                </li>
            )
        );

    }, [
        openBuildingList,
        buildings,
        openCategoryList,
        openFavoriteList,
        openNavigate,
        openMyParking,
        openHowToModal
    ]);

    const closeMainMenu = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
    }, []);

    const reportAnIssueModal = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.OPEN_REPORT_AN_ISSUE, true);
    }, []);

    const staffLoginModal = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.OPEN_STAFF_LOGIN, true);
    }, []);

    const staffLogout = React.useCallback((e) => {
        e.preventDefault();
        Emitter.emit(Events.OPEN_STAFF_LOGOUT, true);
    }, []);

    const initDropbox = React.useCallback(() => {
        const selectSingle: any = document.querySelector('.campuses-item-container .__select');
        const selectSingleTitle = selectSingle.querySelector('.campuses-item-container .__select__title');
        const selectSingleLabels = selectSingle.querySelectorAll('.campuses-item-container .__select__label');

        // Toggle menu
        selectSingleTitle.addEventListener('click', (e: any) => {
            e.stopPropagation();

            if (selectSingle.getAttribute('data-state') === 'active') {
                selectSingle.setAttribute('data-state', '');
            } else {
                selectSingle.setAttribute('data-state', 'active');
            }
        });

        const body: any = document.querySelector("body");
        body.addEventListener("click", () => {
            selectSingle.setAttribute('data-state', '');
        });

        // Close when click to option
        for (let i = 0; i < selectSingleLabels.length; i++) {
            selectSingleLabels[i].addEventListener('click', (evt: any) => {
                const pid: string = evt.target.getAttribute('data-id');
                setCurrentProjId(pid);
                selectSingleTitle.innerHTML = `<i class="campuses-ico"></i><span class="selected-content"><small>${(window as any).lang.getString('homeTab_campus')}</small><span>${evt.target.textContent}</span></span>`;
                selectSingle.setAttribute('data-state', '');

                history.replace(`/project-list/${pid}`);
            });
        }
    }, [
        setCurrentProjId
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        if(!mounted.current && !(window as any).config.noLogin && prodProjects && prodProjects.length > 1) {
            initDropbox();
            mounted.current = true;
        }

        Emitter.on(Events.MENU_UPDATE, () => {
            if(!cleanupFunction.current) setUpdate(!update);
        });

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        initDropbox,
        prodProjects,
        mounted,
        update,
        setUpdate
    ]);

    const renderMenuHTML = React.useCallback(() => (
        <>
            <h4>{ (window as any).lang.getString('homeTab_tabTitleMenu') }</h4>
            {(!(window as any).config.noLogin && prodProjects && prodProjects.length > 1) && (
            <div className="campuses-item-container">
                <div className="__select campuses-item" data-state="">
                    <div className="__select__title">
                        <i className="campuses-ico" />
                        <span className="selected-content">
                                        <small>{(window as any).lang.getString('homeTab_campus')}</small>
                                        <span>{project.pname}</span>
                                    </span>
                    </div>
                    <div className="__select__content"
                         onClick={(e) => e.stopPropagation()}
                         onKeyPress={(e) => e.preventDefault()}
                         role="button"
                         tabIndex={0}
                         aria-label="dropdown list"
                    >
                        <PerfectScrollbar>
                            { renderCampuses() }
                        </PerfectScrollbar>
                    </div>
                </div>
                <i className="campuses-edit-btn"
                   onClick={() => Emitter.emit(Events.OPEN_CAMPUS_MAP, true)}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="edit default campus"
                />
            </div>
            )}
            <ul className="main-menu-list">
                { renderMainMenu() }
            </ul>
            <div className="menu-btns-container">
                {((window as any).config.showLangSel && mobile) &&
                <LangPicker availableLangs={ project.availableLangs }
                            currLang={lang}
                            project={project}
                />
                }
                { !getStaff(project.pid) && <button type="button"
                        className="main-menu-buttons staff-login-btn"
                        onClick={(e) => staffLoginModal(e)}
                >
                    <i className="btns-ico staff-login-ico"/>
                    <span>{ (window as any).lang.getString('btnStaff') }</span>
                </button> }
                { getStaff(project.pid) && <button type="button"
                                                    className="main-menu-buttons staff-login-btn"
                                                    onClick={(e) => staffLogout(e)}
                >
                    <i className="btns-ico logout-ico"/>
                    <span>{ (window as any).lang.getString('btnStaffLogout') }</span>
                </button> }
                {(window as any).config.reportAnIssue &&
                <button type="button"
                        className="main-menu-buttons report-issues-btn"
                        onClick={(e) => reportAnIssueModal(e)}
                >
                    <i className="btns-ico warning-ico"/>
                    <span>{ (window as any).lang.getString('btnReportIssue') }</span>
                </button>
                }
                <a href="pdf/User_Manual_CenTrak_Maps_2.0_V2_2.pdf"
                   className="main-menu-buttons help-instructions-btn"
                   target="_blank"
                >
                    <i className="btns-ico question-ico"/>
                    <span>{ (window as any).lang.getString('btnHelpInstr') }</span>
                </a>
                {((window as any).config.logoutButton && prodProjects && prodProjects.length >= 1) &&
                <button type="button"
                        className="main-menu-buttons logout-btn"
                        onClick={(e) => logout(e)}
                >
                    <i className="btns-ico logout-ico"/>
                    <span>{ (window as any).lang.getString('btnLogout') }</span>
                </button>
                }
            </div>
        </>), [
        prodProjects,
        logout,
        reportAnIssueModal,
        project,
        lang,
        renderCampuses,
        renderMainMenu,
        staffLoginModal,
        staffLogout
    ]);

    resetSearch();
    return (
        <div className="main-menu-container">
            {mobile ? (
                <div>
                    { renderMenuHTML() }
                </div>
            ) : (
                <PerfectScrollbar>
                    { renderMenuHTML() }
                </PerfectScrollbar>
            )}
            <div className="close-main-menu-btn"
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
                 aria-label="Close Main Menu"
                 onClick={(e) => closeMainMenu(e)}
            />
            <StaffLogin project={project} />
            <StaffLogout project={project}/>
            <ReportAnIssue project={project}/>
            {!(window as any).config.noLogin && <CampusMap projects={prodProjects} />}
            <CleanDefaultCampus />
        </div>
    );
}

export default MainMenu;
