const domain: string = window.location.host;
let apiUrl;
if(domain.indexOf('maps.spreo.co') !== -1) {
    apiUrl = 'https://developer.spreo.co';
} else if(domain.indexOf('wayfinding.centrak.com') !== -1) {
    apiUrl = 'https://wayfinding-admin.centrak.com';
} else {
    apiUrl = 'https://developer.spreo.co';
}

export const baseUrl = apiUrl;
export const imagePath: string = 'public/images';

export interface Payload {
    [key: string]: boolean | string | number | Payload | null;
}

export interface BaseAction {
    type: string;
    payload?: any;
    prevLink?: any;
}

// eslint-disable-next-line max-len
export const mobile: boolean = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook|BB10|KFAPWI/i.test(navigator.userAgent))
    || (/iPad|iPhone|iPod/.test(navigator.platform))
    || !!(navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform));

export const mapOptions: any = {
    // The DOM element ID for the map
    container: 'map',

    // Default parameters for zoom and floor (optional)
    // Uses fit bounds method if not specified, not needed for multiple building campuses
    defaultZoom: 18,

    // Default is outdoor floor, marked by entrances usually 0 if not specified
    defaultFloor: 0,

    // Cookies expire time in sec
    cookiesExpire: 8 * 60 * 60,

    poiZoom: 19,

    // Google map API key
    googleMapsApiKey: 'AIzaSyBqldcC9xHiY7v-OYhQ0twmLyahD9WwN6w'
};

interface IProjectData {
    ratio: number;
    timeoutInMinutes: number;
    defaultLang: string;
}

export const projectData: IProjectData = {
    ratio: 16 / 15,
    timeoutInMinutes: 60,
    defaultLang: 'en'
};

export const corporateVersion: boolean = false;

let occupancy: boolean = false
let tabs: string [] = ['home', 'find', 'favorites', 'parking'];

if (corporateVersion) {
    occupancy = true;
    tabs = ['home', 'calendar', 'find', 'favorites', 'parking'];
}

export const uiSettings = {
    // Logo branding and link to the logo click
    logo: "https://spreo.co/wp-content/uploads/2017/10/spreo-logo.png",
    link: "https://spreo.co",

    // Position to place the sidebar
    sidebarPosition: "right",

    // Tabs to add to the application
    tabs,

    // Include Occupancy API. (it will be false by default)
    occupancy,

    // Default interface lang
    lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',

    // Show logout button
    logoutButton: true,

    // Show building labels
    buildingLabels: true,

    // Report an issue
    reportAnIssue: true,

    // Health project
    healthProject: false,

    // Show interface language selector
    showLangSel: true,

    // Show additional functionality like My Meetings, My Workstation, ...
    corporate: corporateVersion,
}

export enum TabNames {
    HOME = 'home',
    FIND = 'find',
    FAVORITES = 'favorites',
    PARKING = 'parking',
    BUILDINGS = 'buildings',
    CATEGORIES = 'categories',
    FILTERS = 'filters',
    NAVIGATE = 'navigate',
    HELP = 'help',
    INFO = 'info'
}

export enum TabModes {
    OPEN_SEARCH = 'open_search',
    OPEN_EMPTY_SEARCH = 'open_empty_search',
    SEARCHED = 'searched',
    POI_DETAILS = 'poi_details',
    OPEN_BUILDING_LIST = 'open_building_list',
    OPEN_BUILDING_POI_LIST = 'open_building_poi_list',
    OPEN_CATEGORY_LIST = 'open_category_list',
    OPEN_CATEGORY_POI_LIST = 'open_category_poi_list',
    OPEN_FAVORITE_LIST = 'open_favorite_list',
    FILTER_MARKERS_BY_CATEGORY = 'filter_markers_by_category',
    FILTERS = 'filters',
    OPEN_NAVIGATE_UI = 'open_navigate_ui',
    OPEN_ROUTE = 'open_route',
    OPEN_NAVIGATE_SEARCH = 'open_navigate_search',
    OPEN_GO_NOW = 'open_go_now',
    HELP_MENU = 'help_menu',
    OPEN_INFO = 'open_info',
    OPEN_MY_PARKING = 'open_my_parking',
    OPEN_QR_MARKER_CARD = 'open_qr_marker_card'
}

export enum Events {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    OPEN_TAB = 'OPEN_TAB',
    UNSET_SEARCH_MODE = 'UNSET_SEARCH_MODE',
    CLOSE_TAB = 'CLOSE_TAB',
    SET_SEARCH_MODE = 'SET_SEARCH_MODE',
    FILTER_MARKERS_BY_CATEGORY = 'FILTER_MARKERS_BY_CATEGORY',
    SHOW_MAP_FILTER = 'SHOW_MAP_FILTER',
    SET_CURRENT_POI = 'SET_CURRENT_POI',
    UNSET_CURRENT_POI = 'UNSET_CURRENT_POI',
    IMAGES_POST_PROCESS = 'IMAGES_POST_PROCESS',
    OPEN_CAMPUS_MAP = 'OPEN_CAMPUS_MAP',
    CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
    ENTER_THIRD_SCREEN = 'ENTER_THIRD_SCREEN',
    EXIT_THIRD_SCREEN = 'EXIT_THIRD_SCREEN',
    EXIT_NAVIGATE_MENU = 'EXIT_NAVIGATE_MENU',
    OPEN_REPORT_AN_ISSUE = 'OPEN_REPORT_AN_ISSUE',
    OPEN_THANK_YOU_MODAL = 'OPEN_THANK_YOU_MODAL',
    SCROLL_FLOOR_PICKER = 'SCROLL_FLOOR_PICKER',
    HIDE_CATEGORY_BAR = 'HIDE_CATEGORY_BAR',
    SHOW_CATEGORY_BAR = 'SHOW_CATEGORY_BAR',
    COLLAPSE_CATEGORY_BAR = 'COLLAPSE_CATEGORY_BAR',
    SELECT_CATEGORY = 'SELECT_CATEGORY',
    UNSELECT_CATEGORY = 'UNSELECT_CATEGORY',
    HANDLE_SEARCH = 'HANDLE_SEARCH',
    OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL',
    SELECT_NAV_POI = 'SELECT_NAV_POI',
    SET_NAV_POI = 'SET_NAV_POI',
    REMOVE_DIRECTIONS = 'REMOVE_DIRECTIONS',
    ADD_DIRECTIONS = 'ADD_DIRECTIONS',
    SET_FLOOR_MARKER = 'SET_FLOOR_MARKER',
    UNSET_FLOOR_MARKERS = 'UNSET_FLOOR_MARKERS',
    SIDEBAR_LOADING = 'SIDEBAR_LOADING',
    REMOVE_NAV_POINT = 'REMOVE_NAV_POINT',
    NAV_FLOOR_CHANGE = 'NAV_FLOOR_CHANGE',
    OPEN_SHARE_NAVIGATE_MODAL = 'OPEN_SHARE_NAVIGATE_MODAL',
    OPEN_DOWNLOAD_MODAL = 'OPEN_DOWNLOAD_MODAL',
    GENERATE_PDF_LOADER = 'GENERATE_PDF_LOADER',
    OPEN_EXIT_NAVIGATION = 'OPEN_EXIT_NAVIGATION',
    OPEN_DELETE_PARKING_MODAL = 'OPEN_DELETE_PARKING_MODAL',
    OPEN_QR_SCANNER = 'OPEN_QR_SCANNER',
    SET_FOCUS = 'SET_FOCUS',
    UNSET_SEARCH_FOCUS = 'UNSET_SEARCH_FOCUS',
    SET_SEARCH = 'SET_SEARCH',
    SHOW_FILTERED_BLOCK = 'SHOW_FILTERED_BLOCK',
    ONLY_TWO_INSTRUCTIONS = 'ONLY_TWO_INSTRUCTIONS',
    HIDE_FILTERED_BLOCK = 'HIDE_FILTERED_BLOCK',
    SHOW_FLOORS_MAP = 'SHOW_FLOORS_MAP',
    RESET_FLOORS_MAP = 'RESET_FLOORS_MAP',
    SCROLL_TO_FIRST = 'SCROLL_TO_FIRST',
    HIDE_MAP_CONTROLS = 'HIDE_MAP_CONTROLS',
    SHOW_MAP_CONTROLS = 'SHOW_MAP_CONTROLS',
    HIDE_FLOATING_PANEL = 'HIDE_FLOATING_PANEL',
    RECALCULATE_FLOATING_PANEL_POSITION = 'RECALCULATE_FLOATING_PANEL_POSITION',
    ADD_BACK_BUTTON = 'ADD_BACK_BUTTON',
    REMOVE_BACK_BUTTON = 'REMOVE_BACK_BUTTON',
    TOGGLE_INPUT_FIELD = 'TOGGLE_INPUT_FIELD',
    DISPLAY_ROUTE = 'DISPLAY_ROUTE',
    EXIT_ROUTE = 'EXIT_ROUTE',
    GO_NOW = "GO_NOW",
    EXIT_GO_NOW = 'EXIT_GO_NOW',
    AUTH_ERROR = 'AUTH_ERROR',
    OPEN_CLEAN_DEFAULT_CAMPUS = 'OPEN_CLEAN_DEFAULT_CAMPUS',
    SET_NAV_POI_FROM_QR = 'SET_NAV_POI_FROM_QR',
    TOGGLE_NAV_ELEMENTS = 'TOGGLE_NAV_ELEMENTS',
    LOAD_CATEGORY_POIS = 'LOAD_CATEGORY_POIS',
    REMOVE_DROPDOWN = 'REMOVE_DROPDOWN',
    MAP_DRAG_END = 'MAP_DRAG_END',
    SHOW_ALL_INSTRUCTIONS = 'SHOW_ALL_INSTRUCTIONS',
    HIDE_ALL_INSTRUCTIONS = 'HIDE_ALL_INSTRUCTIONS',
    INIT_LABELS = 'INIT_LABELS',
    OPEN_STAFF_LOGIN = 'OPEN_STAFF_LOGIN',
    OPEN_HOWTO_MODAL = 'OPEN_HOWTO_MODAL',
    MENU_UPDATE = 'MENU_UPDATE',
    OPEN_STAFF_LOGOUT = 'OPEN_STAFF_LOGOUT',
    SCROLL_CATEGORIES_UPDATE = 'SCROLL_CATEGORIES_UPDATE'
}

export enum MenuStatuses {
    CLOSED = 0,
    OPENED = 1,
    SEARCH_OPENED = 2
}

export enum NavigatePoints {
    START_POINT = 'from',
    END_POINT = 'to',
    NONE = 'none'
}

export enum NavigateModes {
    SELECT_POINTS = 'select_points',
    SELECT_NAV_POI = 'select_nav_poi',
    SELECT_FAVORITES_POI = 'select_favorites_poi',
    SELECT_RECENT_POI = 'select_recent_poi',
    GO_NOW = 'go_now',
    ROUTE = 'route'
}

export enum NavStates {
    Z = 'z', // Zero state - there was not selected navigate mode
    N = 'n', // New state - there was selected navigate mode, origin and destination points haven't selected yet
    O = 'o', // Origin point was selected
    D = 'd', // Destination point was selected
    OD = 'od', // Origin and destination points were selected. Path was created
    W = 'w' // Waiting mode
}

export enum BackStates {
    MAP = 'map',
    NAVIGATE = 'navigate',
    GO_NOW = 'goNow',
    MARKER_LIST = 'markerList',
    CATEGORY_POI_LIST = 'categoryPoiList',
    FAVORITE_LIST = 'favoriteList',
    PARKING_LIST = 'parkingList',
    SHOW_IN_MAP = 'showInMap',
    MARKER_CARD_QR = 'markerCardQR'
}

export enum ScanFrom {
    SEARCH,
    NAV
}

export enum Referrers {
    POI_CARD = 'poiCard',
    MENU_NAVIGATE = 'menuNavigate'
}
