import Cookies from 'universal-cookie';
import { IPoi, IProject } from '@interfaces';
import { Events, mapOptions, mobile, NavigatePoints, NavStates, TabModes, TabNames, BackStates } from '@constants';
import { get } from '@api';
import Emitter from "./emitter";
import { addRecent } from "./recent";
import 'core-js/features/url-search-params';
import { calcPOIIconSize, resetFilter, resetFilterCategory, setBackURL } from "./utils";

let currentFloor = 0;
/**
 *
 * @param poi
 * @param project
 * @param changeFloor
 * @param youAreHere
 * @param youAreHereType
 * @param backState
 * @param floor
 */
export const selectPoi = (poi: any,
                          project: IProject,
                          changeFloor?: (floorNumber: number) => void,
                          youAreHere: boolean = false,
                          youAreHereType: string = '',
                          backState: any = { state: BackStates.MAP, fromPoi: null, toPoi: null },
                          floor?: number) => {
    if (floor && currentFloor !== floor) {
        currentFloor = floor;
    }

    const { google } = window;
    const { gmap } = (window as any);
    const navState: string = sessionStorage.getItem('navigate.state') || NavStates.Z;

    Emitter.emit(Events.INIT_LABELS, null);
    if (backState.state === BackStates.GO_NOW) {
        Emitter.emit(Events.EXIT_GO_NOW, null);
    }

    if (mobile && !youAreHere) {
        Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        Emitter.emit(Events.HIDE_FILTERED_BLOCK, null);
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
    }

    if (mobile) {
        const sidebar: any = document.getElementById('sidebar');
        sidebar.classList.remove('landscape');
        sidebar.classList.remove('info-pane');
    }

    addRecent(project.pid, poi.poid);
    resetSearch();
    resetFilter(project);

    if (navState === NavStates.D) {
        Emitter.emit(Events.ADD_DIRECTIONS, { poi, place: NavigatePoints.START_POINT });
    } else if (navState === NavStates.O) {
        Emitter.emit(Events.ADD_DIRECTIONS, { poi, place: NavigatePoints.END_POINT });
    } else if (navState === NavStates.N && backState !== BackStates.NAVIGATE && backState.state !== BackStates.GO_NOW) {
        Emitter.emit(Events.ADD_DIRECTIONS, { poi });
    } else if (navState === NavStates.OD || navState === NavStates.Z || [BackStates.NAVIGATE, BackStates.GO_NOW].includes(backState.state)){
        let infoWindow = (window as any).infoWindow || null;
        if (infoWindow) {
            infoWindow.close();
        }

        Emitter.removeAllListeners(Events.ADD_DIRECTIONS);
        Emitter.emit(Events.EXIT_NAVIGATE_MENU, null);

        const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
        const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
        poi.iconSize = calcPOIIconSize(poi, project, gmap, []);
        infoWindow = new google.maps.InfoWindow({
            position: new google.maps.LatLng(lat, lon),
            pixelOffset: new google.maps.Size(0, -(poi.iconSize.height / 2))
        });

        let infoWindowContent = poi.description;
        if (youAreHereType === 'parking') {
            infoWindowContent = `<style>.gm-style-iw, .gm-style-iw-c, .gm-style-iw-t, .gm-style-iw-tc {visibility: hidden}</style><div class="marker-content"><h4>Your parking</h4><span>${poi.description}</span></div>`;
        }
        if (youAreHereType === 'youAreHere') {
            infoWindowContent = `<style>.gm-style-iw, .gm-style-iw-c, .gm-style-iw-t, .gm-style-iw-tc {visibility: hidden}</style><div class="marker-content you-are-here-content"><div><h4>You Are Here</h4></div><div><i id="you_are_here_link"></i></div></div>`;
            $(document).on("click", `#you_are_here_link`, () => {
                selectPoi(poi, project, changeFloor);
            });
        }
        infoWindow.setContent(infoWindowContent);

        google.maps.event.addListener(infoWindow, "closeclick", () => {
            resetMap(project);
            Emitter.emit(Events.CLOSE_SIDEBAR, true);

            if (mobile) {
                Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            }
        });

        if (youAreHere) {
            google.maps.event.addListener(infoWindow, "domready", () => {
                const iwDiv: any = document.querySelector('.gm-style-iw');
                iwDiv.classList.remove('gm-style-iw');
                iwDiv.classList.remove('gm-style-iw-c');

                const iwtDiv: any = document.querySelector('.gm-style-iw-t');
                iwtDiv.classList.remove('gm-style-iw-t');

                iwDiv.classList.add('you-are-here');
            });
        }

        Object.defineProperty(window, 'infoWindow', {
            writable: true,
            configurable: false,
            enumerable: true,
            value: infoWindow
        });

        Emitter.emit(Events.REMOVE_DIRECTIONS, null);
        Emitter.emit(Events.RESET_FLOORS_MAP, null);
        if(!youAreHere) Emitter.emit(Events.UNSELECT_CATEGORY, null);

        showPOIDescription(poi, youAreHere, changeFloor, backState);
    }
}

export const showPOIDescription = (poi: any,
                                   youAreHereDisplayed: boolean,
                                   changeFloor?: (floorNumber: number) => void,
                                   backState?: any) =>
    {
        centerPoi(poi, true, changeFloor);

        if (!youAreHereDisplayed) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.POI_DETAILS,
                tabData: { poi, backState },
                tabBackButton: true,
                tabCloseButton: true,
                tabFilterButton: false,
            });
        }
}

/**
 * Center poi, show infoWindow and make sure marker is visible
 * @param poi
 * @param setContent
 * @param changeFloor
 */
export const centerPoi = (poi: any, setContent: boolean, changeFloor?: (floorNumber: number) => void) => {
    const { google } = window;
    const { gmap } = (window as any);
    setContent = setContent !== undefined ? setContent : true;

    // Center map on poi location and floor (regardless of if it is visible or not)
    if (poi.floor !== currentFloor) {
        currentFloor = poi.floor;

        if (changeFloor) {
            changeFloor(poi.floor);
            Emitter.emit(Events.SCROLL_FLOOR_PICKER, currentFloor);
        }
    }

    const currentZoom = gmap.getZoom();
    const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
    const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
    const newCenter = new google.maps.LatLng(lat, lon);
    const poiZoomLevel = poi.visibleAtZoom < mapOptions.poiZoom ? mapOptions.poiZoom : poi.visibleAtZoom;
    const newZoom = currentZoom < mapOptions.poiZoom ? poiZoomLevel : currentZoom;

    centerMap(gmap, newCenter, newZoom);

    // Set info window above marker
    if(setContent) {
        const infoWindow = (window as any).infoWindow || null;
        if (infoWindow) {
            infoWindow.close();

            infoWindow.setOptions({ disableAutoPan: true });
            infoWindow.open(gmap);
        }
    }
}

/**
 *
 * @param gmap
 * @param center
 * @param zoom
 */
export const centerMap = (gmap: any, center: any, zoom: number) => {
    const currentZoom = gmap.getZoom();
    if (zoom && zoom > 15 && currentZoom !== zoom) {
        gmap.setZoom(zoom);
    } // takes a lot of time, so dont do it if not needed

    if (center) gmap.panTo(center);

    if (!mobile) {
        gmap.panBy(100, 0);
    }
}

/**
 *
 * @param project
 * @param changeFloor
 */
export const resetMap = (project: any, changeFloor?: (floorNumber: number) => void) => {
    const { google } = window;
    const { gmap } = (window as any);

    if (gmap) {
        // Map back to defaults, including default floor
        const infoWindow = (window as any).infoWindow || null;
        if (infoWindow) {
            infoWindow.close();
        }

        // Adjust map center and POI Visibility
        setTimeout(() => {
            const center = new google.maps.LatLng(project.campus.lat, project.campus.lon);
            centerMap(gmap, center, project.zoom);
        }, 10);

        const outdoorFloorStorage: string | null = sessionStorage.getItem('outdoorFloor') || null;
        const outdoorFloor: number = outdoorFloorStorage ? parseInt(outdoorFloorStorage, 10) : parseInt(mapOptions.defaultFloor, 10);

        if (changeFloor) {
            changeFloor(outdoorFloor);
            Emitter.emit(Events.SCROLL_FLOOR_PICKER, outdoorFloor);
        }

        // Reset all Searches
        resetSearch();
        gmap.fitBounds(project.bounds);

        // Reset filter by category
        resetFilterCategory();

        if (mapOptions.defaultZoom > 16) {
            gmap.setZoom(mapOptions.defaultZoom);
        } else if (gmap.getZoom() < 16) {
            gmap.setZoom(16);
        }

        Emitter.emit(Events.REMOVE_DIRECTIONS, null);
        Emitter.emit(Events.UNSET_CURRENT_POI, null);
        Emitter.emit(Events.RESET_FLOORS_MAP, null);
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        Emitter.emit(Events.UNSELECT_CATEGORY, null);

        const sidebar: any = document.getElementById('sidebar');
        sidebar.className = 'sidebar sidebar-left home collapsed';
    }
}

/**
 * @return void
 */
export const partialResetMap = (): void => {
    const { gmap } = (window as any);
    if (gmap) {
        // Map back to defaults, including default floor
        const infoWindow = (window as any).infoWindow || null;
        if (infoWindow) {
            infoWindow.close();
        }

        // Reset all Searches
        resetSearch();
    }
}

/**
 * Reset main search input box
 */
export const resetSearch = (): void => {
    localStorage.removeItem('search');
    localStorage.removeItem('showInMap');

    Emitter.emit(Events.SET_SEARCH, '');
}

/**
 * Reset search string for back
 */
export const resetSearchForBack = (): void => {
    localStorage.removeItem('searchForBack');
}

/**
 *
 * @param poi
 * @param project
 * @param changeFloor
 * @param youAreHere
 * @param youAreHereType
 * @param backState
 */
export const selectMarker = (
    poi: IPoi,
    project: IProject,
    changeFloor?: (floorNumber: number) => void,
    youAreHere: boolean = false,
    youAreHereType: string = '',
    backState: any = { state: BackStates.MAP, fromPoi: null, toPoi: null }
) => {
    selectPoi(poi, project, changeFloor, youAreHere, youAreHereType, backState);

    Emitter.emit(Events.SET_CURRENT_POI, poi);
    Emitter.emit(Events.UNSELECT_CATEGORY, null);
}

/**
 *
 * @param addr
 */
export const getPeaceURL = (addr: any) => {
    if(addr.pathname.match(/(\/)$/ig)) {
        return `${addr.pathname}project-list/`;
    }

    return `${addr.pathname}/project-list/`;
}

/**
 *
 * @param link
 */
export const redirectToNewURL = (link: string) => {
    const addr = new URL(link);

    const urlParams = new URLSearchParams(addr.search);
    const pid: any = urlParams.has('pid') ? urlParams.get('pid') : null;

    urlParams.delete('pid');
    const query: string = urlParams.toString();
    const URLRegExp: any = new RegExp(`${ pid }`, 'g');

    let result: string | null;
    if (!addr.pathname.match(URLRegExp) && pid) {
        const peaceURL: string = getPeaceURL(addr);
        result = `${ addr.protocol }//${ addr.host }${ peaceURL }${ pid }${ query ? `?${query}` : '' }`;
    } else {
        result = `${ addr.protocol }//${ addr.host }${addr.pathname}${ query ? `?${query}` : '' }`;
    }

    if (result) {
       window.location.href = result;
    }
}

/**
 * Redirect to campus directly if login is not necessary
 */
export const redirectWithoutLogin = () => {
    const addr = new URL(window.location.href);
    const peaceURL: string = getPeaceURL(addr);
    window.location.href = `${addr.protocol}//${addr.host}${peaceURL}${(window as any).config.pid}`;
}

/**
 *
 * @param poidMap
 * @param project
 * @param changeFloor
 * @param categories
 * @param changeLoading
 */
export const parseURLParameters = (
    poidMap: any [],
    project: IProject,
    changeFloor: (floor: number) => void,
    categories: any,
    changeLoading: any
) => {
    const cookies = new Cookies();
    const urlParams = new URLSearchParams(window.location.search);
    const sourceid: any = urlParams.has('sourceid') ? urlParams.get('sourceid') : null;
    const sourceSystem: any = urlParams.has('sourceSystem') ? urlParams.get('sourceSystem') : null;
    const poi: any = urlParams.has('poi') ? urlParams.get('poi') : null;
    const from: any = urlParams.has('from') ? urlParams.get('from') : null;
    const to: any = urlParams.has('to') ? urlParams.get('to') : null;
    if(urlParams.has('pid') || urlParams.has('poi')
        || urlParams.has('to') || urlParams.has('from')
        || urlParams.has('sourceid') || urlParams.has('sourceSystem')) {
        sessionStorage.setItem('isGuest', '1');
    }

    if (sourceSystem && sourceid) {
        const requestUrl = new URL(`${project.url}/rest/space/get-all/${project.pid}/${sourceSystem}/${sourceid}`)
        requestUrl.searchParams.append('useAssociatedParking', project.useAssociatedParking.toString())

        get(requestUrl.toString(), {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res: any) => {
            const response = res.parsedBody;
            const destinationPOI = poidMap[response.poi.poid];

            if (response.poi && destinationPOI) {
                selectPoi(destinationPOI, project, changeFloor);

                // deprecated flow
                // if (response.poi.future2 && project.useAssociatedParking) {
                //     const originPOID = poidMap[response.poi.future2];
                //
                //     Emitter.emit(Events.OPEN_TAB, {
                //         tabName: TabNames.NAVIGATE,
                //         tabMode: TabModes.OPEN_NAVIGATE_UI,
                //         tabData: { fromPoi: originPOID, toPoi: destinationPOI },
                //         tabBackButton: false,
                //         tabCloseButton: false,
                //     });
                // } else {
                //     Emitter.emit(Events.OPEN_TAB, {
                //         tabName: TabNames.NAVIGATE,
                //         tabMode: TabModes.OPEN_NAVIGATE_UI,
                //         tabData: { toPoi: destinationPOI },
                //         tabBackButton: false,
                //         tabCloseButton: false
                //     });
                // }
            }
        }).catch(error => console.error(error));
    }

    setBackURL(project.pid);
    if (poidMap) {
        if (poi && poidMap[poi]) {
            cookies.set('destinationPOI', poi, {
                expires: new Date(new Date().getTime() + mapOptions.cookiesExpireTime * 1000),
                path: (/\./.test(window.location.pathname) ? '/' : window.location.pathname)
            });

            selectPoi(poidMap[poi], project, changeFloor);
        } else if (to && poidMap[to] && !from) {
            cookies.set('destinationPOI', to, {
                expires: new Date(new Date().getTime() + mapOptions.cookiesExpireTime * 1000),
                path: (/\./.test(window.location.pathname) ? '/' : window.location.pathname)
            });

            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: { toPoi: poidMap[to] },
                tabBackButton: false,
                tabCloseButton: false,
            });
        } else if (from && poidMap[from] && !to) {
            const currentPoi = poidMap[from];
            const isParkingPOI = (poidMap[from].categories.toLocaleLowerCase().includes("parking") ||
              poidMap[from].poid.startsWith("prk"));
            const destinationPOID = cookies.get("destinationPOI");

            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.OPEN_QR_MARKER_CARD,
                tabData: {
                    poi: currentPoi,
                    destinationPOID,
                    isParkingPOI
                },
                tabBackButton: false,
                tabCloseButton: false,
            });

            if (mobile) Emitter.emit(Events.ENTER_THIRD_SCREEN, null);

            selectOnQRCode(currentPoi, project, changeFloor);
            changeLoading(false);
        } else if (from && poidMap[from] && to && poidMap[to]) {
            cookies.set('destinationPOI', to, {
                expires: new Date(new Date().getTime() + mapOptions.cookiesExpireTime * 1000),
                path: (/\./.test(window.location.pathname) ? '/' : window.location.pathname)
            });

            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: { fromPoi: poidMap[from], toPoi: poidMap[to] },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }
    }
}

/**
 *
 * @param poi
 * @param project
 * @param changeFloor
 */
export const selectOnQRCode = (
    poi: any,
    project: IProject,
    changeFloor: (floor: number) => void
) => {
    const { google } = window;
    const { gmap } = (window as any);

    poi.iconSize = calcPOIIconSize(poi, project, gmap, []);

    const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
    const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
    const infoWindow = new google.maps.InfoWindow({
        position: new google.maps.LatLng(lat, lon),
        pixelOffset: new google.maps.Size(0, -(poi.iconSize.height / 2))
    });
    const infoWindowContent = `<div class="marker-content you-are-here-content"><div><h4>You Are Here</h4></div></div>`;

    infoWindow.setContent(infoWindowContent);

    google.maps.event.addListener(infoWindow, "domready", () => {
        const iwDiv: any = document.querySelector('.gm-style-iw');
        iwDiv.classList.remove('gm-style-iw');
        iwDiv.classList.remove('gm-style-iw-c');

        const iwtDiv: any = document.querySelector('.gm-style-iw-t');
        iwtDiv.classList.remove('gm-style-iw-t');

        iwDiv.classList.add('you-are-here');
    });

    Object.defineProperty(window, 'infoWindow', {
        writable: true,
        configurable: false,
        enumerable: true,
        value: infoWindow
    });

    centerPoi(poi, true, changeFloor);
}

/**
 *
 * @param link
 */
export const setPOIFromQRCode = (link: string) => {
    const addr = new URL(link);

    const urlParams = new URLSearchParams(addr.search);
    const poi: any = urlParams.has('poi') ? urlParams.get('poi') : null;
    const from: any = urlParams.has('from') ? urlParams.get('from') : null;
    const to: any = urlParams.has('to') ? urlParams.get('to') : null;

    const poid: any = poi || from || to || null;
    Emitter.emit(Events.SET_NAV_POI_FROM_QR, poid);
}
