import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Emitter from "../../helpers/emitter";
import { Events, TabModes, TabNames, mobile } from "../../constants";
import './ExitNavigation.scss';

export interface IExitNavigation {
    tabData: any;
}

const ExitNavigation: FC<IExitNavigation> = (props: PropsWithChildren<IExitNavigation>) => {
    const {
        tabData
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [action, setAction] = React.useState('');
    const pdfInfo = tabData?.pdfInfo || null;

    const onExit = React.useCallback(() => {
        setShow(false);
        pdfInfo.path.navObj.reset();

        Emitter.removeAllListeners(Events.ADD_DIRECTIONS);
        if (action === 'backTo' || action === 'finishRoute') {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_UI,
                tabData: {
                    fromPoi: tabData?.fromPoi,
                    toPoi: tabData?.toPoi,
                    exitNavigation: true,
                    referrer: tabData?.referrer
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }

        if (action === 'closeSidebar') {
            Emitter.emit(Events.CLOSE_SIDEBAR, mobile ? { exitNavigation: true } : null);
        }

    }, [
        tabData,
        action,
        pdfInfo
    ]);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_EXIT_NAVIGATION, ({ isShow, Action }) => {
            if (!cleanupFunction.current) {
                setShow(isShow);
                setAction(Action);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        setShow,
        cleanupFunction,
        setAction
    ]);

    return (
        <Modal show={show} animation={false} centered id="exit-navigation" onHide={() => false}>
            <Modal.Body className="modal-width">
                { action !== 'finishRoute' ?
                <div className="campus-modal">
                    <div className="modal-close">
                        <i onClick={onClose}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="close modal"
                        />
                    </div>
                    <h4>{(window as any).lang.getString('nav_cancelNavText')}</h4>
                    <div className="exit-navigation-btns">
                        <button type="button"
                                className="modal-btn exit-navigation-btn"
                                onClick={onExit}>
                            <span>{ (window as any).lang.getString('nav_cancelNavCancelBtn') }</span>
                        </button>
                        <button type="button"
                                className="modal-btn continue-btn"
                                onClick={onClose}>
                            <span>{ (window as any).lang.getString('nav_cancelNavContinueBtn') }</span>
                        </button>
                    </div>
                </div>
                : // 'finish route' modal
                <div className="campus-modal finish">
                    <h4>{(window as any).lang.getString('finish_routeNavText')}</h4>
                    <div className="exit-navigation-btns">
                        <button type="button"
                                className="modal-btn finish-back-btn"
                                onClick={onClose}>
                            <span>{(window as any).lang.getString('btnBack')}</span>
                        </button>
                        <button type="button"
                                className="modal-btn continue-btn"
                                onClick={onExit}>
                            <span>{(window as any).lang.getString('btnClose')}</span>
                        </button>
                    </div>
                </div>

                }
            </Modal.Body>
        </Modal>
    );
}

export default ExitNavigation;
