import { takeLatest, call, put, all } from 'redux-saga/effects';
import { get, poiCategoryList } from '@api';
import { HttpResponse } from '@api/interfaces';
import { loaderActions, categoryActions } from '@actions';
import { BaseAction } from '@constants';

interface ICategories extends HttpResponse<Response> {
    parsedBody: any;
}

function* requestCategory(action: BaseAction) {
    try {
        const { projectId } = action.payload;
        const language: string = localStorage.getItem('lang') || 'en';
        const params: string = yield call(poiCategoryList, projectId, language);
        const resCategory: ICategories = yield call(get, params);

        yield put({
            type: categoryActions.CategoryActions.REQUESTCATEGORYSUCCESS,
            payload: resCategory.parsedBody,
        });

    } catch (error) {
        yield put({
            type: categoryActions.CategoryActions.REQUESTCATEGORYERROR,
            payload: JSON.stringify(error),
        });
        yield put({
            type: loaderActions.LoaderEnum.LOADERRERROR,
            payload: {
                loading: false,
                mapOverlay: false,
            },
        });
    }
}

function* CategorySaga() {
    yield takeLatest(categoryActions.CategoryActions.REQUESTCATEGORY, requestCategory);
}

function* All() {
    yield all([CategorySaga()]);
}

export default All;
