import momentTZ from "moment-timezone";
import { imagesUrl } from '@api';
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import {
    IProject,
    IPoiIcon,
    IPoi,
    IPoiCategory,
    ISearchState,
    IOccupancy,
    IReportAnalyticsRequest,
    IPOIsCategories,
} from '../interfaces';
import { mobile, mapOptions, Events, TabNames, TabModes } from '../constants';
import Search from "./search";
import Emitter from "./emitter";
import StateStorage from "./stateStorage";
import { history } from "../configStore";
import { post, reportAnalyticsPath } from "../api";
import { getStaff } from "./staff";

/**
 *
 * @param floorArray
 */
export const sortFloors = function sortFloors(floorArray: []) {
    floorArray.sort((a: any, b: any) => a.findex - b.findex);
}

/**
 * Return google maps bounds for the project
 * @project: contains all information about campus and facility locations
 * campus bounds are not accurate enough so we fit all facilities in the map
 *
 * @param {IProject} project
 * */
export const getProjectBounds = function getProjectBounds(project: IProject): any {
    const { google } = window;
    let projectBounds = new google.maps.LatLngBounds();

    // Check if first two facilities have the almost same coordinates
    let facilityLocationDifference: boolean = false;
    if (project.facilities.length > 1) {
        facilityLocationDifference = Math.abs(project.facilities[0].lat - project.facilities[1].lat) < 0.0002 ||
            Math.abs(project.facilities[0].lon - project.facilities[1].lon) < 0.0002;
    }

    /*
     * If there is 1 facility or 2 on same location (case when theres a campus map and one facility)
     * Extend the bounds to facility bounds, else extend to bounds to include all facilities
     */

    if (project.facilities.length === 1 || (project.facilities.length === 2 && facilityLocationDifference)) {
        project.facilities[0].position = {
            lat: project.facilities[0].lat,
            lng: project.facilities[0].lon,
        };

        projectBounds = getFacilityBounds(project.facilities[0]);
        // return projectBounds
    } else {
        for (let i = 0; i < project.facilities.length; i++) {
            project.facilities[i].bounds = getFacilityBounds(project.facilities[i]);
            project.facilities[i].position = {
                lat: project.facilities[i].lat,
                lng: project.facilities[i].lon,
            };

            const fidBound = new google.maps.LatLng(project.facilities[i].lat, project.facilities[i].lon);
            projectBounds.extend(fidBound);
        }
    }

    return projectBounds;
}

/**
 * Return google maps bounds for specified facility
 * @facility: should contain every corner coordinate of facility (top left, top right, bottom left, bottom right)
 * */
export const getFacilityBounds = function getFacilityBounds(facility: any): any {
    const { google } = window;
    const facilityBounds = new google.maps.LatLngBounds();

    facilityBounds.extend(new google.maps.LatLng(facility.geoTlLat, facility.geoTlLon));
    facilityBounds.extend(new google.maps.LatLng(facility.geoTrLat, facility.geoTrLon));
    facilityBounds.extend(new google.maps.LatLng(facility.geoBlLat, facility.geoBlLon));
    facilityBounds.extend(new google.maps.LatLng(facility.geoBrLat, facility.geoBrLon));

    return facilityBounds;
}

/**
 * Outdoor Floor functions (so we can determine where to draw paths, where to set default floor)
 * */
export const updateOutdoorFloor = function updateOutdoorFloor(poi: any, outdoorFloor: number | null, project?: IProject) {
    if(!poi && project) {
        const outdoorFloorParam = getOutdoorFloorParameter(project);
        outdoorFloor = outdoorFloorParam ? outdoorFloorParam.paramValue : mapOptions.defaultFloor;
    } else {
        const cat = poi && poi.categories && poi.categories.length ? poi.categories.toString().toLowerCase() : "";
        const name = poi && poi.description ? poi.description.toLowerCase() : "";

        if (new RegExp(/idrgep/g).test(poi.poid) &&
            (cat.indexOf("bridge") === -1 && name.indexOf("bridge") === -1)) {
            outdoorFloor = poi.floor;
        }
    }

    return outdoorFloor;
}

/**
 *
 * @param project
 */
export const getOutdoorFloorParameter = (project: IProject) => project.campusParameterList.find((item: any) => item.paramName === 'entrance_floor');

/**
 *
 * @param {any} poiItem
 * @param {IProject} project
 * @param gmap
 * @param poidMap
 *
 * @return {IPoiIcon}
 */
export const calcPOIIconSize = (poiItem: IPoi, project: IProject, gmap: any, poidMap: any []): IPoiIcon => {
    let markerSize: number = gmap.getZoom() * project.ratio;

    let imgRatio: number = 1;
    let width: number = markerSize;
    let height: number = markerSize;

    if (poidMap && poidMap[poiItem.poid] && poidMap[poiItem.poid]?.imgWidth !== "undefined" && poidMap[poiItem.poid]?.imgHeight !== "undefined") {
        imgRatio = poidMap[poiItem.poid].imgWidth / poidMap[poiItem.poid].imgHeight;
        switch (true) {
            case imgRatio < 1:
                markerSize += markerSize * 0.5;

                width = markerSize;
                height = markerSize / imgRatio;
                break;
            case imgRatio > 1:
                markerSize += markerSize * 0.5;

                width = markerSize * imgRatio;
                height = markerSize;
                break;
        }
    }

    return { width, height, ratio: imgRatio };
};

/**
 *
 * @param center
 * @param zoom
 */
export const centerMap = (center: any, zoom: number): void => {
    const { gmap } = (window as any);
    if (!gmap) {
        throw new Error('Google map was not initialized properly');
    }

    const currentZoom = gmap.getZoom();
    if (zoom && zoom > 15 && currentZoom !== zoom) {
        gmap.setZoom(zoom);
    } // takes a lot of time, so dont do it if not needed

    gmap.panTo(center);

    if (!mobile) gmap.panBy(100, 0);
}

/**
 *
 * @param currentZoom
 * @param poi
 */
export const centerPoi = (currentZoom: number, poi: any): void => {
    const { google } = window;
    const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
    const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
    const newCenter = new google.maps.LatLng(lat, lon);
    const newZoom = currentZoom < mapOptions.poiZoom ? mapOptions.poiZoom : currentZoom;

    centerMap(newCenter, newZoom);
}

/**
 *
 * @param project
 */
export const centerCampus = (project: IProject) => {
    const { google } = window;
    const { gmap } = (window as any);

    const center = new google.maps.LatLng(project.campus.lat, project.campus.lon);
    gmap.setCenter(center);
}

/**
 *
 * @param poi
 * @return {String} color
 */
export const getOccupancyColor = (poi: any): string => {
    let color = "#00E500";
    if (poi.fsmFacilityState && poi.fsmFacilityState.color) {
        if (poi.fsmFacilityState.color === "Red") {
            color = "#FF0000";
        } else if (poi.fsmFacilityState.color === "Green") {
            color = "#00E500";
        } else if (poi.fsmFacilityState.color === "Yellow") {
            color = "#FCE80A";
        } else if (poi.fsmFacilityState.color === "Gray") {
            color = "#AAAAAA";
        }
    }

    return color;
}

/**
 *
 * @param project
 * @param gmap
 * @param google
 */
export const boundsChange = (project: any, gmap: any, google: any) => {
    gmap.addListener("bounds_changed", () => {
        const c = gmap.getCenter();
        if (c) {
            project.center = c;
            project.zoom = gmap.getZoom();

            if (mapOptions.defaultZoom > 0) {
                project.zoom = mapOptions.defaultZoom;
                gmap.setZoom(project.zoom);
            }

            mapOptions.poiZoom = project.zoom > 19 ? project.zoom + 1 : 19;
            google.maps.event.clearListeners(gmap, "bounds_changed");
        }
    });
}

/**
 *
 * @param floor
 * @param projectId
 */
export const addTileOverlay = (floor: string | number, projectId: string) => {
    const { google } = window;
    const { gmap } = (window as any);

    const indoorMap = new google.maps.ImageMapType({
        getTileUrl:  (coord: any) => {
            if (projectId) {
                const zoomMap = gmap.getZoom();
                return imagesUrl({ pid: projectId, floor, zoom: zoomMap, coord });
            }
            return '';
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 22,
        minZoom: 16,
        opacity: 1,
        name: `indoorMap${floor}`,
    });

    if (floor === mapOptions.defaultFloor) {
        gmap.overlayMapTypes.setAt(floor, indoorMap);
    }
};

/**
 *
 * @param from
 * @param to
 * @param projectId
 */
export const changeViewFloor = (from: string | number, to: string | number, projectId: string) => {
    const { google } = window;
    const { gmap } = (window as any);

    gmap.overlayMapTypes.removeAt(from);
    const indoorMap = new google.maps.ImageMapType({
        getTileUrl:  (coord: any) => {
            if (projectId) {
                const zoomMap = gmap.getZoom();
                return imagesUrl({ pid: projectId, floor: to, zoom: zoomMap, coord });
            }
            return '';
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 22,
        minZoom: 16,
        opacity: 1,
        name: `indoorMap${to}`,
    });

    gmap.overlayMapTypes.setAt(to, indoorMap);
};

/**
 *
 * @param array
 */
export const makeArray = (array: any) => {
    const cats: any [] = [];
    for(const prop in array) {
        if(Object.prototype.hasOwnProperty.call(array, prop)) {
            if (!isNaN(parseInt(prop, 10))) {
                cats.push(array[prop]);
            }
        }
    }

    return cats;
}

/**
 *
 * @param {any []} categories
 * @param poisCategories
 * @param project
 * @param byFilterParam
 * @param byMenuParam
 * @return categories
 */
export const sortCategories = (
  categories: any [],
  poisCategories: IPOIsCategories,
  project: IProject,
  byFilterParam: boolean = false,
  byMenuParam: boolean = false
) => {
    const cats: IPoiCategory [] = makeArray(categories);

    let categs = cats.map(d => {
        d.name = d.name.replace('##', '').replace('$$', '');

        return d;
    });

    categs = categs.filter(cat => {
        if (poisCategories) {
            const existingCategory = poisCategories[cat.name];

            if (existingCategory) {
                if (getStaff(project.pid)) {
                    // return not empty categories
                    return existingCategory.poisNum;
                }

                // return not empty categories and categories which is contains not only staffOnly pois
                return existingCategory.poisNum && existingCategory.poisNum !== existingCategory.staffPoisNum;
            }

            return false;
        }

        return true;
    });

    if (byFilterParam) {
        categs = categs.filter(cat => cat.filter);
    }

    if (byMenuParam) {
        categs = categs.filter(cat => cat.menu);
    }

    categs.sort((a: IPoiCategory, b: IPoiCategory): number => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0; // default return value (no sorting)
    });

    return Array.from(categs);
}

/**
 * Filter marker by categories
 * @param categories
 */
export const checkMarkerCategoriesToShow = (categories: string): boolean => {
    categories = categories.replace('$$##', '');

    if (!categories.includes('$') && categories !== '[]') {
        const filterCat: string | null = localStorage.getItem('currentCategoryName') || null;

        if (!filterCat) return true;
        return categories.indexOf(filterCat) !== -1;
    }

    return !(categories.includes('$'));
}

/**
 * Filter markers by staffOnly property
 * @param staffOnly
 * @param pid
 */
export const checkMarkerStaffOnlyToShow = (staffOnly: boolean | undefined, pid: string): boolean => {
    if(staffOnly === undefined) return true;
    if(!getStaff(pid)) return !staffOnly;
    if(getStaff(pid)) return true;

    return true;
}

/**
 * Clean array from null elements
 * @param array
 */
export const removeNullElements = (array: any []): any [] => array.filter((item: any) => item !== null);

/**
 * Compare 2 strings and return diff letter
 *
 * @param {String} a
 * @param {String} b
 *
 * @returns {string} result
 */
export const findDiff = (a: string, b: string): string => {
    let i: number = 0;
    let j: number = 0;
    let result: string = "";

    while (j < b.length) {
        if (a[i] !== b[j] || i === a.length) result += b[j]; else i += 1;
        j += 1;
    }

    return result;
}

/**
 *
 * @param search
 * @param pois
 * @param occupancy
 * @param fromNavigate
 * @param navigatePoint
 * @param data
 */
export const processSearchInput = (
    search: ISearchState,
    pois: any [],
    occupancy: IOccupancy,
    fromNavigate?: boolean,
    navigatePoint?: string,
    data?: any
) => {
    // Actions based on what search bar we are typing in
    if (search.name === "home" || search.name === "mobile" || search.input.length) {
        incrementalSearch(search, pois, occupancy, fromNavigate, navigatePoint, data);
    }
}

/*
 * @input: string to search in poi description dictionary
 * @element: document id representing div to display the results
 */
export const incrementalSearch = (
    search: ISearchState,
    pois: any [],
    occupancy: IOccupancy,
    fromNavigate?: boolean,
    navigatePoint?: string,
    data?: any
) => {
    if (!search.input || !search.input.length) {
        if (fromNavigate) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_NAVIGATE_SEARCH,
                tabData: {
                    searchMode: 'results',
                    results: null,
                    navigatePoint,
                    fromPoi: data.fromPoi,
                    toPoi: data.toPoi,
                    navObject: data.navObject,
                    facilityNames: data.facilityNames,
                    referrer: data.referrer
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        } else {
            Emitter.emit(Events.RESET_FLOORS_MAP, null);
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.OPEN_EMPTY_SEARCH,
                tabData: null,
                tabBackButton: true,
                tabCloseButton: true,
                tabFilterButton: false,
            });
        }

        return;
    }

    const input: string = search.input.toLocaleLowerCase();
    const pos: number = input.length - 1;
    const words: number = input.split(' ').length;

    // Include in-category search
    const searchPois = pois;
    let temp: IPoi [] = [];

    // 1 word of input
    if (words === 1) {
        search.empty();

        for (let p: number = 0; p < searchPois?.length; p++) {
            let isMatched = false;
            if (searchPois[p].description) {
                const poisWords = searchPois[p].description.toLocaleLowerCase().split(" ");

                for (let j = 0; j < poisWords.length; j++) {
                    if (poisWords[j].length > 1 && poisWords[j].startsWith(input)) {
                        temp.push(searchPois[p]);
                        isMatched = true;
                    }
                }

                let { keywords } = searchPois[p];
                if (keywords && !isMatched) {
                    keywords = keywords.replace('[', '').replace(']', '').trim();

                    if (keywords.length) {
                        const matchedKeywords: string = keywords.split(',').filter((k: string) => k.toLocaleLowerCase().trim().startsWith(input)).join(', ');

                        if (matchedKeywords) {
                            searchPois[p].matchedKeywords = matchedKeywords;
                            temp.push(searchPois[p]);
                            isMatched = true;
                        }
                    }
                }

                // Get person first/last name who occupies a room
                let occupiedUser: string | string [] = occupancy ? occupancy.addPersonToSearch([], searchPois[p], true) : "";
                if (occupiedUser.length && !isMatched) {
                    occupiedUser = occupiedUser.split(" ");

                    if (occupiedUser[0].toLocaleLowerCase().startsWith(input)) {
                        temp.push(searchPois[p]);
                    } else if (occupiedUser[1].toLocaleLowerCase().startsWith(input)) {
                        temp.push(searchPois[p]);
                    }
                }
            }
        }

        search.push(temp);
    } else if (words > 1) {
        // Search by POI name
        temp = Search.containsSearch(searchPois, input, temp);

        // Search by a person who occupies a room
        if (occupancy) {
            for (let i = 0; i < searchPois.length; i++) {
                temp = occupancy.searchByPerson(temp, searchPois[i], input);
            }
        }

        search.push(temp);
    }

    search.index = pos;
    const searchResults = search.storage[search.count - 1];

    // See ITabData interface to be aware the structure of payload
    if (fromNavigate) {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.NAVIGATE,
            tabMode: TabModes.OPEN_NAVIGATE_SEARCH,
            tabData: {
                searchMode: 'results',
                results: searchResults,
                navigatePoint,
                fromPoi: data.fromPoi,
                toPoi: data.toPoi,
                navObject: data.navObject,
                facilityNames: data.facilityNames,
                referrer: data.referrer
            },
            tabBackButton: false,
            tabCloseButton: false,
        });
    } else {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.FIND,
            tabMode: TabModes.SEARCHED,
            tabData: searchResults,
            tabBackButton: true,
            tabCloseButton: true,
            tabFilterButton: true
        });
    }
}

/**
 *
 * @param results
 * @param poi
 */
export const sortByDistanceToPOI = (results: any [], poi: IPoi): any [] => {
    const { google } = window;
    const lat = poi.geoPoint ? poi.geoPoint.lat : poi.lat;
    const lon = poi.geoPoint ? poi.geoPoint.lon : poi.lon;
    const poiPos = new google.maps.LatLng(lat, lon);

    results.map(marker => {
        const markerPos = new google.maps.LatLng(marker.lat, marker.lon);
        marker.dist = haversineDistance(markerPos, poiPos);

        return marker;
    });

    results.sort((m1, m2) => m1.dist - m2.dist);

    // Put search results markers with the same floor as booked POI floor at first place in array
    return [
        ...results.filter(m => m.floor === poi.floor),
        ...results.filter(m => m.floor !== poi.floor)
    ];
}

/**
 *
 * @param mk1
 * @param mk2
 */
export const haversineDistance = (mk1: any, mk2: any): number => {
    const R = 3958.8;

    const rlat1: number = mk1.lat() * (Math.PI/180);
    const rlat2: number = mk2.lat() * (Math.PI/180);

    const difflat: number = rlat2 - rlat1;
    const difflon: number = (mk2.lng() - mk1.lng()) * (Math.PI/180);

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2) * Math.sin(difflat/2)
        + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon/2) * Math.sin(difflon/2)));
}

/**
 *
 * @param a
 * @param b
 * @return 0 | 1 | -1
 */
export const sortByFacility = (a: any, b: any): number => a.facilityName.localeCompare(b.facilityName, undefined, { sensitivity: 'base', numeric: true });

/**
 *
 * @param a
 * @param b
 * @return 0 | 1 | -1
 */
export const sortByPOIName = (a: any, b: any): number => a.description.localeCompare(b.description, undefined, { sensitivity: 'base', numeric: true });

/**
 *
 * @param project
 * @param fid
 * @param floorIndex
 */
export const getFacilityAndFloor = (project: IProject, fid: string, floorIndex: number) => {
    const info: any = {};
    info.facility = " ";
    info.floorname = " ";

    for (let i: number = 0; i < project.facilities.length; i++) {
        if (project.facilities[i].fid === fid) {
            info.facility = project.facilities[i].fname;
            for (let j = 0; j < project.facilities[i].floors.length; j++) {
                if (floorIndex === project.facilities[i].floors[j].findex) {
                    info.floorname = project.facilities[i].floors[j].title;
                    break;
                }
            }
            break;
        }
    }

    return info;
}

/**
 *
 * @param $array
 */
export const arrayUnique = ($array: any []): any [] => {
    const ids: number [] = [];
    const results: any [] = [];

    $array.forEach((item) => {
        if (!ids.includes(item.id)) {
            ids.push(item.id);
            results.push(item);
        }
    });

    return results;
}

export const execValiant360 = () => {
    const valiantPhotoScript = '$(\'.valiantPhoto\').Valiant360({ crossOrigin: "use-credentials", hideControls: true });$(\'.valiant-progress-bar\').remove()';
    const script = document.createElement('script');
    script.text = valiantPhotoScript;
    document.getElementsByTagName('body')[0].appendChild(script);

    setTimeout(() => {
        document.getElementsByTagName('body')[0].removeChild(script);
    }, 300);
}

/**
 *
 * @param facilityId
 * @param project
 */
export const findFacilityBounds = (facilityId: string, project: IProject): any => {
    let facilityBounds: any = null;

    for (let i: number = 0; i < project.facilities.length; i++) {
        if (project.facilities[i].id === facilityId || project.facilities[i].fid === facilityId) {
            facilityBounds = getFacilityBounds(project.facilities[i]);
            break;
        }
    }

    return facilityBounds;
}

/**
 *
 * @param facilityId
 * @param project
 * @param showInfo
 */
export const fitFacilityOnMap = (facilityId: string, project: IProject, showInfo: boolean) => {
    const { google } = window;
    const { gmap } = (window as any);

    showInfo = showInfo || false;
    const facilityBounds: any = findFacilityBounds(facilityId, project);

    if (facilityBounds) {
        gmap.fitBounds(facilityBounds);
        gmap.setZoom(gmap.getZoom() + 1);

        if (showInfo) {
            const facilityData = project.facilities.find(f => (f.id === facilityId || f.fid === facilityId));

            if (facilityData) {
                let infoWindow = (window as any).infoWindow || null;
                if (infoWindow) {
                    infoWindow.close();
                }

                const infoPos = facilityBounds.getCenter();
                infoWindow = new google.maps.InfoWindow({
                    content: `<div class="building-popup">${facilityData.fname}</div>`,
                    position: infoPos
                });
                infoWindow.open(gmap);
                Object.defineProperty(window, 'infoWindow', {
                    writable: true,
                    configurable: false,
                    enumerable: true,
                    value: infoWindow
                });

                setTimeout(() => {
                    infoWindow.close();
                }, 10000);
            }
        }

        return facilityId;
    }

    return facilityBounds;
}

export const getBrowser = (): string => {
    const navUserAgent: any = navigator.userAgent;
    let browserName: string = navigator.appName;
    let browserVersion: string = parseFloat(navigator.appVersion).toString();
    let tempNameOffset;
    let tempVersionOffset;
    let tempVersion;

    if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
        browserName = "Opera";
        browserVersion = navUserAgent.substring(tempVersionOffset + 6);

        if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1) {
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
        }
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1 ||
        (tempVersionOffset = navUserAgent.indexOf("Trident/")) !== -1) {
        browserName = "Internet Explorer";
        browserVersion = navUserAgent.substring(tempVersionOffset + 5);
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("Edge/")) !== -1) {
        browserName = "Edge";
        browserVersion = navUserAgent.substring(tempVersionOffset + 5);
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("CriOS")) !== -1) {
        browserName = "Chrome";
        browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        browserVersion = navUserAgent.substring(tempVersionOffset + 7);

        if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1) {
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
        }
    }
    else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    }
    else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
        browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
        browserVersion = navUserAgent.substring(tempVersionOffset + 1);

        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(";")) !== -1) browserVersion = browserVersion.substring(0, tempVersion);
    if ((tempVersion = browserVersion.indexOf(" ")) !== -1) browserVersion = browserVersion.substring(0, tempVersion);

    return `${browserName} v${browserVersion}`;
}

export const getDeviceType = (): string => {
    const ua: any = navigator.userAgent;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "Tablet";
    }

    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "Mobile";
    }

    return "Desktop";
}

export const getOS = (): string | null => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms: string [] = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'];
    const windowsPlatforms: string [] = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms: string [] = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

/**
 * Get outdoor floor
 */
export const getOutdoorFloor = () => {
    const outdoorFloor =  sessionStorage.getItem('outdoorFloor') || "0";
    return parseInt(outdoorFloor, 10);
}

/**
 *
 * @param paths
 * @param facilities
 */
export const fitPathsToMap = (paths: any, facilities: any) => {
    const { google } = window;
    const { gmap } = (window as any);

    const bounds = new google.maps.LatLngBounds();
    if (!paths.length || !facilities.length) {
        throw new Error("No path or facilities to fit on map");
    }

    paths.forEach((path: any) =>  {
        if (facilities.indexOf(path.facility) > -1) {
            const points = path.getPath().getArray();
            for (let i = 0; i < points.length; i++) {
                bounds.extend(points[i]);
            }
        }
    });

    gmap.fitBounds(bounds);

    const z = gmap.getZoom();
    gmap.setZoom(z - 1);
}

export const adjustPathFit = () => {
    const { gmap } = (window as any);

    if (!mobile) {
        gmap.setZoom(gmap.getZoom() - 1);
        gmap.panBy(0, 0);
    }
}

/**
 *
 * @param obj
 * @param data
 * @param extra
 * @param project
 */
export const shareLink = (obj: string, data: string, extra: string, project: IProject) => {
    // Get rid of possible query params or tab hash in window href
    const link = window.location.href.split('?')[0].split('#')[0];
    let shareUrl = `${link}?${obj}=${data}`;

    if (extra) shareUrl += extra;

    shareUrl += `&lang=${project.userLang}`;
    return shareUrl;
}

/**
 *
 * @param paths
 * @param stroke
 */
export const changePathStroke = (paths: any [], stroke: number) => {
    if (!paths || !paths.length) throw new Error("No paths to change stroke");

    paths.forEach((path) => {
        path.setOptions({ strokeWeight: stroke });
    });
}

export const getShowInMap = (): boolean => {
    const showInMap: string | boolean = localStorage.getItem('showInMap') || 'false';
    return JSON.parse(showInMap);
}

/**
 *
 * @param value
 */
export const toggleShowInMap = (value: boolean): void => {
    localStorage.setItem('showInMap', JSON.stringify(value));
}

export const getSearchText = () => localStorage.getItem('search') || '';

/**
 *
 * @param project
 */
export const resetFilter = (project: IProject) => {
    StateStorage.setItem(`${ project.pid }.filter.building`, (window as any).lang.getString('modalFilter_selectOptionAny'));
    StateStorage.setItem(`${ project.pid }.filter.floor`, (window as any).lang.getString('modalFilter_selectOptionAny'));
}

/**
 *
 * @param {Map} markers
 * @param {IProject} project
 */
export const calculateNumbersOnFloor = (markers: any [], project: IProject) => {
    const floorsMap: any = new Map();
    markers.forEach((item) => {
        if (item.lang === project.userLang && item.showInCategory) {
            if (floorsMap.has(`floor_${ item.floor }`)) {
                floorsMap.set(`floor_${ item.floor }`, floorsMap.get(`floor_${ item.floor }`) + 1);
            } else {
                floorsMap.set(`floor_${ item.floor }`, 1);
            }
        }
    });

    return floorsMap;
}

/**
 *
 * @param poid
 * @param pois
 */
export const getPoi = (poid: string, pois: any []): IPoi => pois.find((item: IPoi) => item.poid === poid);

/**
 *
 * @param pid
 */
export const setBackURL = (pid: string) => {
    StateStorage.setItem(`${pid}.backURL`, document.location.href);
}

/**
 *
 * @param pid
 */
export const getBackURL = (pid: string): string => StateStorage.getItem(`${pid}.backURL`, '');

/**
 *
 * @param pid
 */
export const resetURL = (pid: string) => {
    window.history.pushState(null, '', `/project-list/${pid}`);
}

export const reloadBackURL = (url: string) => {
    setTimeout(() => { document.location.replace(url); }, 500);
}

/**
 * Filter markers by category
 * @param category
 */
export const filterCategory = (category: string) => {
    localStorage.setItem('currentCategoryName', category);
    Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
}

/**
 * Reset filter markers by category
 */
export const resetFilterCategory = () => {
    localStorage.removeItem('currentCategoryName');
    Emitter.emit(Events.FILTER_MARKERS_BY_CATEGORY, null);
}

/**
 *
 * @param debugMode
 */
export const expiry = (debugMode: boolean = false) => {
    const expiryFromStorage = parseInt(sessionStorage.getItem('expiryTime') || '0', 10);
    if (debugMode) { // eslint-disable-next-line no-console
        console.log('[SessionHelper] Expiry is set to', expiryFromStorage && new Date(expiryFromStorage * 1000));
    }
    return expiryFromStorage;
}

/**
 *
 * @param timeoutInMinutes
 * @param debugMode
 */
export const setExpiry = (timeoutInMinutes: number, debugMode: boolean = false) => {
    const now = Math.round(new Date().getTime() / 1000.0); // round it to nearest second
    const expiryTimestampInSeconds: number = now + (timeoutInMinutes * 60);
    sessionStorage.setItem('expiryTime', expiryTimestampInSeconds.toString());

    if (debugMode) { // eslint-disable-next-line no-console
        console.log('[SessionHelper] Set expiry to', new Date(expiryTimestampInSeconds * 1000));
    }
    return expiryTimestampInSeconds;
}

/**
 *
 * @param debugMode
 */
export const isTokenExpired = (debugMode: boolean = false) => {
    const now = Math.round(new Date().getTime() / 1000.0);
    const expiryTimestamp = expiry(debugMode);
    const tokenExpired =
        window === window.parent &&
        window === window.top &&
        now > expiryTimestamp;

    if (debugMode) { // eslint-disable-next-line no-console
        console.log('[SessionHelper] Token is expired', tokenExpired);
    }
    return tokenExpired;
}

/**
 *
 * @param debugMode
 */
export const isValid = (debugMode: boolean = false) => {
    const uuid: string = sessionStorage.getItem('uuid') || '';
    const validate: boolean = uuidValidate(uuid);
    return !isTokenExpired(debugMode) && validate;
}

/**
 *
 * @param debugMode
 */
export const expiryTimeout = (debugMode: boolean = false) => {
    // return the remaining time until timer has finished
    const now = Math.round(new Date().getTime() / 1000.0);
    const expiryTimestamp = expiry();
    if(!expiryTimestamp) {
        return null;
    }

    const timeTillExpiryInSeconds: number = expiryTimestamp - now;
    if (debugMode) { // eslint-disable-next-line no-console
        console.log(`[SessionHelper] Token expiry timeout function will be triggered in roughly ${Math.round(timeTillExpiryInSeconds / 60)} minute(s) at ${new Date(expiryTimestamp * 1000)}.`);
    }
    return timeTillExpiryInSeconds;
}

/**
 *
 * @param timeoutInMinutes
 * @param debugMode
 */
export const setSessionData = (timeoutInMinutes: number, debugMode: boolean = false) => {
    // Generate a new UUID
    const id: string = uuidv4();
    sessionStorage.setItem('uuid', id);
    setExpiry(timeoutInMinutes, debugMode);
}

/**
 *
 * @param callback
 * @param debugMode
 */
export const startExpiryTimeout = (callback: any, debugMode: boolean = false) => {
    if(!isValid(debugMode)) {
        removeAllExpiry();
        history.replace('/');
    }

    const timeTillExpiryInSeconds: any = expiryTimeout(debugMode);
    if(!timeTillExpiryInSeconds || timeTillExpiryInSeconds <= 0) {
        removeAllExpiry();
        history.replace('/');
    }

    setTimeout(() => {
        callback();
    }, (timeTillExpiryInSeconds * 1000) + 1000);
}

export const removeAllExpiry = () => {
    sessionStorage.removeItem('uuid');
    sessionStorage.removeItem('expiryTime');
}

export const removeExpiry = () => {
    sessionStorage.removeItem('expiryTime');
}

export const isGuest = () => {
    if((window as any).config.noLogin) {
        return true;
    }

    const guest: string = sessionStorage.getItem('isGuest') || '0';
    if(parseInt(guest, 10)) {
        return true;
    }

    const addr = new URL(window.location.href);
    const urlParams = new URLSearchParams(addr.search);

    return urlParams.has('pid') || urlParams.has('poi')
      || urlParams.has('to') || urlParams.has('from')
      || urlParams.has('sourceid') || urlParams.has('sourceSystem');
}

/**
 *
 * @param req
 */
export const reportAnalytics = (req: IReportAnalyticsRequest) => {
    const path: string = reportAnalyticsPath();
    post(path, req, {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
            'Content-Type': 'application/json'
        }
    })
      // .then((result: any) => console.log(result))
      .catch((error: any) => console.error(error));
}

export const generateRandomId = () => `web_${momentTZ().format('YYYY-MM-DD_HH:mm:ss')}_${momentTZ().unix()}`;

export const isSamsungDefaultBrowser = () => {
    const regExp = /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i;
    return navigator.userAgent.match(regExp);
}
