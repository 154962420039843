import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Categories from "../../Categories";
import { TabModes } from "../../../constants";

export interface IBuilding {
    tabData: any;
    tabMode: string;
}

const Building: FC<IBuilding> = (props: PropsWithChildren<IBuilding>) => {
    const {
        tabData,
        tabMode
    } = props;

    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const categories = React.useMemo(() => tabData?.categories || [], [tabData]);
    const poisCategories = React.useMemo(() => tabData?.poisCategories || {}, [tabData]);
    const floor = React.useMemo(() => tabData?.floor || 0, [tabData]);
    const changeFloor = React.useMemo(() => tabData?.changeFloor || null, [tabData]);
    const pois = React.useMemo(() => tabData?.pois || [], [tabData]);
    const cname = React.useMemo(() => tabData?.cname || null, [tabData]);
    const cid = React.useMemo(() => tabData?.cid || null, [tabData]);
    const fromHome = React.useMemo(() => tabData?.fromHome || false, [tabData]);
    const facilityNames = React.useMemo(() => tabData?.facilityNames || null, [tabData]);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const fromParking = React.useMemo(() => tabData?.fromParking || false, [tabData]);
    const category = React.useMemo(() => tabData?.category || null, [tabData]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    let buildingContext;
    switch(tabMode) {
        case TabModes.OPEN_CATEGORY_LIST:
            buildingContext = (
                <Categories project={project}
                            categories={categories}
                            poisCategories={poisCategories}
                            floor={floor}
                            changeFloor={changeFloor}
                            pois={pois}
                            tabMode={tabMode}
                            facilityNames={facilityNames}
                />
            );
            break;
        case TabModes.OPEN_CATEGORY_POI_LIST:
            buildingContext = (
                <Categories project={project}
                            categories={categories}
                            poisCategories={poisCategories}
                            floor={floor}
                            changeFloor={changeFloor}
                            pois={pois}
                            cname={cname}
                            cid={cid}
                            tabMode={tabMode}
                            fromHome={fromHome}
                            fromParking={fromParking}
                            facilityNames={facilityNames}
                            category={category}
                />
            );
            break;
    }

    return (
        <>
            { buildingContext }
        </>
    );
};

export default Building;
