import React, { FC, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { IPoi, IProject } from "../../interfaces";
import FavoriteItems from "../FavoriteItems";
import RecentItems from "../RecentItems";

export interface IWelcomeSearch {
    project: IProject;
    pois: IPoi [];
    changeFloor: (floor: number) => void;
}

const WelcomeSearch: FC<IWelcomeSearch> = (props: PropsWithChildren<IWelcomeSearch>) => {
    const {
        project,
        pois,
        changeFloor
    } = props;

    return (
        <PerfectScrollbar>
            <div className="favorite-header welcome-header">
                <h4>{(window as any).lang.getString('homeTab_tabTitleFavorites')}</h4>
            </div>
            <FavoriteItems project={project}
                           pois={pois}
                           changeFloor={changeFloor}
                           fromWelcomeSearch={true}
            />
            <div className="recent-header welcome-header">
                <h4>{(window as any).lang.getString('homeTab_tabTitleRecent')}</h4>
            </div>
            <RecentItems project={project}
                         pois={pois}
                         changeFloor={changeFloor}
            />
        </PerfectScrollbar>
    )
};

export default WelcomeSearch;
