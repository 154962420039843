import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, NavigateModes, mobile } from '@constants';
import { IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";

import './styles.scss';
import NavigateBottomBar from "../NavigateBottomBar";
import ExitNavigation from "../ExitNavigation";
import ReportAnIssue from "../ReportAnIssue";
import { centerMap } from "../../helpers/marker";


export interface IInstructions {
    project: IProject;
    tabData: any;
}

interface IMouse {
    floor: number;
    fid: string;
    type?: number;
}

const Instructions: FC<IInstructions> = (props: PropsWithChildren<IInstructions>) => {
    const {
        project,
        tabData,
    } = props;


    const destinationText = tabData?.pdfInfo?.path?.destination.description || '';
    const instructionList = tabData?.simplifiedList || null;

    const { google } = window;
    const { gmap } = (window as any);

    const navObj = tabData.pdfInfo?.path.navObj;
    const changeFloor = React.useCallback((from: number, to: number) => {
        navObj.floorChange(from, to);
        navObj.changeViewFloor(to);
    }, [navObj])

    const pdfInfo = tabData?.pdfInfo || null;
    const lang: string = project.userLang || 'en';
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [currStep, setCurrStep] = React.useState(0);
    const [showAllInstruct, setShowAllInstruct] = React.useState(false);

    const backTo = React.useCallback(() => {
        Emitter.emit(Events.OPEN_EXIT_NAVIGATION, { isShow: true, Action: 'backTo' });
    }, []);

    const closeSidebar = React.useCallback(() => {
        Emitter.emit(Events.OPEN_EXIT_NAVIGATION, { isShow: true, Action: 'closeSidebar' });
    }, []);

    const handlerMouseEnter = React.useCallback((e: any, data: IMouse) => {
        e.preventDefault();

        const nav: any = pdfInfo?.path;
        nav?.paths[`floor${data.floor}`].forEach((line: any) => {
            if (line.facility === data.fid && data.type === 1) {
                line.setOptions({ strokeColor: '#4a80f5' });
            }
        });
    }, [pdfInfo]);

    const handlerMouseLeave = React.useCallback((e: any, data: IMouse) => {
        e.preventDefault();

        const nav: any = pdfInfo?.path;
        nav?.paths[`floor${data.floor}`].forEach((line: any) => {
            if (line.facility === data.fid) {
                line.setOptions({ strokeColor: '#1b4297' });
            }
        });
    }, [pdfInfo]);

    const renderInstruction = React.useCallback((instruction: any, i: number) => {
        const list: any [] = [];
        if (lang === 'he') {
            list.push((
                <div key={`instruction-inside${i}`}>
                    {instruction.type === 1 && (
                        <p className="instruction-distance right-dir col-xs-3">
                            { instruction.distance > 1 ? (window as any).lang.getString('nav_instructionDistM', [Math.ceil(instruction.distance)]) : (window as any).lang.getString('nav_instructionSmallDistM') }
                        </p>
                    )}
                    <p className={ instruction.type === 1 ? 'col-xs-6' : 'col-xs-9' }>
                        { instruction.text }
                    </p>
                    <img src={ instruction.icon }
                         className="col-xs-3 instruction-img right-dir pull-right"
                         alt="point icon"
                    />
                </div>
            ));
        } else {
            const ftDistance = Math.round(instruction.distance / 0.3048);
            list.push((
                <div key={`instruction-inside${i}`}>
                   <div className="col-xs-3">
                       <span>{`${(i + 1)}.`}</span>
                       <img src={instruction.icon} className="instruction-img" alt="point icon"/>
                   </div>
                    <p className={instruction.type === 1 ? "col-xs-6 instruction-msg" : "col-xs-9 instruction-msg"}>
                        {instruction.text}
                    </p>
                    {instruction.type === 1 && (
                        <p className="instruction-distance col-xs-3 pull-right text-right">
                            {ftDistance > 1 ? (window as any).lang.getString('nav_instructionDistFt', [ftDistance]) : (window as any).lang.getString('nav_instructionSmallDistFt')}
                        </p>
                    )}
                </div>
            ));
        }

        return list;
    }, [lang]);


    const renderInstructions = React.useCallback(() => {
        const instructions: any [] = [];
        if (!instructionList) {
            return null;
        }

        // Add a divider at the beginning
        for (let i = 0, n = instructionList.length; i < n; i++) {
            const instructionFloor: number = instructionList[i].floor;
            const instructionFid: string = instructionList[i].fid;

            const instructionElement = (
                <div key={`instruction${i}`} className='inst-cont'>
                    <div className="path-instruction"
                         id={`instruction${i}`}
                         onMouseEnter={(e) => handlerMouseEnter(e, {
                             floor: instructionFloor,
                             fid: instructionFid,
                             type: instructionList[i].type
                         })}
                         onMouseLeave={(e) => handlerMouseLeave(e, {
                             floor: instructionFloor,
                             fid: instructionFid
                         })}
                    >
                        { renderInstruction(instructionList[i], i) }
                    </div>
                    {i !== (n - 1) && !mobile ? <div className="instruction-divider"/> : null}
                </div>
            );

            instructions.push(instructionElement);
        }

        return instructions;
    }, [
        handlerMouseEnter,
        handlerMouseLeave,
        renderInstruction,
        instructionList
    ]);

    const highlightNextStep = React.useCallback(() => {
        const currElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(${ currStep + 1}) .path-instruction`);
        currElem.style.border = 'none';

        const nextElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(${ currStep + 2 }) .path-instruction`);
        nextElem.style.border = '1px solid #5fac45';
    }, [currStep]);

    const nextClick = React.useCallback(() => {
        if(showAllInstruct && mobile) {
            setTimeout(highlightNextStep, 100);
        }

        if (currStep < instructionList.length-1) {
            // center map
            const segmentCenter = instructionList[currStep + 1].segment_center
            const newCenter = new google.maps.LatLng(segmentCenter.latitude, segmentCenter.longitude);
            centerMap(gmap, newCenter, 20)

            // change floor if needed
            if (instructionList[currStep + 1].floor !== instructionList[currStep].floor) {
                changeFloor(instructionList[currStep].floor, instructionList[currStep + 1].floor);
            }
            setCurrStep(currStep + 1);
        }
    }, [
        currStep,
        gmap,
        google.maps.LatLng,
        instructionList,
        changeFloor,
        showAllInstruct,
        highlightNextStep
    ]);

    const highlightPrevStep = React.useCallback(() => {
        const currElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(${ currStep + 1 }) .path-instruction`);
        currElem.style.border = 'none';

        const prevElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(${ currStep }) .path-instruction`);
        prevElem.style.border = '1px solid #5fac45';
    }, [currStep]);

    const prevClick = React.useCallback(() => {
        if(showAllInstruct && mobile) {
            setTimeout(highlightPrevStep, 100);
        }

        if (currStep > 0) {
            const segmentCenter = instructionList[currStep - 1].segment_center
            const newCenter = new google.maps.LatLng(segmentCenter.latitude, segmentCenter.longitude);
            centerMap(gmap, newCenter, 20)

            // change floor if needed
            if (instructionList[currStep - 1].floor !== instructionList[currStep].floor) {
                changeFloor(instructionList[currStep].floor, instructionList[currStep - 1].floor);
            }
            setCurrStep(currStep - 1);
        }
    }, [
        currStep,
        gmap,
        google.maps.LatLng,
        instructionList,
        changeFloor,
        showAllInstruct,
        highlightPrevStep
    ]);

    const finishClick = React.useCallback(() => {
        Emitter.emit(Events.OPEN_EXIT_NAVIGATION, { isShow: true, Action: 'finishRoute' });
    }, [])

    const setCurrentStep = React.useCallback(() => {
        if(currStep) {
            const currElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(1) .path-instruction`);
            currElem.style.border = 'none';

            const nextElem: any = document.querySelector(`#nav-window .nav-instruction .inst-cont:nth-child(${ currStep + 1 }) .path-instruction`);
            nextElem.style.border = '1px solid #5fac45';
        }
    }, [currStep]);

    const renderInstructionsMobile = React.useCallback(() => {
        if(showAllInstruct && mobile) {
            setTimeout(setCurrentStep, 300);
        }

        const instructionsUiElements: any = renderInstructions();
        const visibleInstructions = !showAllInstruct ?
            instructionsUiElements.slice(currStep, currStep + 2) : instructionsUiElements;
        if (visibleInstructions.length <= 2) {
            Emitter.emit(Events.ONLY_TWO_INSTRUCTIONS, null);
        }
        return visibleInstructions;
    }, [
        currStep,
        renderInstructions,
        showAllInstruct,
        setCurrentStep
    ])

    React.useEffect(() => {
        cleanupFunction.current = false;
        if (tabData && tabData.pdfInfo && tabData.pdfInfo.path) {
            tabData.pdfInfo.path.navObj.mode = NavigateModes.ROUTE;
        }

        Emitter.on(Events.SHOW_ALL_INSTRUCTIONS, () => {
            if(!cleanupFunction.current) setShowAllInstruct(true);
        });

        Emitter.on(Events.HIDE_ALL_INSTRUCTIONS, () => {
            if(!cleanupFunction.current) setShowAllInstruct(false);
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        tabData,
        setShowAllInstruct
    ]);

    return (
        <div id="navigate-ui-window" className="spreo-ui-window ui-window">
            <div className="window-header">
                <div className="window-header-left">
                    <button className="back-btn pull-left"
                            type="button"
                            onClick={backTo}
                    >
                        <i className="back-btn-ico" />
                    </button>
                    <h4 className="window-poi-title">{ destinationText }</h4>
                </div>
                <button className="btn btn-default srch-close-btn "
                        type="button"
                        onClick={closeSidebar}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div id="nav-window" className="spreo-nav-window">
                <div className="nav-help">
                    <div className="row instruction-cont in-route">
                        <div className="col-xs-5">
                            <span>{(window as any).lang.getString('navigateTab_inRoute')}</span>
                        </div>
                        <div className="col-xs-7 ">
                            <div id="instruction-time" className={project.userLang === 'he' ? "pull-right right-dir" : "pull-right"}>
                                <p className="number-time">{ tabData?.time }</p>
                                <p className="estimated-time">{ (window as any).lang.getString('nav_instructionDist', tabData?.distTextVal) }</p>
                            </div>
                        </div>
                    </div>

                </div>
                {instructionList ? <div className="nav-instruction">
                  {!mobile ?
                    <PerfectScrollbar>
                        {renderInstructions()}
                    </PerfectScrollbar>
                    : // mobile mode
                    <div>
                        {renderInstructionsMobile()}
                        <div className="inst-buttons">
                            {currStep !== instructionList.length-1 ?
                            <button className="inst-btn"
                                type="button"
                                onClick={nextClick}>
                                <div>
                                    <span>
                                        {(window as any).lang.getString('btnNext')}
                                    </span>
                                    <i className="instruction-nav-ico"/>
                                </div>
                            </button>
                            :
                            <button className="inst-btn"
                                type="button"
                                onClick={finishClick}>
                                <div>
                                    <span>
                                        {(window as any).lang.getString('btnFinish')}
                                    </span>
                                    <i className="instruction-nav-ico"/>
                                </div>
                            </button>
                            }
                            {currStep !== 0 &&
                            <button className="inst-btn prev"
                                type="button"
                                onClick={prevClick}>
                                <div>
                                    <i className="instruction-nav-ico" />
                                    <span>
                                        {(window as any).lang.getString('btnPrev')}
                                    </span>
                                </div>
                            </button>
                            }
                        </div>
                    </div>
                    }



                </div> : null}
                <NavigateBottomBar tabData={tabData} project={project}/>
            </div>
            <ExitNavigation tabData={tabData} />
            <ReportAnIssue project={project} />
        </div>
    );
}

export default Instructions;
