import React, { FC } from 'react';
import { IProject } from '@interfaces';

import './error-page.scss';

interface IErrorPage {
  project: IProject;
  changeLoading: (loading: boolean) => void;
}

const ErrorPage: FC<IErrorPage> = (props) => {
  const { project, changeLoading } = props;

  React.useEffect(() => {
    changeLoading(false);
  }, [changeLoading])

  let message = "";

  if (!project.webAppEnabled) {
    message = "This site is not enabled for web browsers.";
  }

  if (!project.production) {
    message = "Sorry, this project isn't marked as production.";
  }

  return (
    <div className="error-message">
      <p>{ message }</p>
    </div>
  );
}

export default ErrorPage;