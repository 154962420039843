import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import configStore, { history } from './configStore';
import 'core-js/features/url-search-params';

import './index.scss';
import './assets/styles/ui.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import { redirectToNewURL, redirectWithoutLogin } from "./helpers/marker";

const urlParams = new URLSearchParams(window.location.search);
const pid: any = urlParams.has('pid') ? urlParams.get('pid') : null;

const lang: string | null = urlParams.has('lang') ? urlParams.get('lang') : null;
if (lang) {
    localStorage.setItem('lang', lang);
}

const { pathname }: any = window.location;
const URLRegExp: any = new RegExp(`project-list/[^/]*`, 'g');

if ((window as any).config.noLogin
    && (window as any).config.pathname
    && pathname === `${(window as any).config.pathname}`) {
    redirectWithoutLogin();
} else if (pid && !pathname.match(URLRegExp)) {
    sessionStorage.setItem('isGuest', '1');
    redirectToNewURL(window.location.href);
} else {
    if (pathname.match(URLRegExp) && !sessionStorage.getItem('uuid')) {
        sessionStorage.setItem('isGuest', '1');
    }

    const store = configStore();

    ReactDOM.render(
        <Provider store={store} context={ReactReduxContext}>
            <App history={history}/>
        </Provider>,
        document.getElementById('root')
    );
}
