import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import ShowInMap from "../../ShowInMap";
import { IGallery } from "../../../interfaces";

interface  IInfo {
    tabData: any;
    tabMode: string;
    galleries: IGallery [][];
}

const Info: FC<IInfo> = (props: PropsWithChildren<IInfo>) => {
    const {
        tabData,
        galleries
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const project = React.useMemo(() => tabData?.project || null, [tabData]);
    const poi = React.useMemo(() => tabData?.poi || null, [tabData]);
    const changeFloor = React.useMemo(() => tabData?.changeFloor || null, [tabData]);
    const youAreHere: boolean = React.useMemo(() => tabData?.youAreHere || false, [tabData]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    return poi ? <ShowInMap project={project}
                            poi={poi}
                            changeFloor={changeFloor}
                            galleries={galleries}
                            youAreHere={youAreHere}
                 /> : null;
}

export default Info;
