import { Reducer } from 'redux';
import { occupancyActions } from '@actions';
import { BaseAction } from '@constants';

const initState: any = {};

const occupancyStore: Reducer<any, BaseAction> = (state = initState, action) => {
    switch (action.type) {
        case occupancyActions.OccupancyActions.REQUESTOCCUPANCY:
        case occupancyActions.OccupancyActions.REQUESTOCCUPANCYERROR:
        case occupancyActions.OccupancyActions.REQUESTOCCUPANCYSUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default occupancyStore;
