import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import { ICampus } from "../../interfaces";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";

import './styles.scss';
import StateStorage from "../../helpers/stateStorage";

export interface ICampusMap {
    projects: ICampus [];
}

const CampusMap: FC<ICampusMap> = (props: PropsWithChildren<ICampusMap>) => {
    const {
        projects
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [defaultMap, setDefaultMap] = React.useState('');

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) setShow(false);
    }, [setShow]);

    const setDefault = React.useCallback(() => {
        StateStorage.setItem(`${localStorage.getItem('username')}.default.project`, defaultMap);
        onClose();
    }, [defaultMap, onClose]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_CAMPUS_MAP, (newValue) => {
            if (!cleanupFunction.current) {
                setShow(newValue);
                const pid: any = StateStorage.getItem(`${localStorage.getItem('username')}.default.project`, '');
                setDefaultMap(pid);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction, setDefaultMap]);

    const renderCampusMap = React.useCallback(() => {
        if (projects && projects.length > 1) {
            return (<ul className="projects text-center">
                {projects.map((item) => (
                    <li key={item.key} className="form_radio">

                        <input type="radio" id={item.key} name="campus" defaultChecked={defaultMap === item.id} onClick={() => setDefaultMap(item.id)}/>
                        <label htmlFor={item.key}>
                            <span>{ item.name }</span>
                        </label>
                    </li>
                ))}
            </ul>);
        }

        return null;
    }, [
        projects,
        defaultMap,
        setDefaultMap
    ]);

    const cleanDefaultCampus = React.useCallback(() => {
        setShow(false);

        Emitter.emit(Events.OPEN_CLEAN_DEFAULT_CAMPUS, true);
    }, [
        setShow
    ]);

    return (
        <>
        <Modal show={show} animation={false} centered id="campus-map" onHide={() => false } role="dialog">
            <Modal.Body className="modal-width">
                <div className="campus-modal projects-list">
                    <div className="campus-header">
                        <h4 className="text-center" data-lang-key="changeCampus">{(window as any).lang.getString('editDefaultCampus')}</h4>
                        <i className="close-ico"
                           onClick={() => onClose()}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="close default campus"
                        />
                    </div>

                    { renderCampusMap() }

                    <div className="confirm-campus">
                        <button type="button"
                                onClick={() => setDefault()}
                                className="btn confirm-btn"
                        >
                            {(window as any).lang.getString('defaultCampusConfirm')}
                        </button>
                    </div>
                    <div className="clean-campus">
                        <button type="button"
                                onClick={() => cleanDefaultCampus()}
                                className="btn clean-btn"
                        >
                            {(window as any).lang.getString('clearDefaultCampus')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
        );
};

export default CampusMap;
