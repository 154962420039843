import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Modal from "react-bootstrap/Modal";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import { IProject } from "../../interfaces";
import { shareLink } from "../../helpers/utils";


export interface IShareNavigateModal {
    pdfInfo: any;
    project: IProject;
}

const ShareNavigateModal: FC<IShareNavigateModal> = (props: PropsWithChildren<IShareNavigateModal>) => {
    const {
        pdfInfo,
        project
    } = props;

    const link = shareLink("from", pdfInfo?.path?.origin?.poid, `&to=${pdfInfo?.path?.destination?.poid}`, project);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const target = React.useRef(null);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) {
            setShow(false);
        }
    }, [setShow]);

    const showCopiedInfo = React.useCallback(() => {
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    }, [setShowTooltip]);

    const copyCode = React.useCallback((code, e?: any) => {
        if(e) e.preventDefault();

        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(() => showCopiedInfo());
            return;
        }

        if (document.execCommand) {
            const area: any = document.createElement('textarea');
            area.value = code;
            area.select();
            document.execCommand('copy');
            showCopiedInfo();
        }
    }, [showCopiedInfo]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_SHARE_NAVIGATE_MODAL, (newValue) => {
            if (!cleanupFunction.current) setShow(newValue)
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [setShow, cleanupFunction]);

    return (
        <Modal show={show} animation={false} centered id="share-link" onHide={() => false }>
            <Modal.Body className="modal-width">
                <div className="campus-modal">
                    <h4>{ (window as any).lang.getString('modalShare_shareRouteTitle') }</h4>
                    <div className="link">
                        <input type="text" readOnly={true} value={link}/>
                        <i className="far fa-copy"
                           onClick={() => copyCode(link)}
                           role="button"
                           onKeyPress={(e) => e.preventDefault()}
                           tabIndex={0}
                           aria-label="Copy share link"
                           ref={target}
                        />
                        <Overlay target={target.current} show={showTooltip} placement="top">
                            {(tooltipProps) => (
                                <Tooltip id="overlay-copied" {...tooltipProps}>
                                    { (window as any).lang.getString('linkWasSuccessfullyCopied') }
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                    <button type="button"
                            className="modal-btn close-btn close-share-modal"
                            onClick={onClose}>
                        <span>{ (window as any).lang.getString('btnClose') }</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ShareNavigateModal;
