import React, { FC, PropsWithChildren } from 'react';
import Floor from "../Floor";
import { IProject } from "../../interfaces";

import '../Floor/floor.scss';
import '../Floor/tiny-slider.css';
import { resetMap } from "../../helpers/marker";
import Emitter from "../../helpers/emitter";
import { Events, mobile } from '../../constants';

export interface IFloatingPanel {
    project: IProject;
    floor: number;
    changeFloor: (floor: number) => void;
}

/**
 * Component which displays floating panel with filter button and floor picker
 * @param {PropsWithChildren<IFloatingPanel>} props
 * @constructor
 */
const FloatingPanel: FC<IFloatingPanel> = (props: PropsWithChildren<IFloatingPanel>) => {
    const {
        project,
        floor,
        changeFloor
    } = props;

    const floatingPanel: any = React.useRef(null);
    const reloadMap = React.useCallback((e) => {
        e.preventDefault();
        resetMap(project, changeFloor);
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
    }, [project, changeFloor]);

    const floatingPanelCallbackRef = React.useCallback(element => {
        floatingPanel.current = element;

        if (element && mobile) {
            const mapReload = document.querySelector<HTMLElement>('#floating-panel .map-reload');

            if (mapReload) {
                element.style.visibility = 'hidden';
                mapReload.style.display = 'none';
            }
        }
    }, []);

    React.useEffect(() => {
        Emitter.on(Events.HIDE_FLOATING_PANEL, () => {
            floatingPanel.current.style.display = 'none';
        });
        Emitter.on(Events.HIDE_MAP_CONTROLS, () => {
            floatingPanel.current.style.display = 'none';

            const gmnoprint: any = document.querySelector('.gmnoprint');

            if (gmnoprint) gmnoprint.style.display = 'none';
        });
        Emitter.on(Events.SHOW_MAP_CONTROLS, () => {
            floatingPanel.current.style.display = 'block';

            const gmnoprint: any = document.querySelector('.gmnoprint');

            if (gmnoprint) gmnoprint.style.display = 'block';
        });
        Emitter.on(Events.RECALCULATE_FLOATING_PANEL_POSITION, () => {
            const mapReload = document.querySelector<HTMLElement>('#floating-panel .map-reload');
            const floorPicker = document.querySelector<HTMLElement>('#floating-panel .floor-picker');

            if (mapReload) mapReload.style.display = 'none';

            const zoomBtns = document.querySelectorAll<HTMLElement>('.gmnoprint')[1];

            if (zoomBtns && mapReload && floatingPanel.current && floorPicker) {
                const zoomBtnsHeight = parseInt(getComputedStyle(zoomBtns).height, 10);
                const zoomBtnsTopPosition = parseInt(getComputedStyle(zoomBtns).top, 10);
                const floorPickerHeight = parseInt(getComputedStyle(floorPicker).height, 10);

                floatingPanel.current.style.setProperty('top', `${zoomBtnsTopPosition - floorPickerHeight / 2 + zoomBtnsHeight / 2}px`, 'important');

                const floatingPanelTopPosition = parseInt(getComputedStyle(floatingPanel.current).top, 10);

                mapReload.style.top = `calc(${zoomBtnsTopPosition}px - ${floatingPanelTopPosition}px - 55px)`;
                mapReload.style.display = 'block';
                floatingPanel.current.style.visibility = 'visible';
            }
        });
    }, [floatingPanel]);

    return (
        <div id="floating-panel" ref={floatingPanelCallbackRef}>
            <div aria-label="map-reload"
                 className="map-reload"
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
                 onClick={(e) => reloadMap(e)}/>
            <Floor project={project}
                   floor={floor}
                   changeFloor={ changeFloor }
            />
        </div>
    );
};

export default FloatingPanel;
