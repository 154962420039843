import React, { FC, PropsWithChildren } from 'react';
import SearchBox from "../../SearchBox";
import MainMenu from "../../MainMenu";
import { IBuilding, ICampus, IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../../interfaces';

interface  IHome {
    project: IProject;
    buildings: IBuilding [];
    projects: ICampus [];
    floor: number;
    changeFloor: (floor: number) => void;
    backButton: boolean;
    closeButton: boolean;
    pois: IPoi [];
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    changeLoading?: (loading: boolean) => void;
}

const Home: FC<IHome> = (props: PropsWithChildren<IHome>) => {
    const {
        project,
        buildings,
        projects,
        floor,
        changeFloor,
        backButton,
        closeButton,
        pois,
        categories,
        poisCategories,
        changeLoading
    } = props;

    return (
        <>
            <SearchBox tab="home"
                       project={project}
                       backButton={backButton}
                       closeButton={closeButton}
                       changeLoading={changeLoading}
            />
            <div className="spreo-ui-window" id="home-ui-window">
                <MainMenu project={project}
                          buildings={buildings}
                          projects={projects}
                          floor={floor}
                          changeFloor={changeFloor}
                          pois={pois}
                          categories={categories}
                          poisCategories={poisCategories}
                />
            </div>
        </>
    );
}

export default Home;
