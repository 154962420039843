import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, NavigateModes, NavigatePoints, TabModes, TabNames, mobile, NavStates, ScanFrom, Referrers } from '@constants';
import Emitter from "../../helpers/emitter";
import { IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import Categories from "../Categories";
import CategoryItems from "../CategoryItems";
import FavoriteItems from "../FavoriteItems";
import RecentItems from "../RecentItems";
import Nav from "../../helpers/nav";
import { useForceUpdate } from "../../hooks";
import NavigateBottomBar from "../NavigateBottomBar";
import { getParking } from "../../helpers/favorites";

import './styles.scss';
import { resetFilterCategory } from "../../helpers/utils";
import ReportAnIssue from "../ReportAnIssue";
import { selectPoi } from "../../helpers/marker";

export interface INavigateUI {
    project: IProject;
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    pois: IPoi [];
    floor: number;
    changeFloor: (floor: number) => void;
    poidMap: IPoi [];
    navMode: string;
    fromPoi?: IPoi;
    toPoi?: IPoi;
    tabData?: any;
    facilityNames?: any;
    referrer?: string;
}

const NavigateUI: FC<INavigateUI> = (props: PropsWithChildren<INavigateUI>) => {
    const {
        project,
        categories,
        poisCategories,
        pois,
        floor,
        changeFloor,
        poidMap,
        navMode,
        fromPoi,
        toPoi,
        tabData,
        facilityNames,
        referrer
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const [showNavigateContent, setShowNavigateContent] = React.useState(false);
    const [showGoNow, setShowGoNow] = React.useState(true);
    const navigatePoint = React.useRef<any>(null);
    const startPointState = React.useState(null);
    const startPoint: any = startPointState[0];
    const setStartPoint: any = startPointState[1];

    const endPointState = React.useState(null);
    const endPoint: any = endPointState[0];
    const setEndPoint: any = endPointState[1];

    const [navigateMode, setNavigateMode] = React.useState(navMode);

    const currentCatName = React.useRef('');
    const nav: any = React.useRef(null);
    const emit: any = React.useRef(false);
    const mounted: any = React.useRef(false);
    const instructionCont: any = React.useRef(null);
    const navigateFields: any = React.useRef(null);

    const parkingPoi: any = pois.find((item: IPoi) => getParking(project.pid) === item.poid);

    const setTimer = React.useCallback(() => {
        setTimeout(() => {
            emit.current = false;
        }, 1000);
    }, []);

    const openRouteList = React.useCallback(() => {
        Emitter.emit(Events.SIDEBAR_LOADING, true);

        if (mobile) {
            instructionCont.current.style.display = 'none';
            navigateFields.current.style.display = 'none';
            Emitter.emit(Events.GO_NOW, { fromPoi: startPoint, toPoi: endPoint });
        }

        setTimeout(() => {
            Emitter.emit(Events.SIDEBAR_LOADING, false);

            let startFloor: number = nav.current.origin.poi.floor;
            const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
            const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

            if (nav.current.origin.poi.x === 0 && nav.current.origin.poi.y === 0 && startFloor !== outdoorFloor) {
                startFloor = outdoorFloor;
            }

            if (nav.current.currentFloor !== startFloor) {
                nav.current.floorChange(nav.current.currentFloor, startFloor);
                nav.current.changeViewFloor(startFloor);
            }

            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.NAVIGATE,
                tabMode: TabModes.OPEN_ROUTE,
                tabData,
                tabBackButton: false,
                tabCloseButton: false,
            });
        }, 1500);
    }, [tabData, startPoint, endPoint]);

    const openNavigateSearch = React.useCallback(() => {
        if (mobile) {
            Emitter.emit(Events.EXIT_NAVIGATE_MENU, null);
        }
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.NAVIGATE,
            tabMode: TabModes.OPEN_NAVIGATE_SEARCH,
            tabData: {
                fromPoi: startPoint,
                toPoi: endPoint,
                navigatePoint: navigatePoint.current,
                navObject: nav.current,
                facilityNames,
                referrer
            },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [
        startPoint,
        endPoint,
        navigatePoint,
        nav,
        facilityNames,
        referrer
    ]);

    const removeDirections = React.useCallback(() => {
        nav.current.reset();

        setTimer();
        Emitter.emit(Events.UNSET_CURRENT_POI, null)
        Emitter.emit(Events.EXIT_GO_NOW, null);
        Emitter.emit(Events.EXIT_ROUTE, null);
    }, [setTimer]);

    const togglePoint = React.useCallback((e: any, point: string) => {
        e.preventDefault();
        e.stopPropagation();

        if (navigatePoint.current === point || navigatePoint.current === null || point === NavigatePoints.NONE) {
            // (navigatePoint.current === point) -> the user intends to close an open field by clicking on it
            // (navigatePoint.current === null) -> the user initially opened one of the inputs after reaching the navigate tab
            // (point === NavigatePoints.NONE) -> the navigation tab was reached by pressing the back button from searchNavigate tab
            setShowNavigateContent(!showNavigateContent);
            if (mobile) {
                Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
            }
        } else {
            setShowNavigateContent(true);
            if (mobile) {
                if (!showNavigateContent) {
                    Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
                }
            }
        }

        if (navigateMode === NavigateModes.GO_NOW && !showNavigateContent) {
            setNavigateMode(NavigateModes.SELECT_POINTS);
        }

        const mainCondition = navigateMode === NavigateModes.SELECT_POINTS
            || navigateMode === NavigateModes.SELECT_NAV_POI
            || navigateMode === NavigateModes.SELECT_RECENT_POI
            || navigateMode === NavigateModes.SELECT_FAVORITES_POI;

        if (mainCondition && startPoint
            && endPoint && navigatePoint.current === point
            && startPoint.poid !== endPoint.poid
        ) { // show route
            setNavigateMode(NavigateModes.GO_NOW);

            if (mobile) {
                Emitter.emit(Events.REMOVE_DROPDOWN, null);
            }
        }

        if (mainCondition && (!startPoint || !endPoint)) {
            setNavigateMode(NavigateModes.SELECT_POINTS);
        }

        navigatePoint.current = point;
        forceUpdate();
    }, [
        navigatePoint,
        setShowNavigateContent,
        showNavigateContent,
        forceUpdate,
        startPoint,
        endPoint,
        navigateMode
    ]);

    const backTo = React.useCallback((e?: any) => {
        Emitter.removeAllListeners(Events.ADD_DIRECTIONS);

        if (mobile) Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        switch(navigateMode) {
            case NavigateModes.GO_NOW:
            case NavigateModes.SELECT_POINTS:
                removeDirections();
                if(referrer === Referrers.POI_CARD) {
                    selectPoi(toPoi, project, changeFloor);
                }
                if(referrer === Referrers.MENU_NAVIGATE) {
                    Emitter.emit(Events.OPEN_TAB, {
                        tabName: TabNames.HOME,
                        tabMode: '',
                        tabData: null,
                        tabBackButton: false,
                        tabCloseButton: true,
                    });
                }
                break;
            case NavigateModes.SELECT_NAV_POI:
            case NavigateModes.SELECT_FAVORITES_POI:
            case NavigateModes.SELECT_RECENT_POI:
                setNavigateMode(NavigateModes.SELECT_POINTS);
                break;
        }
    }, [
        navigateMode,
        removeDirections,
        referrer,
        toPoi,
        project,
        changeFloor
    ]);

    const closeSidebar = React.useCallback(() => {
        Emitter.removeAllListeners(Events.ADD_DIRECTIONS);

        removeDirections();
        if (mobile) {
            Emitter.emit(Events.EXIT_NAVIGATE_MENU, null);
        }
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
    }, [removeDirections]);

    const swapOriginDestination = React.useCallback(() => {
        setStartPoint(endPoint);
        setEndPoint(startPoint);

        nav.current.swapOriginDestination();
    }, [
        startPoint,
        endPoint,
        setStartPoint,
        setEndPoint
    ]);

    const addDirections = React.useCallback(
        (poi?: IPoi,
                place?: any,
                selectFromMap?: boolean,
                handicapped?: boolean
        ) => {
        let success: boolean = false;

        if(handicapped !== undefined) {
            nav.current.handicapped = handicapped;
        }

        nav.current.referrer = referrer;
        if (selectFromMap && !cleanupFunction.current) {
            if (nav.current.state === NavStates.N && !place) {
                place = NavigatePoints.START_POINT;
            }

            switch(place) {
                case NavigatePoints.START_POINT:
                    setStartPoint(poi);
                    break;
                case NavigatePoints.END_POINT:
                    setEndPoint(poi);
                    break;
            }

            navigatePoint.current = place;
            setNavigateMode(NavigateModes.SELECT_POINTS);
            if (tabData?.navigatePoint !== null) {  // (tabData.navigatePoint == null) -> the user reached the navigation tab by clicking on the back button from 'searchNavigate' tab
                setShowNavigateContent(false);
            }
        }

        switch(place) {
            case NavigatePoints.START_POINT:
                nav.current.removeOrigin();
                if (nav.current.state === NavStates.OD || nav.current.state === NavStates.W) {
                    nav.current.removeOriginPath();
                }

                success = nav.current.setOrigin(poi, true);
                break;
            case NavigatePoints.END_POINT:
                nav.current.removeDestination();
                if (nav.current.state === NavStates.OD || nav.current.state === NavStates.W) {
                    nav.current.removeDestinationPath();
                }

                success = nav.current.setDestination(poi, true);
                break;
        }

        if (success) {
            nav.current.addListener(place);
            nav.current.resetHandicapped();
        }

        setTimer();
    }, [
        navigatePoint,
        setStartPoint,
        setEndPoint,
        setNavigateMode,
        setShowNavigateContent,
        setTimer,
        tabData?.navigatePoint,
        referrer
    ]);

    const setNavPoint = React.useCallback((poi: any, place: string) => {
        if (!cleanupFunction.current) {
            switch (place) {
                case NavigatePoints.START_POINT:
                    setStartPoint(poi);
                    break;
                case NavigatePoints.END_POINT:
                    setEndPoint(poi);
                    break;
            }

            addDirections(poi, place);
            setNavigateMode(NavigateModes.SELECT_POINTS);
            setShowNavigateContent(false);
        }

        setTimer();
    }, [
        setNavigateMode,
        setStartPoint,
        setEndPoint,
        addDirections,
        setTimer
    ]);

    const selectParkingPoi = React.useCallback(() => {
        addDirections(parkingPoi, navigatePoint.current, false);
        setNavPoint(parkingPoi, navigatePoint.current);
        if (mobile) {
            Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
        }
    }, [addDirections, setNavPoint, parkingPoi]);

    const removePoint = React.useCallback((point: string) => {
        if (!cleanupFunction.current) {
            setNavigateMode(NavigateModes.SELECT_POINTS);
        }

        switch(point) {
            case NavigatePoints.START_POINT:
                nav.current.removeOrigin();
                if (nav.current.state === NavStates.OD || nav.current.state === NavStates.W) {
                    nav.current.removeOriginPath();
                }
                if (nav.current.state === NavStates.O) {
                    nav.current.state = NavStates.N;
                }

                if (!cleanupFunction.current) {
                    setStartPoint(null);
                }
                break;
            case NavigatePoints.END_POINT:
                nav.current.removeDestination();
                if (nav.current.state === NavStates.OD || nav.current.state === NavStates.W) {
                    nav.current.removeDestinationPath();
                }
                if (nav.current.state === NavStates.D) {
                    nav.current.state = NavStates.N;
                }

                if (!cleanupFunction.current) {
                    setEndPoint(null);
                }
                break;
        }

        Emitter.emit(Events.UNSET_CURRENT_POI, null)
        Emitter.emit(Events.EXIT_ROUTE, null);
        nav.current.setNavigateState();
        setTimer();
    }, [
        setStartPoint,
        setEndPoint,
        setNavigateMode,
        setTimer
    ]);

    const ScanQRCode = React.useCallback(() => {
        Emitter.emit(Events.OPEN_QR_SCANNER, { isShow: true, from: ScanFrom.NAV });
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;

        Emitter.emit(Events.INIT_LABELS, null);
        if(mobile) {
            const sidebar: any = document.getElementById('sidebar');
            sidebar.classList.remove('info-pane');
        }

        resetFilterCategory();
        setNavigateMode(navMode);
        if (tabData?.navigatePoint) { // case where the user reached back 'navigate' tab from 'searchNavigate' via back button
            navigatePoint.current = tabData.navigatePoint
            setShowNavigateContent(true);
            tabData.navigatePoint = null
        }

        if(tabData?.navObject) {
            nav.current = tabData.navObject;

            if (fromPoi) {
                setEndPoint(nav.current.destination.poi);
                addDirections(fromPoi, NavigatePoints.START_POINT, true);
            }
            if (toPoi) {
                setStartPoint(nav.current.origin.poi);
                addDirections(toPoi, NavigatePoints.END_POINT, true);
            }
        }

        if(!nav.current) {
            nav.current = tabData?.nav ? tabData?.nav : new Nav();

            nav.current.setProject(project);
            nav.current.setMarkers(poidMap);
            nav.current.changeFloor = changeFloor;

            const infoWindow = (window as any).infoWindow || null;
            if (infoWindow) {
                infoWindow.close();
            }

            if (fromPoi) {
                addDirections(fromPoi, NavigatePoints.START_POINT, true);
            }
            if (toPoi) {
                addDirections(toPoi, NavigatePoints.END_POINT, true);
            }
        }

        if (!mounted.current) {
            Emitter.on(Events.SELECT_NAV_POI, (data: any) => {
                currentCatName.current = data.cname;
                if (!cleanupFunction.current) setNavigateMode(NavigateModes.SELECT_NAV_POI);
            });

            Emitter.on(Events.SET_NAV_POI, ({ poi }) => {
                setNavPoint(poi, navigatePoint.current);
            });

            Emitter.on(Events.REMOVE_NAV_POINT, (_point: string) => {
                removePoint(_point);
            });

            Emitter.on(Events.ADD_DIRECTIONS, ({ poi, place, handicapped }) => {
                if (!place) {
                    addDirections(poi, null, true);
                } else {
                    addDirections(poi, place, true, handicapped);
                }
            });

            Emitter.on(Events.REMOVE_DIRECTIONS, () => {
                removeDirections();
            });

            Emitter.on(Events.SET_NAV_POI_FROM_QR, (poid: any) => {
                if(poid && poidMap[poid]) {
                    setNavPoint(poidMap[poid], navigatePoint.current);
                    Emitter.emit(Events.REMOVE_DROPDOWN, null);
                }
            });

            Emitter.on(Events.NAV_FLOOR_CHANGE, ({ from, to }) => {
                nav.current.floorChange(from, to);
                nav.current.currentFloor = to;
            });

            Emitter.on(Events.TOGGLE_NAV_ELEMENTS, (isShow: boolean) => {
                if(!cleanupFunction.current) {
                    setNavigateMode(NavigateModes.GO_NOW);
                    setShowGoNow(isShow);
                }
            });

            mounted.current = true;
        }

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        cleanupFunction,
        setNavPoint,
        currentCatName,
        setNavigateMode,
        navMode,
        removeDirections,
        addDirections,
        changeFloor,
        project,
        poidMap,
        navigatePoint,
        removePoint,
        fromPoi,
        toPoi,
        tabData,
        mounted,
        setEndPoint,
        setStartPoint
    ]);

    const angleClassName = project.userLang === "he" ? 'fa fa-angle-left pull-left' : 'fa fa-angle-right pull-right';

    return (
        <div id="navigate-ui-window" className="spreo-ui-window ui-window">
            <div className="window-header">
                <div className="window-header-left">
                    <button className="back-btn pull-left"
                            type="button"
                            onClick={e => backTo(e)}
                    >
                        <i className="back-btn-ico" />
                    </button>
                </div>
                <button className="btn btn-default srch-close-btn close-navigate"
                        type="button"
                        onClick={closeSidebar}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="navigate-fields" ref={navigateFields}>
                <div className="nav-container">
                    <div className="inputs-container">
                        <div className="nav-row">
                            <div className="nav-from"
                                style={showNavigateContent && navigatePoint.current !== NavigatePoints.START_POINT ? { opacity: '0.25' } : {}}
                                onClick={e => togglePoint(e, NavigatePoints.START_POINT)}
                                role="button"
                                onKeyPress={e => e.preventDefault()}
                                tabIndex={0}
                                aria-label="choose nav point"
                            >
                                <i/>
                                <div className={startPoint || endPoint ? "input-from cut-input" : "input-from"}>
                                    <span className={project.userLang === 'he' ? "poi-name text-right" : "poi-name"}>{startPoint ? startPoint.description : (window as any).lang.getString('chooseStartP')}</span>
                                    {showNavigateContent && navigatePoint.current === NavigatePoints.START_POINT ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                                </div>
                            </div>
                            {startPoint && (
                                <div className="nav-remove">
                                    <i className="far fa-times-circle"
                                    onClick={() => removePoint("from")}
                                    role="button"
                                    onKeyPress={e => e.preventDefault()}
                                    tabIndex={0}
                                    aria-label="remove nav point"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="nav-row">
                        <div className="nav-to"
                             style={showNavigateContent && navigatePoint.current !== NavigatePoints.END_POINT ? { opacity: '0.25' } : {}}
                             onClick={(e) => togglePoint(e, NavigatePoints.END_POINT)}
                             role="button"
                             onKeyPress={(e) => e.preventDefault()}
                             tabIndex={0}
                             aria-label="choose nav point"
                        >
                            <i/>
                            <div className={startPoint || endPoint ? "input-to cut-input" : "input-to"}>
                                <span className={project.userLang === 'he' ? "poi-name text-right" : "poi-name"}>{endPoint ? endPoint.description : (window as any).lang.getString('chooseEndP')}</span>
                                {showNavigateContent && navigatePoint.current === 'to' ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                            </div>
                        </div>
                        {endPoint && (
                            <div className="nav-remove">
                                <i className="far fa-times-circle"
                                   onClick={() => removePoint("to")}
                                   role="button"
                                   onKeyPress={(e) => e.preventDefault()}
                                   tabIndex={0}
                                   aria-label="remove nav point"
                                />
                            </div>
                        )}
                    </div>
                    </div>
                </div>
                { startPoint || endPoint ?
                    (
                        <div className="swap-nav">
                            <i onClick={() => swapOriginDestination()}
                               role="button"
                               onKeyPress={(e) => e.preventDefault()}
                               tabIndex={0}
                               aria-label="swap origin and destination"/>
                        </div>
                    ) : null }
            </div>
            { navigateMode === NavigateModes.SELECT_POINTS ?
                (<>
                    {showNavigateContent ? (
                        <PerfectScrollbar>
                            <div className="navigate-content">
                                    <button type="button"
                                            className={project.userLang === 'he' ? "navigate-item nav-search nav-item-right" : "navigate-item nav-search"}
                                            onClick={() => openNavigateSearch()}
                                    >
                                        <div>
                                            <i/>
                                            <span>{(window as any).lang.getString('hotelTab_searchPlaceholder')}</span>
                                        </div>
                                    </button>
                                    {(mobile && navigatePoint.current === NavigatePoints.START_POINT) &&
                                        <button type="button"
                                                className={project.userLang === 'he' ? "navigate-item nav-qr-code nav-item-right" : "navigate-item nav-qr-code"}
                                                onClick={() => ScanQRCode()}
                                        >
                                            <div>
                                                <i/>
                                                <span>{(window as any).lang.getString('homeTab_scanQRCodeMenuItem')}</span>
                                            </div>
                                        </button>
                                    }
                                    {mobile && parkingPoi ? (<button type="button"
                                                className={project.userLang === 'he' ? "navigate-item nav-parking nav-item-right" : "navigate-item nav-parking"}
                                                onClick={() => selectParkingPoi()}
                                    >
                                        <div>
                                            <i/>
                                            <span>{(window as any).lang.getString('homeTab_parkingMenuItem')}</span>
                                        </div>
                                    </button>) : null }
                                    <button type="button"
                                            className={project.userLang === 'he' ? "navigate-item nav-favorites item-right" : "navigate-item nav-favorites"}
                                            onClick={() => setNavigateMode(NavigateModes.SELECT_FAVORITES_POI)}
                                    >
                                        <div>
                                            <i/>
                                            <span>{(window as any).lang.getString('favoritesTab_breadcrumb')}</span>
                                        </div>
                                        <div>
                                            <i className={angleClassName}
                                               aria-hidden="true"
                                            />
                                        </div>
                                    </button>
                                    <button type="button"
                                            className={project.userLang === 'he' ? "navigate-item nav-recent item-right" : "navigate-item nav-recent"}
                                            onClick={() => setNavigateMode(NavigateModes.SELECT_RECENT_POI)}
                                    >
                                        <div>
                                            <i/>
                                            <span>{(window as any).lang.getString('recentPlaces')}</span>
                                        </div>
                                        <div>
                                            <i className={angleClassName} aria-hidden="true" />
                                        </div>
                                    </button>
                                    <Categories project={project}
                                                categories={categories}
                                                poisCategories={poisCategories}
                                                pois={pois}
                                                floor={floor}
                                                changeFloor={changeFloor}
                                                tabMode={TabModes.OPEN_CATEGORY_LIST}
                                                fromNavigate={true}
                                    />
                            </div>
                        </PerfectScrollbar>) : null }
                    </>
                ) : null }

            { navigateMode === NavigateModes.SELECT_NAV_POI ? (
                <PerfectScrollbar className="category-items">
                    <CategoryItems project={project}
                                   pois={pois}
                                   floor={floor}
                                   changeFloor={changeFloor}
                                   cname={currentCatName.current}
                                   fromNavigate={true}
                                   navigatePoint={navigatePoint.current}
                                   fromPoi={fromPoi}
                                   toPoi={toPoi}
                                   facilityNames={facilityNames}
                    />
                </PerfectScrollbar>
            ): null }

            { navigateMode === NavigateModes.SELECT_FAVORITES_POI ? (
                <PerfectScrollbar>
                    <FavoriteItems project={project}
                                   pois={pois}
                                   changeFloor={changeFloor}
                                   fromNavigate={true}
                    />
                </PerfectScrollbar>
            ) : null }

            { navigateMode === NavigateModes.SELECT_RECENT_POI ? (
                <PerfectScrollbar>
                    <RecentItems project={project}
                                 pois={pois}
                                 changeFloor={changeFloor}
                                 fromNavigate={true}
                    />
                </PerfectScrollbar>
            ) : null }

            { navigateMode === NavigateModes.GO_NOW ? (
                <>
                    <div className="instruction-cont" ref={instructionCont}>
                        {showGoNow && <>
                            <NavigateBottomBar tabData={tabData}
                                               project={project}
                                               goNow={true}
                                               isPath={true}
                            />
                            <button className="go-now-btn"
                                type="button"
                                onClick={() => openRouteList()}
                            >
                                <div>
                                    <i className="instruction-navigate-ico"/>
                                    <span>
                                        {mobile ? (window as any).lang.getString('goNow') : (window as any).lang.getString('btnShowInstructions')}
                                    </span>
                                </div>
                                <div>
                                    <p className="number-time">{ tabData?.time }</p>
                                    <p className="estimated-time">{ (window as any).lang.getString('nav_instructionDist', tabData?.distTextVal) }</p>
                                </div>
                            </button>
                        </>}
                        {!showGoNow && (
                            <>
                                <NavigateBottomBar tabData={tabData}
                                                   project={project}
                                                   goNow={true}
                                                   isPath={false}
                                />
                                <div className="no-nav-path">
                                    {(window as any).lang.getString('nav_errorNoPath')}
                                </div>
                            </>
                            )
                        }
                    </div>
                    <ReportAnIssue project={project} />
                </>
            ) : null}
        </div>
    )
}

export default NavigateUI;
