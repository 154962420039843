import StateStorage from './stateStorage';

/**
 *
 * @param {String} pid
 */
export const getStaff = (pid: string) => StateStorage.getItem(`${pid}.staff.token`, "");

/**
 * @param {String} pid
 * @param {String} token
 */
export const saveStaff = (pid: string, token: string) => StateStorage.setItem(`${pid}.staff.token`, token);

/**
 * @param {String} pid
 *
 */
export const deleteStaff = (pid: string) => StateStorage.removeItem(`${pid}.staff.token`);
