import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { Events, TabModes, TabNames, mobile } from "@constants";
import { IPoi, IProject } from "../../interfaces";
import { checkMarkerStaffOnlyToShow, getFacilityAndFloor, removeNullElements } from "../../helpers/utils";
import { selectPoi } from "../../helpers/marker";
import { getFavorites, removeFavorite } from "../../helpers/favorites";
import { useForceUpdate } from "../../hooks";
import Emitter from "../../helpers/emitter";
import { BackStates } from "../../constants";

export interface IFavoriteItems {
    project: IProject;
    pois: IPoi [];
    changeFloor: (floor: number) => void;
    fromWelcomeSearch?: boolean;
    fromNavigate?: boolean;
}

const FavoriteItems: FC<IFavoriteItems> = (props: PropsWithChildren<IFavoriteItems>) => {
    const {
        project,
        changeFloor,
        pois,
        fromWelcomeSearch,
        fromNavigate
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const total = React.useRef(0);

    const removeItem = React.useCallback((e: any, poid: string) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.parentNode.classList.add('button-leave-active');

        setTimeout(() => {
            removeFavorite(project.pid, poid);
            forceUpdate();
        }, 500);
    }, [forceUpdate, project]);

    const selectPoint = React.useCallback((e: any, poi: IPoi) => {
        e.preventDefault();
        e.stopPropagation();

        if (!fromNavigate) {
            selectPoi(poi, project, changeFloor, false, '', {
                state: BackStates.FAVORITE_LIST
            });
        } else {
            Emitter.emit(Events.SET_NAV_POI, { poi });
            if (mobile) {
                Emitter.emit(Events.TOGGLE_INPUT_FIELD, null);
            }
        }
    }, [fromNavigate, project, changeFloor]);

    const noFavoriteItems = React.useCallback(() => (
        <div className="paragraph-container">
            <i className="poi-descr-ico favorites-ico" aria-label="favorite" />
            <p className="favorites-paragraph">
                {(window as any).lang.getString('favoritesTab_welcomeMsg')}
            </p>
        </div>
    ), []);

    const renderFavoriteList = React.useCallback(() => {
        const className: string = 'spreo-poi-btn btn-default btn-block button-leave';
        const favorites = project ? getFavorites(project.pid) : [];

        if (favorites?.length) {
            const slicedFavorites = fromWelcomeSearch ? favorites.slice(0, 3) : favorites;

            let favoriteItems = slicedFavorites.map((poid: string) => {
                const poi: any = pois.find((item: IPoi) => item.poid === poid);
                if (!poi || !checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid)) {
                    return null;
                }

                const markerInfo = getFacilityAndFloor(project, poi.facility, poi.floor);
                return (
                    <button type="button"
                            className={ project.userLang === "he" ? `${ className } text-right info-right-button` : `${ className } text-left` }
                            onClick={ (e) => selectPoint(e, poi) }
                            key={ poid }
                    >
                        <i className="favorites-ico"
                           onClick={ (e) => removeItem(e, poid) }
                           role="button"
                           onKeyPress={ (e) => e.preventDefault() }
                           tabIndex={ 0 }
                           aria-label="Remove Favorite"
                        />
                        <div className={ project.userLang === 'he' ? "info-right" : "" }>
                            <span className="poi-description">{ poi.description }</span>
                            <span className="poi-info">
                                    { (window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname]) }
                                </span>
                        </div>
                    </button>
                );
            });

            favoriteItems = removeNullElements(favoriteItems);
            total.current = favorites.length;
            if(!favoriteItems || !favoriteItems.length) {
                return noFavoriteItems();
            }

            return favoriteItems;
        }

        return noFavoriteItems();
    }, [
        pois,
        project,
        removeItem,
        total,
        fromWelcomeSearch,
        selectPoint,
        noFavoriteItems
    ]);

    const openFavoriteList = React.useCallback((e): boolean => {
        e.preventDefault();
        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FAVORITES,
                tabMode: TabModes.OPEN_FAVORITE_LIST,
                tabData: {
                    project,
                    changeFloor,
                    pois,
                    fromSearch: true
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }

        return true;
    }, [
        project,
        changeFloor,
        pois
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;

        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    return (
        <>
            { renderFavoriteList() }
            { fromWelcomeSearch && total.current > 3 ? (
                <a href="/#"
                   className="show-all-favorites"
                   onClick={(e) => openFavoriteList(e)}
                >
                    <span>
                        { (window as any).lang.getString('welcomeSearchTab_showAll') }
                    </span>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                </a>
            ): null }
        </>
    );
};

export default FavoriteItems;
