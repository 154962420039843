import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from '@sagas';
import createRootReducer from '@reducers';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
  forceRefresh: true,
});

const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore() {
  const store = createStore(createRootReducer(history), {}, composeEnhancer(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
}
