import { IPoi, IProject, ISearchState } from "../interfaces";

class Search implements ISearchState {

    public name: string;

    public searchElement: string;

    public window: any;

    public input: string;

    public action: string;

    public index: number;

    public focused: string;

    public storage: any;

    public count: number;

    public project: IProject;

    public hidden: boolean;

    constructor(_searchElement: string, _windowElement: string, _name: string, _project: IProject) {
        this.name = _name;
        this.searchElement = _searchElement;
        this.window = _windowElement;
        this.input = '';
        this.action = '';
        this.index = 0;
        this.focused = '';
        this.storage = {};
        this.count = 0;
        this.project = _project;
        this.hidden = false;

    }

    /**
     * Push poi data into the storage
     * @param value
     */
    push(value: IPoi []): void {
        this.storage[this.count] = value;
        this.count += 1;
    }

    /**
     * Pop poi data from the storage
     */
    pop(): any {
        // Check to see if the stack is empty
        if (this.count === 0) {
            return undefined;
        }

        this.count -= 1;
        const result = this.storage[this.count];
        delete this.storage[this.count];

        return result;
    }

    /**
     * Clear storage and counter
     */
    empty(): void {
        this.count = 0;
        this.storage = {};
    }

    // /////////////////////////////////////////////////
    /*
     * Search functions
     *
     * Including soundex algorithm, soundex search
     *
     * Algorithm for matching poi names
     *
     */
    // /////////////////////////////////////////////////
    /**
     * Soundex algorithm for better search matching
     * @param {string} s
     * @return {string}
     */
    static soundex(s: string): string {
        const a: string [] = s.toLowerCase().split("");
        const f: any = a.shift();
        const codes: any = {
            a: "",
            e: "",
            i: "",
            o: "",
            u: "",
            b: 1,
            f: 1,
            p: 1,
            v: 1,
            c: 2,
            g: 2,
            j: 2,
            k: 2,
            q: 2,
            s: 2,
            x: 2,
            z: 2,
            d: 3,
            t: 3,
            l: 4,
            m: 5,
            n: 5,
            r: 6,
        };

        const r = f + a
            .map((v: string) => codes[v])
            .filter((v, i: number, b: any []) => i === 0 ? v !== codes[f] : v !== b[i - 1])
            .join("");

        return (`${r}000`).slice(0, 4).toUpperCase();
    }

    /**
     * Apply Soundex searching
     * @param pois
     * @return {any []}
     */
    soundexSearch(pois: IPoi []): any [] {
        const results: any [] = [];
        const keyResults: any [] = [];

        for (let i: number = 0; i < pois.length; i++) {
            const words: string [] = pois[i].description.split(" ");

            for (let j: number = 0; j < words.length; j++) {
                if (words[j].length > 1 && Search.soundex(this.input) === Search.soundex(words[j])) {
                    results.push(pois[i]);
                }
            }

            if (pois[i].keywords.length) {
                const keywords: string [] = pois[i].keywords.split(",");
                for (let j: number = 0; j < keywords.length; j++) {
                    if (Search.soundex(this.input) === Search.soundex(keywords[j])) {
                        keyResults.push(pois[i]);
                    }
                }
            }
        }

        if (results.length) {
            return results;
        }

        return keyResults;
    }

    /**
     *
     * @param possibles
     * @param input
     */
    static wordSearch(possibles: IPoi [], input: string): IPoi [] {
        const pos: number = input.length - 1;
        const temp: IPoi [] = [];

        for (let i: number = 0; i < possibles.length; i++) {
            const words: string [] = possibles[i].description.split(" ");
            for (let j: number = 0; j < words.length; j++) {
                if (words[j].substring(0, pos + 1).toLowerCase() === input && words[j].length > 1) {
                    temp.push(possibles[i]);
                }
            }
        }

        return temp;
    }

    /**
     *
     * @param possibles
     * @param input
     * @param temp
     */
    static indexSearch(possibles: IPoi [], input: string, temp: any): any [] {
        const results: any [] = temp || [];

        for (let i: number = 0; i < possibles.length; i++) {
            if (possibles[i].description.toLowerCase().indexOf(input) !== -1) {
                results.push(possibles[i]);
            }
        }

        return results;
    }

    /**
     *
     * @param possibles
     * @param input
     * @param temp
     */
    static containsSearch(possibles: any [], input: string, temp: IPoi []) {
        const results = temp || [];

        for (let i = 0; i < possibles.length; i++) {
            if (possibles[i].description.toLocaleLowerCase().includes(input)) {
                results.push(possibles[i]);
            }

            let { keywords } = possibles[i];
            if (keywords) {
                keywords = keywords.replace('[', '').replace(']', '').trim();

                if (keywords.length) {
                    const matchedKeywords: string = keywords.split(',').filter((k: string) => k.toLocaleLowerCase().trim().includes(input.toLocaleLowerCase())).join(', ');

                    if (matchedKeywords) {
                        possibles[i].matchedKeywords = matchedKeywords;
                    }
                }
            }
        }

        return results;
    }

    /**
     *
     * @param possibles
     * @param input
     * @param temp
     */
    static indexProfileSearch(possibles: any [], input: string, temp: any []): any [] {
        const results: any [] = temp || [];

        for (let i: number = 0; i < possibles.length; i++) {
            const name = `${possibles[i].first_name} ${possibles[i].last_name}`;
            if (name.toLowerCase().indexOf(input) !== -1) {
                results.push(possibles[i]);
            }
        }

        return results;
    }

    /**
     * Function for home search bar
     * @param search
     * @param input
     */
    fakeSearch(search: ISearchState, input: string): void {
        search.input = input;
        search.index = input.length - 1;
        search.empty();

        if (this.count) {
            search.push(this.storage[this.count - 1]);
        }
    }
}

export default Search;
