import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Project from '@components/Project';
import { mapActions, poiActions, categoryActions, polygonActions, occupancyActions, loaderActions } from '@actions';

const mapStateToProps = (state: any) => ({
  mapStore: state.mapStore,
  center: state.mapStore.center,
  zoom: state.mapStore.zoom,
  floor: state.mapStore.floor,
  pid: state.mapStore.pid,
  project: state.project,
  galleries: state.mapStore.galleries,
  buildings: state.mapStore.buildings,
  pois: state.poiStore.pois,
  poids: state.poiStore.poids,
  poidMap: state.poiStore.poidMap,
  poisCategories: state.poiStore.poisCategories,
  categories: state.categoryStore,
  polygons: state.polygonStore,
  occupancies: state.occupancyStore,
  projects: state.projectLists.projects,
});

const mapDispatch = {
  requestMap: (projectId: string) => mapActions.requestMap({ projectId }),
  requestPoi: (projectId: string) => poiActions.requestPoi({ projectId }),
  requestCategory: (projectId: string) => categoryActions.requestCategory({ projectId }),
  requestPolygon: (projectId: string) => polygonActions.requestPolygon({ projectId }),
  requestOccupancy: (projectId: string) => occupancyActions.requestOccupancy({ projectId }),
  changeFloor: (floorNumber: string | number) => mapActions.changeFloor({ floor: floorNumber }),
  changeLoading: (loading: boolean ) => loaderActions.changeLoading({ loading } )
};
export default connect(mapStateToProps, mapDispatch)(withRouter(Project));
