import React, { FC, useMemo } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Ipropduct } from '@reducers/projectLists';
import { ICampus } from '@interfaces';
import { history } from "../../configStore";
import './projectList.scss';
import StateStorage from "../../helpers/stateStorage";
import { isGuest, removeExpiry, startExpiryTimeout } from "../../helpers/utils";
import Lang from "../../helpers/lang";

export interface IProductList {
  projects: Ipropduct[];
  changeLoading: (loading: boolean) => void;
}

const ProjectList: FC<IProductList> = ({ projects, changeLoading }) => {
  const [asDefault, setAsDefault] = React.useState(false);
  const [langIsLoaded, setLangIsLoaded] = React.useState(false);
  const logout = React.useCallback((e) => {
    e.preventDefault();

    sessionStorage.clear();
    history.push('/');
  }, []);

  let renderProjects = (
    <ul className="projects text-center">
      <li key={0} className="text-center">
        <a href="/#" className="disabled" onClick={(e) => logout(e)}>
          Sorry, there are no projects marked as production for the current user (click to logout).
        </a>
      </li>
    </ul>
  );

  if(!isGuest()) startExpiryTimeout(removeExpiry);

  if (!projects || !projects.length) {
    const proj = localStorage.getItem("proj") || null;
    projects = proj ? JSON.parse(proj) : null;
  }

  const getProductionProjects = (data: ICampus[]) => {
    if (data && data.length) {
      let prodProjects = [...data];

      if (StateStorage.getItem(`projects.test`) === 'false') {
        prodProjects = prodProjects.filter(({ production }) => production);

        // Only the projects that have IsWebAppEnabled flag set to true
        prodProjects = prodProjects.filter(pr => pr.webAppEnabled || pr.webAppEnabled === undefined);
      }

      return prodProjects;
    }

    return data;
  }

  const prodProjects = useMemo(
    () => getProductionProjects(projects),
    [projects]
  );

  if (prodProjects && prodProjects.length > 0) {
    renderProjects = (
      <ul className="projects text-center">
        {prodProjects.map((item) => (
          <li key={item.key}>
            <a href={`project-list/${item.id}`} id={item.id} onClick={() => {
              if (asDefault) {
                StateStorage.setItem(`${localStorage.getItem('username')}.default.project`, item.id);
              }
            }}>{item.name}</a>
          </li>
        ))}
      </ul>
    );
  }

  React.useEffect(() => {
    changeLoading(false);
  });

  // Check that default project is selected or not.
  const savedProject = StateStorage.getItem(`${localStorage.getItem('username')}.default.project`, '');
  if (savedProject) {
    history.replace(`/project-list/${savedProject}`);
  }

  if(!(window as any).lang) {
    const lang: string = localStorage.getItem('lang') || 'en';
    Object.defineProperty(window, 'lang', {
      writable: true,
      configurable: false,
      enumerable: true,
      value: new Lang(lang, () => {
        setLangIsLoaded(true);
      })
    });
  } else if((window as any).lang && !langIsLoaded) {
    setLangIsLoaded(true);
  }

  return (
      !savedProject && langIsLoaded ? (<div className="login-container">
        <h4 className="text-center">{(window as any).lang.getString('projectList_selectCampus')}</h4>
          <div className="projects-list">
            <PerfectScrollbar>
              { renderProjects }
            </PerfectScrollbar>
            {prodProjects.length > 0 && <label className="black-checkbox" htmlFor="set_default">
              <input type="checkbox" id="set_default" onChange={(e) => setAsDefault(e.target.checked)}/>
              <span />
              <div className="checkbox-title">{(window as any).lang.getString('projectList_setSelectionAsDefault')}</div>
            </label>}
          </div>
    </div>) : null
  );
};

export default ProjectList;
