import { BaseAction, Payload } from '@constants';
import { history } from '../configStore';

export enum LoginActions {
  REQUESTLOGIN = 'REQUESTLOGIN',
  REQUESTLOGINSUCCESS = 'REQUESTLOGINSUCCESS',
  REQUESTLOGINERROR = 'REQUESTLOGINERROR'
}

/**
 * User login into the system
 * @param payload
 * @param prevLink
 */
export const requestLogin = (payload: Payload, prevLink?: any): BaseAction => ({
  type: LoginActions.REQUESTLOGIN,
  payload,
  prevLink
});

/**
 * User was successfully logged in
 * @param payload
 * @param prevLink
 */
export const loginSuccess = (payload: Payload, prevLink?: any): BaseAction => {
  const proj = localStorage.getItem("proj") || null;
  const projects = proj ? JSON.parse(proj) : null;

  if (prevLink && projects.some((project: any) => project.id === prevLink.project)) {
    history.push(prevLink.url);
  } else {
    history.push('/project-list');
  }

  return ({
    type: LoginActions.REQUESTLOGINSUCCESS,
    payload,
  })
};

/**
 * Some error was occurred
 * @param payload
 */
export const loginError = (payload: Payload): BaseAction => ({
  type: LoginActions.REQUESTLOGINERROR,
  payload,
});
