import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, mobile, TabModes, TabNames } from "@constants";
import { IPoi, IProject } from '@interfaces';
import Emitter from "../../helpers/emitter";
import FavoriteItems from "../FavoriteItems";

export interface IFavorites {
    project: IProject;
    changeFloor: (floor: number) => void;
    pois: IPoi [];
    fromSearch?: boolean;
}

const Favorites: FC<IFavorites> = (props: PropsWithChildren<IFavorites>) => {
    const {
        project,
        changeFloor,
        pois,
        fromSearch
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [backButton, setBackButton] = React.useState(fromSearch || false);

    const closeSidebar = React.useCallback(() => {
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }
    }, []);

    const backTo = React.useCallback(() => {
        setBackButton(fromSearch || false);

        if (!cleanupFunction.current) {
            if (!fromSearch) {
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.FAVORITES,
                    tabMode: TabModes.OPEN_FAVORITE_LIST,
                    tabData: {
                        project,
                        changeFloor,
                        pois,
                        fromSearch
                    },
                    tabBackButton: false,
                    tabCloseButton: false,
                });
            } else {
                Emitter.emit(Events.SET_SEARCH_MODE, null);
            }
        }
    }, [
        cleanupFunction,
        project,
        changeFloor,
        pois,
        fromSearch
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        if (mobile) {
            Emitter.on(Events.ADD_BACK_BUTTON, () => {
                if (!cleanupFunction.current) setBackButton(true);
            });
        }

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        cleanupFunction,
        setBackButton,
        fromSearch
    ]);


    return (
        <div id="favorite-ui-window" className="spreo-ui-window ui-window">
            <div className="window-header">
                {(mobile && backButton) ?
                    <div className="window-header-left">
                        <button className="back-btn pull-left"
                                type="button"
                                onClick={backTo}
                        >
                            <i className="back-btn-ico" />
                        </button>
                        <h4 className="window-poi-title">{ (window as any).lang.getString('homeTab_tabTitleFavorites') }</h4>
                    </div> :
                    <h4 id="header">{(window as any).lang.getString('homeTab_tabTitleFavorites')}</h4>
                }
                <button className="btn btn-default srch-close-btn "
                        type="button"
                        onClick={closeSidebar}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <PerfectScrollbar>
                <FavoriteItems project={project}
                               pois={pois}
                               changeFloor={changeFloor}
                />
            </PerfectScrollbar>
        </div>
    );
}

export default Favorites;
