import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Events, mobile, TabModes, TabNames } from '@constants';
import loader from '@assets/images/others/loader-1.gif';
import Emitter from '../../helpers/emitter';

import './sidebar.scss';
import Home from "./Tabs/home";
import Find from "./Tabs/find";
import Building from "./Tabs/building";
import Category from "./Tabs/category";
import Favorite from "./Tabs/favorite";
import Filter from "./Tabs/filter";
import Navigate from "./Tabs/navigate";
import Info from "./Tabs/info";
import Parking from "./Tabs/parking";
import { IBuilding, ICampus, IGallery, IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import SearchBox from "../SearchBox";
import HelperCenter from "../HelpCenter";
// import QRScanner from "../QRScanner";
import { getShowInMap, toggleShowInMap } from "../../helpers/utils";
import FilteredBlock from "../FilteredBlock";
import { useForceUpdate } from "../../hooks";
import LangPicker from "../LangPicker/LangPicker";

interface ISidebar {
    pois: IPoi [];
    project: IProject;
    occupancy:  any;
    floor: number;
    changeFloor: (floor: number) => void;
    galleries: IGallery [][];
    buildings: IBuilding [];
    projects: ICampus [];
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    poidMap: IPoi [];
    changeLoading: (loading: boolean) => void;
    facilityNames: any;
}

interface ITabData {
    tabName: any;
    tabMode: string;
    tabData: any;
    tabCloseButton: boolean;
    tabBackButton: boolean;
    tabFilterButton?: boolean;
}

const Sidebar: FC<ISidebar> = (props: PropsWithChildren<ISidebar>) => {
    const {
        pois,
        project,
        occupancy,
        floor,
        changeFloor,
        galleries,
        buildings,
        projects,
        categories,
        poisCategories,
        poidMap,
        changeLoading,
        facilityNames
    } = props;

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate = useForceUpdate(cleanupFunction);
    const sidebar: MutableRefObject<any> = React.useRef(null);
    const homeTab: MutableRefObject<any> = React.useRef(null);
    const findTab: MutableRefObject<any> = React.useRef(null);
    const favoritesTab: MutableRefObject<any> = React.useRef(null);
    const parkingTab: MutableRefObject<any> = React.useRef(null);
    const buildingsTab: MutableRefObject<any> = React.useRef(null);
    const categoriesTab: MutableRefObject<any> = React.useRef(null);
    const filtersTab: MutableRefObject<any> = React.useRef(null);
    const navigateTab: MutableRefObject<any> = React.useRef(null);
    const helpTab: MutableRefObject<any> = React.useRef(null);
    const infoTab: MutableRefObject<any> = React.useRef(null);

    const [currentTab, setCurrentTab] = React.useState(homeTab);
    const [tabData, setTabData] = React.useState(null);
    const [tabMode, setTabMode] = React.useState('');

    const [closeButton, setCloseButton] = React.useState(false);
    const [backButton, setBackButton] = React.useState(false);
    const [filterButton, setFilterButton] = React.useState(false);
    const [thirdScreen, setThirdScreen] = React.useState(false);
    const [goNowMode, setGoNowMode] = React.useState(false);
    const [destinationText, setDestinationText] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const filterDataState = React.useState(null);
    const filterData: any = filterDataState[0];
    const setFilterData: any = filterDataState[1];

    const addTabContent = React.useCallback((data: any, mode: string) => {
        if (!cleanupFunction.current) {
            setTabData(data);
            setTabMode(mode);
        }
    }, [
        setTabData,
        setTabMode
    ]);

    const addThirdScreen = React.useCallback((data: any) => {
        sidebar.current.classList.add('third-screen');

        if (data?.tabName === TabNames.INFO) {
            sidebar.current.classList.add('top66');
        }

        if (data?.tabName === TabNames.PARKING) {
            sidebar.current.classList.add('parking');
        }

        setThirdScreen(true);
    }, []);

    const exitNavigation = React.useCallback((data: any, action: string) => {
        Emitter.emit(Events.UNSET_CURRENT_POI, null)
        if (data?.exitNavigation) {
            // Emitter.emit(Events.UNSET_CURRENT_POI, null)
            setGoNowMode(false);
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null); // remove 'third-screen' class from 'spreo-map' div
            switch (action) {
                case 'closeSidebar':
                    sidebar.current.classList.remove('third-screen', 'route');
                    break;
                case 'backTo':
                    sidebar.current.classList.remove('third-screen');
                    sidebar.current.classList.add('navigation');
                    break;
                default:
                    break;
            }
        }
    }, [])

    const openTab = React.useCallback((data: ITabData) => {
        if (!cleanupFunction.current) {
            // add css class for third-screen mode
            if (mobile && ([
                TabNames.FAVORITES,
                TabNames.INFO,
                TabNames.PARKING
            ].includes(data.tabName) ||
                (data.tabName === TabNames.CATEGORIES && data.tabMode === TabModes.OPEN_CATEGORY_POI_LIST ) ||
                (data.tabName === TabNames.BUILDINGS  && data.tabMode === TabModes.OPEN_BUILDING_POI_LIST)
            )) {
                addThirdScreen(data);
            } else if (
                data.tabName === TabNames.FIND && data.tabMode === TabModes.POI_DETAILS ||
                data.tabName === TabNames.FIND && data.tabMode === TabModes.OPEN_QR_MARKER_CARD ||
                data.tabName !== TabNames.FIND
            ) {
                Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
                Emitter.emit(Events.COLLAPSE_CATEGORY_BAR, null);
            }

            if (data.tabName === TabNames.FIND && data.tabMode === TabModes.OPEN_SEARCH) {
                Emitter.emit(Events.COLLAPSE_CATEGORY_BAR, null);
                Emitter.emit(Events.SHOW_CATEGORY_BAR, null);
            }
            if (data.tabName === TabNames.NAVIGATE &&
                data.tabMode !== TabModes.OPEN_NAVIGATE_SEARCH &&
                data.tabMode !== TabModes.OPEN_ROUTE && mobile) {
                sidebar.current.classList.add('navigation');
            }

            if (mobile && data.tabName === TabNames.FIND && data.tabMode === TabModes.SEARCHED) {
                const showInMap: boolean = getShowInMap();
                if (showInMap) {
                    addThirdScreen(data);
                } else {
                    Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
                }
            }

            if(mobile && data.tabName === TabNames.INFO) {
                sidebar.current.classList.add('info-pane');
            }

            // Reset info window
            const infoWindow = (window as any).infoWindow || null;
            if (infoWindow && infoWindow.anchor) {
                infoWindow.close();
            }

            currentTab?.current.classList.remove('active');
            currentTab?.current.classList.remove('poi-card');
            let tab: any = null;

            switch (data.tabName) {
                case TabNames.HOME:
                    tab = homeTab;
                    break;
                case TabNames.FIND:
                    tab = findTab;
                    break;
                case TabNames.FAVORITES:
                    tab = favoritesTab;
                    break;
                case TabNames.PARKING:
                    tab = parkingTab;
                    break;
                case TabNames.BUILDINGS:
                    tab = buildingsTab;
                    break;
                case TabNames.CATEGORIES:
                    tab = categoriesTab;
                    break;
                case TabNames.FILTERS:
                    tab = filtersTab;
                    break;
                case TabNames.NAVIGATE:
                    tab = navigateTab;
                    break;
                case TabNames.HELP:
                    tab = helpTab;
                    break;
                case TabNames.INFO:
                    tab = infoTab;
                    break;
            }

            tab?.current.classList.add('active');
            if (data.tabMode === TabModes.POI_DETAILS || data.tabMode === TabModes.OPEN_QR_MARKER_CARD) {
                tab?.current.classList.add('poi-card');
            }

            setCurrentTab(tab);
            addTabContent(data.tabData, data.tabMode);

            setCloseButton(data.tabCloseButton);
            setBackButton(data.tabBackButton);
            if (data.tabFilterButton !== undefined) setFilterButton(data.tabFilterButton);

            sidebar.current.classList.remove('collapsed');
        }

        if (data.tabData?.exitNavigation) {
            exitNavigation(data.tabData, 'backTo');
        }
    }, [
        currentTab,
        setCurrentTab,
        addTabContent,
        sidebar,
        cleanupFunction,
        addThirdScreen,
        exitNavigation
    ]);

    const closeTab = React.useCallback(() => {
        if (!cleanupFunction.current) {
            Emitter.emit(Events.SHOW_CATEGORY_BAR, null);
            Emitter.emit(Events.SCROLL_TO_FIRST, null);

            currentTab?.current.classList.remove('active');
            currentTab?.current.classList.remove('poi-card');
            homeTab.current.classList.add('active');
            setCurrentTab(homeTab);

            setCloseButton(false);
            setBackButton(false);

            if(mobile) {
                sidebar.current.classList.remove('landscape');
                sidebar.current.classList.remove('info-pane');
            }

            sidebar.current.classList.add('collapsed');
        }
    }, [
        currentTab,
        cleanupFunction,
        setCurrentTab
    ]);

    const ToggleMenu = React.useCallback((event) => {

        event.preventDefault();
        if (mobile) {
            sidebar.current.classList.remove('navigation', 'dropdown', 'route')
            Emitter.emit(Events.REMOVE_DIRECTIONS, null);
        }
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.HOME,
            tabMode: '',
            tabData: null,
            tabBackButton: false,
            tabCloseButton: true,
        });

    }, []);

    const stopThirdScreen = () => {
        sidebar.current.classList.remove('third-screen');
        sidebar.current.classList.remove('top66');
        sidebar.current.classList.remove('parking');
        setThirdScreen(false);
    }

    const toggleInput = () => {
        sidebar.current.classList.toggle('dropdown');  // toggle css class for dropdown menu
    }

    const removeDropdown = () => {
        sidebar.current.classList.remove('dropdown');  // remove css class for dropdown menu
    }

    const startGoNowMode = React.useCallback((destinationTitle: string) => {
        sidebar.current.classList.remove('navigation')
        sidebar.current.classList.add('third-screen');
        setGoNowMode(true);
        setDestinationText(destinationTitle)
    }, []);

    const goNowBack = React.useCallback(() => {
        Emitter.emit(Events.OPEN_EXIT_NAVIGATION, { isShow: true, Action: 'backTo' });
    }, []);

    const closeSidebar = React.useCallback(() => {
        Emitter.emit(Events.OPEN_EXIT_NAVIGATION, { isShow: true, Action: 'closeSidebar' });
    }, []);


    // handle dragging up on third-screen mode
    // (dragging upwards for 35px or more will turn the menu to full-screen mode)
    const touchStartYpos: MutableRefObject<any> = React.useRef(0);

    const touchStart = React.useCallback((e: any) => {
        touchStartYpos.current = e.changedTouches[0].clientY
    }, [touchStartYpos]);

    const touchMove = React.useCallback((e: any) => {
        if (touchStartYpos.current - e.changedTouches[0].clientY >= 35) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
            Emitter.emit(Events.SHOW_ALL_INSTRUCTIONS, null);

            if (currentTab.current === findTab.current && getShowInMap()) {
                toggleShowInMap(false);
                Emitter.emit(Events.HANDLE_SEARCH, null);
                Emitter.emit(Events.SHOW_CATEGORY_BAR, null);
            }

            if (currentTab.current === favoritesTab.current ||
                currentTab.current === categoriesTab.current
            ) {
                Emitter.emit(Events.ADD_BACK_BUTTON, null);
            }

            sidebar.current.classList.add('landscape');
        }

        if(touchStartYpos.current - e.changedTouches[0].clientY < 0) {
            if(currentTab.current === navigateTab.current) {
                sidebar.current.classList.add('third-screen');
                Emitter.emit(Events.HIDE_ALL_INSTRUCTIONS, null);
            } else {
                Emitter.emit(Events.SHOW_CATEGORY_BAR, null);
                Emitter.emit(Events.ENTER_THIRD_SCREEN, null);
            }
        }
    }, [
        touchStartYpos,
        currentTab
    ]);

    React.useEffect(() => { // useEffect for running each component render
        Emitter.on(Events.SIDEBAR_LOADING, (value: boolean) => setLoading(value));
        Emitter.on(Events.TOGGLE_INPUT_FIELD, () => toggleInput());
        Emitter.on(Events.DISPLAY_ROUTE, () => sidebar.current.classList.add('route'));
        Emitter.on(Events.REMOVE_DROPDOWN, () => removeDropdown());
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;

        Emitter.on(Events.OPEN_TAB, newValue => openTab(newValue));
        Emitter.on(Events.CLOSE_TAB, () => closeTab());

        if (mobile) {
            Emitter.on(Events.EXIT_NAVIGATE_MENU, () => sidebar.current.classList.remove('navigation', 'dropdown', 'route'));
            Emitter.on(Events.EXIT_GO_NOW, () => setGoNowMode(false));
            Emitter.on(Events.EXIT_ROUTE, () => sidebar.current.classList.remove('route'));
            Emitter.on(Events.GO_NOW, (routeData) => startGoNowMode(routeData.toPoi?.description));
            Emitter.on(Events.ENTER_THIRD_SCREEN, (data: ITabData) => addThirdScreen(data));
            Emitter.on(Events.EXIT_THIRD_SCREEN, () => stopThirdScreen());
            Emitter.on(Events.CLOSE_SIDEBAR, data => exitNavigation(data, 'closeSidebar')); // listener for exit navigation
            Emitter.on(Events.ONLY_TWO_INSTRUCTIONS, () => sidebar.current.classList.add('only-two'));
            Emitter.on(Events.SHOW_FILTERED_BLOCK, (data: any) => {
                setFilterData(data);
            });
            Emitter.on(Events.HIDE_FILTERED_BLOCK, () => {
                setFilterData(null);
            });
        }
        return () => {
            cleanupFunction.current = true;
        }
    }, [
        setFilterData,
        openTab,
        closeTab,
        thirdScreen,
        addThirdScreen,
        exitNavigation,
        startGoNowMode,
        forceUpdate
    ]);

    const lang: string = localStorage.getItem('lang') || 'en';
    return (
        <>
            <Tab.Container defaultActiveKey="home">
            {loading ? (
                <div className="sidebar-loading">
                    <img src={ loader }  alt="loader" />
                </div>) : null }
                {/* searchBar for third-screen mode */}
                {mobile && thirdScreen &&
                <div id="third-screen-searchbar">
                    <SearchBox tab="home"
                        project={project}
                        backButton={backButton}
                        closeButton={closeButton}
                        filterButton={filterButton}
                        buildings={buildings}
                        changeFloor={changeFloor}
                        pois={pois}
                        fromThirdScreen={true}
                    />
                    { filterData && <FilteredBlock project={filterData?.project}
                                                   filterBuilding={filterData?.filterBuilding}
                                                   setFilterBuilding={filterData?.setFilterBuilding}
                                                   filterFloor={filterData?.filterFloor}
                                                   setFilterFloor={filterData?.setFilterFloor}
                    /> }
                </div>}

                {/* header bar for 'Go Now' mode */}
                {goNowMode &&
                    <div id="go-now-header">
                        <div className="window-header-left">
                            <button className="back-btn pull-left"
                                    type="button"
                                    onClick={goNowBack}
                                    >
                                <i className="back-btn-ico" />
                            </button>
                            <h4 className="window-poi-title">{destinationText}</h4>
                        </div>
                        <button className="btn btn-default srch-close-btn "
                            type="button"
                            onClick={closeSidebar}
                            >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                }

                <div id="sidebar" className="sidebar sidebar-left home collapsed" ref={sidebar}>
                    {!mobile &&
                    <LangPicker availableLangs={ project.availableLangs }
                                currLang={lang}
                                project={project}
                    />
                    }
                    <div className="sidebar-tabs" id="sidebar-tabs" style={{ 'display': 'none' }}>
                        <ul role="tablist" id="tabs">
                            <li className="disabled spreo-bars">
                                <Nav.Link eventKey="bars" id="bars" style={{ 'marginTop': 0 }}><i className="dot-menu" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="home" id="home-tab"><i className="tab-ico home-main-ico active" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="find" id="find-tab"><i className="tab-ico find-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="favorites" id="favorites-tab"><i className="tab-ico favorites-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="parking" id="parking-tab"><i className="tab-ico parking-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="buildings" id="buildings-tab"><i className="tab-ico buildings-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="categories" id="categories-tab"><i className="tab-ico categories-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="filters" id="filters-tab"><i className="tab-ico filters-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="navigate" id="navigate-tab"><i className="tab-ico navigate-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="help" id="help-tab"><i className="tab-ico help-main-ico" /></Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="info" id="info-tab"><i className="tab-ico info-main-ico" /></Nav.Link>
                            </li>
                        </ul>
                    </div>

                    <Tab.Content className="sidebar-content" id="sidebar-content">
                        {mobile && tabMode !== TabModes.OPEN_INFO && tabMode !== TabModes.OPEN_MY_PARKING &&
                            <div className="slider-rectangle-touch"
                                 onTouchStart={(e) => touchStart(e)}
                                 onTouchMove={(e) => touchMove(e)}
                            >
                                <div className="slider-rectangle" />
                            </div>
                        }
                        <div className="sidebar-pane home active" id="home" ref={homeTab}>
                            { currentTab.current === homeTab.current && <Home project={project}
                                  projects={projects}
                                  buildings={buildings}
                                  floor={floor}
                                  changeFloor={changeFloor}
                                  backButton={false}
                                  closeButton={closeButton}
                                  pois={pois}
                                  categories={categories}
                                  poisCategories={poisCategories}
                                  changeLoading={changeLoading}
                            /> }
                        </div>
                        <div className="sidebar-pane find"
                             id="find"
                             ref={findTab}
                        >
                            {pois && galleries && currentTab.current === findTab.current && <Find tabData={tabData}
                                          tabMode={tabMode}
                                          pois={pois}
                                          poidMap={poidMap}
                                          project={project}
                                          buildings={buildings}
                                          galleries={galleries}
                                          occupancy={occupancy}
                                          floor={floor}
                                          changeFloor={changeFloor}
                                          backButton={backButton}
                                          closeButton={closeButton}
                                          filterButton={filterButton}
                                          changeLoading={changeLoading}
                                          facilityNames={facilityNames}
                                          categories={categories}
                                          poisCategories={poisCategories}
                                          thirdScreen={thirdScreen}
                            /> }
                        </div>
                        <div className="sidebar-pane favorites" id="favorites" ref={favoritesTab}>
                            { currentTab.current === favoritesTab.current && <Favorite tabData={tabData}
                                      tabMode={tabMode}
                            /> }
                        </div>
                        <div className="sidebar-pane buildings" id="buildings" ref={buildingsTab}>
                            { currentTab.current === buildingsTab.current && <Building tabData={tabData}
                                      tabMode={tabMode}
                            /> }
                        </div>
                        <div className="sidebar-pane categories" id="categories" ref={categoriesTab}>
                            { currentTab.current === categoriesTab.current && <Category tabData={tabData}
                                      tabMode={tabMode}
                            /> }
                        </div>
                        <div className="sidebar-pane filters" id="filters" ref={ filtersTab }>
                            { currentTab.current === filtersTab.current && <Filter tabData={tabData}
                                    tabMode={tabMode}
                            />}
                        </div>
                        <div className="sidebar-pane navigate" id="navigate" ref={ navigateTab }>
                            { currentTab.current === navigateTab.current && <Navigate project={project}
                                      buildings={buildings}
                                      categories={categories}
                                      poisCategories={poisCategories}
                                      pois={pois}
                                      changeFloor={changeFloor}
                                      poidMap={poidMap}
                                      tabData={tabData}
                                      tabMode={tabMode}
                                      floor={floor}
                                      facilityNames={facilityNames}
                            /> }
                        </div>
                        <div className="sidebar-pane help" id="help" ref={ helpTab }>
                            { currentTab.current === helpTab.current && <HelperCenter project={project} /> }
                        </div>
                        <div className="sidebar-pane info" id="info" ref={ infoTab }>
                            {currentTab.current === infoTab.current && <Info tabData={tabData}
                                  tabMode={tabMode}
                                  galleries={galleries}
                            />}
                        </div>
                        <div className="sidebar-pane parking" id="parking" ref={ parkingTab }>
                            { currentTab.current === parkingTab.current && <Parking tabData={tabData}
                                     tabMode={tabMode}
                            /> }
                        </div>
                    </Tab.Content>
                </div>
            </Tab.Container>
            <div className="menu-btn"
                 onClick={(e) => ToggleMenu(e)}
                 role="button"
                 onKeyPress={(e) => e.preventDefault()}
                 tabIndex={0}
                 aria-label="Main Menu"
            />

        </>
    );
}

export default Sidebar;
