import { BaseAction, Payload } from '@constants';

export enum MapActions {
  CHANGEFLOOR = 'CHANGEFLOOR',
  REQUESTMAP = 'REQUESTMAP',
  REQUESTMAPSUCCESS = 'REQUESTMAPSUCCESS',
  REQUESTMAPERROR = 'REQUESTMAPERROR'
}

/**
 * Change floor in storage
 * @param payload
 */
export const changeFloor = (payload: Payload): BaseAction => ({
  type: MapActions.CHANGEFLOOR,
  payload,
});

/**
 * Request google map
 * @param payload
 */
export const requestMap = (payload: Payload): BaseAction => ({
  type: MapActions.REQUESTMAP,
  payload,
});

/**
 *
 * @param payload
 * @constructor
 */
export const MaprequestMapSuccess = (payload: Payload): BaseAction => ({
  type: MapActions.REQUESTMAPSUCCESS,
  payload,
});

/**
 *
 * @param payload
 * @constructor
 */
export const MaprequestMapError = (payload: Payload): BaseAction => ({
  type: MapActions.REQUESTMAPERROR,
  payload,
});
