import { BaseAction, Payload } from '@constants';

export enum CategoryActions {
    REQUESTCATEGORY = 'REQUESTCATEGORY',
    REQUESTCATEGORYSUCCESS = 'REQUESTCATEGORYSUCCESS',
    REQUESTCATEGORYERROR = 'REQUESTCATEGORYERROR'
}

/**
 * Fetch POI categories
 * @param payload
 */
export const requestCategory = (payload: Payload): BaseAction => ({
    type: CategoryActions.REQUESTCATEGORY,
    payload,
});

/**
 * Loading POI categories was successfully
 * @param payload
 */
export const requestCategorySuccess = (payload: Payload): BaseAction => ({
    type: CategoryActions.REQUESTCATEGORYSUCCESS,
    payload,
});

/**
 * Some error was occurred
 * @param payload
 */
export const requestCategoryError = (payload: Payload): BaseAction => ({
    type: CategoryActions.REQUESTCATEGORYERROR,
    payload,
});
