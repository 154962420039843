import React, { FC, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchBox from "../../SearchBox";
import { mobile, TabModes } from "../../../constants";
import { IBuilding, IGallery, IOccupancy, IPoi, IPOIsCategories, IProject } from '../../../interfaces';
import MarkerList from "../../MarkerList";
import MarkerCard from "../../MarkerCard";
import CategoryItems from "../../CategoryItems";
import WelcomeSearch from "../../WelcomeSearch";
import { getShowInMap } from "../../../helpers/utils";
import MarkerCardQR from "../../MarkerCardQR";

interface IFind {
    tabData: any;
    tabMode: string;
    pois: IPoi [];
    poidMap: IPoi [];
    project: IProject;
    buildings: IBuilding [];
    galleries: IGallery [][];
    occupancy: IOccupancy;
    floor: number;
    changeFloor: (floor: number) => void;
    backButton: boolean;
    closeButton: boolean;
    filterButton?: boolean;
    changeLoading?: (loading: boolean) => void;
    facilityNames?: any;
    categories?: any;
    poisCategories: IPOIsCategories;
    thirdScreen?: boolean;
}

const Find: FC<IFind> = (props: PropsWithChildren<IFind>) => {
    const {
        tabData,
        tabMode,
        pois,
        poidMap,
        project,
        buildings,
        galleries,
        occupancy,
        floor,
        changeFloor,
        backButton,
        closeButton,
        filterButton,
        changeLoading,
        facilityNames,
        categories,
        poisCategories,
        thirdScreen
    } = props;

    const category = React.useMemo(() => tabData?.category || null, [tabData]);
    const cname = React.useMemo(() => tabData?.cname || null, [tabData]);
    let findClassName: string = 'spreo-ui-window ui-window';
    findClassName += !getShowInMap() ? ' mt-10' : '';

    let findContext;

    switch(tabMode) {
        case TabModes.OPEN_SEARCH:
            findContext = (
                <div id="welcome-ui-window" className="spreo-ui-window mt-10 ui-window">
                    <WelcomeSearch project={project}
                                   pois={pois}
                                   changeFloor={changeFloor}
                    />
                </div>
            );
            break;
        case TabModes.OPEN_EMPTY_SEARCH:
            findContext = (
                <div id="find-ui-window" className="spreo-ui-window mt-10 ui-window">
                    <div className="empty-search-intro">
                        <p>
                            <i className="fas fa-search" />
                        </p>
                        <p>
                            {(window as any).lang.getString('typeKeywordsToFindThat')}
                        </p>
                    </div>
                </div>
            );
            break;
        case TabModes.SEARCHED:
            findContext =
                <div id="find-ui-window" className={findClassName}>
                    <MarkerList markers={tabData}
                        project={project}
                        pois={pois}
                        sort={true}
                        floor={floor}
                        changeFloor={changeFloor}
                        facilityNames={facilityNames}
                        fromSearch={true}
                    />
                </div>
            break;
        case TabModes.POI_DETAILS:
            findContext =
                <div id="find-ui-window" className="spreo-ui-window ui-window">
                    <MarkerCard project={project}
                                poi={tabData.poi}
                                pois={pois}
                                changeFloor={changeFloor}
                                galleries={galleries}
                                backState={tabData.backState}
                                floor={floor}
                                categories={categories}
                                poisCategories={poisCategories}
                    />
                </div>
            break;
        case TabModes.FILTER_MARKERS_BY_CATEGORY:
            findContext = (
                <div id="find-ui-window" className="spreo-ui-window mt-10 ui-window">
                    <PerfectScrollbar>
                        <CategoryItems project={project}
                                       pois={pois}
                                       floor={floor}
                                       changeFloor={changeFloor}
                                       cname={cname}
                                       fromFilter={true}
                                       facilityNames={facilityNames}
                                       category={category}
                        />
                    </PerfectScrollbar>
                </div>
            );
            break;
        case TabModes.OPEN_QR_MARKER_CARD:
            findContext = (
                <div id="find-ui-window" className="spreo-ui-window ui-window qr-scan-screen">
                    <MarkerCardQR project={project}
                                  pois={pois}
                                  poidMap={poidMap}
                                  poi={tabData?.poi}
                                  destinationPOID={tabData?.destinationPOID}
                                  isParkingPOI={tabData?.isParkingPOI}
                                  galleries={galleries}
                                  changeFloor={changeFloor}
                                  thirdScreen={thirdScreen}
                    />
                </div>
            );
            break;
    }

    const isShow: boolean = tabMode !== TabModes.POI_DETAILS
                            && tabMode !== TabModes.OPEN_QR_MARKER_CARD
                            && !getShowInMap();
    return (
        <>
            {mobile ? (isShow) && (<SearchBox tab="find"
                       pois={pois}
                       project={project}
                       buildings={buildings}
                       occupancy={occupancy}
                       changeFloor={changeFloor}
                       backButton={backButton}
                       closeButton={closeButton}
                       filterButton={filterButton}
            />) : <SearchBox tab="find"
                            pois={pois}
                            project={project}
                            buildings={buildings}
                            occupancy={occupancy}
                            changeFloor={changeFloor}
                            backButton={backButton}
                            closeButton={closeButton}
                            filterButton={filterButton}
                            changeLoading={changeLoading}
                            backState={tabData?.backState}
                            categories={categories}
                             poisCategories={poisCategories}
            />}
            { findContext }
        </>
    );
};

export default Find;
