import React, { FC, PropsWithChildren } from 'react';
import { BackStates, Events, mobile, TabModes, TabNames } from '@constants';
import { IGallery, IPoi, IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";
import { resetMap, selectPoi } from "../../helpers/marker";
import { getBackURL, getFacilityAndFloor, reloadBackURL } from '../../helpers/utils';

export interface IShowInMap {
   project: IProject;
   poi: IPoi;
   changeFloor: (floor: number) => void;
   galleries: IGallery [][];
   youAreHere?: boolean;
}

const ShowInMap: FC<IShowInMap> = (props: PropsWithChildren<IShowInMap>) => {
    const {
        project,
        poi,
        changeFloor,
        galleries,
        youAreHere
    } = props;

    const backToMenu = React.useCallback(() => {
        resetMap(project);

        if (mobile) {
            Emitter.emit(Events.CLOSE_SIDEBAR, null);
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }

        const backURL: string = getBackURL(project.pid);
        if (youAreHere && backURL) {
            reloadBackURL(backURL);
        } else {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.HOME,
                tabMode: '',
                tabData: null,
                tabBackButton: false,
                tabCloseButton: true,
            });
        }
    }, [
        project,
        youAreHere
    ]);

    const closeSidebar = React.useCallback(() => {
        // Map back to defaults, including default floor
        const infoWindow = (window as any).infoWindow || null;

        if (infoWindow) {
            infoWindow.close();
        }

        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        Emitter.emit(Events.CLOSE_SIDEBAR, null);

        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);

            setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);
            setTimeout(() => Emitter.emit(Events.MAP_DRAG_END, null), 500);
        }
    }, []);

    const getInfo = React.useCallback(() => {
        Emitter.emit(Events.HIDE_FLOATING_PANEL, null);
        selectPoi(poi, project, changeFloor, false, '', { state: BackStates.SHOW_IN_MAP });
    }, [poi, project, changeFloor]);

    const goFromHere = React.useCallback(() => {
        Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.NAVIGATE,
            tabMode: TabModes.OPEN_NAVIGATE_UI,
            tabData: { fromPoi: poi },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [poi]);

    React.useEffect(() => {
        if (youAreHere) {
            Emitter.emit(Events.HIDE_FLOATING_PANEL, null);
            selectPoi(poi, project, changeFloor, youAreHere, 'youAreHere');
        }
    }, [
        poi,
        project,
        changeFloor,
        youAreHere
    ]);

    const baseUrl: string = `${project.url}/middle/ios/res/${project.pid}/${poi.campus}/${poi.facility}/`;
    const images: any [] = [];

    if (galleries) {
        galleries.forEach((gallery: IGallery []) => gallery.forEach((item: IGallery) => {
                if (item.poi_id === poi.poid) {
                    images.push({
                        original: baseUrl + item.uri
                    });
                }
            }
        ));
    }

    const qrPOIInfo = getFacilityAndFloor(project, poi.facility, poi.floor);
    const poiImg = images && images.length ? <img src={images[0].original} className="space-img" alt="POI"/> : null;

    return <div id="info-ui-window" className="spreo-ui-window ui-window">
            <div className={youAreHere ? "window-header you-are-here-info" : "window-header"}>
                <div className="window-header-left">
                    {!youAreHere && <button className="back-btn pull-left"
                            type="button"
                            onClick={backToMenu}
                    >
                        <i className="back-btn-ico" />
                    </button>}
                    <h4 className="window-poi-title">{poi.description}</h4>
                    {youAreHere && mobile && <p className="help-block">
                        {(window as any).lang.getString('bookTab_poiDetails', [qrPOIInfo.facility, qrPOIInfo.floorname])}
                    </p>}
                </div>
                <button className="btn btn-default srch-close-btn "
                        type="button"
                        onClick={closeSidebar}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="window-content">
                {poiImg &&
                    <div className="poi-image">
                        {poiImg}
                    </div>
                }
                <div className="poi-info-buttons">
                    <button type="button"
                            className="poi-info-btn"
                            onClick={getInfo}
                    >
                        <span>{ (window as any).lang.getString('showInMap_Information') }</span>
                        <i />
                    </button>
                    <button type="button"
                            className="poi-go-from-btn"
                            onClick={goFromHere}
                    >
                        <span>{ (window as any).lang.getString('showInMap_GoFromHere') }</span>
                        <i />
                    </button>
                </div>
            </div>
        </div>;
};

export default ShowInMap;
