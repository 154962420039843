import momentTZ from 'moment-timezone';
import { baseUrl } from '@constants';
import { IPoi, IProject } from '@interfaces';
import { Params, HttpResponse, ITiles } from './interfaces';

const authUrl: string = `${baseUrl}/middle/ios/auth`;
const gMapStyleUrl: string = '/gmapStyle.json';
const projectUrl = (project: string, language: string) => `${baseUrl}/rest/project/${project}?language=${language}`;
const poiCategoryList = (project: string, language: string) => `${baseUrl}/rest/poicategory/list/${project}?language=${language}`;
const poisByCategory = (project: string, language: string, category: string) => `${baseUrl}/rest/poi?project=${project}&lang=${language}&category=${category}`;
const polygonList = (project: string, language: string) => `${baseUrl}/rest/polygons/${project}?language=${language}`;
const occupancyList = (pid: string, cid: string) => `${baseUrl}/rest/fsm-action/current-states-changed-in-last-seconds/${pid}/${cid}/15`;
const poiGallery = (project: string, campus: string, facility: string) => `${baseUrl}/rest/project/poi_gallery/${project}/${campus}/${facility}`;
const imagesUrl = ({ pid, floor, zoom, coord }: ITiles) => `https://tiles.route.spreo.co/${pid}/${floor}/zoom${zoom}/${coord.x}-${coord.y}-${zoom}.png`
const poiAllList = (project: string) => `${baseUrl}/pois-data-files/compressed/${project}.json?_=${momentTZ().unix()}`;
const poiAllListOtherAPI = (project: string) => `https://search.api.route.spreo.co/poi/${project}`;
const poisListByLanguage = (project: string, language: string) => `${baseUrl}/rest/poi/list/${project}?language=${language}`;
const availableLanguages = (project: any) => `${baseUrl}/middle/ios/projres?req=30&pid=${project.pid}&cid=${project.cid}&fid=${project.facilities[0].id}`;
const geofenceContents = (project: any) => `${baseUrl}/middle/ios/projres?req=42&pid=${project.pid}`;
const reportAnIssue = () => `${baseUrl}/rest/operational/reported-issue/create`;
const virtualPaths = (language: string) => `${baseUrl}/rest/navi/build-a-star-using-virtual-paths?lang=${language}`;
const qrData = (project: IProject, poi: IPoi) => `${baseUrl}/rest/poi/with-translations/${project.pid}/${poi.poid}`;
const reportAnalyticsPath = () => `${baseUrl}/rest/analytics/create`;
const staffLogin = (project: string) => `${baseUrl}/rest/staff/login/${project}`;

const esc = encodeURIComponent;

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: any = await fetch(request);
  response.parsedBody = await response.json();
  return response;
}

const query = (params: Params) =>
    Object.keys(params)
        .map((k) => `${esc(k)}=${esc(params[k])}`)
        .join('&');

async function get<T>(path: string, args: RequestInit = { method: 'get' }): Promise<HttpResponse<T>> {
  const result = await http<T>(new Request(path, args));
  return result;
}

async function post<T>(
    path: string,
    body: any,
    args: RequestInit = { method: 'post', body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  const result = await http<T>(new Request(path, args));
  return result;
}

async function put<T>(
    path: string,
    body: any,
    args: RequestInit = { method: 'put', body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  const result = await http<T>(new Request(path, args));
  return result;
}

export {
  query,
  get,
  post,
  put,
  authUrl,
  gMapStyleUrl,
  projectUrl,
  poiCategoryList,
  poisByCategory,
  polygonList,
  occupancyList,
  poiGallery,
  imagesUrl,
  poiAllList,
  poiAllListOtherAPI,
  poisListByLanguage,
  availableLanguages,
  geofenceContents,
  reportAnIssue,
  virtualPaths,
  qrData,
  reportAnalyticsPath,
  staffLogin
};
