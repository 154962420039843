import React, { FC, useState, useCallback } from 'react';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { IconComponent } from '@components';
import { Events } from '@constants';
import { useLocation } from "react-router-dom";
import './login.scss';
import Emitter from '../../helpers/emitter';
import { history } from '../../configStore';
import Lang from "../../helpers/lang";
import StateStorage from '../../helpers/stateStorage';

export interface IFormData {
  req: number;
  name: string;
  pass: string;
}
export interface ILoginForm {
  onSubmit: (data: IFormData, prevLink?: any) => void;
}

export interface ILogin {
  location: any
}

const LoginForm: FC<ILoginForm> = ({ onSubmit }) => {
  const urlParams = new URLSearchParams(window.location.search);

  StateStorage.setItem(`projects.test`, urlParams.has('test') ? 'true' : 'false');

  if(sessionStorage.getItem('uuid')) {
    history.replace('/project-list');
  }

  const [langIsLoaded, setLangIsLoaded] = React.useState(false);
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');
  const [authError, setAuthError] = useState(false);
  const [validTest, setValidTest] = useState({ name: true, pass: true });
  const validateForm = React.useCallback(() => ({ name: name.length > 0, pass: pass.length > 0 }), [name, pass]);

  const location: any = useLocation();

  if(!(window as any).lang) {
      const lang: string = localStorage.getItem('lang') || 'en';
      Object.defineProperty(window, 'lang', {
          writable: true,
          configurable: false,
          enumerable: true,
          value: new Lang(lang, () => {
              setLangIsLoaded(true);
          })
      });
  } else if((window as any).lang && !langIsLoaded) {
      setLangIsLoaded(true);
  }

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const prevLinkUrl = location.state?.url
      const prevLink = { url: prevLinkUrl, project: prevLinkUrl?.split('project-list/')[1].split('?')[0] }

      event.preventDefault();
      setValidTest(validateForm());

      if (validateForm()) {
        const formData = {
          req: 1,
          name,
          pass,
        };
        onSubmit(formData, prevLink);
      }

      return false;
    },
    [
        name,
        onSubmit,
        pass,
        setValidTest,
        validateForm,
        location.state?.url
    ]
  );

  React.useEffect(() => {
    Emitter.on(Events.AUTH_ERROR, () => {
      setAuthError(true);
    });
  }, [])

  if(!sessionStorage.getItem('uuid') && langIsLoaded) {
      return (
          <div className="login-container">
              <h4>{(window as any).lang.getString('loginForm_Login')}</h4>
              <Form onSubmit={handleSubmit} className="spreo-login-form">
                  <div className="spreo-login">
                      <div className="form-block">
                          <div className="form-label">
                              <span>{(window as any).lang.getString('loginForm_Username')}</span>
                          </div>
                          <InputGroup>
                              <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1" className="login-input-icon">
                                      <IconComponent className="login-ico user-ico" />
                                  </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  className="login-input"
                                  placeholder={(window as any).lang.getString('loginForm_Username')}
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                              />
                          </InputGroup>
                          {!validTest.name && <span className="login-error">{(window as any).lang.getString('loginForm_InvalidUsername')}</span>}
                      </div>
                      <div className="form-block">
                          <div className="form-label">
                              <span>{(window as any).lang.getString('loginForm_Password')}</span>
                          </div>
                          <InputGroup>
                              <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon2" className="login-input-icon">
                                      <i className="login-ico pass-ico" aria-hidden="true" />
                                  </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  type="password"
                                  className="login-input"
                                  placeholder={(window as any).lang.getString('loginForm_Password')}
                                  aria-label="Username"
                                  aria-describedby="basic-addon2"
                                  value={pass}
                                  onChange={(e) => setPass(e.target.value)}
                              />
                          </InputGroup>
                          {!validTest.pass && <span className="login-error">{(window as any).lang.getString('loginForm_InvalidPassword')}</span>}
                          {(authError && validTest.name && validTest.pass) && <span className="login-error">{(window as any).lang.getString('loginForm_InvalidUsernameOrPassword')}</span>}
                      </div>
                  </div>
                  <div className="text-center ml-2 login-btn-container">
                      <Button variant="light" type="submit" className="login-btn">
                          <span>{(window as any).lang.getString('loginForm_Login')}</span>
                      </Button>
                  </div>
              </Form>
          </div>
      );
  }

  return null;
};

export default LoginForm;
