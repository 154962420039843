import React, { MutableRefObject, PropsWithChildren } from 'react';
import { Marker, InfoBox } from "@react-google-maps/api";
import { uiSettings, Events, projectData } from '@constants';
import { useForceUpdate } from '../../hooks';
import { IProject, IMarkerLabel, IMarkerIconSize, IInfoBoxOptions } from "../../interfaces";
import {
    calcPOIIconSize,
    getOccupancyColor,
    getFacilityBounds,
    checkMarkerCategoriesToShow,
    checkMarkerStaffOnlyToShow
} from '../../helpers/utils';
import Emitter from '../../helpers/emitter';
import { selectPoi } from "../../helpers/marker";

interface IMarker {
    pois: any [];
    project: IProject;
    zoom: number;
    floor: string | number;
    poidMap: any [];
    backState: any;
}

interface IMarkerWithLabel {
    position: any;
    icon: any;
    floor: number;
    facility: string;
    campus: string;
    poid: any;
    occupied: number;
    categories: string;
    y: number;
    zoomVisible: number;
    visibleInNav: boolean;
    isOutdoor: boolean;
    label: IMarkerLabel | string;
    iconSize: IMarkerIconSize;
    description: string;
    visible: boolean;
    searchable: boolean;
}

/**
 *
 * @param props
 * @constructor
 */
const LabelMarker: React.FC<IMarker> = (props: PropsWithChildren<IMarker>) => {
    const {
        pois,
        project,
        zoom,
        floor,
        poidMap,
        backState
    } = props;

    const initialPoi = React.useMemo(() => ({
        id: null,
        campus: null,
        facility: null,
        iconUrl: ''
    }), []);

    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [currentFloor, setCurrentFloor] = React.useState(floor);
    const [currentPoi, setCurrentPoi] = React.useState(initialPoi);
    const forceUpdate = useForceUpdate(cleanupFunction);

    const canvas: any = React.useRef(document.createElement("canvas"));
    const mounted: any = React.useRef(false);
    const updated: any = React.useRef(false);

    const initLabels = React.useCallback((timeout: number = 150) => {
        updated.current = true;
        setTimeout(() => {
            const labelsClassName: string = '.spreo-label';
            $(labelsClassName).parent().parent().parent().css("z-index", "-500");

            const map: any = $('.gm-style').find('div').get(1);
            const iconsContainer: any = $(map).children('div')[1];
            const iconsDiv: any = $(iconsContainer).find('div:first-child').children('div')[2];
            $(iconsDiv).find('div').each((index, element) => {
                const left: number = parseInt($(element).css('left'), 10) - 45;
                $(element).css('left', `${left}px`);
                $(element).css('width', `110px`);
                $(element).css('height', `61px`);
            });

            updated.current = false;
        }, timeout);
    }, []);

    const showMarkerInfo = React.useCallback((poi: any): void => {
        selectPoi(poi, project, undefined, undefined, undefined, backState);
        setCurrentPoi(poi);
    }, [project, setCurrentPoi, backState]);

    const addMarker = React.useCallback((poi: any, iconUrl: string) => {
        const { google } = window;
        const { gmap } = (window as any);

        const lat = poi.geoPoint ? parseFloat(poi.geoPoint.lat) : poi.lat;
        const lon = poi.geoPoint ? parseFloat(poi.geoPoint.lon) : poi.lon;
        const poiLatLng = new google.maps.LatLng(lat, lon);
        const iconSize = calcPOIIconSize(poi, project, gmap, poidMap);

        let newIcon: any = {
            url: iconUrl,
            scaledSize: new google.maps.Size(iconSize.width, iconSize.height),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(iconSize.width / 2, iconSize.height / 2),
            labelOrigin: new google.maps.Point(iconSize.width / 2, 0)
        };

        if (poidMap[poi.poid]?.categories === `[${(window as any).lang.getString('findTab_categoryDesks')}]` ||
            poidMap[poi.poid]?.categories === `[${(window as any).lang.getString('findTab_categoryWorkstations')}]`) {
            const color = getOccupancyColor(poidMap[poi.poid]);
            newIcon = {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 5,
                fillColor: color,
                fillOpacity: 0.9,
                strokeColor: color,
                strokeWeight: 1,
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(0, 3),
            };
        } else if (poidMap[poi.poid]?.iconUrl === "workstation.png") {
            const color = poi.occupied ? "#FF0000" : "#00E500";

            newIcon = {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 3,
                fillColor: color,
                fillOpacity: 0.9,
                strokeColor: color,
                strokeWeight: 1,
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(0, 3),
            };
        }

        let labelClass: string = zoom > 18 ? `spreo-label ${poi.poid}` : `spreo-label lower ${poi.poid}`;
        if (iconSize.ratio < 1) {
            labelClass += " extra-space";
        }

        const words: string [] = poi.description.split(' ');

        if (words.length === 1 || poi.description.indexOf('Elevator') !== -1) {
            labelClass += " spreo-small-label";
        }

        if (words.length > 2) {
            labelClass += " spreo-big-label";
        }

        poi.iconSize = iconSize;
        const marker: IMarkerWithLabel = {
            position: poiLatLng,
            icon: newIcon,
            floor: poi.floor,
            facility: poi.facility,
            campus: poi.campus,
            poid: poi.poid,
            occupied: 0,
            categories: poi.categories,
            y: poi.y,
            zoomVisible: poi.visibleAtZoom,
            visibleInNav: false,
            isOutdoor: false,
            label: poi.showTextLabel ? {
                text: poi.description,
                color: "black",
                fontSize: "13px",
                fontWeight: "500",
                className: labelClass
            } : "",
            iconSize,
            description: poi.description,
            visible: poi.visible,
            searchable: poi.searchable
        };

        // Hide all markers if zoom is less or equal 17
        const currentZoom = gmap.getZoom();
        let isOutdoor = null;

        if (!cleanupFunction.current && currentPoi.id && currentPoi.id === poi.id && poi.floor === floor) {
            return <Marker position={ marker.position }
                           key={ poi.id }
                           icon={ marker.icon }
                           onClick={() => showMarkerInfo(poi)}
                           label={ marker.label }
                           clickable={ true }
                           visible={ true }
                    />;
        }

        if (poi.floor === floor &&
            (currentZoom > 17 && marker.zoomVisible <= currentZoom)
            && checkMarkerCategoriesToShow(poi.categories)
            && checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid))
        {
                return <Marker position={ marker.position }
                           key={ poi.id }
                           icon={ marker.icon }
                           onClick={() => showMarkerInfo(poi)}
                           label={ marker.label }
                           clickable={ true }
                           visible={ poi.visible }
                    />;
        }

        // Outdoor Pois always show up (only add pois with (x,y) coords to floor markers arrays)
        if (poi.x === 0 && poi.y === 0) {
            if (currentZoom > 17 && poi.zoomVisible <= currentZoom
                && checkMarkerCategoriesToShow(poi.categories)
                && checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid)) {
                return <Marker position={ marker.position }
                               key={ poi.id }
                               icon={ marker.icon }
                               onClick={() => showMarkerInfo(poi)}
                               label={ marker.label }
                               clickable={ true }
                        />;
            }

            isOutdoor = <Marker position={ marker.position }
                                key={ poi.id }
                                icon={ marker.icon }
                                onClick={() => showMarkerInfo(poi)}
                                label={ marker.label }
                                clickable={ true }
                        />;
        } else {
            // Floor markers
            const bounds = gmap.getBounds();
            if (bounds && bounds.contains(marker.position)) {
                const flag = marker.zoomVisible;

                if (currentZoom > 17 && flag <= currentZoom && poi.floor === floor
                    && checkMarkerCategoriesToShow(poi.categories)
                    && checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid)) {
                    return <Marker position={ marker.position }
                                   key={ poi.id }
                                   icon={ marker.icon }
                                   onClick={() => showMarkerInfo(poi)}
                                   label={ marker.label }
                                   clickable={ true }
                                   visible={ poi.visible }
                            />;
                }
            }
        }

        if (isOutdoor && currentZoom > 17 && marker.zoomVisible <= currentZoom
            && checkMarkerCategoriesToShow(marker.categories)
            && checkMarkerStaffOnlyToShow(poi.staffOnly, project.pid)) {
            return isOutdoor;
        }

        return null;
    }, [
        showMarkerInfo,
        zoom,
        floor,
        poidMap,
        project,
        currentPoi
    ]);

    /**
     *
     * @param text
     * @param font
     */
    const getTextWidth = (text: string, font: string) => {
        // re-use canvas object for better performance
        const Canvas: any = canvas.current;
        const context: any = Canvas.getContext("2d");
        context.font = font;
        return context.measureText(text).width;
    }

    const addBuildings = React.useCallback(() => {
        const { google } = (window as any);

        return project.facilities.map(facility => {
            const facilityBounds = getFacilityBounds(facility);
            if (facility.fname.toLowerCase().includes('outdoor') || facility.fname.toLowerCase().includes('campus')) {
                return null;
            }

            const center: any = facilityBounds.getCenter();
            const pixelOffset: number = getTextWidth(facility.fname, "bold 14px Open Sans");

            let buildingX: number = -pixelOffset / 2;
            const buildingY: number = -20;

            if(project.pid === 'st_vincent_indianapolis') {
                buildingX = -pixelOffset / 3;
            }

            const options: IInfoBoxOptions = {
                boxStyle: {
                    border: "0",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "140px",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontWeight: "600",
                    textShadow: "-1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff",
                    padding: "5px 10px",
                    zIndex: "-400"
                },
                disableAutoPan: true,
                closeBoxURL: "",
                isHidden: false,
                enableEventPropagation: true,
                pixelOffset: new google.maps.Size(buildingX, buildingY)
            };

            return <InfoBox
                options={ options }
                position={ center }
                key={ facility.id }
            >
                <div>{ facility.fname }</div>
            </InfoBox>
        });
    }, [project]);

    const addVisibleMarker = React.useCallback(() => {
            if (!cleanupFunction.current && currentPoi.id) {
                const iconUrl: string = `${ project.url }/middle/ios/resources/${ project.pid }/${ currentPoi.campus }/${ currentPoi.facility }/icons/${ currentPoi.iconUrl }`;
                return addMarker(currentPoi, iconUrl);
            }

            return null;
        }, [currentPoi, project, addMarker]);

    const addMarkers = React.useCallback(() => {
        const { gmap } = (window as any);
        if (!gmap || !pois) {
            return null;
        }

        const currentZoom = gmap.getZoom();
        if (currentZoom < 15) {
            return null;
        }

        if (currentZoom >= 15 && currentZoom <= 17) {
            return (
                <>
                    { addVisibleMarker() }
                    { uiSettings.buildingLabels ? addBuildings() : null }
                </>
            );
        }

        const userLang = pois.some(item => item.language === project.userLang) ? project.userLang : projectData.defaultLang;

        return pois.map((poi: any) => {
            if (poi.visible || poi.searchable) {
                const iconUrl: string = `${ project.url }/middle/ios/resources/${ project.pid }/${ poi.campus }/${ poi.facility }/icons/${ poi.iconUrl }`;
                // TODO define img width and height
                // img is ready to use
                if (poidMap[poi.poid]) {
                    poidMap[poi.poid].imgWidth = 19;
                    poidMap[poi.poid].imgHeight = 19;
                }

                if ([userLang, projectData.defaultLang].includes(poi.language)) {
                    return addMarker(poi, iconUrl);
                }
            }

            return null;
        });
    }, [
        pois,
        project,
        addMarker,
        poidMap,
        addBuildings,
        addVisibleMarker
    ]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        if(!updated.current) initLabels();

        if(!mounted.current) {
            Emitter.on(Events.FILTER_MARKERS_BY_CATEGORY, () => {
                forceUpdate();
            });
            Emitter.on(Events.SET_CURRENT_POI, (poi: any) => {
                setCurrentPoi(poi);
            });
            Emitter.on(Events.UNSET_CURRENT_POI, () => {
                setCurrentPoi(initialPoi);
            });

            Emitter.on(Events.MAP_DRAG_END, () => {
                if(!updated.current) initLabels();
            });

            Emitter.on(Events.INIT_LABELS, () => {
                updated.current = false;
                initLabels(500);
            });

            mounted.current = true;
        }


        return () => {
            cleanupFunction.current = true;
        }
    }, [
        addMarkers,
        currentFloor,
        setCurrentFloor,
        floor,
        forceUpdate,
        cleanupFunction,
        initialPoi,
        initLabels
    ]);

    if(!updated.current) initLabels();

    return (
        <>
            { addMarkers() }
        </>
    );
};

export default LabelMarker;
