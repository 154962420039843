interface ILang {
    lang: string;
}
interface IElement extends HTMLInputElement {
    readonly dataset: any;
}
type WordsType = any [] | null;
type CallbackType = (() => void) | undefined;

export default class Lang implements ILang{
    lang: string;

    constructor(lang: string, callback?: CallbackType) {
        this.lang = lang;
        this.init(callback);
    }

    init(callback: CallbackType) {
        const script: HTMLScriptElement = document.createElement("script");
        script.type = "module";
        script.src = `lang/${this.lang}.js?_=${(Math.floor(Date.now() / 1000))}`;
        document.body.appendChild(script);

        script.onload = () => {
            const elementsToReplace: NodeListOf<IElement> = document.querySelectorAll("[data-lang-key]");

            elementsToReplace.forEach((elem: IElement) => {
                const translatedTxt = this.getString(elem.dataset.langKey);

                if (translatedTxt !== null) {
                    elem.innerText = translatedTxt;
                }

                if (elem.tagName === 'INPUT') {
                    elem.placeholder = translatedTxt;
                }
            });

            if (callback !== undefined) {
                callback();
            }
        }
    }

    getString(string: string, words: WordsType = null) {
        if (typeof (window as any)[this.lang] !== 'undefined') {
            const langString = (window as any)[this.lang][string];

            if (words && words.length > 0) {
                return Lang.putDynamicValue(langString, words);
            }

            return langString;
        }

        throw new Error(`"${this.lang}" language is not found`);
    }

    static putDynamicValue(langString: string, words: any []) {
        for (let i = 0; i < words.length; i++) {
            const spat = new RegExp(`{dv${(i + 1)}}`);

            langString = langString.replace(spat, words[i]);
        }

        return langString;
    }

    static getAvailableLanguages() {
        return [
            'en',
            'es'
        ];
    }
}
