import React, { FC, MutableRefObject, PropsWithChildren, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import './LangPicker.scss';
import { history } from "../../configStore";
import { IProject } from "../../interfaces";
import Lang from "../../helpers/lang";

export interface ILangPicker {
    project: IProject;
    currLang: string;
    availableLangs: any [];
}

const LangPicker: FC<ILangPicker> = (props: PropsWithChildren<ILangPicker>) => {
    const {
        project,
        currLang,
        availableLangs
    } = props;

    const mounted: MutableRefObject<any> = React.useRef(false);
    const AvailableLangs = availableLangs.filter((item) => Lang.getAvailableLanguages().includes(item.code));
    const getLangName = React.useCallback((lang: string) => {
        const langObj = AvailableLangs.find((item) => item.code === lang);
        if(lang === 'he') langObj.name = 'עברית';
        if(lang === 'es') langObj.name = 'Español';
        return langObj.name;
    }, [AvailableLangs]);

    const [currentLang, setCurrLang] = useState(getLangName(currLang))
    const handleLangClick = React.useCallback((selectedLang: any) => {
        setCurrLang(getLangName(selectedLang.code));
        localStorage.setItem('lang', selectedLang.code);

        history.replace(`/project-list/${project.pid}`);
    }, [project, getLangName]);

    React.useEffect(() => {
        if(!mounted.current) {
            const selectSingle: any = document.querySelector('.lang-picker .__select');
            const selectSingleTitle = selectSingle.querySelector('.lang-picker .__select__title');
            const selectSingleLabels = selectSingle.querySelectorAll('.lang-picker .__select__label');

            // Toggle menu
            selectSingleTitle.addEventListener('click', (e: any) => {
                e.stopPropagation();

                if (selectSingle.getAttribute('data-state') === 'active') {
                    selectSingle.setAttribute('data-state', '');
                } else {
                    selectSingle.setAttribute('data-state', 'active');
                }
            });

            const body: any = document.querySelector("body");
            body.addEventListener('click', () => {
                selectSingle.setAttribute('data-state', '');
            });

            // Close when click to option
            for (let i = 0; i < selectSingleLabels.length; i++) {
                selectSingleLabels[i].addEventListener('click', (evt: any) => {
                    const code: string = evt.target.getAttribute('data-id');
                    const name: string = getLangName(code) || '';
                    setCurrLang(name);
                    selectSingleTitle.contentText = name;
                    selectSingle.setAttribute('data-state', '');
                });
            }

            mounted.current = true;
        }
    }, [setCurrLang, getLangName]);

    return (
        <div className="lang-picker">
            <div className="__select" data-state="">
                <div className="__select__title">
                    <i/>
                    <span>{currentLang}</span>
                </div>
                <div className="__select__content">
                    <PerfectScrollbar>
                        {AvailableLangs.map((item: any, i: number) =>
                            <span key={item.code}>
                                <input id={`langSelect${i}`}
                                       className="__select__input"
                                       type="radio"
                                       name="langSelect"
                                       defaultChecked={currentLang === item.name}
                                       onClick={() => handleLangClick(item)}/>
                                <label htmlFor={`langSelect${i}`}
                                       className="__select__label"
                                       data-id={item.code}
                                >
                                    {getLangName(item.code)}
                                </label>
                            </span>
                        )}
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    )
}

export default LangPicker
