import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Events, TabModes, TabNames, mobile } from "@constants";
import { IBuilding, IPoi, IProject } from "../../interfaces";
import { resetMap, selectMarker } from "../../helpers/marker";
import { checkMarkerStaffOnlyToShow, fitFacilityOnMap, getFacilityAndFloor } from "../../helpers/utils";
import Emitter from "../../helpers/emitter";

export interface IBuildings {
    project: IProject;
    buildings: IBuilding [];
    changeFloor: (floor: number) => void;
    pois: IPoi [];
    fname?: string;
    fid?: string;
    tabMode: string;
}

const Buildings: FC<IBuildings> = (props: PropsWithChildren<IBuildings>) => {
    const {
        project,
        buildings,
        changeFloor,
        pois,
        fname,
        fid,
        tabMode
    } = props;

    const facilityName = React.useMemo(() => fname || '', [fname]);
    const facilityId = React.useMemo(() => fid || '', [fid]);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);

    const backToBuildingList = React.useCallback(() => {
        resetMap(project, changeFloor);

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.BUILDINGS,
            tabMode: TabModes.OPEN_BUILDING_LIST,
            tabData: {
                buildings,
                project,
                changeFloor,
                pois,
                tabMode: TabModes.OPEN_BUILDING_LIST
            },
            tabBackButton: false,
            tabCloseButton: false,
        });

        if (mobile) {
            Emitter.emit(Events.HIDE_CATEGORY_BAR, null);
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }
    }, [
        buildings,
        project,
        changeFloor,
        pois
    ]);

    const showBuilding = React.useCallback((e: any, _facilityId: string, _facilityName: string) => {
        e.preventDefault();
        resetMap(project, changeFloor);

        fitFacilityOnMap(_facilityId, project, true);

        if (!cleanupFunction.current) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.BUILDINGS,
                tabMode: TabModes.OPEN_BUILDING_POI_LIST,
                tabData: {
                    buildings,
                    project,
                    changeFloor,
                    pois,
                    fname: _facilityName,
                    fid: _facilityId,
                    tabMode: TabModes.OPEN_BUILDING_POI_LIST
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
        }
    }, [
        buildings,
        project,
        changeFloor,
        pois
    ]);

    const PoiButtonClick = React.useCallback((poi: any) => {
        selectMarker(poi, project)
    }, [project])

    const renderBuildingPoiList = React.useCallback((_facilityId: string) => {
        const outdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
        const buildingPois: any = pois?.filter((item: IPoi) => item.facility === _facilityId
            && item.floor === parseInt(outdoorFloor, 10)
            && checkMarkerStaffOnlyToShow(item.staffOnly, project.pid));
        const className = 'spreo-poi-btn btn-default btn-block building-poi-btn';

        if (buildingPois.length) {
            return buildingPois.map((poi: IPoi) => {
                const markerInfo = getFacilityAndFloor(project, poi.facility, poi.floor);
                return (
                    <button type="button"
                            className={project.userLang === "he" ? `${className} text-right info-right` : `${className} text-left`}
                            key={poi.id}
                            onClick={() => PoiButtonClick(poi)}
                    >
                        <span className="poi-description">{poi.description}</span>
                        <span className="poi-info">
                            {(window as any).lang.getString('bookTab_poiDetails', [markerInfo.facility, markerInfo.floorname])}
                        </span>
                    </button>
                )
            });
        }

        return (<p className="no-poi-building">{(window as any).lang.getString('buildings_NoPointsOfInterest')}</p>);
    }, [pois, project, PoiButtonClick]);

    const backToBuildings = React.useCallback((e: any) => {
        if (mobile) {
            const sidebar: any = document.getElementById('sidebar');

            if (sidebar.classList.contains('third-screen')) {
                backToBuildingList();
            } else {
                showBuilding(e, facilityId, facilityName);
            }
        } else {
            backToBuildingList();
        }
    }, [
        backToBuildingList,
        showBuilding,
        facilityId,
        facilityName
    ]);

    const renderBuildingList = React.useCallback(() => {
        /** Adding building in sidebar */
        const className = 'spreo-poi-btn btn-default btn-block';
        if (buildings.length) {
            return buildings.map((building: IBuilding, i: number) => {
                const angleClassName = project.userLang === "he" ? 'fa fa-angle-left pull-left' : 'fa fa-angle-right pull-right';
                if (building.fname.toLocaleLowerCase().indexOf("campus") === -1 &&
                    building.fname.toLocaleLowerCase().indexOf("outdoor") === -1) {
                    return (
                        <button type="button"
                                id={`buildings${i}`}
                                className={project.userLang === "he" ? `${className} text-right` : `${className} text-left`}
                                key={`buildings${building.id}`}
                                onClick={(e) => showBuilding(e, building.fid, building.fname)}
                        >
                            <span>{ building.fname }</span>
                            <i className={angleClassName} aria-hidden="true" />
                        </button>
                    );
                }

                return null;
            });
        }

        return null;
    }, [project, buildings, showBuilding]);

    const closeSidebar = React.useCallback(() => {
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }
    }, []);

    const backToMenu = React.useCallback(() => {
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.HOME,
            tabMode: '',
            tabData: null,
            tabBackButton: false,
            tabCloseButton: true,
        });
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;
        return () => {
            cleanupFunction.current = true;
        };
    }, [cleanupFunction]);

    let buildingContext;
    switch(tabMode) {
        case TabModes.OPEN_BUILDING_LIST:
            buildingContext = (
                <div id="building-ui-window" className="spreo-ui-window ui-window">
                    <div className="window-header">
                        { mobile ?
                            <div className="window-header-left">
                                <button className="back-btn pull-left"
                                        type="button"
                                        onClick={ backToMenu }
                                >
                                    <i className="back-btn-ico" />
                                </button>
                                <h4 className="window-poi-title">{ (window as any).lang.getString('buildingsTitle') }</h4>
                            </div> :
                            <h4 id="header">{ (window as any).lang.getString('buildingsTitle') }</h4>
                        }
                        {!mobile ? <button className="btn btn-default srch-close-btn "
                                type="button"
                                onClick={closeSidebar}
                        >
                            <span aria-hidden="true">×</span>
                        </button> : null }
                    </div>
                    <PerfectScrollbar>
                        { renderBuildingList() }
                    </PerfectScrollbar>
                </div>
            );
            break;
        case TabModes.OPEN_BUILDING_POI_LIST:
            buildingContext = (
                <div id="building-ui-window" className="spreo-ui-window ui-window">
                    <div className="window-header">
                        <div className="window-header-left">
                            <button className="back-btn pull-left"
                                    type="button"
                                    onClick={(e) => backToBuildings(e)}
                            >
                                <i className="back-btn-ico" />
                            </button>
                            <h4 className="window-poi-title">{ facilityName }</h4>
                        </div>
                        <button className="btn btn-default srch-close-btn "
                                type="button"
                                onClick={closeSidebar}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <PerfectScrollbar>
                        { renderBuildingPoiList(facilityId) }
                    </PerfectScrollbar>
                </div>
            );
            break;
    }

    return (
        <>
            { buildingContext }
        </>
    );
};

export default Buildings;
