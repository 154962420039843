import Cookies from 'universal-cookie';
import { Polyline } from '@react-google-maps/api';
import { Events, mapOptions, mobile, NavigateModes, TabModes, TabNames } from '@constants';
import { post, virtualPaths } from '@api';
import { INav, IPath, IPathRequest, IReportAnalyticsRequest } from "../interfaces";
import { adjustPathFit, fitPathsToMap, generateRandomId, getOutdoorFloor, reportAnalytics } from "./utils";
import Instructions from "./instructions";
import Emitter from "./emitter";
import { getStaff } from "./staff";

class Path implements IPath {

    public origin: any;

    public destination: any;

    public navObj: any;

    public project: any;

    public paths: any;

    public helpPaths: any;

    public floorInfos: any [];

    public outdoorFloor: number;

    public markers: any;

    public state: number;

    public mobile: boolean;

    public distance: number;

    public interrupt: boolean;

    public gmap: any;

    public naviRequest: IPathRequest | null;

    public intermediateFloors: number [];

    public routeFacilities: any;

    public routeFloors: number [];

    constructor() {
        const { gmap } = window as any;

        this.origin = {};
        this.destination = {};
        this.navObj = null;

        this.project = {};
        this.paths = {};
        this.helpPaths = {};

        // Floor Change Info Windows
        this.floorInfos = [];
        this.markers = {};
        this.outdoorFloor = 0;

        this.state = 0;
        this.mobile = false;
        this.distance = 0;
        this.interrupt = false;
        this.gmap = gmap;
        this.naviRequest = null;
        this.intermediateFloors = [];
        this.routeFacilities = null;
        this.routeFloors = [];
    }

    init(markerMap: any) {
        this.markers = markerMap;
        this.outdoorFloor = getOutdoorFloor();
    }

    /**
     *
     * @param nav
     */
    createPath (nav: INav) {
        if (!nav || !nav.origin) {
            throw new Error("nav not defined");
        }

        this.interrupt = false;
        this.navObj = nav;
        this.origin = nav.origin.poi;
        this.destination = nav.destination.poi;
        this.project = nav.project;
        this.mobile = mobile;

        for (let i = 0, floorsLength = this.project.floors; i < floorsLength; ++i) {
            this.paths[`floor${i}`] = [];
            this.helpPaths[`floor${i}`] = [];
        }

        const authorizationLevel = getStaff(this.project.pid) ? 1 : 0;
        const { handicapped } = nav;
        const originLat = this.origin.geoPoint ? this.origin.geoPoint.lat : this.origin.lat;
        const originLon = this.origin.geoPoint ? this.origin.geoPoint.lon : this.origin.lon;
        const destLat = this.destination.geoPoint ? this.destination.geoPoint.lat : this.destination.lat;
        const destLon = this.destination.geoPoint ? this.destination.geoPoint.lon : this.destination.lon;

        const pathRequest = {
            pid: this.project.pid,
            authorizationLevel,
            handicapped,
            from: {
                x: this.origin.x,
                y: this.origin.y,
                latitude: originLat,
                longitude: originLon,
                floor: this.origin.floor,
                cid: this.project.cid,
                fid: this.origin.facility,
                mode: this.origin.navtype === "INDOOR" || this.origin.navtype === true ? 0 : 1,
                poid: this.origin.poid,
            },
            to: {
                x: this.destination.x,
                y: this.destination.y,
                latitude: destLat,
                longitude: destLon,
                floor: this.destination.floor,
                cid: this.project.cid,
                fid: this.destination.facility,
                mode: this.destination.navtype === "INDOOR" || this.destination.navtype === true ? 0 : 1,
                poid: this.destination.poid,
            },
        };

        // Store navigation request in case we need it later for different languages
        this.naviRequest = pathRequest;

        // Call navigation API
        Emitter.emit(Events.SIDEBAR_LOADING, true);
        const path: string = virtualPaths(this.project.userLang);
        return post(path, pathRequest, { method: 'post', body: JSON.stringify(pathRequest), headers: {
                'Content-Type': 'application/json'
            }
        }).then((result: any) => {
            // Report navigation for analytics
            const clientId: string = generateRandomId();
            const request: IReportAnalyticsRequest = {
                action: "navigate",
                clientId,
                platform: "web",
                data: nav.destination.poi.description,
                project: nav.project.pid,
                campus: nav.project.campus.cid,
                facility: nav.destination.poi.facility,
                floor: nav.destination.poi.floor
            };
            reportAnalytics(request);

            this.drawPath(result.parsedBody);
            const cookies: any = new Cookies();
            cookies.set('destinationPOI', pathRequest.to.poid, {
                expires: new Date(new Date().getTime() + mapOptions.cookiesExpireTime * 1000),
                path: (/\./.test(window.location.pathname) ? '/' : window.location.pathname)
            });

            Emitter.emit(Events.TOGGLE_NAV_ELEMENTS, true);
            Emitter.emit(Events.SIDEBAR_LOADING, false);
        }).catch(() => {
            Emitter.emit(Events.TOGGLE_NAV_ELEMENTS, false);
            Emitter.emit(Events.SIDEBAR_LOADING, false);

            if (mobile) {
                Emitter.emit(Events.DISPLAY_ROUTE, { fromPoi: this.origin, toPoi: this.destination });
            }
        });
    }

    /**
     *
     * @param jdata
     */
    drawPath(jdata: any) {
        const { google } = window;
        const { gmap } = window as any;

        this.state = 1;
        this.distance = 0;

        // If response is null, throw an error and return
        if (!jdata) {
            throw new Error("No navigation data");
        }

        const intermediateFloors: number [] = [];
        const routeFacilities: any = {};
        const routeFloors: number [] = [];
        let interruptBuild = false;

        let startFloor: number = this.origin.floor;
        const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
        const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

        if (this.origin.x === 0 && this.origin.y === 0 && startFloor !== outdoorFloor) {
            startFloor = outdoorFloor;
        }

        const bridgeSteps: { lat: number; lng: number; }[] = [];
        let bridgeConnections: any[] = [];

        // Get bridge paths from the navigation path
        jdata.navigation_paths.forEach((np: any) => {
            const bridgeSegments = np.segments.filter((nps: any) => nps.virtual && nps.bridgeConnector);

            if (bridgeSegments.length) bridgeConnections = [...bridgeConnections, ...bridgeSegments];
        });

        let isPrevFromOutdoor = false;
        let isPrevFromIndoor = false;
        const clearedBridgeConnections: any[] = [];

        bridgeConnections.forEach((bC: any, idx) => {
            if (!isPrevFromOutdoor && bC.start.x === 0) {
                isPrevFromOutdoor = true;

                if (isPrevFromIndoor) {
                    clearedBridgeConnections.splice(clearedBridgeConnections.length - 1, 1);
                    isPrevFromIndoor = false;
                }
            }
            else if (isPrevFromOutdoor && bC.start.x > 0) isPrevFromOutdoor = false;
            else if (bC.start.x > 0 && !isPrevFromOutdoor) {
                isPrevFromIndoor = true;
                clearedBridgeConnections.push(bC);
            }
        });

        if (clearedBridgeConnections.length > 1) {
            const [line1, line2] = clearedBridgeConnections;

            const { latitude: lat1, longitude: lng1 } = line1.start;
            const { latitude: lat2, longitude: lng2 } = line2.end;

            bridgeSteps.push({
                lat: lat1,
                lng: lng1
            });

            bridgeSteps.push({
                lat: lat2,
                lng: lng2
            });
        }

        let bridgeLineAdded = false;

        const addLineToMap = (
          facility: string,
          floor: number,
          steps: { lat: number; lng: number; }[]): {floorLine: Polyline, helpLine: Polyline} => {
            const floorLine = new google.maps.Polyline({
                path: steps,
                strokeColor: "#1b4297", // blue is #4a80f5", orange is "#eb392e"
                strokeOpacity: 1.0,
                strokeWeight: 7,
                facility,
                zIndex: 1000
            });
            const helpLine = new google.maps.Polyline({
                path: steps,
                strokeOpacity: 0,
                zIndex: 1000,
                icons: [{
                    icon: {
                        path: "M 0,-0.6 0,0",
                        strokeOpacity: 0.5,
                        strokeColor: "#1b4297",
                        scale: 7
                    },
                    offset: "0",
                    repeat: "18px",
                }]
            });

            // Determine what to do with the floorline based on source floor
            if (floor === startFloor) floorLine.setMap(gmap);
            else helpLine.setMap(gmap);

            return { floorLine, helpLine };
        };

        // Draw navigation lines on the map
        for (let i = 0, navPathLength = jdata.navigation_paths.length; i < navPathLength; ++i) {
            if (this.interrupt) {
                interruptBuild = true;
                return;
            }

            const navPath = jdata.navigation_paths[i];
            const floor = navPath.floor == null ? this.outdoorFloor : navPath.floor;
            const facility = navPath.fid == null ? "outdoor" : navPath.fid;

            // Keep track of all the intermediate floors that are not part of origin or destination
            if (floor !== startFloor && floor !== this.destination.floor && intermediateFloors.indexOf(floor) === -1) {
                intermediateFloors.push(floor);
            }

            // Keep track of how many facilities a floor route goes through (for printing pdf)
            if (!routeFloors.length || routeFloors[routeFloors.length - 1] !== floor) {
                routeFloors.push(floor);
                routeFacilities[`route${floor}`] = [];
                routeFacilities[`route${floor}`].push(facility);
            }
            else routeFacilities[`route${floor}`].push(facility);

            /*
             *   Main Loop for drawing the segments
             */
            const pathLength = navPath.segments ? navPath.segments.length : 0;

            for (let j = 0; j < pathLength; ++j) {
                if (this.interrupt) {
                    interruptBuild = true;
                    return;
                }

                const segment = navPath.segments[j];

                // Do not draw the virtual paths for bridge connection,
                // instead draw a single line to connect two buildings
                if (segment.bridgeConnector && segment.virtual && segment.start.x) {
                    if (!bridgeLineAdded) {
                        const { floorLine, helpLine } = addLineToMap(facility, floor, bridgeSteps);

                        this.helpPaths[`floor${floor}`].push(helpLine);
                        this.paths[`floor${floor}`].push(floorLine);
                        bridgeLineAdded = true;
                    }

                    continue;
                }

                /* eslint no-continue: 0 */
                if (segment.instruction.poi !== undefined) {
                    if (this.origin.poid === segment.instruction.poi.poid
                        && this.markers[segment.instruction.poi.poid].nodalPOI) {
                        continue;
                    }
                    if (this.destination.poid === segment.instruction.poi.poid
                        && this.markers[segment.instruction.poi.poid].nodalPOI) {
                        continue;
                    }
                }

                // Get steps, set visibility and draw path between both points
                const steps = [];
                steps.push({
                    lat: segment.start.latitude,
                    lng: segment.start.longitude,
                });

                steps.push({
                    lat: segment.end.latitude,
                    lng: segment.end.longitude,
                });

                const { floorLine, helpLine } = addLineToMap(facility, floor, steps);

                this.helpPaths[`floor${floor}`].push(helpLine);
                this.paths[`floor${floor}`].push(floorLine);
            }
        }

        // Do not finish this part if we interrupt by clear origin or destination fields during drawPath operation
        if (interruptBuild) return;

        // Fit navigation paths to map
        if (this.paths[`floor${this.origin.floor}`].length) {
            fitPathsToMap(
                this.paths[`floor${this.origin.floor}`],
                routeFacilities[`route${this.origin.floor}`]
            );
            adjustPathFit();
        }

        // Save intermediate and facilities for printing
        this.intermediateFloors = intermediateFloors;
        this.routeFacilities = routeFacilities;
        this.routeFloors = routeFloors;

        // Prepare instructions to display (with outdoorFloor)
        jdata.outdoorFloor = this.outdoorFloor;
        jdata.markers = this.markers;
        jdata.origin = this.origin;
        jdata.destination = this.destination;

        const pathInstructions = new Instructions(jdata, 'en', this.project);
        this.distance = pathInstructions.distance;

        this.floorChangeInfo(jdata);
        this.facilityChangeInfo(jdata);
        this.exitEnterInfo(jdata);
        this.prepareUI(pathInstructions.list, pathInstructions.simplified);
    }

    prepareUI(instructionList: any, simplifiedList: any) {
        // Get time and distance for overall navigation
        let calcTime = Math.round((this.distance / 3500) * 60);
        calcTime += (this.routeFloors.length - 1) * 2; // 2 minutes for each floor change?? is that a good assumption?
        const time = calcTime > 0 ?
            (window as any).lang.getString('navInstruction_durationMin', [calcTime]) :
            (window as any).lang.getString('navInstruction_durationLessMin');

        const pdfInfo = {
            instructions: instructionList,
            simplified: simplifiedList,
            time,
            path: this,
        };

        const pathFtDistance = Math.round(this.distance / 0.3048);
        let distTextVal: number [] = [pathFtDistance, Math.round(this.distance)];

        if (this.project.userLang === 'he') {
            distTextVal = [Math.round(this.distance)];
        }

        const tabData: any = {
            isPath: true,
            instructionList,
            simplifiedList,
            time,
            pdfInfo,
            distTextVal,
            fromPoi: this.navObj.origin.poi,
            toPoi: this.navObj.destination.poi,
            referrer: this.navObj.referrer
        }

        switch(this.navObj.mode) {
            case NavigateModes.GO_NOW:
                if (mobile) {
                    Emitter.emit(Events.DISPLAY_ROUTE, { fromPoi: this.origin, toPoi: this.destination });
                }
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_GO_NOW,
                    tabData,
                    tabBackButton: false,
                    tabCloseButton: false,
                });
                break;
            case NavigateModes.ROUTE:
                Emitter.emit(Events.OPEN_TAB, {
                    tabName: TabNames.NAVIGATE,
                    tabMode: TabModes.OPEN_ROUTE,
                    tabData,
                    tabBackButton: false,
                    tabCloseButton: false,
                });
                break;
        }


    }

    floorChangeInfo (json: any) {
        const { google } = window;
        const { gmap } = window as any;

        let markerFloor: number = json.origin.floor;
        const storedOutdoorFloor: string = sessionStorage.getItem('outdoorFloor') || '0';
        const outdoorFloor: number = parseInt(storedOutdoorFloor, 10);

        if (json.origin.x === 0 && json.origin.y === 0 && markerFloor !== outdoorFloor) {
            markerFloor = outdoorFloor;
        }

        this.navObj.changeViewFloor(markerFloor);

        const switchFloorsArray = json.switch_floors;
        for (let i = 0; i < switchFloorsArray.length; i++) {
            const switchData = switchFloorsArray[i];
            const { from, to } = switchData;

            let directionMSG = (window as any).lang.getString('nav_floorChangeUp');
            if (from.floor > to.floor) {
                directionMSG = (window as any).lang.getString('nav_floorChangeDown');
            }

            const { floorNames } = this.project;
            const floorChangeMSG = `${directionMSG}<a href="javascript: void(0);" id="nav-floorChange-${to.floor}">${(window as any).lang.getString('nav_floorText', [floorNames[to.floor]])}</a>`;

            $(document).on("click", `#nav-floorChange-${to.floor}`, (e) => {
                this.navObj.floorChange(from.floor, to.floor);
                this.navObj.changeViewFloor(to.floor);
            });

            const infoWindow = new google.maps.InfoWindow();
            infoWindow.setContent(floorChangeMSG);
            infoWindow.setPosition({ lat: from.latitude, lng: from.longitude });
            if (this.origin.floor === from.floor) {
                infoWindow.open(gmap);
            }

            const floorInfoWindow = {
                info: infoWindow,
                floor: from.floor,
            };

            this.floorInfos.push(floorInfoWindow);
        }
    }

    facilityChangeInfo (json: any) {
        const { google } = window;
        const { gmap } = window as any;

        const bridgesArray: any [] = json.bridges;

        for (let i = 0; i < bridgesArray.length; i++) {
            const bridgeData = bridgesArray[i];
            const { from, to } = bridgeData;

            if (from !== null && to !== null && from.floor !== to.floor) {
                const facilityId: string = to.fid;
                let directionMSG = "";
                if (facilityId !== null) {
                    directionMSG = (window as any).lang.getString('nav_bridgeBubbleText');
                    const { facilities } = this.project;
                    if (facilities !== null) {
                        facilities.forEach((facility: any) => {
                            if (facility.fid === facilityId) {
                                directionMSG =
                                    `<span class="bridge-bubble-head">${directionMSG}</span><br/>`;
                                directionMSG += `<span class="bridge-bubble-info">${facility.fname}<br/>`;
                            }
                        });
                    }

                    const toFloor: number = to.floor;
                    if (toFloor !== null) {
                        const { floorNames } = this.project;
                        if (floorNames !== null) {
                            directionMSG += ` ${(window as any).lang.getString('nav_floorText', [floorNames[toFloor]])}</span>`;
                        }
                    }
                }

                const infoWindow = new google.maps.InfoWindow();
                infoWindow.setContent(directionMSG);
                infoWindow.setPosition({ lat: from.latitude, lng: from.longitude });
                if (this.origin.floor === from.floor) {
                    infoWindow.open(gmap);
                }

                const floorInfoWindow = {
                    info: infoWindow,
                    floor: from.floor,
                };

                this.floorInfos.push(floorInfoWindow);
            }
        }
    }

    proceedData(navData: any, text: string) {
        const { google } = window;
        const { gmap } = window as any;

        const infoWindow = new google.maps.InfoWindow();

        navData.forEach((info: any) => {
            const facilityChangeMSG = `<span class="bridge-bubble-head">${text}</span><br/>`;

            infoWindow.setContent(facilityChangeMSG);
            infoWindow.setPosition({ lat: info.latitude, lng: info.longitude });

            if (this.origin.floor === info.floor) {
                infoWindow.open(gmap);
            }

            this.floorInfos.push({
                info: infoWindow,
                floor: info.floor,
            });
        });
    }

    exitEnterInfo(data: any) {
        if (data.exits.length) {
            this.proceedData(data.exits, (window as any).lang.getString('navInstruction_exitBuildingText'));
        }

        if (data.entrances.length) {
            this.proceedData(data.entrances, (window as any).lang.getString('navInstruction_enterBuildingText'));
        }
    }

    floorChange(from: number, to: number) {
        const { gmap } = window as any;

        if (!this.state) {
            return;
        }

        const fromPaths = this.paths[`floor${from}`];
        for (let i = 0, pathLength = fromPaths.length; i < pathLength; ++i) {
            fromPaths[i].setMap(null);
        }

        const toHelpPath = this.helpPaths[`floor${from}`];
        for (let i = 0, pathLength = toHelpPath.length; i < pathLength; ++i) {
            toHelpPath[i].setMap(gmap);
        }

        const toPaths = this.paths[`floor${to}`];
        const toFacilities = new Set();
        for (let i = 0, pathLength = toPaths.length; i < pathLength; ++i) {
            toPaths[i].setMap(gmap);
            toFacilities.add(toPaths[i].facility);
        }

        const fromHelpPath = this.helpPaths[`floor${to}`];
        for (let i = 0, pathLength = fromHelpPath.length; i < pathLength; ++i) {
            fromHelpPath[i].setMap(null);
        }

        // Check if its printing or not to fit paths on map (if not it would do it twice)
        const isNotprinting = true;
        if (toPaths.length && toFacilities.size && isNotprinting) {
            fitPathsToMap(toPaths, Array.from(toFacilities));
            adjustPathFit();
        }

        // Hide or show floor infowindows
        for (let i = 0, floorInfLength = this.floorInfos.length; i < floorInfLength; ++i) {
            if (this.floorInfos[i].floor === from) {
                if (this.floorInfos[i].marker) {
                    this.floorInfos[i].info.close();
                    this.floorInfos[i].marker.setMap(null);
                } else {
                    this.floorInfos[i].info.setMap(null);
                }
            } else if (this.floorInfos[i].floor === to) {
                if (this.floorInfos[i].marker) {
                    this.floorInfos[i].marker.setMap(gmap);
                    this.floorInfos[i].info.open(gmap, this.floorInfos[i].marker);
                } else {
                    this.floorInfos[i].info.open(gmap);
                }
            }
        }
    }

    resetFloorChangeInfo() {
        for (let i = 0; i < this.floorInfos.length; i++) {
            this.floorInfos[i].info.close();
        }
        this.floorInfos = [];
    }

    reset() {
        for (let i: number = 0, floorsLength = this.project.floors; i < floorsLength; ++i) {
            if (this.paths[`floor${i}`] && this.paths[`floor${i}`].length) {
                const floorPath = this.paths[`floor${i}`];
                for (let j = 0, pathLength = floorPath.length; j < pathLength; ++j) {
                    floorPath[j].setMap(null);
                }

                const floorHelpPath = this.helpPaths[`floor${i}`];
                for (let j = 0, pathLength = floorHelpPath.length; j < pathLength; ++j) {
                    floorHelpPath[j].setMap(null);
                }
            }
        }

        this.resetFloorChangeInfo();

        this.origin = {};
        this.destination = {};
        this.interrupt = true;
        this.state = 0;
        this.paths = [];
        this.helpPaths = [];
    }
}

export default Path;
