import React, { FC, PropsWithChildren } from 'react';

export interface IFAQ {
    onCloseContent: () => void;
}

const FAQ: FC<IFAQ> = (props: PropsWithChildren<IFAQ>) =>  {
    const {
        onCloseContent
    } = props;

    return (
        <div className="help-center-container">
            <div className="help-center-header">
                <h4>FAQ</h4>
                <i className="close-btn"
                   onClick={() => onCloseContent()}
                   role="button"
                   onKeyPress={(e) => e.preventDefault()}
                   tabIndex={0}
                   aria-label="close content"
                />
            </div>
        </div>
    );
}

export default FAQ;
