import React, { FC, PropsWithChildren } from 'react';
import { IProject } from "../../interfaces";
import Emitter from "../../helpers/emitter";
import { Events, TabNames } from "../../constants";

import './styles.scss';
import Guides from "./Guides";
import Blog from "./Blog";
import FAQ from "./FAQ";

export interface IHelperCenter {
    project: IProject
}

const HelperCenter: FC<IHelperCenter> = (props: PropsWithChildren<IHelperCenter>) => {
    const helpContent = React.useRef(null);
    const [item, setItem] = React.useState('');

    const toggleFloatingPanel = React.useCallback((action: string) => {
        const floating: any = document.getElementById('floating-panel');

        if (action === 'show') {
            floating.style.display = 'block';
        } else if(action === 'hide') {
            floating.style.display = 'none';
        }
    }, []);

    const closeContent = React.useCallback(() => {
        const content: any = helpContent.current;
        if (content) {
            content.classList.remove('open');
        }

        setItem('');
        setTimeout(() => {
            toggleFloatingPanel('show');
        }, 400);
    }, [toggleFloatingPanel]);

    const backTo = React.useCallback(() => {
        closeContent();

        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.HOME,
            tabMode: '',
            tabData: null,
            tabBackButton: false,
            tabCloseButton: true,
        });
    }, [closeContent]);

    const openContent = React.useCallback((_item: string) => {
        setItem(_item);

        const window: any = helpContent.current;
        if (window) {
            window.classList.add('open');
        }

        toggleFloatingPanel('hide');
    }, [setItem, toggleFloatingPanel]);

    return (
        <>
            <div id="help-ui-window" className="spreo-ui-window ui-window">
                <div className="window-header help-header">
                    <button className="back-btn pull-left"
                            type="button"
                            onClick={backTo}
                    >
                        <i className="back-btn-ico" />
                    </button>
                    <h4>Help Center</h4>
                </div>
                <div className="help-center-menu">
                    <span className="search-prepend-icon">
                        <i className="fas fa-search"/>
                    </span>
                    <input type="text"
                           placeholder="How can we help you?"
                           className="help-center-search"
                    />
                    <ul className="menu-items">
                        <li className={item === 'guides' ? "menu-item active" : "menu-item"}>
                            <button type="button" onClick={() => openContent('guides')}>
                                <i className="menu-ico guides-ico"/>
                                <span>Guides</span>
                            </button>
                        </li>
                        <li className={item === 'blog' ? "menu-item active" : "menu-item"}>
                            <button type="button" onClick={() => openContent('blog')}>
                                <i className="menu-ico blog-ico"/>
                                <span>Blog</span>
                            </button>
                        </li>
                        <li className={item === 'faq' ? "menu-item active" : "menu-item"}>
                            <button type="button" onClick={() => openContent('faq')}>
                                <i className="menu-ico faq-ico"/>
                                <span>FAQ</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="help-center-content" ref={helpContent}>
                {item === 'guides' && (
                    <Guides onCloseContent={closeContent}/>
                )}
                {item === 'blog' && (
                    <Blog onCloseContent={closeContent} />
                )}
                {item === 'faq' && (
                    <FAQ onCloseContent={closeContent} />
                )}
            </div>
        </>
    );
};

export default HelperCenter;
