import React, { FC, PropsWithChildren } from 'react';
import { resetMap, selectPoi } from "../../helpers/marker";
import Emitter from "../../helpers/emitter";
import { BackStates, Events, mobile, TabModes, TabNames } from "../../constants";
import { IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';
import DeleteParking from "../DeleteParking";
import { isSamsungDefaultBrowser, sortCategories } from "../../helpers/utils";

export interface IMyParking {
    project: IProject;
    poi: IPoi;
    floor: number;
    changeFloor: (floor: number) => void;
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    pois: IPoi [];
}

const MyParking: FC<IMyParking> = (props: PropsWithChildren<IMyParking>) => {
    const {
        project,
        poi,
        floor,
        changeFloor,
        categories,
        poisCategories,
        pois
    } = props;

    const parkingBtn = React.useRef(null);
    const mount = React.useRef(false);
    const backToMenu = React.useCallback(() => {
        resetMap(project);
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.HOME,
            tabMode: '',
            tabData: null,
            tabBackButton: false,
            tabCloseButton: true,
        });

        setTimeout(() => {
            Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        }, 500);

        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }
    }, [project]);

    const closeSidebar = React.useCallback(() => {
        resetMap(project);
        Emitter.emit(Events.CLOSE_SIDEBAR, null);
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);

        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        }
    }, [project]);

    const openParkingInfo = React.useCallback((e) => {
        e.preventDefault();

        selectPoi(poi, project, changeFloor, false, '', {
            state: BackStates.PARKING_LIST
        });
        setTimeout(() => {
            Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        }, 500);

    }, [poi, project, changeFloor]);

    const deleteParking = React.useCallback(() => {
        Emitter.emit(Events.OPEN_DELETE_PARKING_MODAL, true);
    }, []);


    const seeAllParking = React.useCallback(() => {
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project);
        const parking: any = data.find((item: IPoiCategory) => item.name.indexOf('Parking') !== -1);
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.CATEGORIES,
            tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
            tabData: {
                categories,
                poisCategories,
                project,
                floor,
                changeFloor,
                pois,
                cid: parking?.id,
                cname: parking?.name,
                tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
                fromParking: true
            },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [
        project,
        categories,
        poisCategories,
        floor,
        changeFloor,
        pois
    ]);

    const goToParking = React.useCallback(() => {
        Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
        Emitter.emit(Events.SHOW_MAP_CONTROLS, null);
        Emitter.emit(Events.OPEN_TAB, {
            tabName: TabNames.NAVIGATE,
            tabMode: TabModes.OPEN_NAVIGATE_UI,
            tabData: { toPoi: poi },
            tabBackButton: false,
            tabCloseButton: false,
        });
    }, [poi]);

    React.useEffect(() => {
        if (poi) {
            Emitter.emit(Events.HIDE_MAP_CONTROLS, null);
            selectPoi(poi, project, changeFloor, true, 'parking');
        }

        if(!mount.current) {
            if (parkingBtn.current && isSamsungDefaultBrowser()) {
                // @ts-ignore
                parkingBtn.current.style.marginBottom = '40px';
            }

            mount.current = true;
        }
    }, [
        poi,
        project,
        changeFloor
    ]);

    return <div id="parking-ui-window" className="spreo-ui-window ui-window">
        <div className="window-header">
            <div className="window-header-left">
                <button className="back-btn pull-left"
                        type="button"
                        onClick={backToMenu}
                >
                    <i className="back-btn-ico" />
                </button>
                <h4 className="window-poi-title">{ (window as any).lang.getString('userLocationInformation_parkingStickerTitle') }</h4>
            </div>
            <button className="btn btn-default srch-close-btn "
                    type="button"
                    onClick={closeSidebar}
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        {poi ?
        <div className="window-content">
            <div className="parking-top">
                <i className="my-parking-ico"/>
                <p>
                    <span className="parking-header">{(window as any).lang.getString('myParking_YouAreParkedIn')}</span>
                    <a href="/#" onClick={(e) => openParkingInfo(e)} className="parking-descr">{poi.description}</a>
                </p>
            </div>
            <div className="parking-buttons" ref={parkingBtn}>
                <button type="button"
                        className="parking-delete-btn"
                        onClick={() => deleteParking()}
                >
                    <span>{(window as any).lang.getString('myParking_deleteParking')}</span>
                </button>
                <button type="button"
                        className="go-to-parking-btn"
                        onClick={() => goToParking()}
                >
                    <span>{(window as any).lang.getString('myParking_goToParking')}</span>
                    <i />
                </button>
            </div>
        </div>
    : (
        <div className="window-content">
            <div className="parking-top">
                <i className="no-parking-ico"/>
                <p>
                    <span>
                        {(window as any).lang.getString('myParking_noSavedParking')}
                    </span>
                </p>
            </div>
            <div className="parking-buttons" ref={parkingBtn}>
                <button type="button"
                        className="all-btn"
                        onClick={() => seeAllParking()}
                >
                    <span>{(window as any).lang.getString('myParking_seeAllParking')}</span>
                </button>
            </div>
        </div>
        )}
        <DeleteParking project={project}
                       pois={pois}
                       changeFloor={changeFloor}
                       categories={categories}
        />
    </div>;
}

export default MyParking;
