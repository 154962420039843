import { IPoi, IProject, IOccupancy } from "../interfaces";

class Occupancy implements IOccupancy {
    public project: IProject | null;

    public polygons: any;

    public occupancyMap: any;

    public fakeEvents: any;

    public pois: IPoi [];

    constructor(_pois: IPoi []) {
        this.project = null;
        this.polygons = null;
        this.occupancyMap = {};
        this.fakeEvents = {};
        this.pois = _pois;
    }

    /*
     *	Main Starter function
     */
    start(polygonObj: any, occupancyPois: any) {
        this.polygons = polygonObj;
        return this.updateOccupancyMap(occupancyPois);
    }

    setProject(_project: IProject) {
        this.project = _project;
    }

    getStatus(poid: string) {
        return this.occupancyMap[poid];
    }

    getOccupancyPOIs() {
        return this.occupancyMap;
    }

    updateOccupancyMap(occupancyPois: IPoi []) {
        // Change Marker Colors based on occupancy data
        for (let i: number = 0; i < occupancyPois.length; i++) {
            this.occupancyMap[occupancyPois[i].poid] = occupancyPois[i];
        }
    }

    addPersonToSearch(words: string [], poi: IPoi, mainSearch: boolean): string {
        mainSearch = mainSearch || false;

        const poiOccupancy = this.getStatus(poi.poid);
        if (poiOccupancy && poiOccupancy.occupied) {
            if (poiOccupancy.username !== null) {
                if (mainSearch) return poiOccupancy.username.toLocaleLowerCase();

                words = words.concat(poiOccupancy.username.split(" "));
            } else if (poiOccupancy.profile !== null) {
                if (mainSearch) {
                    const name = `${poiOccupancy.profile.firstName} ${poiOccupancy.profile.lastName}`;
                    return name.toLocaleLowerCase();
                }

                if (poiOccupancy.profile.firstName !== null) {
                    words.push(poiOccupancy.profile.firstName);
                }

                if (poiOccupancy.profile.lastName !== null) {
                    words.push(poiOccupancy.profile.lastName);
                }
            }
        }

        return words.join(" ");
    }

    searchByPerson(temp: IPoi [], poi: IPoi, searchStr: string): IPoi [] {
        const poiOccupancy = this.getStatus(poi.poid);
        if (!poiOccupancy && !poiOccupancy?.occupied) {
            return temp;
        }

        if (poiOccupancy.username && poiOccupancy.username.toLowerCase().indexOf(searchStr) !== -1) {
            temp.push(poi);
        } else if (poiOccupancy.profile?.firstName && poiOccupancy.profile.firstName.toLowerCase().indexOf(searchStr) !== -1) {
                    temp.push(poi);
        }

        if (poiOccupancy.profile?.lastName && poiOccupancy.profile.lastName.toLowerCase().indexOf(searchStr) !== -1) {
                temp.push(poi);
        }

        return temp;
    }
}

export default Occupancy;
