import React, { FC } from 'react';
import loader from '@assets/images/others/loader-1.gif';

import '../Loader/loader.scss';
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";

export interface IPrintLoader {

}

const PrintLoader: FC<IPrintLoader> = () => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        Emitter.on(Events.GENERATE_PDF_LOADER, (value) => {
            setLoading(value);
        });
    }, [setLoading]);

    return loading ? (
            <div className="tab-overlay-desktop map">
                <img src={ loader } className='overlay-spin map loader-width' alt="loader" />
                <p id="printing" className="print-load">
                    {(window as any).lang.getString('pdf_printLoad')}
                </p>
            </div>
    ) : null;
};

export default PrintLoader;
