import React, { FC, PropsWithChildren } from 'react';
import NavigateUI from "../../Navigate";
import { NavigateModes, TabModes } from "../../../constants";
import { IBuilding, IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../../interfaces';
import Instructions from "../../Instructions";
import SearchNavigate from "../../SearchNavigate";

interface  INavigate {
    project: IProject;
    buildings: IBuilding [];
    categories: IPoiCategory [];
    poisCategories: IPOIsCategories;
    pois: IPoi [];
    changeFloor: (floor: number) => void;
    poidMap: IPoi [];
    tabData: any;
    tabMode: string;
    floor: number;
    facilityNames?: any;
}

const Navigate: FC<INavigate> = (props: PropsWithChildren<INavigate>) => {
    const {
        project,
        buildings,
        categories,
        poisCategories,
        pois,
        changeFloor,
        poidMap,
        tabMode,
        tabData,
        floor,
        facilityNames
    } = props;

    const fromPoi = tabData?.fromPoi || null;
    const toPoi = tabData?.toPoi || null;
    const navigatePoint = tabData?.navigatePoint || '';
    const navObject = tabData?.navObject || null;
    const referrer = tabData?.referrer || null;

    let navigateContext;
    switch(tabMode) {
        case TabModes.OPEN_NAVIGATE_UI:
            navigateContext = <NavigateUI project={project}
                                          categories={categories}
                                          poisCategories={poisCategories}
                                          pois={pois}
                                          floor={floor}
                                          changeFloor={changeFloor}
                                          navMode={NavigateModes.SELECT_POINTS}
                                          poidMap={poidMap}
                                          fromPoi={fromPoi}
                                          toPoi={toPoi}
                                          tabData={tabData}
                                          facilityNames={facilityNames}
                                          referrer={referrer}
                              />;
            break;
        case TabModes.OPEN_GO_NOW:
            navigateContext = <NavigateUI project={project}
                                          categories={categories}
                                          poisCategories={poisCategories}
                                          pois={pois}
                                          floor={floor}
                                          changeFloor={changeFloor}
                                          navMode={NavigateModes.GO_NOW}
                                          poidMap={poidMap}
                                          fromPoi={fromPoi}
                                          toPoi={toPoi}
                                          tabData={tabData}
                                          facilityNames={facilityNames}
                                          referrer={referrer}
            />;
            break;
        case TabModes.OPEN_ROUTE:
            navigateContext = <Instructions project={project}
                                            tabData={tabData}
                              />
            break;
        case TabModes.OPEN_NAVIGATE_SEARCH:
            navigateContext = <SearchNavigate project={project}
                                              buildings={buildings}
                                              pois={pois}
                                              tabData={tabData}
                                              changeFloor={changeFloor}
                                              floor={floor}
                                              fromPoi={fromPoi}
                                              toPoi={toPoi}
                                              navigatePoint={navigatePoint}
                                              navObject={navObject}
                                              facilityNames={facilityNames}
                              />
            break;
    }

    return (
        <>
            { navigateContext }
        </>
    );
}

export default Navigate;
