import React, { FC, MutableRefObject } from 'react';
import Modal from "react-bootstrap/Modal";
import Emitter from "../../helpers/emitter";
import { Events } from "../../constants";
import StateStorage from "../../helpers/stateStorage";

import './styles.scss';

export interface ICleanDefaultCampus {

}

const CleanDefaultCampus: FC<ICleanDefaultCampus> = () => {
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);

    const onClose = React.useCallback(() => {
        if (!cleanupFunction.current) setShow(false);
    }, [setShow]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        Emitter.on(Events.OPEN_CLEAN_DEFAULT_CAMPUS, (newValue) => {
            if (!cleanupFunction.current) {
                setShow(newValue);
            }
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        setShow,
        cleanupFunction
    ]);

    const clearDefaultCampus = React.useCallback(() => {
        StateStorage.removeItem(`${localStorage.getItem('username')}`);
        setShow(false);
    }, [setShow]);

    return (
        <Modal show={show} animation={false} centered id="clean_default_campus" onHide={() => false } role="dialog">
            <Modal.Body className="modal-width">
                <div className="campus-modal clean-default-campus">
                    <div className="campus-header">
                        <i className="warning-icon" />
                        <h4 className="text-center">{(window as any).lang.getString('clearDefaultCampus')}</h4>
                    </div>

                    <div className="campus-text">
                        <p>
                            {(window as any).lang.getString('areYouSureYouWantToClear')}
                        </p>
                    </div>

                    <div className="campus-container-btn">
                        <button type="button"
                                onClick={() => clearDefaultCampus()}
                                className="btn clean-btn"
                        >
                            {(window as any).lang.getString('yesClearMyDefaultCampus')}
                        </button>
                    </div>
                    <div className="campus-container-btn">
                        <button type="button"
                                onClick={() => onClose()}
                                className="btn cancel-clean-btn"
                        >
                            {(window as any).lang.getString('noIWantToKeepItAsDefault')}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CleanDefaultCampus;
