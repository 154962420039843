import React, { FC, MutableRefObject, PropsWithChildren } from 'react';
import { tns } from "tiny-slider/src/tiny-slider"
import { Events, TabModes, TabNames, mobile } from "@constants";
import { IPoi, IPoiCategory, IPOIsCategories, IProject } from '../../interfaces';

import "./styles.scss";
import { filterCategory, resetFilter, resetFilterCategory, sortCategories } from '../../helpers/utils';
import Emitter from "../../helpers/emitter";
import { useForceUpdate } from "../../hooks";
import { resetSearch, resetSearchForBack } from "../../helpers/marker";

export interface IScrollCategories {
    categories: IPoiCategory [];
    project: IProject;
    floor: number;
    changeFloor: (floor: number) => void;
    pois: IPoi [];
    poisCategories: IPOIsCategories;
}

const ScrollCategories: FC<IScrollCategories> = (props: PropsWithChildren<IScrollCategories>) => {
    const {
        categories,
        project,
        floor,
        changeFloor,
        pois,
        poisCategories
    } = props;

    const expand = React.useRef(false);
    const categoryBar: MutableRefObject<any> = React.useRef(null);
    const categoryPicker: any = React.useRef(null);
    const total: any = React.useRef(0);
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const forceUpdate: any = useForceUpdate(cleanupFunction);
    const currentCategory: any = React.useRef(null);
    const mounted: any = React.useRef(false);
    const [currentIndex, setCurrentIndex] = React.useState(-1);
    const [update, setUpdate] = React.useState(false);

    const highlightCategory = React.useCallback((cname: string, index: number) => {
        filterCategory(cname);
        currentCategory.current = cname;
        setCurrentIndex(index - 1);
        resetSearch();

        Emitter.emit(Events.COLLAPSE_CATEGORY_BAR, true);
        if(!mobile) {
            categoryPicker.current.goTo(index - 3);
        }
    }, [
        currentCategory,
        categoryPicker,
        setCurrentIndex
    ]);

    const selectCategory = React.useCallback((
        e: any,
        cname: string,
        cid: number,
        index: number,
        category?: IPoiCategory
    ) => {
        resetSearchForBack();
        resetFilter(project);
        highlightCategory(cname, index);

        Emitter.emit(Events.RESET_FLOORS_MAP, null);
        if (mobile) {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.CATEGORIES,
                tabMode: TabModes.OPEN_CATEGORY_POI_LIST,
                tabData: {
                    categories,
                    poisCategories,
                    project,
                    floor,
                    changeFloor,
                    pois,
                    cid,
                    cname,
                    fromHome: true,
                    category,
                    tabMode: TabModes.OPEN_CATEGORY_POI_LIST
                },
                tabBackButton: false,
                tabCloseButton: false,
            });
            setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);
        } else {
            Emitter.emit(Events.OPEN_TAB, {
                tabName: TabNames.FIND,
                tabMode: TabModes.FILTER_MARKERS_BY_CATEGORY,
                tabData: {
                    cname,
                    category
                },
                tabBackButton: false,
                tabCloseButton: true,
                tabFilterButton: false
            });
        }
    }, [
        project,
        floor,
        changeFloor,
        pois,
        highlightCategory,
        categories,
        poisCategories
    ]);

    const unselectCategory = React.useCallback(() => {
        resetFilterCategory();
        currentCategory.current = null;
        setCurrentIndex(-1);

        if (mobile) {
            Emitter.emit(Events.EXIT_THIRD_SCREEN, null);
            setTimeout(() => Emitter.emit(Events.RECALCULATE_FLOATING_PANEL_POSITION, null), 50);
        }
    }, [
        currentCategory,
        setCurrentIndex
    ]);

    const toggleCategory = React.useCallback((
        e: any,
        cname: string,
        cid: number,
        index: number,
        category?: IPoiCategory
    ) => {
        if (!currentCategory.current || currentCategory.current !== cname) {
            selectCategory(e, cname, cid, index, category);
        } else {
            unselectCategory();
            Emitter.emit(Events.CLOSE_TAB, null);
        }
    }, [
        currentCategory,
        selectCategory,
        unselectCategory
    ]);

    const renderCategories = React.useCallback(() => {
        const data: IPoiCategory [] = sortCategories(categories, poisCategories, project, true);

        total.current = data.length;

        if (!total.current) return null;

        return data.map((category: IPoiCategory, index: number) => (
                  <div className={
                      currentIndex === index ?
                        "category-btn tns-item tns-slide-active selected-category" :
                        "category-btn tns-item tns-slide-active"
                  }
                       aria-hidden="true"
                       key={category.id}
                       onClick={(e) => toggleCategory(e, category.name, category.id, index + 1, category)}
                       role="button"
                       onKeyPress={(e) => e.preventDefault()}
                       tabIndex={0}
                  >
                      <span>{category.name}</span>
                  </div>
                ));
    }, [
        categories,
        poisCategories,
        project,
        total,
        toggleCategory,
        currentIndex
    ]);

    const wheelHandler = React.useCallback((event: any) => {
        event.preventDefault();

        if (event.deltaY < 0) {
            categoryPicker.current.goTo("next");
        } else {
            categoryPicker.current.goTo("prev");
        }

        return true;
    }, [categoryPicker]);

    const calculateContainerWidth = React.useCallback(() => {
        const categoriesBtn: any = document.querySelector('.categories-btn');
        let w: any = 0;
        const width: any = $(document).width();
        const categoryButtons: any = document.querySelectorAll('.category-btn');

        for (let n = 0, len = categoryButtons.length; n < len; n += 1) {
            w += parseFloat($(categoryButtons[n]).css("width"));
        }

        if (mobile) {
            w += width;
        } else {
            w = !expand.current ? w + width/4 : w - width/3 - 150;
        }
        categoriesBtn.style.width = `${w}px`;
    }, [expand]);

    const toggleCategoryBar = React.useCallback(() => {
        if (mobile) {
            categoryPicker.current.goTo('next');
            return;
        }

        if(!mobile) {
            resetFilter(project);
        }

        const bar: any = document.querySelector(".tns-outer");
        if (!expand.current) {
            Emitter.emit(Events.CLOSE_TAB, true);

            let w: any = jQuery(window).width();
            w -= 100;
            jQuery(bar).animate({
               width: `${w}px`
            }, 500);

            unselectCategory();
        } else {
            jQuery(bar).animate({
                width: '320px'
            }, 500);
        }

        expand.current = !expand.current;
        calculateContainerWidth();

        forceUpdate();
    }, [
        expand,
        forceUpdate,
        unselectCategory,
        calculateContainerWidth,
        project
    ]);

    const HandleOpenTab = React.useCallback((tabData: any) => {
        if (mobile && ([
            TabNames.FAVORITES
        ].includes(tabData.tabName) ||
            (
                tabData.tabName === TabNames.CATEGORIES &&
                tabData.tabMode === TabModes.OPEN_CATEGORY_POI_LIST
            ) || (
                tabData.tabName === TabNames.BUILDINGS &&
                tabData.tabMode === TabModes.OPEN_BUILDING_POI_LIST
            ))) {
                categoryBar.current.style.display = 'flex';
        }
    }, []);

    const changeSize = React.useCallback(() => {
        let width: any = $(document).width();
        if (mobile) {
            width -= 60;
        } else {
            width = 320;
        }
        $(".categories-btn-container > .tns-outer").css('width', `${width}px`);
    }, []);

    React.useEffect(() => {
        cleanupFunction.current = false;

        // add css class for third-screen mode
        Emitter.on(Events.OPEN_TAB, (tabData) => HandleOpenTab(tabData));

        if (!categoryPicker.current && total.current) {
            categoryPicker.current = tns({
                container: ".categories-btn",
                items: 3,
                slideBy: 1,
                axis: "horizontal",
                center: false,
                controls: false,
                nav: false,
                loop: false,
                gutter: 5,
                mouseDrag: true,
                preventScrollOnTouch: 'auto',
                onInit: () => {
                    setTimeout(() => {
                        calculateContainerWidth();
                    }, 500);
                },
            });

            document.querySelector(".categories-btn-container .tns-outer")?.addEventListener("wheel", (event: any) => {
                wheelHandler(event);
            }, false);

            window.onresize = () => {
                expand.current = false;
                forceUpdate();
                changeSize();

                if (mobile) calculateContainerWidth();
            }
            changeSize();
        }

        if(!mounted.current) {
            Emitter.on(Events.COLLAPSE_CATEGORY_BAR, () => {
                if (expand.current) {
                    toggleCategoryBar();
                }
            });

            Emitter.on(Events.HIDE_CATEGORY_BAR, () => {
                if (categoryBar.current && categoryBar.current.style) {
                    categoryBar.current.style.display = 'none';
                }
            });

            Emitter.on(Events.SHOW_CATEGORY_BAR, () => {
                if (categoryBar.current && categoryBar.current.style) {
                    categoryBar.current.style.display = 'flex';
                }
            });

            Emitter.on(Events.SELECT_CATEGORY, (data: any) => {
                highlightCategory(data.cname, data.index + 1);
            });

            Emitter.on(Events.UNSELECT_CATEGORY, () => {
                unselectCategory();
            });

            Emitter.on(Events.SCROLL_TO_FIRST, () => {
                if(categoryPicker && categoryPicker.current) categoryPicker.current.goTo(0);
            });

            mounted.current = true;
        }

        Emitter.on(Events.SCROLL_CATEGORIES_UPDATE, () => {
            if (!cleanupFunction.current) {
                sortCategories(categories, poisCategories, project, true);
                setUpdate(!update);
            }
        });

        return () => {
            cleanupFunction.current = true;
        }
    }, [
        wheelHandler,
        toggleCategoryBar,
        categoryPicker,
        expand,
        unselectCategory,
        calculateContainerWidth,
        changeSize,
        categoryBar,
        highlightCategory,
        HandleOpenTab,
        forceUpdate,
        update,
        setUpdate,
        categories,
        poisCategories,
        project
    ]);

    const data: IPoiCategory [] = sortCategories(categories, poisCategories, project, true);

    return (
            <div className="categories-btn-container" ref={categoryBar}>
                <div className="categories-btn">
                    { renderCategories() }
                </div>
                {data.length ? <div className="expand-container">
                    <span className="expand-categories-btn"
                          role="button"
                          onKeyPress={(e) => e.preventDefault()}
                          tabIndex={0}
                          aria-label="expand"
                          onClick={() => toggleCategoryBar()}
                    >
                        {!expand.current ? <i className="fas fa-chevron-right" /> : <i className="fas fa-chevron-left" /> }
                    </span>
                </div> : null}
            </div>
        );
}

export default ScrollCategories;
