import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login, ProjectList, Project, Loader } from '@pages';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDrAwG0oNu6FJSJcQf4-rzuxQjfgZBGL8c",
  authDomain: "web-app-25f78.firebaseapp.com",
  projectId: "web-app-25f78",
  storageBucket: "web-app-25f78.appspot.com",
  messagingSenderId: "656817994407",
  appId: "1:656817994407:web:20b93e8cbabb8e1fcd7982",
  measurementId: "G-X1VYPZDB93"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getPerformance(app);

const RoutesComponent: React.FC = () => (
  <>
    <Loader />
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/project-list" component={ProjectList} />
      <Route exact path="/project-list/:projectId" component={Project} />
      <Route exact path="*" component={Login} />
    </Switch>
  </>
);

export default RoutesComponent;
